/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { router } from 'expo-router'
import isEqual from 'lodash/isEqual'
import numeral from 'numeral'
import { useState } from 'react'
import { Toast } from 'react-native-toast-universal'
import { states } from 'shared'
import { Button, ButtonGroup, Checkbox, colorWithBrightness, colorWithOpacity, MultiSelect, Panel, Select, Spacing, Table, Text, useTheme, Wrapper } from 'ui'
import { Head } from '@/components/web/Head'
import { NavButtons } from '@/components/web/NavButtons'
import { Constants } from '@/constants'
import { defaultAccidentFilter, useStore } from '@/providers/store'
import { useAccidents, useAccidentsExport } from '@/queries/accident.queries'
import { useConfig } from '@/queries/config.queries'
import { useLawFirmContacts, useLawFirms } from '@/queries/law-firm.queries'
import { useLocations, useProviders } from '@/queries/provider.queries'
import { useSaveAccidentFilter, useUser, useUsers } from '@/queries/user.queries'
import { AccidentExportEntity, AccidentExportReplacements, AccidentExportType, AccidentListItem, AccidentStatusSchema } from '@/types/accident'
import { AccidentFilter } from '@/types/filter'
import { statusers } from '@/types/user'
import { format } from '@/utils'

export function Accidents() {
  const theme = useTheme()
  const filter = useStore.useAccidentFilter()
  const setFilter = useStore.useSetAccidentFilter()

  const user = useUser()
  const accidents = useAccidents(filter)
  const saveAccidentFilter = useSaveAccidentFilter()

  function updateFilter(filter: AccidentFilter) {
    setFilter(filter)
    saveAccidentFilter.mutate(filter)
  }

  const styles = {
    wrapper: css({
      display: 'flex',
    }),
    content: css({
      flex: 1,
      margin: `${theme.spacing * 1.5}px ${theme.spacing}px`,
      overflowX: 'auto',
      [`@media (min-width: ${theme.breakpoints.m}px)`]: {
        margin: theme.spacing * 1.5,
      },
    }),
  }

  return <>
    <Head title='Cases' />

    <div css={styles.wrapper}>
      <Sidebar onUpdateFilter={updateFilter} />

      <div css={styles.content}>
        <Wrapper maxWidth={user.data?.role === 'provider' ? 1400 : 1800} margin={false}>
          <Header
            count={accidents.data ? accidents.data.accidents.length : undefined}
            total={accidents.data ? accidents.data.total : undefined}
            onUpdateFilter={updateFilter}
          />
          <Panel>
            <AccidentsTable
              accidents={accidents.data?.accidents ?? []}
              loading={accidents.isPending}
              onUpdateFilter={updateFilter}
            />
          </Panel>
        </Wrapper>
      </div>
    </div>
  </>
}

function Header({ count: propCount, total: propTotal, onUpdateFilter }: {
  count: number | undefined
  total: number | undefined
  onUpdateFilter: (filter: AccidentFilter) => void
}) {
  const theme = useTheme()
  const modal = useStore.useModal()
  const filter = useStore.useAccidentFilter()

  const user = useUser()

  const [count, setCount] = useState(propCount)
  const [prevCount, setPrevCount] = useState(propCount)
  if (propCount !== prevCount && propCount !== undefined) {
    setCount(propCount)
    setPrevCount(propCount)
  }
  const [total, setTotal] = useState(propTotal)
  const [prevTotal, setPrevTotal] = useState(propTotal)
  if (propTotal !== prevTotal && propTotal !== undefined) {
    setTotal(propTotal)
    setPrevTotal(propTotal)
  }

  const offset = (filter.page - 1) * filter.show

  const styles = {
    wrapper: css({
      background: theme.colors.pageBackground,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: theme.spacing,
      marginBottom: theme.spacing,
    }),
    heading: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing,
      rowGap: theme.spacing * 0.2,
      flexWrap: 'wrap',
    }),
    info: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      opacity: 0.8,
    }),
    buttons: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing * 0.5,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.heading}>
        <Text type='heading'>Cases</Text>
        <div css={styles.info}>
          {count !== undefined ? `Showing ${count > 1 ? `${offset + 1} - ${Math.min(offset + count, total ?? 0)}` : count}` : ''}
          {(count !== undefined && total !== undefined && total > count) ? ` (of ${numeral(total).format()})` : ''}
        </div>
      </div>

      <div css={styles.buttons}>
        {user.data?.role !== 'provider' && (
          <Button
            text='Export'
            type='tertiary'
            compact={true}
            textLeft={(
              <FontAwesomeIcon
                icon={['far', 'file-export']}
                size={15}
                color={colorWithOpacity(theme.colors.foreground, 0.9)}
                style={{ marginRight: 7 }}
              />
            )}
            onPress={() => modal.open({
              title: 'Export',
              content: <Export />,
            })}
          />
        )}
        <NavButtons
          prev={{
            disabled: filter.page === 1,
            onClick: () => onUpdateFilter({ ...filter, page: filter.page - 1 }),
          }}
          next={{
            disabled: count === undefined || total === undefined ? true : offset + count >= total,
            onClick: () => onUpdateFilter({ ...filter, page: filter.page + 1 }),
          }}
        />
      </div>
    </div>
  )
}

function Export() {
  const theme = useTheme()
  const modal = useStore.useModal()
  const filter = useStore.useAccidentFilter()

  const accidentsExport = useAccidentsExport()

  const [entity, setEntity] = useState<AccidentExportEntity>('accident')
  const [type, setType] = useState<AccidentExportType>('current')
  const [replacements, setReplacements] = useState<AccidentExportReplacements>('exclude')

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <ButtonGroup
        label='Type'
        fullWidth={true}
        compact={true}
        buttons={[
          {
            text: 'Cases',
            active: entity === 'accident',
            onPress: () => setEntity('accident'),
          },
          {
            text: 'Accounts',
            active: entity === 'account',
            onPress: () => setEntity('account'),
          },
        ]}
      />
      <Spacing height={1} />
      <ButtonGroup
        label='Columns'
        fullWidth={true}
        compact={true}
        buttons={[
          {
            text: 'Current',
            active: type === 'current',
            onPress: () => setType('current'),
          },
          {
            text: 'All',
            active: type === 'full',
            onPress: () => setType('full'),
          },
        ]}
      />
      <Spacing height={1} />
      <ButtonGroup
        label='Replacement Cases'
        fullWidth={true}
        compact={true}
        buttons={[
          {
            text: 'Exclude',
            active: replacements === 'exclude',
            onPress: () => setReplacements('exclude'),
          },
          {
            text: 'Include',
            active: replacements === 'include',
            onPress: () => setReplacements('include'),
          },
        ]}
      />
      <Spacing height={1.4} />
      <Button
        text='Export'
        type='primary'
        loading={accidentsExport.isPending}
        onPress={() => {
          const exportParams = { entity, type, replacements }
          accidentsExport.mutate({ filter, exportParams }, {
            onSuccess: (res) => {
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(new Blob([res.data]))
              link.setAttribute('download', `${entity === 'accident' ? 'cases' : 'accounts'}-export.xlsx`)
              document.body.appendChild(link)
              link.click()
              link.remove()
              Toast.success(`${entity === 'accident' ? 'Cases' : 'Accounts'} exported`)
              modal.close()
            },
            onError: () => Toast.error('Unable to export cases'),
          })
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function AccidentsTable({ accidents, loading, onUpdateFilter }: {
  accidents: AccidentListItem[]
  loading: boolean
  onUpdateFilter: (filter: AccidentFilter) => void
}) {
  const filter = useStore.useAccidentFilter()
  const clearFilter = useStore.useClearAccidentFilter()

  const user = useUser()
  const saveAccidentFilter = useSaveAccidentFilter()

  return (
    <Table
      columns={[
        {
          data: 'provider',
          title: 'Provider',
          width: 230,
          hidden: user.data?.role === 'provider' && user.data.providers.length === 1,
        },
        {
          data: 'name',
          title: 'Name',
          width: 220,
          sortable: true,
        },
        {
          data: 'status',
          title: 'Status',
          width: user.data?.role === 'provider' ? 130 : 230,
          sortable: user.data?.role !== 'provider',
          style: { whiteSpace: 'nowrap' },
        },
        {
          data: 'lastContact',
          title: 'Last Contact',
          width: 140,
          sortable: true,
        },
        {
          data: 'nextContact',
          title: 'Next Contact',
          width: 140,
          sortable: true,
        },
        {
          data: 'lawFirm',
          title: 'Law Firm',
          width: 250,
          sortable: true,
        },
        {
          data: 'target',
          title: 'Target',
          width: 122,
          alignRight: true,
          sortable: true,
          hidden: user.data?.role === 'provider',
        },
        {
          data: 'invoices',
          title: 'Invoices',
          width: 122,
          alignRight: true,
          sortable: true,
        },
        {
          data: 'collected',
          title: 'Collected',
          width: 122,
          alignRight: true,
          sortable: true,
          hidden: user.data?.role === 'provider',
        },
        {
          data: 'cost',
          title: 'Cost',
          width: 122,
          alignRight: true,
          sortable: true,
          hidden: user.data?.role === 'provider',
        },
      ]}
      data={accidents.map((accident) => {
        const providers = accident.accounts.filter((a) => {
          return user.data?.role === 'provider' ? user.data.providers.map((p) => p.id).includes(a.provider.id) : a
        }).map((a) => a.provider.name)

        return {
          id: accident.id,
          provider: providers.join(', '),
          name: accident.patient.fullName,
          status: user.data?.role === 'provider' ? (accident.open ? 'Open' : 'Settled') : accident.status,
          lastContact: accident.lastContact ? format.date(accident.lastContact) : '',
          nextContact: accident.nextContact ? format.date(accident.nextContact) : '',
          lawFirm: accident.lawFirm?.name ?? '',
          target: accident.target != null ? format.currency(accident.target) : '',
          invoices: accident.invoices != null ? format.currency(accident.invoices) : '',
          collected: accident.collected != null ? format.currency(accident.collected) : '',
          cost: accident.cost != null ? format.currency(accident.cost) : '',
        }
      })}
      loading={loading}
      noDataMessage='No cases found'
      noDataButton={(
        <Button
          text='Clear Filters'
          type='tertiary'
          compact={true}
          onPress={() => {
            const cleared = clearFilter(filter)
            saveAccidentFilter.mutate(cleared)
          }}
        />
      )}
      fixedLayout={true}
      stickyHeader={true}
      orderBy={filter.orderBy}
      sortOrder={filter.sortOrder}
      onUpdateSort={({ orderBy, sortOrder }) => onUpdateFilter({ ...filter, orderBy, sortOrder, page: 1 })}
      onRowClick={(row) => router.navigate(`/cases/${row.id}`)}
    />
  )
}

function Sidebar({ onUpdateFilter }: { onUpdateFilter: (filter: AccidentFilter) => void }) {
  const theme = useTheme()
  const filter = useStore.useAccidentFilter()

  const user = useUser()
  const users = useUsers()
  const locations = useLocations()
  const providers = useProviders(filter.locations)
  const lawFirms = useLawFirms(filter.locations, filter.providers)
  const lawFirmContacts = useLawFirmContacts(filter.lawFirms)
  const clearFilter = useStore.useClearAccidentFilter()
  const saveAccidentFilter = useSaveAccidentFilter()

  const styles = {
    sidebar: css({
      width: 320,
      height: '100%',
      minHeight: `calc(100vh - ${Constants.headerHeight}px)`,
      background: theme.colors.headerBackground,
      borderRight: `1px solid ${theme.colors.panelBorder}`,
      padding: `${theme.spacing * 1.5}px ${theme.spacing}px`,
      display: 'none',
      flexDirection: 'column',
      rowGap: theme.spacing,
      [`@media (min-width: ${theme.breakpoints.m}px)`]: {
        display: 'flex',
      },
    }),
    divider: css({
      height: 1,
      background: theme.colors.panelBorder,
      margin: `0 -${theme.spacing}px`,
    }),
  }

  return (
    <div css={styles.sidebar}>
      {user.data?.role !== 'provider' && (
        <MultiSelect
          label='State'
          value={filter.locations}
          options={locations.data?.map((l) => ({ value: l, label: states[l] })) ?? []}
          placeholder='Select state(s)'
          noOptions='State not found'
          compact={true}
          onChange={(value) => onUpdateFilter({ ...filter, locations: value, providers: [], lawFirms: [], lawFirmContacts: [], page: 1 })}
        />
      )}
      {(user.data?.role !== 'provider' || (providers.data && providers.data?.length > 1)) && (
        <MultiSelect
          label='Provider'
          value={filter.providers}
          options={providers.data?.map((p) => ({ value: p.id.toString(), label: p.name })) ?? []}
          placeholder='Select provider(s)'
          noOptions='Provider not found'
          compact={true}
          onChange={(value) => onUpdateFilter({ ...filter, providers: value, lawFirms: [], lawFirmContacts: [], page: 1 })}
        />
      )}
      <MultiSelect
        label='Law Firm'
        value={filter.lawFirms}
        options={lawFirms.data?.map((l) => ({ value: l.id.toString(), label: l.name })) ?? []}
        placeholder='Select law firm(s)'
        noOptions='Law firm not found'
        compact={true}
        onChange={(value) => onUpdateFilter({ ...filter, lawFirms: value, lawFirmContacts: [], page: 1 })}
      />
      <MultiSelect
        label='Law Firm Contact'
        value={filter.lawFirmContacts}
        options={lawFirmContacts.data?.map((c) => ({ value: c.id.toString(), label: c.displayName })) ?? []}
        placeholder='Select law firm contact(s)'
        noOptions='No contacts found'
        compact={true}
        disabled={filter.lawFirms.length === 0}
        onChange={(value) => onUpdateFilter({ ...filter, lawFirmContacts: value, page: 1 })}
      />
      {user.data?.role === 'provider' ? (
        <Select
          label='Status'
          value={filter.openSettled}
          options={['All Cases', 'Open', 'Settled']}
          compact={true}
          onChange={(value) => onUpdateFilter({ ...filter, openSettled: value, page: 1 })}
        />
      ) : (
        <MultiSelect
          label='Status'
          value={filter.statuses}
          options={AccidentStatusSchema.options}
          placeholder='Select status(es)'
          noOptions='Status not found'
          compact={true}
          onChange={(value) => onUpdateFilter({ ...filter, statuses: value, page: 1 })}
        />
      )}
      <Checkbox
        label='Documents'
        text='Missing Documents'
        value={filter.missingDocuments}
        compact={true}
        onChange={(value) => onUpdateFilter({ ...filter, missingDocuments: value, page: 1 })}
      />
      {user.data?.role !== 'provider' && (
        <MultiSelect
          label='Assigned Staff'
          value={filter.statusers}
          options={statusers(users.data ?? []).map((u) => ({ value: u.id.toString(), label: u.fullName ?? '' }))}
          placeholder='Select staff'
          noOptions='Staff not found'
          compact={true}
          onChange={(value) => onUpdateFilter({ ...filter, statusers: value, page: 1 })}
        />
      )}
      {user.data?.role !== 'provider' && (
        <Tags onUpdateFilter={onUpdateFilter} />
      )}
      <div css={styles.divider} />
      <Button
        text='Clear Filters'
        type='tertiary'
        compact={true}
        disabled={isEqual(filter, defaultAccidentFilter)}
        onPress={() => {
          const cleared = clearFilter(filter)
          saveAccidentFilter.mutate(cleared)
        }}
      />
    </div>
  )
}

function Tags({ onUpdateFilter }: { onUpdateFilter: (filter: AccidentFilter) => void }) {
  const TAG_HEIGHT = 22

  const theme = useTheme()
  const filter = useStore.useAccidentFilter()

  const config = useConfig()

  const styles = {
    wrapper: css({
      background: colorWithBrightness(theme.colors.headerBackground, -7),
      boxShadow: `inset 1px 0 3px ${colorWithOpacity('#000000', 0.1)}`,
      margin: `0 -${theme.spacing}px`,
      padding: theme.spacing,
      display: 'flex',
      flexWrap: 'wrap',
      gap: 7,
    }),
    tag: css({
      height: TAG_HEIGHT,
      borderRadius: TAG_HEIGHT,
      lineHeight: `${TAG_HEIGHT}px`,
      padding: '0 9px',
      color: '#FFFFFF',
      fontFamily: theme.fonts.body[600],
      fontSize: 10,
      letterSpacing: 0.2,
      textTransform: 'uppercase',
      cursor: 'pointer',
    }),
  }

  return (
    <div css={styles.wrapper}>
      {config.data?.tags.map((tag) => (
        <div
          key={tag.name}
          css={styles.tag}
          style={{
            background: tag.color,
            opacity: filter.tags.includes(tag.name) ? 1 : 0.5,
          }}
          onClick={() => {
            let newTags = [...filter.tags]
            if (newTags.includes(tag.name)) {
              newTags = newTags.filter((t) => t !== tag.name)
            } else {
              newTags.push(tag.name)
            }
            onUpdateFilter({ ...filter, tags: newTags, page: 1 })
          }}
        >
          {tag.name}
        </div>
      ))}
    </div>
  )
}
