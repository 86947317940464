import { z } from 'zod'
import { AccidentFilterSchema, BatchFilterSchema } from './filter'
import { ProviderSchema } from './provider'

export const UserRoleSchema = z.enum(['staff', 'manager', 'executive', 'provider'])

export const UserRoleSelectSchema = z.enum(['Staff', 'Market Manager', 'Executive', 'Provider - Staff', 'Provider - Submitter'])

export const UserStatusSelectSchema = z.enum(['Active', 'Inactive'])

export const UserSchema = z.object({
  id: z.number(),
  providers: ProviderSchema.array(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  fullName: z.string().nullable(),
  email: z.string(),
  role: UserRoleSchema,
  photoUrl: z.string().nullable(),
  filter: z.object({
    accident: AccidentFilterSchema.optional(),
    batch: BatchFilterSchema.optional(),
  }).nullable(),
  canSubmit: z.boolean(),
  active: z.boolean(),
})

export type UserRole = z.infer<typeof UserRoleSchema>
export type UserRoleSelect = z.infer<typeof UserRoleSelectSchema>
export type UserStatusSelect = z.infer<typeof UserStatusSelectSchema>
export type User = z.infer<typeof UserSchema>

export function statusers(users: User[]) {
  return users.filter((u) => {
    const managerStatusers = ['montana@lienstar.com', 'brad@lienstar.com', 'tyson@lienstar.com']
    return (u.role === 'staff' && u.active) || managerStatusers.includes(u.email)
  })
}
