import * as WebBrowser from 'expo-web-browser'
import { Platform } from 'react-native'
import { useEvent, useHandler } from 'react-native-reanimated'

export function launchUrl(url: string, params?: { fullScreen?: boolean }): void {
  if (Platform.OS === 'web') {
    window.open(url, '_blank')
  } else {
    WebBrowser.openBrowserAsync(url, {
      presentationStyle: params?.fullScreen
        ? WebBrowser.WebBrowserPresentationStyle.FULL_SCREEN
        : WebBrowser.WebBrowserPresentationStyle.AUTOMATIC,
    })
  }
}

export function usePagerScrollHandler(handlers: any, dependencies?: any) {
  const { context, doDependenciesDiffer } = useHandler(handlers, dependencies)

  return useEvent<any>(
    (event) => {
      'worklet'
      const { onPageScroll } = handlers
      if (onPageScroll && event.eventName.endsWith('onPageScroll')) {
        onPageScroll(event, context)
      }
    },
    ['onPageScroll'],
    doDependenciesDiffer,
  )
}
