import Svg, { Path, SvgProps } from 'react-native-svg'
import { useColorScheme } from '../hooks/useColorScheme'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

export function AppleLogo(props: SvgProps) {
  const colorScheme = useColorScheme()
  return (
    <Svg width={19} height={19} viewBox='0 0 18 18' {...props}>
      <Path
        fill={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
        d='M12.2464939,9.5632692 C12.2690488,12.2924098 14.6373113,13.194605 14.6598661,13.2058824 C14.6373113,13.273547 14.2764332,14.502788 13.4193477,15.7658613 C12.6637592,16.8710504 11.8856159,17.9536847 10.6563749,17.9762396 C9.44968881,17.9987945 9.06625581,17.2657609 7.69040811,17.2657609 C6.30328301,17.2657609 5.88601771,17.9536847 4.73571881,17.9987945 C3.55158761,18.0439042 2.64939241,16.8146632 1.89380391,15.7207516 C0.348794605,13.4878184 -0.835336595,9.4053851 0.754782405,6.6536897 C1.51039531,5.2978377 2.92473131,4.4406644 4.47633771,4.3982017 C5.63791401,4.3756468 6.73182571,5.1876225 7.45358191,5.1876225 C8.16406061,5.1876225 9.48352111,4.2064852 10.8932011,4.3530919 C11.479628,4.3756468 13.1261343,4.5899181 14.1862137,6.1349274 C14.1072716,6.1913146 12.2239391,7.2852263 12.2464939,9.5632692 L12.2464939,9.5632692 Z M9.97972851,2.8757472 C10.6112651,2.1088813 11.0285304,1.0488019 10.915756,0 C10.0135608,0.0338323 8.90837171,0.6089818 8.26555761,1.3645703 C7.67913071,2.0412167 7.17164591,3.1125735 7.30697521,4.150098 C8.32194481,4.2290401 9.34819181,3.6313357 9.97972851,2.8757472'
      />
    </Svg>
  )
}

export function GoogleLogo(props: SvgProps) {
  return (
    <Svg width={17} height={17} viewBox='0 0 17 17' {...props}>
      <Path
        fill='#4285F4'
        d='M16.66 8.7c0-.61-.05-1.19-.15-1.74H8.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24Z'
      />
      <Path
        fill='#34A853'
        d='M8.5 17a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H.9v2.2A8.5 8.5 0 0 0 8.5 17Z'
      />
      <Path
        fill='#FBBC05'
        d='M3.74 10.12a5.11 5.11 0 0 1 0-3.23v-2.2H.9A8.49 8.49 0 0 0 0 8.5c0 1.37.33 2.67.9 3.82l2.84-2.2Z'
      />
      <Path
        fill='#EA4335'
        d='M8.5 3.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 8.5 0 8.5 8.5 0 0 0 .9 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5Z'
      />
    </Svg>
  )
}

export function UpsLogo(props: SvgProps) {
  const theme = useTheme()
  return (
    <Svg width={19} height={22} viewBox='0 0 19 22' {...props}>
      <Path
        fill={colorWithOpacity(theme.colors.foreground, 0.9)}
        d='M4.885 13.295c-.256.177-1.606.645-1.606-.935V7.238H1.67v5.053c0 3.688 3.95 2.516 4.821 1.92V7.238H4.885v6.057ZM0 2.06v10.878c0 5.106 3.688 6.657 9.243 9.065 5.534-2.408 9.242-3.95 9.242-9.065V2.059C12.758-.974 4.61-.383 0 2.06Zm17.633 10.878c0 4.264-2.62 5.593-8.39 8.14C3.457 18.525.847 17.2.847 12.936V6.713c5.037-4.62 11.404-4.925 16.786-4.422v10.646ZM7.313 7.648v10.479h1.61v-3.383c1.201.36 3.53-.128 3.53-3.866 0-4.796-3.974-3.984-5.14-3.23Zm1.61 5.776V8.485c.413-.207 1.89-.625 1.89 2.428 0 3.343-1.792 2.55-1.89 2.511Zm3.894-4.254c.005 2.329 2.541 2.092 2.57 3.466.03 1.157-1.496 1.133-2.5.242v1.482c1.782 1.058 4.032.399 4.096-1.65.084-2.536-2.664-2.294-2.63-3.604.03-1 1.508-1.01 2.389-.109V7.6c-1.403-1.083-3.934-.453-3.925 1.57Z'
      />
    </Svg>
  )
}

export function UspsLogo(props: SvgProps) {
  const theme = useTheme()
  return (
    <Svg width={22} height={14} viewBox='0 0 22 14' {...props}>
      <Path
        fill={colorWithOpacity(theme.colors.foreground, 0.9)}
        d='M13.33788,3.07101386 C11.7918988,2.86968942 3.4422398,2.94219342 2.21511294,2.95402803 L0,13.2324568 C3.39109251,11.5259601 6.4097348,10.053027 9.76790804,8.45072931 C14.0373464,6.4138157 16.9745067,5.89173247 17.5837852,5.86207792 C17.9937797,5.84221754 17.7613044,5.66361011 17.5665094,5.62062463 C15.181767,5.09473255 9.39777034,7.7765645 9.39777034,7.7765645 L8.13405141,3.9424222 L16.3683569,3.95017591 C15.9140167,3.27737141 15.0075126,3.28852587 13.33788,3.07101386 L13.33788,3.07101386 Z'
      />
      <Path
        fill={colorWithOpacity(theme.colors.foreground, 0.9)}
        d='M3.4680855,0 C7.23343121,0.826376337 10.9986064,1.6535296 14.7636107,2.48145973 C16.5740343,2.88057182 16.8054214,3.50086874 16.8054214,3.50086874 C16.8054214,3.50086874 18.7865627,3.26145589 19.1500349,3.95099209 C19.6160738,4.83437108 18.4244508,6.51284556 18.4244508,6.51284556 L1.29840659,13.2323207 L19.0833802,13.2323207 L22,0 L3.4680855,0 Z'
      />
      <Path
        fill={colorWithOpacity(theme.colors.foreground, 0.9)}
        d='M16.4424933,3.96309876 C16.4424933,3.96309876 16.3574745,4.439884 14.597382,4.58747658 C14.4360504,4.60094355 14.3975539,4.70405431 14.6104409,4.71085581 C14.8229198,4.71765732 17.7648412,4.61631495 18.0925375,4.64841803 C18.4203699,4.68052112 18.3765682,4.87844481 18.3345349,5.07011111 C18.2920936,5.26164139 18.0717249,5.83582413 18.0151364,5.97062988 C17.95882,6.10557167 18.0664198,6.10244298 18.1530709,6.01443155 C18.2394499,5.92628409 18.7680626,4.86443371 18.783298,4.61332229 C18.7983973,4.36221086 18.7086175,4.1014413 18.2999833,4.01138942 C17.8912131,3.92092946 16.4424933,3.96309876 16.4424933,3.96309876 L16.4424933,3.96309876 Z'
      />
    </Svg>
  )
}
