import { QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { z } from 'zod'
import { api } from '@/queries/api'
import { keys as dashboardKeys } from '@/queries/dashboard.queries'
import { Accident, AccidentExport, AccidentListItem, AccidentListItemSchema, AccidentPreview, AccidentPreviewSchema, AccidentSchema, Account, Document, DocumentSchema, DocumentTag, Event, ReplacementAccidentsSummary, SplitAccidentsSummary, Transaction } from '@/types/accident'
import { AccidentFilter } from '@/types/filter'
import { TagName } from '@/types/tag'

export const keys = {
  accidents: (filter?: AccidentFilter): QueryKey => filter ? ['accidents', filter] : ['accidents'],
  accidentSearch: (input: string): QueryKey => ['accidentSearch', input],
  accident: (id?: number): QueryKey => id ? ['accident', id] : ['accident'],
  splitAccidentsSummary: (id: number): QueryKey => ['splitAccidentsSummary', id],
  replacementAccidentsSummary: (id: number): QueryKey => ['replacementAccidentsSummary', id],
}

export function useAccidents(filter: AccidentFilter) {
  return useQuery({
    queryKey: keys.accidents(filter),
    queryFn: async () => {
      const res = await api.get('accidents', {
        params: {
          ...filter,
          locations: filter.locations.length ? filter.locations.join(',') : undefined,
          providers: filter.providers.length ? filter.providers.join(',') : undefined,
          lawFirms: filter.lawFirms.length ? filter.lawFirms.join(',') : undefined,
          lawFirmContacts: filter.lawFirmContacts.length ? filter.lawFirmContacts.join(',') : undefined,
          statuses: filter.statuses.length ? filter.statuses.join(',') : undefined,
          statusers: filter.statusers.length ? filter.statusers.join(',') : undefined,
          tags: filter.tags.length ? filter.tags.join(',') : undefined,
        },
      })
      return {
        accidents: res.data.accidents.map((item: any) => AccidentListItemSchema.parse(item)) as AccidentListItem[],
        total: res.data.total,
      }
    },
  })
}

export function useAccidentsExport() {
  return useMutation({
    mutationFn: ({ filter, exportParams }: { filter: AccidentFilter, exportParams: AccidentExport }) => {
      return api.get('accidents', {
        responseType: 'blob',
        params: {
          ...filter,
          locations: filter.locations.length ? filter.locations.join(',') : undefined,
          providers: filter.providers.length ? filter.providers.join(',') : undefined,
          lawFirms: filter.lawFirms.length ? filter.lawFirms.join(',') : undefined,
          lawFirmContacts: filter.lawFirmContacts.length ? filter.lawFirmContacts.join(',') : undefined,
          statuses: filter.statuses.length ? filter.statuses.join(',') : undefined,
          statusers: filter.statusers.length ? filter.statusers.join(',') : undefined,
          tags: filter.tags.length ? filter.tags.join(',') : undefined,
          exportEntity: exportParams.entity,
          exportType: exportParams.type,
          exportReplacements: exportParams.replacements,
        },
      })
    },
  })
}

export function useAccidentSearch(input: string) {
  return useQuery({
    queryKey: keys.accidentSearch(input),
    queryFn: async () => {
      if (!input.length) return null

      const res = await api.get('accidents/search', { params: { input } })
      return res.data.accidents.map((item: any) => AccidentPreviewSchema.parse(item)) as AccidentPreview[]
    },
    placeholderData: (previousData) => previousData,
    staleTime: 1000 * 10,
  })
}

export function useAccidentAutocomplete() {
  return useMutation({
    mutationFn: async (input: string) => {
      const res = await api.get('accidents/search', { params: { input } })
      return res.data.accidents.map((item: any) => AccidentPreviewSchema.parse(item)) as AccidentPreview[]
    },
  })
}

export function useAccident(accidentId: number) {
  return useQuery({
    queryKey: keys.accident(accidentId),
    queryFn: () => accidentQuery(accidentId),
  })
}

export async function accidentQuery(accidentId: number) {
  const res = await api.get(`accidents/${accidentId}`)
  return res.data.accident ? AccidentSchema.parse(res.data.accident) : null
}

export function useUpdateAccident() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, users, doa, lawFirm, lawFirmContact, insuranceCarrier, policyLimit, tags, notes }: {
      id: number
      users?: number[]
      doa?: string
      lawFirm?: string
      lawFirmContact?: string
      insuranceCarrier?: string
      policyLimit?: {
        amount?: string
        accidentPercentage?: string
      }
      tags?: TagName[]
      notes?: string
    }) => {
      const res = await api.patch(`accidents/${id}`, { users, doa, lawFirm, lawFirmContact, insuranceCarrier, policyLimit, tags, notes })
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useMergeAccidents() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ from, into }: { from: number, into: number }) => {
      const res = await api.post('accidents/merge', { from, into })
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useSplitAccident() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ accidentId, accountIds }: { accidentId: number, accountIds: number[] }) => {
      const res = await api.post('accidents/split', { accidentId, accountIds })
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useSplitAccidentsSummary(accidentId: number) {
  return useQuery({
    queryKey: keys.splitAccidentsSummary(accidentId),
    queryFn: async () => {
      const res = await api.get(`accidents/splitSummary/${accidentId}`)
      return SplitAccidentsSummary.parse(res.data)
    },
  })
}

export function useReplacementAccidentsSummary(accidentId: number) {
  return useQuery({
    queryKey: keys.replacementAccidentsSummary(accidentId),
    queryFn: async () => {
      const res = await api.get(`accidents/replacementSummary/${accidentId}`)
      return ReplacementAccidentsSummary.parse(res.data)
    },
  })
}

export function useUpdatePatient() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ accident, patient }: {
      accident: Accident
      patient: {
        firstName: string
        lastName: string
        dob: string
      }
    }) => {
      const res = await api.patch(`patients/${accident.patient.id}`, {
        accidentId: accident.id,
        firstName: patient.firstName,
        lastName: patient.lastName,
        dob: patient.dob,
      })
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useUpdateAccount() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ account, policyLimit }: {
      account: Account
      policyLimit?: {
        accountPercentage?: string
      },
    }) => {
      const res = await api.patch(`accounts/${account.id}`, { policyLimit })
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useSyncAccountWithQuickbooks() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ account }: { account: Account }) => {
      const res = await api.patch(`accounts/${account.id}/syncWithQuickbooks`)
      return {
        accident: AccidentSchema.parse(res.data.accident),
        exportedTransactions: z.number().parse(res.data.exportedTransactions),
        importedTransactions: z.number().parse(res.data.importedTransactions),
      }
    },
    onSuccess: ({ accident }) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
    },
  })
}

export function useCreateEvent() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ accident, visibility, type, status, createdAt, nextContact, note }: {
      accident: Accident
      visibility: string
      type: string
      status: string
      createdAt: string
      nextContact: string
      note: string
    }) => {
      const account = accountFromVisibility(accident, visibility)
      const res = await api.post('events', {
        accidentId: accident.id,
        accountId: account?.id,
        visibility,
        type,
        status,
        createdAt,
        nextContact,
        note,
      })
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useLogEvent() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ accident, action }: {
      accident: Accident
      action: string
    }) => {
      const res = await api.post('events/log', { accidentId: accident.id, action })
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
    },
  })
}

export function useUpdateEvent() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ accident, event, visibility, type, status, createdAt, nextContact, note }: {
      accident: Accident
      event: Event
      visibility: string
      type: string
      status: string
      createdAt: string
      nextContact: string
      note: string
    }) => {
      const account = accountFromVisibility(accident, visibility)
      const res = await api.patch(`events/${event.id}`, {
        accountId: account?.id,
        visibility,
        type,
        status,
        createdAt,
        nextContact,
        note,
      })
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useDeleteEvent() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ event }: { accident: Accident, event: Event }) => {
      const res = await api.delete(`events/${event.id}`)
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useUpdateTransaction() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ transaction, procedure, note }: {
      accident: Accident
      transaction: Transaction
      procedure: string
      note: string
    }) => {
      const res = await api.patch(`transactions/${transaction.id}`, { procedure, note })
      return res.data.accident ? AccidentSchema.parse(res.data.accident) : null
    },
    onSuccess: (accident) => {
      if (accident) queryClient.setQueryData(keys.accident(accident.id), accident)
    },
  })
}

export function useUploadDocuments() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ accident, account, files, onProgress }: {
      accident: Accident
      account: Account | undefined
      files: FileList
      onProgress: (progress: number) => void
    }) => {
      const res = await api.postForm('documents', { accidentId: accident.id, accountId: account?.id, files }, {
        onUploadProgress: (e) => {
          if (!e.total) return
          onProgress(Math.round((e.loaded * 100) / e.total))
        },
      })
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
    },
  })
}

export function useUpdateDocument() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ accident, document, visibility, name, tags, transactions }: {
      accident: Accident
      document: Document
      visibility?: string
      name?: string
      tags?: DocumentTag[]
      transactions?: number[]
    }) => {
      const account = visibility ? accountFromVisibility(accident, visibility) : undefined
      const res = await api.patch(`documents/${document.id}`, { accountId: account?.id, visibility, name, tags, transactions })
      return {
        accident: AccidentSchema.parse(res.data.accident),
        document: DocumentSchema.parse(res.data.document),
      }
    },
    onSuccess: (res) => {
      queryClient.setQueryData(keys.accident(res.accident.id), res.accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
    },
  })
}

export function useDeleteDocument() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ document }: { document: Document }) => {
      const res = await api.delete(`documents/${document.id}`)
      return AccidentSchema.parse(res.data.accident)
    },
    onSuccess: (accident) => {
      queryClient.setQueryData(keys.accident(accident.id), accident)
      queryClient.invalidateQueries({ queryKey: keys.accidents() })
    },
  })
}

function accountFromVisibility(accident: Accident, visibility: string) {
  switch (visibility) {
    case 'Staff Only': return undefined
    case 'Public': return undefined
    default: {
      const account = accident.accounts.find((a) => a.provider.name === visibility)
      if (!account) throw new Error('Account not found')
      return account
    }
  }
}
