/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useFocusEffect } from 'expo-router'
import { CSSProperties, ReactNode, useCallback, useRef } from 'react'
import { colorWithBrightness, useTheme } from '../../hooks/useTheme'
import { ActivityIndicator } from '../ActivityIndicator'

type Props = {
  columns: {
    data: string
    title: string
    width?: number | string
    alignRight?: boolean
    style?: CSSProperties
    sortable?: boolean
    hidden?: boolean
    renderer?: (row: number, value: any) => ReactNode
  }[]
  data: any[]
  loading?: boolean
  noDataMessage?: string
  noDataButton?: ReactNode
  orderBy?: string
  sortOrder?: 'asc' | 'desc'
  compact?: boolean
  minHeight?: number
  fixedLayout?: boolean
  stickyHeader?: boolean
  scrollHorizontally?: boolean
  borderRadius?: number
  rowClasses?: (row: number) => string[]
  onRowClick?: (row: any) => void
  onUpdateSort?: ({ orderBy, sortOrder }: { orderBy: string, sortOrder: 'asc' | 'desc' }) => void
}

export function Table({
  columns,
  data,
  loading,
  noDataMessage,
  noDataButton,
  orderBy,
  sortOrder,
  compact,
  minHeight,
  fixedLayout,
  stickyHeader,
  scrollHorizontally = true,
  borderRadius,
  rowClasses,
  onRowClick,
  onUpdateSort,
}: Props) {
  const theme = useTheme()

  const filteredColumns = columns.filter((c) => !c.hidden)

  const tableRef = useRef<HTMLTableElement>(null)

  useFocusEffect(
    useCallback(() => {
      if (!stickyHeader) return
      if (!tableRef.current) return
      if (window.innerWidth <= theme.breakpoints.l) return

      const tableRect = tableRef.current.getBoundingClientRect()
      const headerRow = tableRef.current.querySelector('thead tr') as HTMLTableRowElement

      function handleScroll() {
        if (window.scrollY > tableRect.top) {
          headerRow.style.transform = `translateY(${window.scrollY - tableRect.top}px)`
        } else {
          headerRow.style.transform = 'none'
        }
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [theme, stickyHeader])
  )

  const styles = css({
    width: '100%',
    tableLayout: fixedLayout ? 'fixed' : undefined,
    borderCollapse: 'collapse',
    'thead': {
      'tr': {
        willChange: stickyHeader ? 'transform' : undefined,
      },
      'th': {
        fontFamily: theme.fonts.body[600],
        fontWeight: 'normal',
        fontSize: compact ? 13.5 : 14,
        textAlign: 'left',
        padding: compact ? '12px 14px' : '15px 18px',
        background: theme.colors.headerBackground,
        borderBottom: `1px solid ${theme.colors.panelBorder}`,
        position: 'relative',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        '&:first-child': {
          borderTopLeftRadius: borderRadius,
        },
        '&:last-child': {
          borderTopRightRadius: borderRadius,
        },
        '&.sortActive': {
          '&:after': {
            content: '"\\2303"',
            display: 'inline-block',
            lineHeight: 1,
            fontSize: 16,
            fontFamily: theme.fonts.body[400],
            fontWeight: 'normal',
            marginLeft: 6,
            transform: 'translateY(4px)',
          },
          '&.desc:after': {
            transform: 'rotate(180deg) translateY(3px)',
          },
        },
      },
    },
    'tbody': {
      'tr': {
        borderTop: `1px solid ${theme.colors.panelBorder}`,
        '&.clickable': {
          cursor: 'pointer',
          ':hover': {
            background: colorWithBrightness(theme.colors.surface, -5),
          },
        },
      },
      'td': {
        color: theme.colors.foreground,
        fontFamily: theme.fonts.body[400],
        fontSize: compact ? 13.5 : 14,
        lineHeight: 1.35,
        padding: compact ? '12px 14px' : '15px 18px',
        '&.loading, &.noData': {
          height: minHeight ?? (compact ? 120 : 250),
          '> div': {
            position: 'sticky',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: theme.spacing,
            '> .text': {
              fontSize: compact ? 14.5 : 15,
              textAlign: 'center',
              opacity: 0.7,
            },
          },
        },
      },
    },
  })

  return (
    <div css={{ overflowX: scrollHorizontally ? 'auto' : undefined }}>
      <table ref={tableRef} css={styles}>
        <thead>
          <tr>
            {filteredColumns.map((col) => (
              <th
                key={col.data}
                className={orderBy === col.data ? `sortActive ${sortOrder}` : undefined}
                style={{
                  width: col.width,
                  textAlign: col.alignRight ? 'right' : undefined,
                  cursor: col.sortable ? 'pointer' : undefined,
                }}
                onClick={(onUpdateSort && col.sortable) ?
                  () => onUpdateSort({
                    orderBy: col.data,
                    sortOrder: orderBy !== col.data ? 'asc' : sortOrder === 'asc' ? 'desc' : 'asc',
                  }) : undefined}
              >{col.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={filteredColumns.length} className='loading'>
                <div>
                  <ActivityIndicator style={{ marginHorizontal: 'auto' }} />
                </div>
              </td>
            </tr>
          ) : data.length > 0 ? (
            data.map((row, index) => {
              const classes = rowClasses ? rowClasses(index) : []
              if (onRowClick) classes.push('clickable')
              return (
                <tr
                  key={index}
                  className={classes.join(' ')}
                  onClick={onRowClick ? () => onRowClick(row) : undefined}
                >
                  {filteredColumns.map((col) => (
                    <td key={col.data} style={{ textAlign: col.alignRight ? 'right' : 'left', ...col.style }}>
                      {col.renderer ? col.renderer(index, row[col.data]) : row[col.data]}
                    </td>
                  ))}
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={filteredColumns.length} className='noData'>
                <div>
                  <div className='text'>{noDataMessage ?? 'No data'}</div>
                  {noDataButton}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
