import { router, Stack, useLocalSearchParams } from 'expo-router'
import { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { ActivityIndicator, Button, Feedback } from 'ui'
import { Head } from '@/components/web/Head'
import { useSignInWithSlug } from '@/queries/auth.queries'

export default function Signin() {
  const params = useLocalSearchParams<{ slug?: string }>()

  const { mutate: signInWithSlug, isPending } = useSignInWithSlug()

  useEffect(() => signInWithSlug(params.slug), [params.slug, signInWithSlug])

  const styles = StyleSheet.create({
    wrapper: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  return <>
    <Stack.Screen options={{ title: 'Sign In' }} />
    <Head title='Sign In' />

    <View style={styles.wrapper}>
      {isPending ? (
        <ActivityIndicator />
      ) : (
        <Feedback
          status='danger'
          heading='Unable to Sign In'
          body='Your sign in code has expired.'
          action={(
            <Button
              type='primary'
              text='Sign In Again'
              style={{ minWidth: 250 }}
              onPress={() => router.navigate('/auth')}
            />
          )}
        />
      )}
    </View>
  </>
}
