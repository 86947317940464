/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { router } from 'expo-router'
import { useState } from 'react'
import { Button, Select, Spacing, useTheme } from 'ui'
import { useStore } from '@/providers/store'
import { fieldErrors } from '@/queries/client'
import { useProxyUser, useUser, useUsers } from '@/queries/user.queries'

export function ProxyUser() {
  const theme = useTheme()
  const modal = useStore.useModal()

  const user = useUser()
  const users = useUsers()
  const proxyUser = useProxyUser()

  const [selectedUser, setSelectedUser] = useState('')
  const [error, setError] = useState<{ user?: string }>()

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <Select
        label='User'
        value={selectedUser}
        options={users.data
          ? users.data.filter((u) => u.id !== user.data?.id && u.active).map((u) => {
            return { value: u.id.toString(), label: `${u.fullName} - ${u.email}` }
          })
          : []}
        placeholder='Select user'
        error={error?.user}
        style={{ maxWidth: 330 }}
        onChange={setSelectedUser}
      />
      <Spacing height={1.4} />
      <Button
        text='Proxy User'
        type='primary'
        loading={proxyUser.isPending}
        disabled={!users.data}
        onPress={() => {
          proxyUser.mutate({ id: Number(selectedUser) }, {
            onSuccess: () => {
              modal.close()
              router.navigate('/')
            },
            onError: (error) => setError(fieldErrors(error)),
          })
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}
