import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Pressable, StyleSheet, View, ViewProps } from 'react-native'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Text } from './Text'

type Props = {
  value: number
  onChange: (value: number) => void
} & ViewProps

export function QuantitySelector({ value, onChange, ...props }: Props) {
  const theme = useTheme()

  const height = 30

  const styles = StyleSheet.create({
    wrapper: {
      height,
      backgroundColor: theme.colors.transparencyBase,
      borderWidth: 1,
      borderColor: theme.input.border,
      borderRadius: height / 2,
      flexDirection: 'row',
      alignSelf: 'flex-start',
      userSelect: 'none',
      ...theme.input.shadow,
    },
    button: {
      width: height,
      justifyContent: 'center',
      alignItems: 'center',
    },
    value: {
      fontSize: 13.5,
      lineHeight: height,
      paddingHorizontal: 5,
    },
  })

  return (
    <View {...props} style={[styles.wrapper, props.style]}>
      <Pressable style={styles.button} onPress={() => onChange(value - 1)}>
        {value > 1 ? (
          <FontAwesomeIcon
            icon={faMinus}
            size={13}
            color={colorWithOpacity(theme.colors.foreground, 0.9)}
          />
        ) : (
          <FontAwesomeIcon
            icon={faTrash}
            size={13}
            color={colorWithOpacity(theme.colors.foreground, 0.9)}
          />
        )}
      </Pressable>
      <Text style={styles.value}>{value}</Text>
      <Pressable style={styles.button} onPress={() => onChange(value + 1)}>
        <FontAwesomeIcon
          icon={faPlus}
          size={13}
          color={colorWithOpacity(theme.colors.foreground, 0.9)}
        />
      </Pressable>
    </View>
  )
}
