import { Stack, useLocalSearchParams } from 'expo-router'
import { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { ActivityIndicator, Feedback } from 'ui'
import { Head } from '@/components/web/Head'
import { useSubmitBatch } from '@/queries/batch.queries'

export function SubmitBatch() {
  const params = useLocalSearchParams<{ slug?: string }>()

  const { mutate: submitBatch, isPending, isSuccess } = useSubmitBatch()

  useEffect(() => submitBatch(params.slug), [params.slug, submitBatch])

  const styles = StyleSheet.create({
    wrapper: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  return <>
    <Stack.Screen options={{ title: 'Submit Funding Request' }} />
    <Head title='Submit Funding Request' />

    <View style={styles.wrapper}>
      {isPending ? (
        <ActivityIndicator />
      ) : isSuccess ? (
        <Feedback
          status='success'
          heading='Funding Request Submitted'
          body='The funding request has been sent to Lienstar for review.'
        />
      ) : (
        <Feedback
          status='danger'
          heading='Submit Funding Request'
          body='We were unable to submit the funding request.'
        />
      )}
    </View>
  </>
}
