type Props = {
  value: number
  size: number
  strokeWidth: number
  pathColor: string
  trailColor: string
}

export function ProgressCircle({ value, size, strokeWidth, pathColor, trailColor }: Props) {
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const offset = circumference - value * circumference

  return (
    <svg width={size} height={size}>
      <circle
        fill='transparent'
        stroke={trailColor}
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        fill='transparent'
        stroke={pathColor}
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={offset}
        r={radius}
        cx={size / 2}
        cy={size / 2}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
    </svg>
  )
}
