import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { BlurView } from 'expo-blur'
import { Tabs } from 'expo-router'
import { ReactNode } from 'react'
import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useColorScheme } from '../hooks/useColorScheme'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

type TabBarProps = {
  activeTintColor?: string
  inactiveTintColor?: string
  children: ReactNode
}

export function TabBar({ activeTintColor, inactiveTintColor, children }: TabBarProps) {
  const { width } = useWindowDimensions()
  const colorScheme = useColorScheme()
  const insets = useSafeAreaInsets()
  const theme = useTheme()

  return (
    <Tabs
      screenOptions={{
        sceneStyle: {
          backgroundColor: theme.colors.pageBackground,
        },
        headerShown: false,
        tabBarStyle: {
          position: 'absolute',
          borderTopColor: colorWithOpacity(theme.colors.divider, 0.5),
          borderTopWidth: StyleSheet.hairlineWidth,
          height: insets.bottom + 50,
          paddingTop: Platform.OS === 'ios' ? 2 : 3,
          backgroundColor: Platform.OS === 'android' ? theme.colors.background : 'transparent',
        },
        tabBarBackground: () => (
          Platform.OS === 'ios' ? (
            <BlurView
              tint={colorScheme === 'dark' ? 'dark' : 'light'}
              intensity={100}
              style={StyleSheet.absoluteFill}
            />
          ) : (
            <View style={[{ backgroundColor: theme.colors.background }, StyleSheet.absoluteFill]} />
          )
        ),
        tabBarActiveTintColor: activeTintColor ?? theme.colors.primary,
        tabBarInactiveTintColor: inactiveTintColor ?? colorWithOpacity(theme.colors.foreground, 0.45),
        tabBarLabelPosition: width >= theme.breakpoints.s ? 'beside-icon' : 'below-icon',
        tabBarLabelStyle: {
          fontFamily: theme.fonts.body[500],
          fontSize: width >= theme.breakpoints.s ? 12.5 : 11,
          letterSpacing: 0.1,
          paddingBottom: width < theme.breakpoints.s ? (Platform.OS === 'ios' ? 6 : 2) : 0,
        },
      }}
    >
      {children}
    </Tabs>
  )
}

type TabBarIconProps = {
  icon: IconProp
  size: number
  color: string
}

export function TabBarIcon({ icon, size, color }: TabBarIconProps) {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const _size = width >= theme.breakpoints.s ? size + 2 : size

  return <FontAwesomeIcon icon={icon} size={_size} color={color} />
}
