import * as Haptics from 'expo-haptics'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { LayoutChangeEvent, NativeSyntheticEvent, Platform, Pressable, StyleSheet, Text, TextInput, TextInputKeyPressEventData, View } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withSequence, withTiming } from 'react-native-reanimated'
import { useTheme } from '../hooks/useTheme'

type Props = {
  length: number
  autoFocus?: boolean
  onChange: (value: string) => Promise<boolean>
}

export type CodeInputHandle = {
  focus: () => void
}

const SLOT_GAP = 9

export const CodeInput = forwardRef<CodeInputHandle, Props>(({
  length,
  autoFocus,
  onChange,
}, ref) => {
  const theme = useTheme()

  const [value, setValue] = useState('')
  const [cursor, setCursor] = useState('')
  const [focused, setFocused] = useState(false)
  const [slotWidth, setSlotWidth] = useState(0)
  const [status, setStatus] = useState<'success' | 'error'>()

  const slotsOffset = useSharedValue(0)
  const animatedSlotsStyles = useAnimatedStyle(() => ({
    transform: [{
      translateX: withTiming(slotsOffset.value, {
        duration: 50,
        easing: theme.easing.curveLinearToEaseOut,
      }),
    }],
  }))

  const cursorRef = useRef<TextInput>(null)
  useImperativeHandle(ref, () => ({
    focus: () => cursorRef.current?.focus(),
  }), [])

  function handleLayout(e: LayoutChangeEvent) {
    setSlotWidth((e.nativeEvent.layout.width - (length - 1) * SLOT_GAP) / length)
    if (autoFocus) setTimeout(() => cursorRef.current?.focus(), 100)
  }

  function handleChangeText(text: string) {
    const updatedValue = value + text.toUpperCase()
    if (value.length < length) setValue(updatedValue)
    if (updatedValue.length === length) checkCode(updatedValue)
    setCursor('')
  }

  function handleKeyPress(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    if (e.nativeEvent.key === 'Backspace') {
      setValue(value => value.substring(0, value.length - 1))
    }
  }

  async function checkCode(code: string) {
    const success = await onChange(code)
    setStatus(success ? 'success' : 'error')

    if (!success) {
      if (Platform.OS !== 'web') {
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error)
      }
      const timing = (value: number) => withTiming(value, { duration: 50 })
      slotsOffset.value = withSequence(timing(3), timing(-3), timing(3), timing(-3), timing(0))
      setTimeout(() => {
        setValue('')
        setStatus(undefined)
        setTimeout(() => cursorRef.current?.focus())
      }, 400)
    }
  }

  const styles = StyleSheet.create({
    slots: {
      flexDirection: 'row',
      columnGap: SLOT_GAP,
      height: theme.input.height,
      position: 'relative',
      ...Platform.select({ web: { cursor: 'text' as any } }),
    },
    slot: {
      width: slotWidth,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.surface,
      borderWidth: 1,
      borderRadius: theme.borderRadius.s,
    },
    slotText: {
      fontFamily: theme.fonts.body[400],
      fontSize: 21,
      lineHeight: theme.input.height,
      color: theme.colors.foreground,
    },
    input: {
      display: value.length >= length ? 'none' : 'flex',
      width: slotWidth,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: value.length * slotWidth + value.length * SLOT_GAP - 1,
      textAlign: 'center',
      fontSize: 21,
      lineHeight: Platform.OS === 'web' ? theme.input.height : undefined,
      color: Platform.OS === 'web' ? undefined : 'transparent',
    },
  })

  return (
    <Pressable
      onLayout={handleLayout}
      onPress={() => cursorRef.current?.focus()}
    >
      <Animated.View style={[styles.slots, animatedSlotsStyles]}>
        {[...Array(length).keys()].map(index => (
          <View
            key={index}
            style={{
              ...styles.slot,
              borderColor: status === 'success' ? theme.colors.success
                : status === 'error' ? theme.colors.danger
                  : value.length === index && focused ? theme.colors.primary
                    : theme.input.border,
            }}
          >
            <Text style={styles.slotText}>{value[index]}</Text>
          </View>
        ))}

        <TextInput
          ref={cursorRef}
          value={cursor}
          autoCapitalize='none'
          autoCorrect={false}
          selectionColor={theme.colors.primary}
          style={styles.input}
          textContentType='oneTimeCode'
          onChangeText={handleChangeText}
          onKeyPress={handleKeyPress}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </Animated.View>
    </Pressable>
  )
})
