import { BlurView } from 'expo-blur'
import { ReactNode } from 'react'
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import Animated, { AnimatedStyle } from 'react-native-reanimated'
import { useColorScheme } from '../hooks/useColorScheme'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

type Props = {
  style?: StyleProp<ViewStyle>
  animatedStyle?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>
  hideBorder?: boolean
  children?: ReactNode
}

export function Header({ style, animatedStyle, hideBorder, children }: Props) {
  const colorScheme = useColorScheme()
  const theme = useTheme()

  const styles = StyleSheet.create({
    wrapper: {
      position: 'absolute',
      top: 0,
      width: '100%',
      backgroundColor: Platform.OS === 'android' ? theme.colors.pageBackground : undefined,
    },
    border: {
      borderBottomWidth: hideBorder ? 0 : StyleSheet.hairlineWidth,
      borderBottomColor: Platform.OS === 'web' ? undefined : colorWithOpacity(theme.colors.divider, 0.5),
    },
  })

  return <>
    {Platform.OS !== 'web' && (
      <Animated.View style={[styles.wrapper, styles.border, style, animatedStyle]}>
        <BlurView
          tint={colorScheme === 'dark' ? 'dark' : 'light'}
          intensity={100}
          style={StyleSheet.absoluteFill}
        />
      </Animated.View>
    )}
    <View style={[styles.wrapper, style]}>{children}</View>
  </>
}
