import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft'
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import { Stack, useNavigationContainerRef } from 'expo-router'
import { ReactNode } from 'react'
import { Platform, Pressable } from 'react-native'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

type Props = {
  screenOptions?: NativeStackNavigationOptions
  children?: ReactNode
}

export function CustomStack({ screenOptions, children }: Props) {
  const theme = useTheme()

  return (
    <Stack screenOptions={{
      ...screenOptions,
      headerShown: Platform.OS !== 'web',
      headerTitleAlign: 'center',
      headerTitleStyle: {
        color: theme.colors.foreground,
        fontFamily: theme.fonts.body[600],
        fontSize: Platform.OS === 'android' ? 17 : 16.5,
      },
      headerLeft: () => (
        <HeaderLeft
          type={screenOptions?.presentation === 'modal' ? 'close' : 'back'}
          tintColor={screenOptions?.headerTintColor}
        />
      ),
      contentStyle: screenOptions?.contentStyle ?? {
        backgroundColor: theme.colors.pageBackground,
      },
    }}>
      {children}
    </Stack>
  )
}

type HeaderLeftProps = {
  type: 'back' | 'close'
  tintColor?: string
  onPress?: () => void,
}

export function HeaderLeft({ type, tintColor, onPress }: HeaderLeftProps) {
  const rootNavigation =  useNavigationContainerRef()
  const theme = useTheme()

  return (
    <Pressable
      hitSlop={10}
      style={({ pressed }) => ({
        opacity: pressed ? 0.3 : 1,
        marginTop: type === 'close' ? 1 : undefined,
        marginLeft: type === 'back' ? -6 : undefined,
      })}
      onPress={() => onPress ? onPress() : rootNavigation.goBack()}
    >
      {type === 'close' ? (
        <FontAwesomeIcon
          icon={faXmark}
          size={24}
          color={tintColor ?? colorWithOpacity(theme.colors.foreground, 0.85)}
        />
      ) : (
        <FontAwesomeIcon
          icon={faAngleLeft}
          size={28}
          color={tintColor ?? colorWithOpacity(theme.colors.foreground, 0.85)}
        />
      )}
    </Pressable>
  )
}
