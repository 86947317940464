import { createContext, useContext } from 'react'
import { EasingFunction } from 'react-native-reanimated'

export type Theme = {
  colors: {
    foreground: string
    background: string
    pageBackground: string
    primary: string
    accent?: string
    success: string
    warning: string
    danger: string
    info: string
    surface: string
    transparencyBase: string
    divider: string
    headerBackground: string
    panelBorder: string
  }
  fonts: {
    body: {
      400: string
      500: string
      600: string
      700: string
    }
    mono?: string
  }
  spacing: number
  gap: number
  borderRadius: {
    s: number
    m: number
    l: number
  }
  breakpoints: {
    xs: number
    s: number
    m: number
    l: number
  }
  easing: {
    curveLinearToEaseOut: EasingFunction
  }
  input: {
    height: number
    fontFamily: string
    fontSize: number
    placeholder: string
    border: string
    borderHover: string
    shadow: {
      shadowColor: string
      shadowOffset: { width: number; height: number }
      shadowRadius: number
      shadowOpacity: number
      elevation: number
    }
    compact: {
      height: number
      fontSize: number
    }
  }
  button: {
    height: number
    fontFamily: string
    fontSize: number
    borderRadius: number
    compact: {
      height: number
      fontFamily: string
      fontSize: number
      borderRadius: number
    }
  }
  dialog?: {
    fontSize?: number
  }
  web: {
    easing: {
      curveLinearToEaseOut: string
    }
    shadow: {
      input: string
      panel: string
      overlay: string
    }
  }
}

export const ThemeContext = createContext<Theme | undefined>(undefined)

export function useTheme() {
  const context = useContext(ThemeContext)
  if (!context) throw new Error('useTheme must be used within a ThemeProvider')
  return context
}

export type ColorStatus = 'success' | 'warning' | 'danger' | 'info'

export function colorWithOpacity(color: string, opacity: number): string {
  if (color.length !== 7 || !color.startsWith('#')) {
    throw new Error('Color should be formatted as a 6 letter hex color (e.g. #ABCDEF)')
  }

  const intValue = Math.round(opacity * 255)
  const hexValue = intValue.toString(16)
  return color + hexValue.padStart(2, '0').toUpperCase()
}

export function colorWithBrightness(color: string, amount: number): string {
  if (color.length !== 7 || !color.startsWith('#')) {
    throw new Error('Color should be formatted as a 6 letter hex color (e.g. #ABCDEF)')
  }

  return '#' + color.replace(/^#/, '').replace(/../g, (color) => {
    return ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).slice(-2)
  })
}
