/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons/faFloppyDisk'
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons/faPenToSquare'
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import * as RadixPopover from '@radix-ui/react-popover'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import { ComponentProps, Dispatch, ReactNode, SetStateAction, useRef, useState } from 'react'
import { colorWithOpacity, useTheme } from '../../hooks/useTheme'
import { ActivityIndicator } from '../ActivityIndicator'

type Form = {
  fields: ReactNode[]
  loading?: boolean
  onSubmit: () => Promise<boolean | void>
}

type Props = ComponentProps<'div'> & {
  form: Form
  headerAction?: ReactNode
  disabled?: boolean
  transparentTooltip?: boolean
}

export function EditableValue({ form, headerAction, disabled, transparentTooltip, ...props }: Props) {
  const [popoverOpen, setPopoverOpen] = useState(false)

  if (disabled) return <div {...props} />
  return (
    <Popover open={popoverOpen} setOpen={setPopoverOpen} form={form} headerAction={headerAction}>
      <EditableValueTooltip
        transparent={transparentTooltip}
        onIconClick={() => setPopoverOpen(true)}
        {...props}
      />
    </Popover>
  )
}

type TooltipProps = ComponentProps<'div'> & {
  transparent?: boolean
  onIconClick: () => void
}

export function EditableValueTooltip({ transparent = true, onIconClick, ...props }: TooltipProps) {
  const theme = useTheme()

  const slideLeftFadeIn = keyframes({
    from: {
      opacity: 0,
      transform: 'translateX(2px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0)',
    },
  })
  const slideRightFadeOut = keyframes({
    from: {
      opacity: 1,
      transform: 'translateX(0)',
    },
    to: {
      opacity: 0,
      transform: 'translateX(2px)',
    },
  })

  const slideRightFadeIn = keyframes({
    from: {
      opacity: 0,
      transform: 'translateX(-2px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0)',
    },
  })
  const slideLeftFadeOut = keyframes({
    from: {
      opacity: 1,
      transform: 'translateX(0)',
    },
    to: {
      opacity: 0,
      transform: 'translateX(-2px)',
    },
  })

  const styles = {
    content: css({
      cursor: 'pointer',
      transformOrigin: 'var(--radix-tooltip-content-transform-origin)',
      animationDuration: '0.2s',
      animationTimingFunction: theme.web.easing.curveLinearToEaseOut,
      willChange: 'transform, opacity',
      '&[data-state=delayed-open][data-side=left]': {
        animationName: slideLeftFadeIn,
      },
      '&[data-state=closed][data-side=left]': {
        animationName: slideRightFadeOut,
      },
      '&[data-state=delayed-open][data-side=right]': {
        animationName: slideRightFadeIn,
      },
      '&[data-state=closed][data-side=right]': {
        animationName: slideLeftFadeOut,
      },
    }),
    icon: css({
      background: transparent ? undefined : theme.colors.surface,
      borderRadius: '50%',
      display: 'flex',
      justifySelf: 'center',
      alignItems: 'center',
      margin: 1,
      padding: 7,
    })
  }

  return (
    <RadixTooltip.Provider delayDuration={200}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild={true}>
          <div {...props} />
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content side='left' sideOffset={theme.spacing * 0.25} css={styles.content}>
            <div css={styles.icon} onClick={onIconClick}>
              <FontAwesomeIcon
                icon={faPenToSquare}
                size={15}
                color={theme.colors.foreground}
              />
            </div>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}

type PopoverProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  form: Form
  headerAction?: ReactNode
  children: ReactNode
}

function Popover({ open, setOpen, form, headerAction, children }: PopoverProps) {
  const theme = useTheme()

  const anchorRef = useRef<HTMLDivElement>(null)

  const contentShow = keyframes({
    from: {
      opacity: 0,
      transform: 'scale(0.96)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
  })
  const contentHide = keyframes({
    from: {
      opacity: 1,
      transform: 'scale(1)',
    },
    to: {
      opacity: 0,
      transform: 'scale(0.96)',
    },
  })

  const styles = {
    content: css({
      backgroundColor: theme.colors.surface,
      borderRadius: theme.borderRadius.s + 2,
      boxShadow: theme.web.shadow.overlay,
      transformOrigin: 'var(--radix-popover-content-transform-origin)',
      animationDuration: '0.2s',
      animationTimingFunction: theme.web.easing.curveLinearToEaseOut,
      willChange: 'transform, opacity',
      '&[data-state=open]': {
        animationName: contentShow,
      },
      '&[data-state=closed]': {
        animationName: contentHide,
      },
    }),
    header: css({
      height: 42,
      background: theme.colors.pageBackground,
      borderTopLeftRadius: theme.borderRadius.s + 2,
      borderTopRightRadius: theme.borderRadius.s + 2,
      borderBottom: `1px solid ${theme.colors.panelBorder}`,
      display: 'flex',
      alignItems: 'center',
      padding: `0 ${theme.spacing}px`,
      paddingRight: theme.spacing * 2.2,
    }),
    save: css({
      fontFamily: theme.fonts.body[500],
      fontSize: 14.5,
      color: theme.colors.success,
      display: 'flex',
      alignItems: 'center',
      columnGap: 6,
      cursor: 'pointer',
    }),
    saveCheck: css({
      width: 17,
      height: 17,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    fields: css({
      display: form.fields.length > 1 ? 'grid' : undefined,
      gridTemplateColumns: 'repeat(2, auto)',
      columnGap: theme.spacing * 0.7,
      rowGap: theme.spacing * 0.8,
      padding: theme.spacing,
    }),
    close: css({
      position: 'absolute',
      top: 12,
      right: 12,
      cursor: 'pointer',
    }),
  }

  return (
    <RadixPopover.Root open={open} onOpenChange={(open) => setOpen(open)}>
      <RadixPopover.Anchor ref={anchorRef}>{children}</RadixPopover.Anchor>
      <RadixPopover.Portal>
        <RadixPopover.Content
          side='right'
          sideOffset={(anchorRef.current?.getBoundingClientRect().width ?? 0) * -1}
          hideWhenDetached={true}
          css={styles.content}
        >
          <div css={styles.header}>
            <div
              css={styles.save}
              onClick={async () => {
                const success = await form.onSubmit()
                if (success !== false) setOpen(false)
              }}
            >
              <div css={styles.saveCheck}>
                {form.loading ? (
                  <ActivityIndicator scale={0.7} color={theme.colors.success} />
                ) : (
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    size={16}
                    color={theme.colors.success}
                  />
                )}
              </div>
              Save
            </div>

            {headerAction}

            <RadixPopover.Close asChild={true}>
              <div css={styles.close}>
                <FontAwesomeIcon
                  icon={faXmark}
                  size={18}
                  color={colorWithOpacity(theme.colors.foreground, 0.7)}
                />
              </div>
            </RadixPopover.Close>
          </div>

          <div css={styles.fields}>
            {form.fields.map((field, index) => <div key={index}>{field}</div>)}
          </div>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  )
}
