import { RefreshControlProps, RefreshControl as RNRefreshControl } from 'react-native'
import { useTheme } from '../hooks/useTheme'

export function RefreshControl({ refreshing, onRefresh, ...props }: RefreshControlProps) {
  const theme = useTheme()

  return <RNRefreshControl
    colors={[theme.colors.primary]}
    refreshing={refreshing}
    onRefresh={onRefresh}
    {...props}
  />
}
