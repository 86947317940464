import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Platform, Pressable, TextInput as RNTextInput, StyleProp, StyleSheet, TextInputProps, View, ViewStyle } from 'react-native'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Text } from './Text'

export type TextInputHandle = {
  focus: () => void
}

type Props = TextInputProps & {
  label?: string
  compact?: boolean
  clearIcon?: boolean
  clearIconOffset?: number
  error?: string | boolean
  containerStyle?: StyleProp<ViewStyle>
}

export const TextInput = forwardRef<TextInputHandle, Props>(({
  label,
  compact,
  clearIcon = true,
  clearIconOffset = 0,
  editable = true,
  error,
  style,
  containerStyle,
  ...props
}, ref) => {
  const theme = useTheme()

  const [hovered, setHovered] = useState(false)
  const [focused, setFocused] = useState(false)

  const inputRef = useRef<RNTextInput>(null)
  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.focus(),
  }), [])

  const height = compact ? theme.input.compact.height : theme.input.height

  const styles = StyleSheet.create({
    wrapper: {
      position: 'relative',
    },
    input: {
      height,
      backgroundColor: editable ? theme.colors.surface : colorWithOpacity(theme.colors.foreground, 0.03),
      borderWidth: 1,
      borderColor: !editable ? theme.input.border
        : error ? theme.colors.danger
          : focused ? theme.colors.primary
            : hovered ? theme.input.borderHover : theme.input.border,
      borderRadius: theme.borderRadius.s,
      paddingHorizontal: height * 0.3,
      color: theme.colors.foreground,
      fontFamily: theme.input.fontFamily,
      fontSize: compact ? theme.input.compact.fontSize : theme.input.fontSize,
      ...theme.input.shadow,
    },
    clearIconWrapper: {
      position: 'absolute',
      right: 12 + clearIconOffset,
      height: '100%',
      justifyContent: 'center',
    },
    clearIcon: {
      backgroundColor: colorWithOpacity(theme.colors.foreground, 0.25),
      width: 20,
      height: 20,
      borderRadius: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  return (
    <View style={containerStyle}>
      {label && <Text type='inputLabel' compact={compact}>{label}</Text>}

      <Pressable
        style={styles.wrapper}
        onHoverIn={() => setHovered(true)}
        onHoverOut={() => setHovered(false)}
        onFocus={() => inputRef.current?.focus()}
      >
        {/* FIXME: shift+tab not moving focus to previous input on web */}
        <RNTextInput
          {...props}
          ref={inputRef}
          editable={editable}
          placeholderTextColor={theme.input.placeholder}
          selectionColor={theme.colors.primary}
          style={[styles.input, style]}
          onFocus={(e) => {
            setFocused(true)
            if (props.onFocus) props.onFocus(e)
          }}
          onBlur={(e) => {
            setFocused(false)
            if (props.onBlur) props.onBlur(e)
          }}
        />

        {clearIcon && props.value && focused && Platform.OS !== 'web' ? (
          <View style={styles.clearIconWrapper}>
            <Pressable
              hitSlop={10}
              style={({ pressed }) => [styles.clearIcon, { opacity: pressed ? 0.3 : 1 }]}
              onPress={() => props.onChangeText ? props.onChangeText('') : undefined}
            >
              <FontAwesomeIcon
                icon={faXmark}
                size={13}
                color={theme.colors.background}
              />
            </Pressable>
          </View>
        ) : null}
      </Pressable>

      {typeof error === 'string' && <Text type='inputError' compact={compact}>{error}</Text>}
    </View>
  )
})
