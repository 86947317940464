import { StyleSheet, View } from 'react-native'

type Props = {
  value: number
  width: number
  height: number
  pathColor: string
  trailColor: string
}

export function ProgressBar({ value, width, height, pathColor, trailColor }: Props) {
  const styles = StyleSheet.create({
    trail: {
      width,
      height,
      backgroundColor: trailColor,
      borderRadius: height / 2,
      overflow: 'hidden',
      position: 'relative',
    },
    path: {
      position: 'absolute',
      width: `${value * 100}%`,
      height: '100%',
      backgroundColor: pathColor,
    },
  })

  return (
    <View style={styles.trail}>
      <View style={styles.path} />
    </View>
  )
}
