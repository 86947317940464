import { Text as RNText, StyleSheet, TextProps } from 'react-native'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

type Props = TextProps & {
  type?: 'title' | 'heading' | 'subheading' | 'body' | 'caption'
  | 'headerTitle' | 'headerAction'
  | 'cardHeading' | 'cardSubheading' | 'cardBody' | 'cardAction'
  | 'listHeading' | 'listSubheading' | 'listBody' | 'listMeta'
  | 'inputLabel' | 'inputText' | 'inputError',
  compact?: boolean
}

export function Text({ type, compact, style, children, ...props }: Props) {
  const theme = useTheme()

  let color = theme.colors.foreground
  let fontFamily = theme.fonts.body[400]
  let fontSize: number | undefined
  let lineHeight: number | undefined

  switch (type) {
    case 'title':
      fontFamily = theme.fonts.body[700]
      fontSize = 24
      lineHeight = 29
      break
    case 'heading':
      fontFamily = theme.fonts.body[600]
      fontSize = 21
      lineHeight = 25
      break
    case 'subheading':
      fontFamily = theme.fonts.body[600]
      fontSize = 19
      lineHeight = 23
      break
    case 'body':
      fontFamily = theme.fonts.body[400]
      fontSize = 16
      lineHeight = 22
      break
    case 'caption':
      fontFamily = theme.fonts.body[400]
      fontSize = 15
      lineHeight = 21
      break
    case 'headerTitle':
      fontFamily = theme.fonts.body[600]
      fontSize = 16.5
      lineHeight = 20
      break
    case 'headerAction':
      fontFamily = theme.fonts.body[500]
      fontSize = 16.5
      lineHeight = 20
      break
    case 'cardHeading':
      fontFamily = theme.fonts.body[600]
      fontSize = 17
      lineHeight = 21
      break
    case 'cardSubheading':
      fontFamily = theme.fonts.body[500]
      fontSize = 16
      lineHeight = 20
      break
    case 'cardBody':
      fontFamily = theme.fonts.body[400]
      fontSize = 15.5
      lineHeight = 20
      break
    case 'cardAction':
      color = theme.colors.primary
      fontFamily = theme.fonts.body[500]
      fontSize = 15.5
      lineHeight = 20
      break
    case 'listHeading':
      fontFamily = theme.fonts.body[500]
      fontSize = 16
      lineHeight = 20
      break
    case 'listSubheading':
      fontFamily = theme.fonts.body[400]
      fontSize = 16
      lineHeight = 20
      break
    case 'listBody':
      fontFamily = theme.fonts.body[400]
      fontSize = 15
      lineHeight = 19
      break
    case 'listMeta':
      color = colorWithOpacity(theme.colors.foreground, 0.7)
      fontFamily = theme.fonts.body[400]
      fontSize = 14.5
      lineHeight = 18
      break
    case 'inputLabel':
      fontFamily = theme.fonts.body[500]
      fontSize = (compact ? theme.input.compact.fontSize : theme.input.fontSize) - 0.5
      lineHeight = compact ? 18 : 19
      break
    case 'inputText':
      fontFamily = theme.input.fontFamily
      fontSize = compact ? theme.input.compact.fontSize : theme.input.fontSize
      lineHeight = compact ? 19 : 20
      break
    case 'inputError':
      color = theme.colors.danger
      fontFamily = theme.fonts.body[400]
      fontSize = (compact ? theme.input.compact.fontSize : theme.input.fontSize) - 0.5
      lineHeight = compact ? 17 : 18
      break
  }

  const styles = StyleSheet.create({
    text: {
      display: type === 'inputLabel' || type === 'inputError' ? 'flex' : undefined,
      color,
      fontFamily,
      fontSize,
      lineHeight,
      marginTop: type === 'inputError' ? 6 : undefined,
      marginBottom: type === 'inputLabel' ? 6 : undefined,
    },
  })

  return <RNText {...props} style={[styles.text, style]}>{children}</RNText>
}
