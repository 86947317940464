/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState } from 'react'
import { Toast } from 'react-native-toast-universal'
import { states } from 'shared'
import { Button, Panel, Select, Spacing, Table, Text, useTheme, Wrapper } from 'ui'
import { Head } from '@/components/web/Head'
import { TextInput } from '@/components/web/TextInput'
import { useStore } from '@/providers/store'
import { fieldErrors } from '@/queries/client'
import { useAllProviders, useCreateProvider, useUpdateProvider } from '@/queries/provider.queries'
import { useUsers } from '@/queries/user.queries'
import { ProcedureSchema } from '@/types/procedure'
import { ProviderClassificationSchema, ProviderListItem } from '@/types/provider'
import { User } from '@/types/user'

export function Providers() {
  const theme = useTheme()

  const users = useUsers()

  return <>
    <Head title='Providers' />

    <Wrapper maxWidth={1400} style={{ marginVertical: theme.spacing * 1.5 }}>
      <Header users={users.data ?? []} />
      <Panel><ProvidersTable users={users.data ?? []} /></Panel>
    </Wrapper>
  </>
}

function Header({ users }: { users: User[] }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const styles = {
    wrapper: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Text type='heading'>Providers</Text>

      <Button
        text='New Provider'
        type='primary'
        compact={true}
        fontFamily={theme.fonts.body[500]}
        onPress={() => modal.open({
          title: 'New Provider',
          content: <ProviderDialog users={users} />
        })}
      />
    </div>
  )
}

function ProviderDialog({ provider, users }: { provider?: ProviderListItem, users: User[] }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const createProvider = useCreateProvider()
  const updateProvider = useUpdateProvider()

  const [name, setName] = useState(provider?.name ?? '')
  const [state, setState] = useState(provider?.state ?? '')
  const [procedure, setProcedure] = useState(provider?.procedure ?? '')
  const [manager, setManager] = useState(provider?.manager.id.toString() ?? '')
  const [staff, setStaff] = useState(provider?.staff?.id.toString() ?? '')
  const [rate, setRate] = useState(provider?.rate?.toString() ?? '')
  const [classification, setClassification] = useState(provider?.classification ?? '')
  const [error, setError] = useState<{
    name?: string
    state?: string
    procedure?: string
    manager?: string
    staff?: string
    rate?: string
    classification?: string
  }>()

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    fields: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing * 0.8,
      [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 200px)',
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <div css={styles.fields}>
        <TextInput
          label='Name'
          value={name}
          compact={true}
          error={error?.name}
          onChange={(e) => setName(e.target.value)}
        />
        <Select
          label='Location'
          value={state}
          options={Object.entries(states).map(([value, label]) => ({ value, label }))}
          placeholder='Select state'
          compact={true}
          error={error?.state}
          onChange={setState}
        />
        <Select
          label='Primary Lien Type'
          value={procedure}
          options={ProcedureSchema.options}
          placeholder='Select type'
          compact={true}
          error={error?.procedure}
          onChange={setProcedure}
        />
        <Select
          label='Market Manager'
          value={manager}
          options={users.filter((u) => u.role === 'manager').map((u) => ({ value: u.id.toString(), label: u.fullName ?? '' }))}
          placeholder='Select manager'
          compact={true}
          error={error?.manager}
          onChange={setManager}
        />
        <Select
          label='Staff'
          value={staff}
          options={users.filter((u) => u.role === 'staff' || u.email === 'montana@lienstar.com').map((u) => ({ value: u.id.toString(), label: u.fullName ?? '' }))}
          placeholder='Select staff'
          compact={true}
          error={error?.staff}
          onChange={setStaff}
        />
        <TextInput
          label='Default Buy Rate'
          value={rate}
          compact={true}
          error={error?.rate}
          onChange={(e) => setRate(e.target.value)}
        />
        <Select
          label='Classification'
          value={classification}
          options={ProviderClassificationSchema.options}
          placeholder='Select classification'
          compact={true}
          error={error?.classification}
          onChange={setClassification}
        />
      </div>
      <Spacing height={1.4} />
      <Button
        text={`${provider ? 'Update' : 'Add'} Provider`}
        type='primary'
        loading={createProvider.isPending || updateProvider.isPending}
        onPress={() => {
          if (provider) {
            updateProvider.mutate({ id: provider.id, name, state, procedure, manager, staff, rate, classification }, {
              onSuccess: () => {
                modal.close()
                Toast.success('Provider updated')
              },
              onError: (error) => setError(fieldErrors(error)),
            })
          } else {
            createProvider.mutate({ name, state, procedure, manager, staff, rate, classification }, {
              onSuccess: () => {
                modal.close()
                Toast.success('Provider added')
              },
              onError: (error) => setError(fieldErrors(error)),
            })
          }
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function ProvidersTable({ users }: { users: User[] }) {
  const modal = useStore.useModal()

  const [orderBy, setOrderBy] = useState('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const providers = useAllProviders(orderBy, sortOrder)

  return (
    <Table
      columns={[
        {
          data: 'name',
          title: 'Name',
          width: 400,
          sortable: true,
        },
        {
          data: 'state',
          title: 'Location',
          width: 160,
          sortable: true,
        },
        {
          data: 'procedure',
          title: 'Primary Lien Type',
          width: 170,
          sortable: true,
        },
        {
          data: 'manager',
          title: 'Market Manager',
          width: 180,
          sortable: true,
        },
        {
          data: 'staff',
          title: 'Staff',
          width: 180,
          sortable: true,
        },
        {
          data: 'classification',
          title: 'Classification',
          width: 130,
          sortable: true,
        },
        {
          data: 'rate',
          title: 'Default Buy Rate',
          width: 160,
          alignRight: true,
          sortable: true,
        },
      ]}
      data={providers.data?.map((provider) => ({
        id: provider.id,
        name: provider.name,
        state: states[provider.state],
        procedure: provider.procedure,
        manager: provider.manager.fullName,
        staff: provider.staff?.fullName,
        classification: provider.classification,
        rate: provider.rate + '%',
      })) ?? []}
      loading={providers.isPending}
      noDataMessage='No providers found'
      fixedLayout={true}
      stickyHeader={true}
      orderBy={orderBy}
      sortOrder={sortOrder}
      onUpdateSort={({ orderBy, sortOrder }) => {
        setOrderBy(orderBy)
        setSortOrder(sortOrder)
      }}
      onRowClick={(row) => modal.open({
        title: 'Edit Provider',
        content: <ProviderDialog provider={providers.data?.find((p) => p.id === row.id)} users={users} />
      })}
    />
  )
}
