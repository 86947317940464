/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import * as RadixDialog from '@radix-ui/react-dialog'
import { useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { colorWithBrightness, useTheme } from '../../hooks/useTheme'

const TRANSITION_DURATION = 250

export type AlertOptions = {
  title: string
  message?: string
  buttons: AlertButton[]
}

export type AlertButton = {
  text: string
  style?: 'primary' | 'destructive'
  onPress: () => void
}

type Props = {
  active: AlertOptions | undefined
  open: (alert: AlertOptions) => void
  close: () => void
}

export function Alert(props: Props) {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const [active, setActive] = useState<AlertOptions>()
  const [open, setOpen] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setOpen(props.active !== undefined)

    if (props.active) {
      setActive(props.active)
      setTimeout(() => (document.body.style.pointerEvents = ''), 0)
    } else {
      setTimeout(() => setActive(undefined), TRANSITION_DURATION)
    }
  }, [props.active])

  const overlayShow = keyframes({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  })
  const overlayHide = keyframes({
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  })

  const contentShow = keyframes({
    from: {
      opacity: 0,
      transform: 'translate(-50%, -50%) scale(0.96)',
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    },
  })
  const contentHide = keyframes({
    from: {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    },
    to: {
      opacity: 0,
      transform: 'translate(-50%, -50%) scale(0.96)',
    },
  })

  const fontSize = theme.dialog?.fontSize ?? 19

  const styles = {
    overlay: css({
      position: 'fixed',
      inset: 0,
      background: 'rgba(0, 0, 0, 0.32)',
      '&[data-state=open]': {
        animation: `${overlayShow}
          ${TRANSITION_DURATION}ms
          ${theme.web.easing.curveLinearToEaseOut}`,
      },
      '&[data-state=closed]': {
        animation: `${overlayHide}
          ${TRANSITION_DURATION}ms
          ${theme.web.easing.curveLinearToEaseOut}`,
      },
    }),
    content: css({
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: width < 420 ? '90vw' : undefined,
      minWidth: width >= 420 ? 360 : undefined,
      maxHeight: '85vh',
      overflowY: 'auto',
      background: theme.colors.surface,
      borderRadius: theme.borderRadius.m,
      boxShadow: '0 40px 125px -25px rgba(0, 0, 0, 0.1)',
      '&[data-state=open]': {
        animation: `${contentShow}
          ${TRANSITION_DURATION}ms
          ${theme.web.easing.curveLinearToEaseOut}`,
      },
      '&[data-state=closed]': {
        animation: `${contentHide}
          ${TRANSITION_DURATION}ms
          ${theme.web.easing.curveLinearToEaseOut}`,
      },
    }),
    title: css({
      color: theme.colors.foreground,
      fontFamily: theme.fonts.body[600],
      fontWeight: 600,
      fontSize,
      textAlign: 'center',
      marginTop: theme.spacing * 1.1,
      marginBottom: theme.spacing * 0.1,
      [`@media (min-width: ${theme.breakpoints.s}px)`]: {
        fontSize: fontSize * 1.05,
      },
      [`@media (min-width: ${theme.breakpoints.m}px)`]: {
        fontSize: fontSize * 1.1,
      },
    }),
  }

  return (
    <RadixDialog.Root
      open={open}
      onOpenChange={() => props.close()}
    >
      <RadixDialog.Overlay css={styles.overlay} />
      <RadixDialog.Content
        ref={contentRef}
        aria-describedby={undefined}
        css={[styles.content]}
        onPointerDownOutside={(e) => {
          if (!contentRef.current) return
          const rect = contentRef.current.getBoundingClientRect()
          const clickedInside =
            e.detail.originalEvent.clientX > rect.left &&
            e.detail.originalEvent.clientX < rect.left + rect.width &&
            e.detail.originalEvent.clientY > rect.top &&
            e.detail.originalEvent.clientY < rect.top + rect.height
          if (clickedInside) e.preventDefault()
        }}
      >
        <RadixDialog.Title css={styles.title}>{active?.title}</RadixDialog.Title>

        {active && <AlertContent {...active} />}
      </RadixDialog.Content>
    </RadixDialog.Root>
  )
}

function AlertContent(alert: AlertOptions) {
  const theme = useTheme()

  const styles = {
    message: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 15.5,
      lineHeight: 1.3,
      textAlign: 'center',
      maxWidth: 320,
      margin: `${theme.spacing * 1.2}px auto`,
    }),
    buttons: css({
      display: 'flex',
      borderTop: `1px solid ${theme.colors.divider}`,
      marginTop: theme.spacing * 1.4,
    }),
    button: css({
      flex: 1,
      height: 50,
      cursor: 'pointer',
      fontFamily: theme.fonts.body[500],
      fontSize: 15.5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderLeft: `1px solid ${theme.colors.divider}`,
      '&:first-of-type': {
        borderLeft: 'none',
      },
      '&:hover': {
        background: colorWithBrightness(theme.colors.surface, -5),
      },
    }),
  }

  return <>
    {alert.message && (
      <div css={styles.message}>{alert.message}</div>
    )}

    {alert.buttons && (
      <div css={styles.buttons}>
        {alert.buttons.map((button) => (
          <div
            key={button.text}
            css={styles.button}
            style={{
              color: button.style === 'primary' ? theme.colors.primary
                : button.style === 'destructive' ? theme.colors.danger : theme.colors.foreground,
            }}
            onClick={button.onPress}
          >{button.text}</div>
        ))}
      </div>
    )}
  </>
}
