import { GoogleSignin } from '@react-native-google-signin/google-signin'
import { Stack } from 'expo-router'
import { Dispatch, ReactNode, SetStateAction, useRef, useState } from 'react'
import { Platform, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Toast } from 'react-native-toast-universal'
import { Button } from '../../components/Button'
import { CodeInput, CodeInputHandle } from '../../components/CodeInput'
import { DobInput } from '../../components/DobInput'
import { PhoneInput } from '../../components/PhoneInput'
import { Select } from '../../components/Select'
import { Spacing } from '../../components/Spacing'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { TextLink } from '../../components/TextLink'
import { colorWithOpacity, useTheme } from '../../hooks/useTheme'
import { launchUrl } from '../../utils'
import { AppleAuth } from './AppleAuth'
import { DevAuth, DevAuthProps } from './DevAuth'
import { GoogleAuth } from './GoogleAuth'

type mode = {
  screen: 'access' | 'signin' | 'signup'
  params?: {
    email?: string
    firstName?: string
    lastName?: string
    photo?: string
    accountCreated?: boolean
    emailVerified?: boolean
  }
}
export type setMode = Dispatch<SetStateAction<mode>>

type SignInWithEmail = {
  loading: boolean
  onPress: (email: string, onSuccess: (isUser: boolean) => void, onError: (error: any) => void) => void
}
type SignInWithCode = {
  loading: boolean
  onPress: (code: string) => Promise<boolean>
}
export type SignInWithIdToken = {
  loading: boolean
  onPress: (idToken: string, onSuccess: (form: any) => void) => void
}
type SignUp = {
  loading: boolean
  onPress: (data: any, onSuccess: (verifiedEmail: boolean) => void, onError: (error: any) => void) => void
}
type SignupFields = ('email' | 'firstName' | 'lastName' | 'phone' | 'sex' | 'dob')[]

type Props = {
  title: string
  wrapperStyle?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  preContent?: ReactNode
  access: {
    prompt: string
    signInWithEmail: SignInWithEmail
    apple?: {
      servicesId: string
      signIn: SignInWithIdToken
    }
    google?: {
      iosClientId: string
      androidClientId: string
      webClientId: string
      signIn: SignInWithIdToken
    }
    dev?: DevAuthProps | DevAuthProps[]
  }
  signin: {
    signInWithCode: SignInWithCode
    signInWithEmail: SignInWithEmail
  }
  signup?: {
    userAgreementUrl?: string
    fields: SignupFields
    signUp: SignUp
  }
}

export function AuthModule({
  title,
  wrapperStyle,
  contentStyle,
  preContent,
  access,
  signin,
  signup,
}: Props) {
  const theme = useTheme()

  const [mode, setMode] = useState<mode>({ screen: 'access' })

  const styles = StyleSheet.create({
    content: {
      paddingHorizontal: theme.spacing * 1.5,
    },
    heading: {
      textAlign: 'center',
      marginTop: theme.spacing * 1.5,
    },
    prompt: {
      textAlign: 'center',
      alignSelf: 'center',
      marginVertical: theme.spacing * 1.5,
    },
  })

  let content: ReactNode
  switch (mode.screen) {
    case 'access':
      content = <>
        {access.prompt && (
          <Text type='body' style={{ ...styles.prompt, maxWidth: 260 }}>{access.prompt}</Text>
        )}
        <Access
          setMode={setMode}
          signInWithEmail={access.signInWithEmail}
          apple={access.apple}
          google={access.google}
          dev={access.dev}
        />
      </>
      break
    case 'signin':
      content = <>
        {mode.params?.accountCreated && (
          <Text type='subheading' style={styles.heading}>Account Created!</Text>
        )}
        <Text type='body' style={{ ...styles.prompt, maxWidth: 220 }}>
          To sign in, enter the code sent to your inbox.
        </Text>
        <Signin
          mode={mode}
          signInWithCode={signin.signInWithCode}
          signInWithEmail={signin.signInWithEmail}
        />
      </>
      break
    case 'signup':
      content = signup ? <>
        {signup.userAgreementUrl && (
          <Text type='body' style={{ ...styles.prompt, maxWidth: 270 }}>
            By creating an account, I accept the terms of the&nbsp;
            <Text
              type='body'
              style={{ color: theme.colors.primary }}
              onPress={() => launchUrl(signup.userAgreementUrl!)}
            >User Agreement</Text>.
          </Text>
        )}
        <Signup
          mode={mode}
          setMode={setMode}
          fields={signup.fields}
          signUp={signup.signUp}
        />
      </> : null
      break
  }

  return <>
    {Platform.OS !== 'web' && (
      <Stack.Screen options={{ title }} />
    )}

    <ScrollView
      automaticallyAdjustKeyboardInsets={true}
      automaticallyAdjustsScrollIndicatorInsets={false}
      keyboardShouldPersistTaps='handled'
      contentContainerStyle={wrapperStyle}
    >
      <View style={[styles.content, contentStyle]}>
        {preContent}
        {content}
      </View>
    </ScrollView>
  </>
}

type AccessProps = {
  setMode: setMode
  signInWithEmail: SignInWithEmail
  apple?: {
    servicesId: string
    signIn: SignInWithIdToken
  }
  google?: {
    iosClientId: string
    androidClientId: string
    webClientId: string
    signIn: SignInWithIdToken
  }
  dev?: DevAuthProps | DevAuthProps[]
}

function Access({ setMode, signInWithEmail, apple, google, dev }: AccessProps) {
  const theme = useTheme()

  const [email, setEmail] = useState('')
  const [error, setError] = useState<{ email?: string }>()

  async function handleSubmit() {
    signInWithEmail.onPress(
      email,
      (isUser) => {
        setMode({ screen: isUser ? 'signin' : 'signup', params: { email } })
      },
      (error) => setError(error),
    )
  }

  const styles = StyleSheet.create({
    divider: {
      marginVertical: theme.spacing * 1.7,
      flexDirection: 'row',
      alignItems: 'center',
    },
    dividerLine: {
      flex: 1,
      height: 1,
      backgroundColor: colorWithOpacity(theme.colors.foreground, 0.2),
    },
    dividerText: {
      textTransform: 'uppercase',
      marginHorizontal: 18,
      fontFamily: theme.fonts.body[600],
      fontSize: 13.5,
      color: colorWithOpacity(theme.colors.foreground, 0.6),
    },
    buttons: {
      rowGap: theme.spacing * 0.8,
    },
  })

  return <>
    <TextInput
      value={email}
      placeholder='Email'
      autoCapitalize='none'
      autoComplete='email'
      keyboardType='email-address'
      textContentType='emailAddress'
      error={error?.email}
      onChangeText={setEmail}
      onSubmitEditing={handleSubmit}
    />
    <Spacing height={1.5} />
    <Button
      text='Continue with Email'
      type='primary'
      loading={signInWithEmail.loading}
      onPress={handleSubmit}
    />

    <View style={styles.divider}>
      <View style={styles.dividerLine} />
      <Text style={styles.dividerText}>or</Text>
      <View style={styles.dividerLine} />
    </View>

    <View style={styles.buttons}>
      {apple && <AppleAuth setMode={setMode} apple={apple} />}
      {google && <GoogleAuth setMode={setMode} google={google} />}
      {dev && (
        Array.isArray(dev) ? (
          dev.map((button, index) => (
            <DevAuth
              key={index}
              text={button.text}
              loading={button.loading}
              onPress={button.onPress}
            />
          ))
        ) : (
          <DevAuth
            text={dev.text}
            loading={dev.loading}
            onPress={dev.onPress}
          />
        )
      )}
    </View>
    <Spacing height={1.5} />
  </>
}

type SigninProps = {
  mode: mode
  signInWithCode: SignInWithCode
  signInWithEmail: SignInWithEmail
}

function Signin({ mode, signInWithCode, signInWithEmail }: SigninProps) {
  const codeInputRef = useRef<CodeInputHandle>(null)

  const styles = StyleSheet.create({
    resendCode: {
      alignSelf: 'center',
    },
  })

  return <>
    <CodeInput
      ref={codeInputRef}
      length={5}
      autoFocus={true}
      onChange={(value) => signInWithCode.onPress(value)}
    />
    <Spacing height={1.5} />
    <Button
      text='Enter Code'
      type='primary'
      loading={signInWithCode.loading || signInWithEmail.loading}
      onPress={() => codeInputRef.current?.focus()}
    />
    <Spacing height={1.2} />
    <TextLink
      type='info'
      style={styles.resendCode}
      onPress={() => {
        signInWithEmail.onPress(
          mode.params!.email!,
          (_) => Toast.success('Code resent. Please check your inbox.'),
          (_) => Toast.error('Unable to send code. Please try again later.'),
        )
      }}
    >Resend code</TextLink>
    <Spacing height={1.5} />
  </>
}

type SignupProps = {
  mode: mode
  setMode: setMode
  fields: SignupFields
  signUp: SignUp
}

function Signup({ mode, setMode, fields, signUp }: SignupProps) {
  const theme = useTheme()

  const [email, setEmail] = useState(mode.params?.email ?? '')
  const [firstName, setFirstName] = useState(mode.params?.firstName ?? '')
  const [lastName, setLastName] = useState(mode.params?.lastName ?? '')
  const [phone, setPhone] = useState('')
  const [sex, setSex] = useState('')
  const [dob, setDob] = useState('')
  const [error, setError] = useState<{
    email?: string
    firstName?: string
    lastName?: string
    phone?: string
    sex?: string
    dob?: string
  }>()

  return <>
    <View style={{ rowGap: theme.spacing }}>
      {fields.map((field) => {
        switch (field) {
          case 'email':
            return (
              <TextInput
                key='email'
                label='Email'
                placeholder='Email'
                value={email}
                autoCapitalize='none'
                autoComplete='email'
                error={error?.email}
                keyboardType='email-address'
                editable={!mode.params?.emailVerified}
                textContentType='emailAddress'
                onChangeText={setEmail}
              />
            )
          case 'firstName':
            return (
              <TextInput
                key='firstName'
                label='First name'
                placeholder='First name'
                value={firstName}
                autoCapitalize='words'
                autoComplete='name-given'
                error={error?.firstName}
                textContentType='givenName'
                onChangeText={setFirstName}
              />
            )
          case 'lastName':
            return (
              <TextInput
                key='lastName'
                label='Last name'
                value={lastName}
                placeholder='Last name'
                autoCapitalize='words'
                autoComplete='name-family'
                error={error?.lastName}
                textContentType='familyName'
                onChangeText={setLastName}
              />
            )
          case 'phone':
            return (
              <PhoneInput
                key='phone'
                label='Phone'
                placeholder='Phone'
                value={phone}
                error={error?.phone}
                onChangeText={setPhone}
              />
            )
          case 'sex':
            return (
              <Select
                key='sex'
                label='Sex'
                value={sex}
                options={['Male', 'Female', 'Other']}
                placeholder='Sex'
                error={error?.sex}
                onChange={setSex}
              />
            )
          case 'dob':
            return (
              <DobInput
                key='dob'
                value={dob}
                error={error?.dob}
                style={{ maxWidth: 340 }}
                onChange={setDob}
              />
            )
        }
      })}
    </View>
    <Spacing height={1.5} />
    <Button
      text='Create Account'
      type='primary'
      loading={signUp.loading}
      onPress={() => {
        signUp.onPress(
          { email, firstName, lastName, phone, sex, dob, photo: mode.params?.photo },
          (verifiedEmail) => {
            if (!verifiedEmail) setMode({ screen: 'signin', params: { accountCreated: true } })
          },
          (error) => setError(error)
        )
      }}
    />
    <Spacing height={1.5} />
  </>
}

AuthModule.signOut = function () {
  if (Platform.OS === 'android') {
    GoogleSignin.configure()
    GoogleSignin.signOut()
  }
}
