export function toName(str: string, ...type: ('name' | 'address')[]): string {
  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

  let s: string = str

  // general case
  s = s.toLowerCase().replace(/\s+/g, ' ').trim().replace(/\b\w/g, (l) => l.toUpperCase())

  // dumb down smart quotes
  s = s.replace(/[`‘’]/g, "'").replace(/[“”]/g, '"')

  // initials (should this be :name only?)
  s = s.replace(/\b([a-z])\. ?([bcdfghjklmnpqrstvwxyz])\.?(?=\W|$)/gi, (match, p1, p2) => `${p1}${p2}`.toUpperCase())

  // uppercase apparent acronyms
  s = s.replace(/\b([a-z](?:(?![aeiouy])[a-z]){1,4})\b/gi, (match, p1) => p1.toUpperCase())

  // force camel-case
  s = s.replace(/\b([djs]r|us|acct|[ai]nn?|apps|ed|erb|elk|esq|grp|in[cj]|of[cf]|st|up)\.?(?=\W|$)/gi, (match) => capitalize(match))

  // articles, conjunctions, and prepositions
  s = s.replace(/(^|(?<=\d ))?\b(and|at|as|of|the|in|on|or|for|to|by|de l[ao]s?|del?|(el-)|el|las)($)?\b/ig, (match, p1, p2, p3, p4, o) => {
    return (o === 0 || p1 || p3 || p4) ? capitalize(p2) : p2.toLowerCase()
  })

  // mixed case (Irish)
  s = s.replace(/\b(mc|mac(?=d[ao][a-k,m-z][a-z]|[fgmpw])|[dol]')([a-z])/gi, (match: string, p1: string, p2: string) => {
    return capitalize(p1) + capitalize(p2)
  })

  // capitalize
  if (type.includes('name')) {
    s = s.replace(/\b(ahn|an[gh]|al|art[sz]?|ash|e[dnv]|echt|elms|emms|eng|epps|essl|i[mp]|mrs?|ms|ng|ock|o[hm]|ohrt|ong|orr|ohrt|ost|ott|oz|sng|tsz|u[br]|ung)\b/gi, function (match) {
      return capitalize(match)
    })
  }

  // road features FIXME: not working
  if (type.includes('address')) {
    s = s.replace(/(?<=^| |[[:punct:]])(apt?s?|arch|ave?|bldg|blvd|cr?t|co?mn|drv?|elm|end|f[lt]|hts?|ln|old|pkw?y|plc?|prk|pt|r[dm]|spc|s[qt]r?|srt|street|[nesw])\.?(?=\W|$)/gi, (matched) => capitalize(matched))
  }

  // ordinal numbers
  s = s.replace(/(1st|2nd|3rd|[\d]th|de l[ao]s)\b/gi, (match) => match.toLowerCase())

  // uppercase (Spanish) FIXME: not working
  // s = s.replace(/(?<=^|\d |\b[nesw] |\b[ns][ew] )(d?el|las?|los)\b/gi, (match) => capitalize(match))

  // force uppercase
  s = s.replace(/\b(ca|dba|fbo|ihop|mri|ucla|usa|vru|[ns][ew]|i{1,3}v?)\b/gi, (match) => match.toUpperCase())

  // domain names, email
  s = s.replace(/\b([-@.\w]+\.(?:com|net|io|org))\b/gi, (match) => match.toLowerCase())

  // ensure "Lee's Ferry" is not "Lee'S Ferry"
  s = s.replace(/(?<=\w')S\b/g, 's')

  // collapse spaces following a number sign
  s = s.replace(/# /g, '#')

  // nuke any trailing period, comma, or hash signs
  s = s.replace(/[.,#]+$/, '')

  // PO Boxes
  s = s.replace(/\bP\.? ?O\.? ?Box/i, 'PO Box')

  return s
}
