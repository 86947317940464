import { router } from 'expo-router'
import { useStore } from '@/providers/store'
import { useAccidents } from '@/queries/accident.queries'
import { AccidentDetails } from '@/screens/accidents/AccidentDetails'

export default function AccidentDetailsScreen() {
  const filter = useStore.useAccidentFilter()

  const accidents = useAccidents(filter)

  return (
    <AccidentDetails
      list={accidents.data ? {
        items: accidents.data.accidents.map((a) => a.id),
        onNav: (accidentId: number) => router.navigate(`/cases/${accidentId}`),
      } : undefined}
    />
  )
}
