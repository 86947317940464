import { QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { api } from '@/queries/api'

const keys = {
  authUrl: (): QueryKey => ['quickbooksAuthUrl'],
}

export function useQuickbooksAuthUrl() {
  return useQuery({
    queryKey: keys.authUrl(),
    queryFn: async () => {
      const res = await api.get('quickbooks/authUrl')
      return res.data.authUrl ?? null
    },
  })
}

export function useQuickbooksAuth() {
  return useMutation({
    mutationFn: (url: string) => api.post('quickbooks/auth', { url }),
  })
}

export function useQuickbooksDisconnect() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => api.post('quickbooks/disconnect'),
    onSuccess: (res) => {
      queryClient.setQueryData(keys.authUrl(), res.data.authUrl)
    },
  })
}
