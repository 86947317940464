import 'expo-dev-client'
import '@/providers/font-awesome'
import NetInfo from '@react-native-community/netinfo'
import { DarkTheme, DefaultTheme, ThemeProvider as RNThemeProvider } from '@react-navigation/native'
import * as Sentry from '@sentry/react-native'
import { focusManager, onlineManager, QueryClientProvider } from '@tanstack/react-query'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import { useFonts } from 'expo-font'
import * as NavigationBar from 'expo-navigation-bar'
import { Stack, usePathname } from 'expo-router'
import * as SplashScreen from 'expo-splash-screen'
import { StatusBar } from 'expo-status-bar'
import { PostHogProvider, usePostHog } from 'posthog-react-native'
import { useEffect, useState } from 'react'
import { AppState, Platform, Alert as RNAlert, View } from 'react-native'
import { KeyboardProvider } from 'react-native-keyboard-controller'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Toaster } from 'react-native-toast-universal'
import { Alert, CustomStack, Modal, useColorScheme, useTheme } from 'ui'
import { useStore } from '@/providers/store'
import { ThemeProvider } from '@/providers/theme'
import { client } from '@/queries/client'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(weekday)
SplashScreen.preventAutoHideAsync()

Sentry.init({
  dsn: process.env.EXPO_PUBLIC_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  attachScreenshot: true,
  // enabled: process.env.NODE_ENV === 'production',
  enabled: false, // TODO: enable when we're live
})

if (Platform.OS !== 'web') {
  onlineManager.setEventListener((setOnline) => {
    return NetInfo.addEventListener((state) => {
      setOnline(!!state.isConnected)
    })
  })
}

export default Sentry.wrap(RootLayout)

function RootLayout() {
  const colorScheme = useColorScheme()
  const [fontsLoaded] = useFonts({
    Inter400: require('assets/fonts/Inter-400.ttf'),
    Inter500: require('assets/fonts/Inter-500.ttf'),
    Inter600: require('assets/fonts/Inter-600.ttf'),
    Inter700: require('assets/fonts/Inter-700.ttf'),
  })

  if (Platform.OS === 'android') {
    NavigationBar.setBackgroundColorAsync('transparent')
  }

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (status) => {
      if (Platform.OS !== 'web') focusManager.setFocused(status === 'active')
    })

    return () => subscription.remove()
  }, [])

  useEffect(() => {
    if (fontsLoaded) SplashScreen.hide()
  }, [fontsLoaded])

  if (!fontsLoaded) return null
  return (
    <PostHogProvider
      apiKey={process.env.EXPO_PUBLIC_POSTHOG_KEY}
      options={{ disabled: process.env.NODE_ENV !== 'production' }}
      autocapture={false}
    >
      <QueryClientProvider client={client}>
        <KeyboardProvider statusBarTranslucent={true} navigationBarTranslucent={true}>
          <SafeAreaProvider>
            <ThemeProvider>
              <RNThemeProvider value={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
                <StatusBar style='auto' />
                <RootStack />
                {Platform.OS === 'web' && <GlobalWeb />}
                {Platform.OS !== 'web' && <AlertNative />}
              </RNThemeProvider>
            </ThemeProvider>
          </SafeAreaProvider>
        </KeyboardProvider>
      </QueryClientProvider>
    </PostHogProvider>
  )
}

function RootStack() {
  const pathname = usePathname()
  const theme = useTheme()
  const posthog = usePostHog()

  useEffect(() => {
    posthog?.screen(pathname)
  }, [pathname, posthog])

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.pageBackground }}>
      <CustomStack screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen
          name='(app)'
          options={{
            presentation: 'card',
            animation: 'fade',
            headerShown: false,
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name='welcome'
          options={{
            presentation: 'card',
            animation: 'fade',
            headerShown: false,
            gestureEnabled: false,
          }}
        />
      </CustomStack>
    </View>
  )
}

function GlobalWeb() {
  const theme = useTheme()
  const modal = useStore.useModal()
  const alert = useStore.useAlert()

  return <>
    <Modal {...modal} />
    <Alert {...alert} />

    <Toaster
      position='bottom-center'
      gutter={theme.spacing * 0.7}
      containerStyle={{ bottom: theme.spacing * 1.5 }}
      toastOptions={{
        style: {
          fontFamily: theme.fonts.body[400],
          fontSize: 15.5,
          lineHeight: 1.3,
          borderRadius: theme.borderRadius.m,
          maxWidth: 400,
        },
        success: {
          iconTheme: {
            primary: theme.colors.success,
            secondary: '#FFFFFF',
          },
        },
        error: {
          iconTheme: {
            primary: theme.colors.danger,
            secondary: '#FFFFFF',
          },
        },
      }}
    />

    <style>
      @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap')
    </style>

    <style>{`
      html {
        box-sizing: border-box;
      }
      *, *::before, *::after {
        box-sizing: inherit;
      }
      *:focus {
        outline: none;
      }
      body {
        overflow-y: scroll;
        background: ${theme.colors.pageBackground};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    `}</style>
  </>
}

function AlertNative() {
  const alert = useStore.useAlert()

  const [prevAlert, setPrevAlert] = useState(alert.active)
  if (alert.active !== prevAlert) {
    if (alert.active) {
      RNAlert.alert(
        alert.active.title,
        alert.active.message,
        alert.active.buttons.map((item) => ({
          text: item.text,
          style: item.style === 'destructive' ? 'destructive' : undefined,
          onPress: item.onPress,
        })),
        { cancelable: true },
      )
    }
    setPrevAlert(alert.active)
  }

  return null
}
