import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import * as Haptics from 'expo-haptics'
import { useEffect, useRef, useState } from 'react'
import { Platform, Pressable, StyleSheet, View, ViewProps } from 'react-native'
import Animated, { Keyframe } from 'react-native-reanimated'
import { colorWithBrightness, colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Text } from './Text'

type Props = ViewProps & {
  label?: string
  text: string
  value: boolean
  compact?: boolean
  error?: string | boolean
  onChange: (checked: boolean) => void
}

export function Checkbox({ label, text, value, compact, error, onChange, ...props }: Props) {
  const theme = useTheme()

  const [hovered, setHovered] = useState(false)
  const [pressed, setPressed] = useState(false)

  const renderedRef = useRef(false)
  useEffect(() => {
    renderedRef.current = true
  }, [])

  useEffect(() => {
    if (value && Platform.OS !== 'web') Haptics.selectionAsync()
  }, [value])

  const animation = new Keyframe({
    0: {
      transform: [{ scale: 0 }],
      opacity: 0,
    },
    15: {
      transform: [{ scale: 1.3 }],
      opacity: 1,
      easing: theme.easing.curveLinearToEaseOut,
    },
    100: {
      transform: [{ scale: 1 }],
      easing: theme.easing.curveLinearToEaseOut,
    },
  }).duration(250)

  const height = compact ? theme.input.compact.height : theme.input.height
  const size = compact ? 18.5 : 20.5

  const styles = StyleSheet.create({
    outer: {
      backgroundColor: theme.colors.transparencyBase,
      borderRadius: theme.borderRadius.s,
      ...theme.input.shadow,
    },
    inner: {
      height,
      backgroundColor: value ? colorWithOpacity(theme.colors.primary, 0.12)
        : colorWithBrightness(theme.colors.surface, pressed || hovered ? -5 : 0),
      borderWidth: 1,
      borderColor: error ? theme.colors.danger : theme.input.border,
      borderRadius: theme.borderRadius.s,
      paddingHorizontal: height * 0.3,
      flexDirection: 'row',
      alignItems: 'center',
    },
    text: {
      color: colorWithOpacity(theme.colors.foreground, 0.9),
      userSelect: 'none',
    },
    check: {
      width: size,
      height: size,
      backgroundColor: value ? theme.colors.primary : undefined,
      borderWidth: 1,
      borderColor: value ? theme.colors.primary : theme.input.border,
      borderRadius: size,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 9,
    },
  })

  return (
    <View {...props}>
      {label && <Text type='inputLabel' compact={compact}>{label}</Text>}

      <View style={styles.outer}>
        <Pressable
          style={styles.inner}
          onHoverIn={() => setHovered(true)}
          onHoverOut={() => setHovered(false)}
          onPressIn={() => setPressed(true)}
          onPressOut={() => setPressed(false)}
          onPress={() => onChange(!value)}
        >
          <View style={styles.check}>
            {value && (
              <Animated.View entering={renderedRef.current ? animation : undefined}>
                <FontAwesomeIcon
                  icon={faCheck}
                  size={compact ? 13 : 14.5}
                  color='#FFFFFF'
                />
              </Animated.View>
            )}
          </View>
          <Text type='inputText' compact={compact} style={styles.text}>{text}</Text>
        </Pressable>
      </View>

      {typeof error === 'string' && <Text type='inputError'>{error}</Text>}
    </View>
  )
}
