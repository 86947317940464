import { ReactNode } from 'react'
import { ExecutiveDashboardScreen } from './ExecutiveDashboard'
import { StaffDashboardScreen } from './StaffDashboard'
import { Head } from '@/components/web/Head'
import { useUser } from '@/queries/user.queries'

export function Dashboard() {
  const user = useUser()

  let content: ReactNode
  switch (user.data?.role) {
    case 'staff':
      content = <StaffDashboardScreen />
      break
    case 'manager':
      if (user.data.email === 'montana@lienstar.com') {
        content = <StaffDashboardScreen />
      } else {
        content = <ExecutiveDashboardScreen />
      }
      break
    case 'executive':
      content = <ExecutiveDashboardScreen />
      break
  }

  return <>
    <Head title='Dashboard' />
    {content}
  </>
}
