/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ComponentProps, forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { Text, useTheme } from 'ui'

type Props = {
  label?: string
  compact?: boolean
  fontSize?: number
  error?: string
}

export const TextInput = forwardRef<HTMLInputElement, ComponentProps<'input'> & Props>(({
  label,
  compact,
  fontSize,
  error,
  ...props
}, ref) => {
  return (
    <Input label={label} compact={compact} fontSize={fontSize} error={error}>
      <input ref={ref} className={error && 'error'} {...props} />
    </Input>
  )
})

export const TextArea = forwardRef<HTMLTextAreaElement, ComponentProps<'textarea'> & Props>(({
  label,
  compact,
  fontSize,
  error,
  ...props
}, ref) => {
  const localRef = useRef<HTMLTextAreaElement>(null)

  useImperativeHandle(ref, () => localRef.current!)

  const autoSize = useCallback(() => {
    if (localRef.current) {
      localRef.current.style.height = 'auto'
      localRef.current.style.height = localRef.current.scrollHeight + 'px'
    }
  }, [])

  useEffect(() => autoSize(), [autoSize])

  return (
    <Input label={label} compact={compact} fontSize={fontSize} error={error}>
      <textarea ref={localRef} className={error && 'error'} onInput={autoSize} {...props} />
    </Input>
  )
})

function Input({ label, compact, fontSize, error, children }: Props & { children: ReactNode }) {
  const theme = useTheme()

  const height = compact ? theme.input.compact.height : theme.input.height

  const styles = {
    fieldset: css({
      margin: 0,
      padding: 0,
      border: 'none',
      'label': {
        display: 'block',
        lineHeight: 0,
      },
      'input, textarea': {
        width: '100%',
        color: theme.colors.foreground,
        background: theme.colors.surface,
        padding: `0 ${height * 0.3}px`,
        border: `1px solid ${theme.input.border}`,
        borderRadius: theme.borderRadius.s,
        boxShadow: theme.web.shadow.input,
        fontFamily: theme.input.fontFamily,
        fontSize: fontSize ?? (compact ? theme.input.compact.fontSize : theme.input.fontSize),
        '::placeholder': {
          color: theme.input.placeholder,
        },
        ':hover': {
          borderColor: theme.input.borderHover,
        },
        ':focus': {
          borderColor: theme.colors.primary,
        },
        ':disabled': {
          opacity: 0.5,
        },
        '&.error': {
          borderColor: theme.colors.danger,
        },
      },
      'input': {
        height,
        '&[type="date"]': {
          WebkitAppearance: 'none',
        },
      },
      'textarea': {
        padding: height * 0.3,
        resize: 'none',
        lineHeight: 1.4,
        overflow: 'hidden',
      },
    }),
  }

  return (
    <fieldset css={styles.fieldset}>
      <label>
        {label && <Text type='inputLabel' compact={compact}>{label}</Text>}
        {children}
      </label>
      {typeof error === 'string' && <Text type='inputError' compact={compact}>{error}</Text>}
    </fieldset>
  )
}
