import { ReactNode } from 'react'
import { Pressable, PressableProps, StyleSheet, View, ViewProps } from 'react-native'
import { colorWithBrightness, colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Text } from './Text'

type Button = PressableProps & {
  text: string
  textLeft?: ReactNode
  textRight?: ReactNode
  active?: boolean
}

type Props = ViewProps & {
  label?: string
  buttons: Button[]
  fullWidth?: boolean
  maxWidth?: number
  compact?: boolean
  disabled?: boolean
}

export function ButtonGroup({
  label,
  buttons,
  fullWidth,
  maxWidth,
  compact,
  disabled,
  ...props
}: Props) {
  const theme = useTheme()

  const borderRadius = theme.borderRadius.s
  const activeIndex = buttons.findIndex((b) => b.active)

  const height = compact ? theme.button.compact.height : theme.button.height
  const styles = StyleSheet.create({
    buttons: {
      maxWidth,
      overflowX: maxWidth ? 'auto' : undefined,
      flexDirection: 'row',
      backgroundColor: theme.colors.transparencyBase,
      borderRadius,
      ...theme.input.shadow,
    },
    button: {
      height,
      flex: fullWidth ? 1 : undefined,
      paddingHorizontal: height * 0.3,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: disabled ? 'none' : undefined,
      opacity: disabled ? 0.5 : 1,
    },
    buttonText: {
      fontFamily: theme.fonts.body[400],
      fontSize: compact ? theme.button.compact.fontSize : theme.button.fontSize,
    },
  })

  return (
    <View {...props}>
      {label && <Text type='inputLabel' compact={compact}>{label}</Text>}

      <View style={styles.buttons}>
        {buttons.map((button, index) => (
          <Pressable
            key={index}
            {...button}
            style={({ hovered, pressed }) => [styles.button, {
              backgroundColor: button.active
                ? colorWithOpacity(theme.colors.primary, 0.1)
                : colorWithBrightness(theme.colors.surface, hovered ? -5 : pressed ? -10 : 0),
              borderWidth: 1,
              borderColor: theme.input.border,
              borderLeftColor: activeIndex >= 0 && index === activeIndex + 1 ? 'transparent' : undefined,
              borderRightColor: !button.active && index !== buttons.length - 1 ? 'transparent' : undefined,
              borderTopLeftRadius: index === 0 ? borderRadius : undefined,
              borderBottomLeftRadius: index === 0 ? borderRadius : undefined,
              borderTopRightRadius: index === buttons.length - 1 ? borderRadius : undefined,
              borderBottomRightRadius: index === buttons.length - 1 ? borderRadius : undefined,
            }]}
          >
            {button.textLeft}
            <Text style={[styles.buttonText, {
              color: colorWithOpacity(theme.colors.foreground, button.active ? 1 : 0.9),
            }]}>{button.text}</Text>
            {button.textRight}
          </Pressable>
        ))}
      </View>
    </View>
  )
}
