/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { router } from 'expo-router'
import numeral from 'numeral'
import { useRef, useState } from 'react'
import { Toast } from 'react-native-toast-universal'
import { states } from 'shared'
import { Button, colorWithOpacity, Panel, Select, Spacing, Table, Text, useTheme, Wrapper } from 'ui'
import { NavButtons } from '../../components/web/NavButtons'
import { Head } from '@/components/web/Head'
import { TextInput } from '@/components/web/TextInput'
import { useStore } from '@/providers/store'
import { fieldErrors } from '@/queries/client'
import { useAllLawFirms, useCreateLawFirm, useLawFirmsExport } from '@/queries/law-firm.queries'
import { LawFirmFilter, LawFirmFilterSchema, LawFirmListItem, lawFirmRatingMap } from '@/types/law-firm'

export function LawFirms() {
  const theme = useTheme()

  const [filter, setFilter] = useState(() => {
    const stored = JSON.parse(localStorage.getItem('lawFirmFilter') ?? '{}')
    return LawFirmFilterSchema.parse(stored)
  })

  const lawFirms = useAllLawFirms(filter)

  function onUpdateFilter(filter: LawFirmFilter) {
    setFilter(filter)
    localStorage.setItem('lawFirmFilter', JSON.stringify(filter))
  }

  return <>
    <Head title='Law Firms' />

    <Wrapper maxWidth={1400} style={{ marginVertical: theme.spacing * 1.5 }}>
      <Header
        count={lawFirms.data?.lawFirms.length}
        total={lawFirms.data?.total}
        filter={filter}
        onUpdateFilter={onUpdateFilter}
      />
      <Panel>
        <LawFirmsTable
          lawFirms={lawFirms.data?.lawFirms ?? []}
          loading={lawFirms.isPending}
          filter={filter}
          onUpdateFilter={onUpdateFilter}
        />
      </Panel>
    </Wrapper>
  </>
}

function Header({ count: propCount, total: propTotal, filter, onUpdateFilter }: {
  count: number | undefined
  total: number | undefined
  filter: LawFirmFilter
  onUpdateFilter: (filter: LawFirmFilter) => void
}) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const lawFirmsExport = useLawFirmsExport()

  const [count, setCount] = useState(propCount)
  const [prevCount, setPrevCount] = useState(propCount)
  if (propCount !== prevCount && propCount !== undefined) {
    setCount(propCount)
    setPrevCount(propCount)
  }
  const [total, setTotal] = useState(propTotal)
  const [prevTotal, setPrevTotal] = useState(propTotal)
  if (propTotal !== prevTotal && propTotal !== undefined) {
    setTotal(propTotal)
    setPrevTotal(propTotal)
  }

  const offset = (filter.page - 1) * filter.show

  const inputRef = useRef<HTMLInputElement>(null)

  const styles = {
    wrapper: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing,
      flexWrap: 'wrap',
      marginBottom: theme.spacing,
    }),
    heading: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing,
      rowGap: theme.spacing * 0.2,
      flexWrap: 'wrap',
    }),
    info: css({
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
    }),
    buttons: css({
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing * 0.5,
      flexWrap: 'wrap',
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.heading}>
        <Text type='heading'>Law Firms</Text>
        <div css={styles.info}>
          {count !== undefined ? `Showing ${count > 1 ? `${offset + 1} - ${Math.min(offset + count, total ?? 0)}` : count}` : ''}
          {(count !== undefined && total !== undefined && total > count) ? ` (of ${numeral(total).format()})` : ''}
        </div>
      </div>

      <div css={styles.buttons}>
        <TextInput
          ref={inputRef}
          value={filter.input}
          compact={true}
          placeholder='Search for law firm...'
          style={{ width: 250, paddingRight: 35 }}
          onChange={(e) => onUpdateFilter({ ...filter, input: e.target.value, page: 1 })}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              onUpdateFilter({ ...filter, input: '', page: 1 })
              if (!filter.input) inputRef.current?.blur()
            }
          }}
        />
        <Button
          text='Export'
          type='tertiary'
          compact={true}
          textLeft={(
            <FontAwesomeIcon
              icon={['far', 'file-export']}
              size={15}
              color={colorWithOpacity(theme.colors.foreground, 0.9)}
              style={{ marginRight: 7 }}
            />
          )}
          loading={lawFirmsExport.isPending}
          onPress={() => {
            lawFirmsExport.mutate({ filter }, {
              onSuccess: (res) => {
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(new Blob([res.data]))
                link.setAttribute('download', 'law-firms-export.xlsx')
                document.body.appendChild(link)
                link.click()
                link.remove()
                Toast.success('Law firms exported')
              },
              onError: () => Toast.error('Unable to export law firms'),
            })
          }}
        />
        <Button
          text='New Law Firm'
          type='primary'
          compact={true}
          fontFamily={theme.fonts.body[500]}
          onPress={() => modal.open({
            title: 'New Law Firm',
            content: <LawFirmDialog />,
          })}
        />
        <NavButtons
          prev={{
            disabled: filter.page === 1,
            onClick: () => onUpdateFilter({ ...filter, page: filter.page - 1 }),
          }}
          next={{
            disabled: count === undefined || total === undefined ? true : offset + count >= total,
            onClick: () => onUpdateFilter({ ...filter, page: filter.page + 1 }),
          }}
        />
      </div>
    </div>
  )
}

function LawFirmDialog() {
  const theme = useTheme()
  const modal = useStore.useModal()

  const createLawFirm = useCreateLawFirm()

  const [name, setName] = useState('')
  const [rating, setRating] = useState('')
  const [error, setError] = useState<{ name?: string }>()

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    fields: css({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 200px)',
      gap: theme.spacing * 0.8,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <div css={styles.fields}>
        <TextInput
          label='Name'
          value={name}
          compact={true}
          error={error?.name}
          onChange={(e) => setName(e.target.value)}
        />
        <Select
          label='Rating'
          value={rating}
          options={Object.entries(lawFirmRatingMap)
            .sort(([keyA], [keyB]) => Number(keyB) - Number(keyA))
            .map(([_, value]) => value)}
          placeholder='Select rating'
          compact={true}
          onChange={setRating}
        />
      </div>
      <Spacing height={1.4} />
      <Button
        text='Add Law Firm'
        type='primary'
        loading={createLawFirm.isPending}
        onPress={() => {
          const ratingValue = rating ? Number(Object.entries(lawFirmRatingMap).find(([_, val]) => val === rating)?.[0]) : undefined
          createLawFirm.mutate({ name, rating: ratingValue }, {
            onSuccess: (lawFirm) => {
              modal.close()
              router.navigate(`/law-firms/${lawFirm.id}`)
            },
            onError: (error) => setError(fieldErrors(error)),
          })
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function LawFirmsTable({ lawFirms, loading, filter, onUpdateFilter }: {
  lawFirms: LawFirmListItem[]
  loading: boolean
  filter: LawFirmFilter
  onUpdateFilter: (filter: LawFirmFilter) => void
}) {
  return (
    <Table
      columns={[
        {
          data: 'name',
          title: 'Name',
          width: 380,
          sortable: true,
        },
        {
          data: 'states',
          title: 'Location',
          width: 250,
        },
        {
          data: 'totalAccidents',
          title: 'Total Cases',
          width: 150,
          sortable: true,
        },
        {
          data: 'settledAccidents',
          title: 'Settled Cases',
          width: 150,
          sortable: true,
        },
        {
          data: 'ROI',
          title: 'ROI',
          width: 150,
          sortable: true,
        },
        {
          data: 'IRR',
          title: 'IRR',
          width: 150,
          sortable: true,
        },
        {
          data: 'score',
          title: 'Score',
          width: 150,
          sortable: true,
        },
      ]}
      data={lawFirms.map((lawFirm) => ({
        id: lawFirm.id,
        name: lawFirm.name,
        states: lawFirm.states?.map((state) => states[state]).join(', '),
        totalAccidents: lawFirm.totalAccidents ?? 0,
        settledAccidents: lawFirm.settledAccidents ?? 0,
        ROI: lawFirm.ROI ? lawFirm.ROI + 'x' : '',
        IRR: lawFirm.IRR ? lawFirm.IRR + '%' : '',
        score: lawFirm.score !== null ? Math.round(lawFirm.score) : '',
      })) ?? []}
      loading={loading}
      noDataMessage='No law firms found'
      fixedLayout={true}
      stickyHeader={true}
      orderBy={filter.orderBy}
      sortOrder={filter.sortOrder}
      onUpdateSort={({ orderBy, sortOrder }) => onUpdateFilter({ ...filter, orderBy, sortOrder, page: 1 })}
      onRowClick={(row) => router.navigate(`/law-firms/${row.id}`)}
    />
  )
}
