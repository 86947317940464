import { z } from 'zod'

export const TagNameSchema = z.enum([
  'Red Flag',
  'Do Not Fund',
  'Email',
  'Call',
  'Follow-up Call',
  'Send Records',
  'No Auto Reject',
  'Need Attorney Info',
  'Patient Suit',
  'Provider Suit',
  'Third Party Suit',
  'Split Case',
  'Replacement Case',
])

export const TagSchema = z.object({
  name: TagNameSchema,
  color: z.string(),
})

export type TagName = z.infer<typeof TagNameSchema>
export type Tag = z.infer<typeof TagSchema>
