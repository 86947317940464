/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import dayjs from 'dayjs'
import { router } from 'expo-router'
import _orderBy from 'lodash/orderBy'
import { Dispatch, SetStateAction, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { ActivityIndicator, colorWithOpacity, DateRange, DateRangePicker, Panel, ProgressCircle, Select, Table, Text, useTheme, Wrapper } from 'ui'
import { useStore } from '@/providers/store'
import { useExecutiveDashboard } from '@/queries/dashboard.queries'
import { useAllProviders } from '@/queries/provider.queries'
import { useSaveAccidentFilter } from '@/queries/user.queries'
import { statusesFromPortfolioStatus } from '@/types/accident'
import { ExecutiveDashboard } from '@/types/dashboard'
import { format } from '@/utils'

type Timeframe = 'This Week' | 'Last Week' | 'This Month' | 'This Year' | 'Last 12 Months' | 'All Time'

export function ExecutiveDashboardScreen() {
  const theme = useTheme()

  const [dateRange, setDateRange] = useState<DateRange>(() => ({
    from: dayjs().startOf('month').toDate(),
    to: dayjs().endOf('day').toDate(),
  }))

  const [provider, setProvider] = useState<string>()

  const dashboard = useExecutiveDashboard(dateRange, provider)

  const styles = StyleSheet.create({
    loading: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 2.5,
      marginVertical: theme.spacing * 1.5
    },
  })

  return (
    dashboard.isPending ? (
      <View style={styles.loading}><ActivityIndicator /></View>
    ) : dashboard.data ? (
      <Wrapper style={styles.wrapper}>
        <KeyNumbers
          dashboard={dashboard.data}
          loading={dashboard.isFetching && dashboard.isPlaceholderData}
          dateRange={dateRange}
          setDateRange={setDateRange}
          provider={provider}
          setProvider={setProvider}
        />
        <Portfolio
          dashboard={dashboard.data}
          loading={dashboard.isFetching && dashboard.isPlaceholderData}
          provider={provider}
        />
        <StaffScores
          dashboard={dashboard.data}
          loading={dashboard.isFetching && dashboard.isPlaceholderData}
        />
      </Wrapper>
    ) : null
  )
}

function KeyNumbers({ dashboard, loading, dateRange, setDateRange, provider, setProvider }: {
  dashboard: ExecutiveDashboard
  loading: boolean
  dateRange: DateRange
  setDateRange: Dispatch<SetStateAction<DateRange>>
  provider: string | undefined
  setProvider: Dispatch<SetStateAction<string | undefined>>
}) {
  const theme = useTheme()

  const providers = useAllProviders()

  const [timeframe, setTimeframe] = useState<Timeframe | undefined>('This Month')

  const styles = {
    header: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: theme.spacing,
      rowGap: theme.spacing * 0.5,
      flexWrap: 'wrap',
      marginBottom: theme.spacing,
    }),
    filters: css({
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing * 0.5,
      flexWrap: 'wrap',
    }),
    metrics: css({
      opacity: loading ? 0.5 : 1,
      minHeight: 130,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      columnGap: theme.spacing * 3,
      rowGap: theme.spacing * 1.2,
      padding: theme.spacing * 1.2,
    }),
    metric: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 0.3,
      '> div': {
        fontFamily: theme.fonts.body[600],
        fontSize: 24,
        color: theme.colors.foreground,
      },
      '> label': {
        fontFamily: theme.fonts.body[400],
        fontSize: 15,
        color: colorWithOpacity(theme.colors.foreground, 0.7),
      },
    }),
  }

  return (
    <div>
      <div css={styles.header}>
        <Text type='heading'>Key Numbers</Text>
        <div css={styles.filters}>
          <DateRangePicker
            value={dateRange}
            compact={true}
            footerButtons={[
              {
                text: 'This Week',
                active: timeframe === 'This Week',
                onClick: () => {
                  const newWeek = dayjs().day() >= 4
                  if (newWeek) {
                    setDateRange({
                      from: dayjs().weekday(4).startOf('day').toDate(), // current Thursday
                      to: dayjs().weekday(3).add(1, 'week').endOf('day').toDate(), // next Wednesday
                    })
                  } else {
                    setDateRange({
                      from: dayjs().weekday(-3).startOf('day').toDate(), // previous Thursday
                      to: dayjs().weekday(3).endOf('day').toDate(), // current Wednesday
                    })
                  }
                  setTimeframe('This Week')
                },
              },
              {
                text: 'Last Week',
                active: timeframe === 'Last Week',
                onClick: () => {
                  const newWeek = dayjs().day() >= 4
                  let from = dayjs().weekday(-3).startOf('day') // previous Thursday
                  let to = dayjs().weekday(3).endOf('day') // current Wednesday
                  if (!newWeek) {
                    from = from.subtract(1, 'week')
                    to = to.subtract(1, 'week')
                  }
                  setDateRange({ from: from.toDate(), to: to.toDate() })
                  setTimeframe('Last Week')
                },
              },
              {
                text: 'This Month',
                active: timeframe === 'This Month',
                onClick: () => {
                  setDateRange({
                    from: dayjs().startOf('month').toDate(),
                    to: dayjs().endOf('day').toDate(),
                  })
                  setTimeframe('This Month')
                },
              },
              {
                text: 'This Year',
                active: timeframe === 'This Year',
                onClick: () => {
                  setDateRange({
                    from: dayjs().startOf('year').toDate(),
                    to: dayjs().endOf('day').toDate(),
                  })
                  setTimeframe('This Year')
                },
              },
              {
                text: 'Last 12 Months',
                active: timeframe === 'Last 12 Months',
                onClick: () => {
                  setDateRange({
                    from: dayjs().subtract(12, 'months').toDate(),
                    to: dayjs().endOf('day').toDate(),
                  })
                  setTimeframe('Last 12 Months')
                },
              },
              {
                text: 'All Time',
                active: timeframe === 'All Time',
                onClick: () => {
                  setDateRange({
                    from: dayjs('2019-01-01').toDate(),
                    to: dayjs().endOf('day').toDate(),
                  })
                  setTimeframe('All Time')
                },
              },
            ]}
            onChange={(value) => {
              if (!value) return

              if (value.from?.valueOf() === value.to?.valueOf()) {
                setDateRange({
                  from: value.from,
                  to: dayjs(value.to).endOf('day').toDate(),
                })
              } else {
                setDateRange(value)
              }

              setTimeframe(undefined)
            }}
          />
          <Select
            value={provider ?? 'All Providers'}
            options={[
              'All Providers',
              ...providers.data?.map((p) => ({ value: p.id.toString(), label: p.name })) ?? [],
            ]}
            compact={true}
            style={{ maxWidth: 330 }}
            onChange={(value) => setProvider(value === 'All Providers' ? undefined : value)}
          />
        </div>
      </div>
      <Panel>
        <div css={styles.metrics}>
          <div css={styles.metric}>
            <div>{format.currency(dashboard.keyNumbers.collected)}</div>
            <label>Collections</label>
          </div>
          <div css={styles.metric}>
            <div>{format.currency(dashboard.keyNumbers.cost)}</div>
            <label>Spend</label>
          </div>
          <div css={styles.metric}>
            <div>{Math.round(dashboard.keyNumbers.ROI * 100) / 100}x</div>
            <label>ROI</label>
          </div>
          <div css={styles.metric}>
            <div>{dashboard.keyNumbers.IRR}%</div>
            <label>IRR</label>
          </div>
        </div>
      </Panel>
    </div>
  )
}

function Portfolio({ dashboard, loading, provider }: {
  dashboard: ExecutiveDashboard
  loading: boolean
  provider: string | undefined
}) {
  const theme = useTheme()

  const accidentFilter = useStore.useAccidentFilter()
  const setAccidentFilter = useStore.useSetAccidentFilter()
  const clearAccidentFilter = useStore.useClearAccidentFilter()

  const saveAccidentFilter = useSaveAccidentFilter()

  const styles = {
    heading: css({
      marginBottom: theme.spacing,
    }),
  }

  return (
    <div>
      <div css={styles.heading}>
        <Text type='heading'>Outstanding Portfolio</Text>
      </div>
      <Panel>
        <Table
          columns={[
            {
              data: 'status',
              title: 'Status',
              width: 400,
            },
            {
              data: 'accidents',
              title: '# of Cases',
              width: 130,
            },
            {
              data: 'invoices',
              title: 'Invoices',
              width: 180,
              alignRight: true,
            },
            {
              data: 'cost',
              title: 'Cost',
              width: 180,
              alignRight: true,
            },
            {
              data: 'target',
              title: 'Target Collections',
              width: 180,
              alignRight: true,
            },
          ]}
          data={dashboard.portfolio.map((item) => ({
            status: item.label,
            accidents: format.number(item.accidents),
            invoices: format.currency(item.invoices),
            cost: format.currency(item.cost),
            target: format.currency(item.target),
          }))}
          loading={loading}
          fixedLayout={true}
          onRowClick={(row) => {
            const providers = provider ? [provider] : []
            const statuses = statusesFromPortfolioStatus(row.status)
            const cleared = clearAccidentFilter(accidentFilter)
            const filter = { ...cleared, providers, statuses }
            setAccidentFilter(filter)
            saveAccidentFilter.mutate(filter)
            router.navigate('/cases')
          }}
        />
      </Panel>
    </div>
  )
}

function StaffScores({ dashboard, loading }: { dashboard: ExecutiveDashboard, loading: boolean }) {
  const theme = useTheme()

  const [orderBy, setOrderBy] = useState('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const summary = _orderBy(dashboard.staffSummary,
    (e) => {
      switch (orderBy) {
        case 'name': return e.user.fullName
        case 'statuses': return e.statuses.value
        case 'totalStatused': return e.totalStatused.value
        case 'return': return e.return.value
        case 'callsMade': return e.callsMade.value
        case 'emailsSent': return e.emailsSent.value
        case 'score': return e.score
      }
    },
    sortOrder,
  )

  const styles = {
    header: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: theme.spacing,
      marginBottom: theme.spacing,
    }),
    score: css({
      display: 'flex',
      '> .content': {
        display: 'flex',
        position: 'relative',
        '> .score': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: theme.fonts.body[600],
          fontSize: 13,
        },
      },
    }),
  }

  return (
    <div>
      <div css={styles.header}>
        <Text type='heading'>Staff Scores</Text>
      </div>
      <Panel>
        <Table
          columns={[
            {
              data: 'name',
              title: 'Name',
              width: 300,
              sortable: true,
            },
            {
              data: 'statuses',
              title: '# of Statuses',
              width: 130,
              sortable: true,
            },
            {
              data: 'totalStatused',
              title: 'Total Statused',
              width: 140,
              sortable: true,
            },
            {
              data: 'return',
              title: 'Return',
              width: 130,
              sortable: true,
            },
            {
              data: 'callsMade',
              title: 'Calls Made',
              width: 130,
              sortable: true,
            },
            {
              data: 'emailsSent',
              title: 'Emails Sent',
              width: 130,
              sortable: true,
            },
            {
              data: 'score',
              title: 'Score',
              width: 130,
              sortable: true,
              renderer: (row) => {
                const score = summary[row].score
                const scoreColor = score >= 0.85 ? theme.colors.success
                  : score >= 0.75 ? theme.colors.warning : theme.colors.danger
                return (
                  <div css={styles.score}>
                    <div className='content'>
                      <ProgressCircle
                        value={score}
                        size={33}
                        strokeWidth={2.5}
                        pathColor={scoreColor}
                        trailColor={colorWithOpacity(scoreColor, 0.12)}
                      />
                      <div className='score' style={{ color: scoreColor }}>{Math.round(score * 100)}</div>
                    </div>
                  </div>
                )
              },
            },
          ]}
          data={summary.map((item) => ({
            name: item.user.fullName,
            statuses: item.statuses.value,
            totalStatused: Math.round(item.totalStatused.value * 100) + '%',
            return: Math.round(item.return.value * 100) / 100 + 'x',
            callsMade: item.callsMade.value,
            emailsSent: item.emailsSent.value,
            score: Math.round(item.score * 100),
          }))}
          loading={loading}
          fixedLayout={true}
          orderBy={orderBy}
          sortOrder={sortOrder}
          onUpdateSort={({ orderBy, sortOrder }) => {
            setOrderBy(orderBy)
            setSortOrder(sortOrder)
          }}
        />
      </Panel>
    </div>
  )
}
