import { faCode } from '@fortawesome/pro-regular-svg-icons/faCode'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { View } from 'react-native'
import { Button } from '../../components/Button'
import { colorWithOpacity, useTheme } from '../../hooks/useTheme'

export type DevAuthProps = {
  text?: string
  loading: boolean
  onPress: () => void
}

export function DevAuth({ text, loading, onPress }: DevAuthProps) {
  const theme = useTheme()

  if (!__DEV__) return null
  return (
    <Button
      text={text ?? 'Sign in as Test User'}
      type='tertiary'
      textLeft={(
        <View style={{ flex: 1 }}>
          <FontAwesomeIcon
            icon={faCode}
            size={23}
            color={colorWithOpacity(theme.colors.foreground, 0.9)}
          />
        </View>
      )}
      textRight={(
        <View style={{ flex: 1 }} />
      )}
      loading={loading}
      onPress={onPress}
    />
  )
}
