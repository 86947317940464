import { z } from 'zod'

export const ProcedureSchema = z.enum([
  'Assessment',
  'Clinic',
  'Follow Up',
  'Imaging',
  'Pharmacy',
  'PSL',
  'Surgery',
  'Other',
])

export type Procedure = z.infer<typeof ProcedureSchema>
