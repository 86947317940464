import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { ReactNode } from 'react'
import { Pressable, PressableProps, StyleProp, StyleSheet, Text, ViewStyle } from 'react-native'
import { useTheme } from '../hooks/useTheme'

type Props = PressableProps & {
  type?: 'info' | 'danger'
  size?: number
  bold?: boolean
  icon?: IconProp
  style?: StyleProp<ViewStyle>
  children: ReactNode
}

export function TextLink({
  type,
  size = 16,
  bold = true,
  icon,
  style,
  onPress,
  children,
  ...props
}: Props) {
  const theme = useTheme()

  const color = type === 'info' ? theme.colors.primary
    : type === 'danger' ? theme.colors.danger
      : theme.colors.foreground

  const styles = StyleSheet.create({
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    icon: {
      marginRight: 7,
      color,
    },
    text: {
      color,
      fontFamily: bold ? theme.fonts.body[500] : theme.fonts.body[400],
      fontSize: size,
    },
  })

  return (
    <Pressable
      {...props}
      hitSlop={10}
      style={({ hovered, pressed }) => [styles.wrapper, style, {
        opacity: hovered ? 0.6 : pressed ? 0.3 : 1,
      }]}
      onPress={onPress}
    >
      {icon && <FontAwesomeIcon icon={icon} size={size + 2.5} style={styles.icon} />}

      <Text style={styles.text}>{children}</Text>
    </Pressable>
  )
}
