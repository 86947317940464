/** @jsxImportSource @emotion/react */
import { ColorStatus } from 'ui'
import { Badge } from '@/components/web/Badge'
import { useUser } from '@/queries/user.queries'
import { BatchStatus } from '@/types/batch'

type Props = {
  status: BatchStatus
  displayStatus?: BatchStatus
  compact?: boolean
}

export function BatchStatusBadge({ status, displayStatus, compact }: Props) {
  const user = useUser()

  let colorStatus: ColorStatus = 'info'
  if (!displayStatus || status === displayStatus) {
    switch (status) {
      case 'Provider - Review':
        if (user.data?.role === 'provider' && user.data.canSubmit) colorStatus = 'warning'
        break
      case 'Staff - Review':
        if (user.data?.role === 'staff') colorStatus = 'warning'
        break
      case 'Manager - Review':
        if (user.data?.role === 'manager') colorStatus = 'warning'
        break
      case 'Executive - Review':
        if (user.data?.role === 'executive') colorStatus = 'warning'
        break
      case 'Approved':
        colorStatus = 'success'
        break
      case 'Rejected':
        colorStatus = 'danger'
        break
    }
  }

  return <Badge status={colorStatus} compact={compact}>{displayStatus ?? status}</Badge>
}
