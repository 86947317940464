/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { MenuView } from '@react-native-menu/menu'
import { Platform, View, ViewProps } from 'react-native'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

type Props = ViewProps & {
  value?: string
  items: MenuItem[]
  webTriggerAsChild?: boolean
  onChange: (value: string) => void
}

export type MenuItem = {
  value: string
  nativeImage?: string
  webIcon?: IconProp
  disabled?: boolean
  destructive?: boolean
}

export function Menu(props: Props) {
  return Platform.OS === 'web' ? <MenuWeb {...props} /> : <MenuNative {...props} />
}

function MenuNative({ value, items, children, onChange, ...props }: Props) {
  return (
    <View {...props}>
      <MenuView
        actions={items.map((item) => ({
          id: item.value,
          title: item.value,
          image: item.nativeImage,
          attributes: {
            disabled: item.disabled,
            destructive: item.destructive,
          },
          state: value === item.value ? 'on' : 'off',
        }))}
        onPressAction={({ nativeEvent }) => onChange(nativeEvent.event)}
      >
        {children}
      </MenuView>
    </View>
  )
}

function MenuWeb({ value, items, webTriggerAsChild, children, onChange, ...props }: Props) {
  const theme = useTheme()

  const TRANSITION_DURATION = 200

  const contentShow = keyframes({
    from: {
      opacity: 0,
      transform: 'scale(0.96)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
  })
  const contentHide = keyframes({
    from: {
      opacity: 1,
      transform: 'scale(1)',
    },
    to: {
      opacity: 0,
      transform: 'scale(0.96)',
    },
  })

  const styles = {
    trigger: css({
      appearance: 'none',
      height: '100%',
      border: 'none',
      background: 'none',
      margin: 0,
      padding: 0,
      userSelect: 'none',
    }),
    content: css({
      padding: 4,
      backgroundColor: theme.colors.surface,
      borderRadius: theme.borderRadius.s + 2,
      boxShadow: theme.web.shadow.overlay,
      transition: `0.2s ${theme.web.easing.curveLinearToEaseOut}`,
      transformOrigin: 'var(--radix-dropdown-menu-content-transform-origin)',
      willChange: 'transform, opacity',
      '&[data-state=open]': {
        animation: `${contentShow}
          ${TRANSITION_DURATION}ms
          ${theme.web.easing.curveLinearToEaseOut}`,
      },
      '&[data-state=close]': {
        animation: `${contentHide}
          ${TRANSITION_DURATION}ms
          ${theme.web.easing.curveLinearToEaseOut}`,
      },
    }),
    item: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      fontFamily: theme.fonts.body[500],
      fontSize: 15,
      padding: '8px 11px',
      borderRadius: theme.borderRadius.s,
      cursor: 'pointer',
      userSelect: 'none',
      '&[data-highlighted]': {
        background: colorWithOpacity(theme.colors.foreground, 0.07),
      },
      '&[data-disabled]': {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    }),
  }

  return (
    <DropdownMenu.Root {...props}>
      <DropdownMenu.Trigger
        asChild={webTriggerAsChild}
        css={!webTriggerAsChild && styles.trigger}
      >{children}</DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={theme.spacing * 0.5}
          css={styles.content}
        >
          {items.map((item) => (
            <DropdownMenu.Item
              key={item.value}
              disabled={item.disabled}
              css={[styles.item, {
                color: item.value === value ? theme.colors.primary
                  : item.destructive ? theme.colors.danger : theme.colors.foreground
              }]}
              onSelect={() => onChange(item.value)}
            >
              {item.webIcon && (
                <FontAwesomeIcon
                  icon={item.webIcon}
                  color={item.value === value ? theme.colors.primary
                    : item.destructive ? theme.colors.danger : theme.colors.foreground}
                  size={15}
                />
              )}
              {item.value}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
