/** @jsxImportSource @emotion/react */
import Select from 'react-select/async'
import CreatableSelect from 'react-select/async-creatable'
import { colorWithOpacity, useTheme } from '../../hooks/useTheme'
import { ActivityIndicator } from '../ActivityIndicator'
import { Text } from '../Text'

type Option = { value: string, label: string }

type Props = {
  label?: string
  value: Option | undefined
  placeholder?: string
  loadOptions: (input: string) => Promise<Option[]>
  noOptions?: string
  compact?: boolean
  loading?: boolean
  disabled?: boolean
  error?: string
  onChange: (option: Option) => void
  onCreateOption?: (input: string) => void
}

export function Autocomplete({
  label,
  value,
  placeholder,
  loadOptions,
  noOptions,
  compact,
  loading,
  disabled,
  error,
  onChange,
  onCreateOption,
}: Props) {
  const theme = useTheme()

  const height = compact ? theme.input.compact.height : theme.input.height

  return (
    <div>
      {label && <Text type='inputLabel' compact={compact}>{label}</Text>}

      {onCreateOption ? (
        <CreatableSelect
          value={value}
          loadOptions={loadOptions}
          placeholder={placeholder}
          noOptionsMessage={() => null}
          isLoading={loading}
          isDisabled={disabled}
          maxMenuHeight={350}
          formatCreateLabel={(input) => `Add "${input}"`}
          components={{
            DropdownIndicator: () => null,
            LoadingIndicator: () => (
              <ActivityIndicator color={theme.input.placeholder} scale={0.7} style={{ marginRight: 7 }} />
            ),
          }}
          styles={{
            container: (base) => ({
              ...base,
              fontFamily: theme.input.fontFamily,
              fontSize: compact ? theme.input.compact.fontSize : theme.input.fontSize,
            }),
            control: (base, props) => ({
              ...base,
              minHeight: height,
              background: theme.colors.surface,
              borderColor: props.isFocused ? theme.colors.primary : theme.input.border,
              borderRadius: theme.borderRadius.s,
              boxShadow: theme.web.shadow.input,
              outline: 'none',
              transition: 'none',
              cursor: 'pointer',
              color: theme.colors.foreground,
              opacity: props.isDisabled ? 0.5 : 1,
              ':hover': {
                borderColor: props.isFocused ? theme.colors.primary : theme.input.borderHover,
              },
            }),
            valueContainer: (base) => ({
              ...base,
              padding: `4px 10px`,
            }),
            singleValue: (base) => ({
              ...base,
              color: theme.colors.foreground,
            }),
            input: (base) => ({
              ...base,
              color: theme.colors.foreground,
            }),
            placeholder: (base) => ({
              ...base,
              color: theme.input.placeholder,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            menu: (base) => ({
              ...base,
              borderRadius: theme.borderRadius.s,
              boxShadow: theme.web.shadow.overlay,
            }),
            menuList: (base) => ({
              ...base,
              padding: 4,
            }),
            option: (base, props) => ({
              ...base,
              fontSize: 14,
              lineHeight: 1.4,
              padding: '8px 10px',
              borderRadius: theme.borderRadius.s - 2,
              background: props.isSelected ? theme.colors.primary
                : props.isFocused ? colorWithOpacity(theme.colors.foreground, 0.07) : undefined,
              color: props.isSelected ? '#FFFFFF' : theme.colors.foreground,
              cursor: 'pointer',
              ':active': {
                background: colorWithOpacity(theme.colors.foreground, 0.1),
              },
            }),
            loadingMessage: (base) => ({
              ...base,
              color: colorWithOpacity(theme.colors.foreground, 0.8),
              fontSize: 14.5,
              textAlign: 'left',
            }),
          }}
          onChange={(option) => option ? onChange(option) : undefined}
          onCreateOption={onCreateOption}
        />
      ) : (
        <Select
          value={value}
          placeholder={placeholder}
          loadOptions={loadOptions}
          noOptionsMessage={({ inputValue }) => inputValue.length ? noOptions : null}
          isLoading={loading}
          isDisabled={disabled}
          maxMenuHeight={350}
          components={{
            DropdownIndicator: () => null,
            LoadingIndicator: () => (
              <ActivityIndicator color={theme.input.placeholder} scale={0.7} style={{ marginRight: 7 }} />
            ),
          }}
          styles={{
            container: (base) => ({
              ...base,
              fontFamily: theme.input.fontFamily,
              fontSize: compact ? theme.input.compact.fontSize : theme.input.fontSize,
            }),
            control: (base, props) => ({
              ...base,
              minHeight: height,
              background: theme.colors.surface,
              borderColor: props.isFocused ? theme.colors.primary : theme.input.border,
              borderRadius: theme.borderRadius.s,
              boxShadow: theme.web.shadow.input,
              outline: 'none',
              transition: 'none',
              cursor: 'pointer',
              color: theme.colors.foreground,
              opacity: props.isDisabled ? 0.5 : 1,
              ':hover': {
                borderColor: props.isFocused ? theme.colors.primary : theme.input.borderHover,
              },
            }),
            valueContainer: (base) => ({
              ...base,
              padding: `4px 10px`,
            }),
            singleValue: (base) => ({
              ...base,
              color: theme.colors.foreground,
            }),
            input: (base) => ({
              ...base,
              color: theme.colors.foreground,
            }),
            placeholder: (base) => ({
              ...base,
              color: theme.input.placeholder,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            menu: (base) => ({
              ...base,
              borderRadius: theme.borderRadius.s,
              boxShadow: theme.web.shadow.overlay,
            }),
            menuList: (base) => ({
              ...base,
              padding: 4,
            }),
            option: (base, props) => ({
              ...base,
              fontSize: 14,
              lineHeight: 1.4,
              padding: '8px 10px',
              borderRadius: theme.borderRadius.s - 2,
              background: props.isSelected ? theme.colors.primary
                : props.isFocused ? colorWithOpacity(theme.colors.foreground, 0.07) : undefined,
              color: props.isSelected ? '#FFFFFF' : theme.colors.foreground,
              cursor: 'pointer',
              ':active': {
                background: colorWithOpacity(theme.colors.foreground, 0.1),
              },
            }),
            loadingMessage: (base) => ({
              ...base,
              color: colorWithOpacity(theme.colors.foreground, 0.8),
              fontSize: 14.5,
              textAlign: 'left',
            }),
            noOptionsMessage: (base) => ({
              ...base,
              color: colorWithOpacity(theme.colors.foreground, 0.8),
              fontSize: 14.5,
              textAlign: 'left',
            })
          }}
          onChange={(option) => option ? onChange(option) : undefined}
        />
      )}

      {error && <Text type='inputError' compact={compact}>{error}</Text>}
    </div>
  )
}
