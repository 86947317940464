import { StyleSheet, View, ViewProps } from 'react-native'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

type Props = ViewProps & {
  shadow?: boolean
}

export function Panel({ shadow = false, style, ...props }: Props) {
  const theme = useTheme()

  const styles = StyleSheet.create({
    wrapper: {
      backgroundColor: theme.colors.surface,
      borderWidth: 1,
      borderColor: theme.colors.panelBorder,
      borderRadius: theme.borderRadius.m,
      overflow: 'hidden',
    },
    shadow: {
      shadowColor: colorWithOpacity('#000000', 0.15),
      shadowOffset: { width: 0, height: 2 },
      shadowRadius: 3,
      shadowOpacity: 0.3,
      elevation: 3,
    },
  })

  return (
    <View {...props} style={[styles.wrapper, shadow && styles.shadow, style]} />
  )
}
