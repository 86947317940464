import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { BlurView } from 'expo-blur'
import { Image } from 'expo-image'
import { useVideoPlayer, VideoPlayer, VideoSource, VideoView, VideoViewProps } from 'expo-video'
import { useState } from 'react'
import { Pressable, StyleSheet, View, ViewProps } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

type BaseProps = ViewProps & {
  width: number
  height: number
  posterUrl?: string
  videoView?: Omit<VideoViewProps, 'player'>
}

type PropsWithSource = BaseProps & {
  source: VideoSource
  player?: never
}

type PropsWithPlayer = BaseProps & {
  source?: never
  player: VideoPlayer
}

export function Video(props: PropsWithSource | PropsWithPlayer) {
  if (props.source) {
    return <VideoWithSource {...props} />
  } else if (props.player) {
    return <VideoWithPlayer {...props} />
  }
}

function VideoWithSource({ source, ...props }: BaseProps & PropsWithSource) {
  const player = useVideoPlayer(source)

  return <VideoWithPlayer player={player} {...props} />
}

function VideoWithPlayer({ player, width, height, posterUrl, videoView, ...props }: BaseProps & PropsWithPlayer) {
  const [started, setStarted] = useState(false)

  return (
    <View {...props}>
      <VideoView
        player={player}
        allowsFullscreen={true}
        style={{ width: '100%', aspectRatio: width / height }}
        {...videoView}
      />
      {!started && (
        <VideoPoster
          url={posterUrl}
          onPress={() => {
            player.play()
            setStarted(true)
          }}
        />
      )}
    </View>
  )
}

function VideoPoster({ url, onPress }: { url?: string, onPress: () => void }) {
  const theme = useTheme()

  const scale = useSharedValue(1)
  const animatedStyles = useAnimatedStyle(() => ({
    transform: [{
      scale: withTiming(scale.value, {
        duration: 300,
        easing: theme.easing.curveLinearToEaseOut,
      }),
    }],
  }))

  const styles = StyleSheet.create({
    wrapper: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    scrim: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: colorWithOpacity('#000000', 0.4),
      justifyContent: 'center',
      alignItems: 'center',
    },
    playIcon: {
      width: 60,
      height: 60,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 30,
      overflow: 'hidden',
      paddingLeft: 5,
    },
  })

  return (
    <Pressable
      style={styles.wrapper}
      onPress={() => {
        scale.value = 1
        onPress()
      }}
      onPressIn={() => (scale.value = 0.8)}
      onPressOut={() => (scale.value = 1)}
    >
      {url && <Image source={url} style={{ flex: 1 }} />}
      <View style={styles.scrim}>
        <BlurView
          tint='dark'
          intensity={50}
          style={styles.playIcon}
        >
          <Animated.View style={animatedStyles}>
            <FontAwesomeIcon
              icon={faPlay}
              size={38}
              color='#FFFFFF'
            />
          </Animated.View>
        </BlurView>
      </View>
    </Pressable>
  )
}
