/** @jsxImportSource @emotion/react */
import 'react-day-picker/src/style.css'
import { css, Global } from '@emotion/react'
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons/faArrowRightLong'
import { faCalendarRange } from '@fortawesome/pro-regular-svg-icons/faCalendarRange'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import * as dateFns from 'date-fns'
import { ChangeEvent, ComponentProps, useEffect, useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import { useWindowDimensions } from 'react-native'
import { colorWithBrightness, colorWithOpacity, useTheme } from '../../hooks/useTheme'
import { Popover } from './Popover'

type Props = {
  value: DateRange | undefined
  compact?: boolean
  footerButtons?: {
    text: string
    active?: boolean
    onClick: () => void
  }[]
  onChange: (value: DateRange | undefined) => void
}

export type { DateRange }

export function DateRangePicker({ value, compact, footerButtons, onChange }: Props) {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const [month, setMonth] = useState(value?.from)
  const [popoverOpen, setPopoverOpen] = useState(false)

  useEffect(() => {
    if (value?.from) setMonth(value?.from)
  }, [value?.from])

  const height = compact ? theme.input.compact.height : theme.input.height

  const styles = {
    inputs: css({
      height,
      display: 'flex',
      alignItems: 'center',
      background: theme.colors.surface,
      border: `1px solid ${theme.input.border}`,
      borderRadius: theme.borderRadius.s,
      boxShadow: theme.web.shadow.input,
      paddingLeft: height * 0.3,
      overflow: 'hidden',
      cursor: 'pointer',
    }),
    calendarIcon: css({
      padding: height * 0.3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    calendarFooter: css({
      borderTop: `1px solid ${theme.colors.divider}`,
      padding: `${theme.spacing * 1}px ${theme.spacing}px`,
      display: width >= theme.breakpoints.s ? 'flex' : 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing * 0.5,
    }),
    calendarFooterButton: css({
      fontFamily: theme.fonts.body[500],
      fontSize: 14,
      color: colorWithOpacity(theme.colors.foreground, 0.9),
      background: theme.colors.surface,
      border: `1px solid ${theme.input.border}`,
      borderRadius: theme.button.compact.borderRadius,
      padding: '8px 11px',
      cursor: 'pointer',
      ':hover': {
        background: colorWithBrightness(theme.colors.surface, -5),
      },
      '&.active': {
        color: theme.colors.background,
        background: theme.colors.primary,
        borderColor: theme.colors.primary,
      },
    }),
    global: css({
      '.rdp-root': {
        fontFamily: theme.fonts.body[400],
        color: theme.colors.foreground,
        padding: theme.spacing,
        position: 'relative',
        '--rdp-accent-color': theme.colors.primary,
        '--rdp-range_middle-background-color': colorWithOpacity(theme.colors.primary, 0.07),
        '--rdp-weekday-opacity': 0.6,
        '--rdp-outside-opacity': 0.3,
        '.rdp-month_caption': {
          fontSize: 16,
          fontFamily: theme.fonts.body[600],
          fontWeight: 'normal',
          justifyContent: 'center',
        },
      },
      '.rdp-nav': {
        top: 4,
        left: 0,
        right: 0,
        '.rdp-button_previous, .rdp-button_next': {
          position: 'absolute',
          top: 0,
          borderRadius: '50%',
          ':hover': {
            background: colorWithOpacity(theme.colors.foreground, 0.03),
          },
        },
        '.rdp-button_previous': {
          left: 0,
        },
        '.rdp-button_next': {
          right: 0,
        },
      },
      '.rdp-weekday': {
        fontFamily: theme.fonts.body[500],
        fontSize: 12,
      },
      '.rdp-day_button': {
        fontFamily: theme.fonts.body[500],
        fontSize: 14,
      },
      '.rdp-day:not(.rdp-selected):not(.rdp-disabled) .rdp-day_button': {
        ':hover': {
          background: colorWithOpacity(theme.colors.foreground, 0.03),
        },
      },
      '.rdp-selected': {
        fontFamily: theme.fonts.body[500],
        fontWeight: 500,
      },
    })
  }

  return <>
    <Popover
      sideOffset={theme.spacing * 0.5}
      collisionPadding={theme.spacing}
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      onOpenAutoFocus={(e) => e.preventDefault()}
      content={<>
        <DayPicker
          mode='range'
          selected={value}
          month={month}
          role='dialog'
          numberOfMonths={width >= theme.breakpoints.s ? 2 : 1}
          showOutsideDays={true}
          components={{
            Chevron: ({ orientation, disabled }) => (
              <FontAwesomeIcon
                icon={orientation === 'left' ? faAngleLeft : faAngleRight}
                size={18}
                color={colorWithOpacity(theme.colors.foreground, disabled ? 0.5 : 0.8)}
              />
            ),
          }}
          onSelect={onChange}
          onMonthChange={setMonth}
        />
        {footerButtons && (
          <div css={styles.calendarFooter}>
            {footerButtons.map((button) => (
              <div
                key={button.text}
                css={styles.calendarFooterButton}
                className={button.active ? 'active' : ''}
                onClick={button.onClick}
              >
                {button.text}
              </div>
            ))}
          </div>
        )}
      </>}
    >
      <div css={styles.inputs}>
        <DateInput
          value={value?.from}
          compact={compact}
          popoverOpen={popoverOpen}
          onChange={(inputValue) => {
            onChange({ from: inputValue, to: value?.to })
            setMonth(inputValue)
          }}
        />
        <FontAwesomeIcon
          icon={faArrowRightLong}
          size={compact ? theme.input.compact.fontSize : theme.input.fontSize}
          color={colorWithOpacity(theme.colors.foreground, 0.8)}
          style={{ marginHorizontal: theme.spacing * 0.6 }}
        />
        <DateInput
          value={value?.to}
          compact={compact}
          popoverOpen={popoverOpen}
          onChange={(inputValue) => onChange({ from: value?.from, to: inputValue })}
        />
        <div css={styles.calendarIcon}>
          <FontAwesomeIcon
            icon={faCalendarRange}
            size={compact ? theme.input.compact.fontSize : theme.input.fontSize}
            color={colorWithOpacity(theme.colors.foreground, 0.8)}
          />
        </div>
      </div>
    </Popover>
    <Global styles={styles.global} />
  </>
}

function DateInput({ compact, popoverOpen, ...props }: Omit<ComponentProps<'input'>, 'value' | 'onChange'> & {
  value: Date | undefined
  compact: boolean | undefined
  popoverOpen: boolean
  onChange: (value: Date) => void
}) {
  const theme = useTheme()

  const [value, setValue] = useState(props.value ? dateFns.format(props.value, 'MM/dd/yyyy') : '')
  const [prevValue, setPrevValue] = useState(props.value)
  if (prevValue !== props.value) {
    setValue(props.value ? dateFns.format(props.value, 'MM/dd/yyyy') : '')
    setPrevValue(props.value)
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>, addLeadingZeros: boolean) {
    const cleaned = e.target.value.replace(/[^0-9/]/g, '')
    const match = cleaned.match(/^(\d{0,2})(\/?)(\d{0,2})(\/?)(\d{0,4})$/)
    if (match) {
      const part1 = addLeadingZeros ? match[1].padStart(2, '0') : match[1]
      const part2 = match[3] ? (addLeadingZeros ? `/${match[3].padStart(2, '0')}` : `/${match[3]}`) : match[2]
      const part3 = match[5] ? `/${match[5]}` : match[4]
      const formatted = `${part1}${part2}${part3}`
      setValue(formatted)

      if (formatted.length === 10) {
        const parsedDate = dateFns.parse(formatted, 'MM/dd/yyyy', new Date())
        if (dateFns.isValid(parsedDate)) props.onChange(parsedDate)
      }
    }
  }

  const styles = {
    input: css({
      width: '10ch',
      height: '100%',
      color: theme.colors.foreground,
      fontFamily: theme.input.fontFamily,
      fontSize: compact ? theme.input.compact.fontSize : theme.input.fontSize,
      border: 'none',
      margin: 0,
      padding: 0,
      paddingLeft: 3,
      '::placeholder': {
        color: theme.input.placeholder,
      },
    })
  }

  return (
    <input
      {...props}
      type='text'
      value={value}
      maxLength={10}
      placeholder='mm/dd/yyyy'
      css={styles.input}
      onClick={(e) => {
        if (popoverOpen) e.stopPropagation()
      }}
      onChange={(e) => handleChange(e, false)}
      onFocus={(e) => {
        setTimeout(() => e.target.select(), 0)
        if (props.onFocus) props.onFocus(e)
      }}
      onBlur={(e) => {
        if (e.target.value.length) handleChange(e, true)
        if (props.onBlur) props.onBlur(e)
      }}
    />
  )
}
