import * as Linking from 'expo-linking'
import { router, Stack } from 'expo-router'
import { useState } from 'react'
import { Platform, StyleSheet, useWindowDimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Toast } from 'react-native-toast-universal'
import { Avatar, Button, DynamicScrollView, EditablePhoto, Spacing, TextInput, useBottomTabBarHeight, useTheme } from 'ui'
import { Constants } from '@/constants'
import { useStore } from '@/providers/store'
import { fieldErrors } from '@/queries/client'
import { useUpdateProfile, useUser } from '@/queries/user.queries'

export function Profile() {
  const { width } = useWindowDimensions()
  const insets = useSafeAreaInsets()
  const tabBarHeight = useBottomTabBarHeight()
  const modal = useStore.useModal()
  const alert = useStore.useAlert()
  const theme = useTheme()

  const user = useUser()
  const updateProfile = useUpdateProfile()

  const [firstName, setFirstName] = useState(user.data?.firstName ?? '')
  const [lastName, setLastName] = useState(user.data?.lastName ?? '')
  const [photo, setPhoto] = useState<string>()
  const [error, setError] = useState<{
    firstName?: string
    lastName?: string
    phone?: string
  }>()

  const styles = StyleSheet.create({
    editablePhoto: {
      marginBottom: theme.spacing * 1.3,
      alignItems: 'center',
    },
    editablePhotoContent: {
      marginTop: theme.spacing * 2,
      marginBottom: theme.spacing * 0.7,
    },
  })

  return <>
    {Platform.OS !== 'web' && (
      <Stack.Screen options={{ title: 'Profile' }} />
    )}

    <DynamicScrollView
      keyboardShouldPersistTaps='handled'
      automaticallyAdjustKeyboardInsets={true}
      automaticallyAdjustsScrollIndicatorInsets={false}
      scrollIndicatorInsets={{ bottom: tabBarHeight ? tabBarHeight : insets.bottom }}
      contentContainerStyle={{
        paddingHorizontal: width > theme.breakpoints.xs ? theme.spacing * 1.5 : theme.spacing,
        paddingBottom: tabBarHeight ? tabBarHeight : insets.bottom,
        maxWidth: 400,
      }}
    >
      <EditablePhoto
        setPhoto={setPhoto}
        width={300}
        height={300}
        style={styles.editablePhoto}
        contentStyle={styles.editablePhotoContent}
        onCameraPermissionsDenied={() => {
          alert.open({
            title: 'Allow Access',
            message: `Allow ${Constants.appName} to access your camera to upload a photo.`,
            buttons: [
              {
                text: 'Cancel',
                onPress: () => alert.close(),
              },
              {
                text: 'Allow Access',
                onPress: () => Linking.openSettings(),
              },
            ],
          })
        }}
      >
        <Avatar
          source={photo ?? (user.data?.photoUrl ?? undefined)}
          size={100}
          placeholderName={user.data?.fullName ?? undefined}
        />
      </EditablePhoto>
      <TextInput
        label='First name'
        placeholder='First name'
        value={firstName}
        autoCapitalize='words'
        autoComplete='name-given'
        error={error?.firstName}
        textContentType='givenName'
        onChangeText={setFirstName}
      />
      <Spacing height={1} />
      <TextInput
        label='Last name'
        placeholder='Last name'
        value={lastName}
        autoCapitalize='words'
        autoComplete='name-family'
        error={error?.lastName}
        textContentType='familyName'
        onChangeText={setLastName}
      />
      <Spacing height={1.5} />
      <Button
        text='Update Profile'
        type='primary'
        loading={updateProfile.isPending}
        onPress={() => {
          updateProfile.mutate({ firstName, lastName, photo }, {
            onSuccess: () => {
              if (Platform.OS === 'web') {
                modal.close()
              } else {
                router.back()
              }
              Toast.success('Profile updated!')
            },
            onError: (error) => setError(fieldErrors(error)),
          })
        }}
      />
      <Spacing height={1.5} />
    </DynamicScrollView>
  </>
}
