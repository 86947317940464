/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import * as RadixDialog from '@radix-ui/react-dialog'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { colorWithOpacity, useTheme } from '../../hooks/useTheme'
import { TextLink } from '../TextLink'

const TRANSITION_DURATION = 250

export type ModalOptions = {
  title: string
  headerActionLeft?: ModalHeaderAction
  fullscreen?: boolean
  content: ReactNode
  disableOverflow?: boolean
  onOpenChange?: (open: boolean) => void
}

export type ModalHeaderAction = {
  text: string
  destructive?: boolean
  onPress: () => void
}

type Props = {
  active: ModalOptions | undefined
  open: (modal: ModalOptions) => void
  close: () => void
}

export function Modal(props: Props) {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const [active, setActive] = useState<ModalOptions>()
  const [open, setOpen] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setOpen(props.active !== undefined)

    if (props.active) {
      setActive(props.active)
      setTimeout(() => (document.body.style.pointerEvents = ''), 0)
    } else {
      setTimeout(() => setActive(undefined), TRANSITION_DURATION)
    }
  }, [props.active])

  const overlayShow = keyframes({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  })
  const overlayHide = keyframes({
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  })

  const contentShow = keyframes({
    from: {
      opacity: 0,
      transform: 'translate(-50%, -50%) scale(0.96)',
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    },
  })
  const contentHide = keyframes({
    from: {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    },
    to: {
      opacity: 0,
      transform: 'translate(-50%, -50%) scale(0.96)',
    },
  })

  const fontSize = theme.dialog?.fontSize ?? 18

  const styles = {
    overlay: css({
      position: 'fixed',
      inset: 0,
      background: 'rgba(0, 0, 0, 0.32)',
      '&[data-state=open]': {
        animation: `${overlayShow}
          ${TRANSITION_DURATION}ms
          ${theme.web.easing.curveLinearToEaseOut}`,
      },
      '&[data-state=closed]': {
        animation: `${overlayHide}
          ${TRANSITION_DURATION}ms
          ${theme.web.easing.curveLinearToEaseOut}`,
      },
    }),
    content: css({
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: width < 420 ? `calc(100dvw - ${theme.spacing * 2}px)` : undefined,
      minWidth: width >= 420 ? 380 : undefined,
      maxHeight: `calc(100dvh - ${theme.spacing * 2}px)`,
      overflowY: active?.disableOverflow ? undefined : 'auto',
      background: theme.colors.surface,
      borderRadius: theme.borderRadius.m,
      boxShadow: '0 40px 125px -25px rgba(0, 0, 0, 0.1)',
      '&[data-state=open]': {
        animation: `${contentShow}
          ${TRANSITION_DURATION}ms
          ${theme.web.easing.curveLinearToEaseOut}`,
      },
      '&[data-state=closed]': {
        animation: `${contentHide}
          ${TRANSITION_DURATION}ms
          ${theme.web.easing.curveLinearToEaseOut}`,
      },
    }),
    contentFullscreen: css({
      width: `calc(100dvw - ${theme.spacing * 2}px)`,
      height: `calc(100dvh - ${theme.spacing * 2}px)`,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 'none',
    }),
    title: css({
      color: theme.colors.foreground,
      fontFamily: theme.fonts.body[600],
      fontWeight: 'normal',
      fontSize,
      textAlign: 'center',
      marginTop: theme.spacing * 1.1,
      marginBottom: theme.spacing * 0.1,
      [`@media (min-width: ${theme.breakpoints.s}px)`]: {
        fontSize: fontSize * 1.05,
      },
      [`@media (min-width: ${theme.breakpoints.m}px)`]: {
        fontSize: fontSize * 1.1,
      },
    }),
    headerActionLeft: css({
      position: 'absolute',
      top: 15,
      left: 15,
    }),
    close: css({
      position: 'absolute',
      top: 10,
      right: 10,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.6,
      },
    }),
  }

  return (
    <RadixDialog.Root
      open={open}
      onOpenChange={(open) => {
        if (!open) props.close()
        if (props.active?.onOpenChange) props.active.onOpenChange(open)
      }}
    >
      <RadixDialog.Overlay css={styles.overlay} />
      <RadixDialog.Content
        ref={contentRef}
        aria-describedby={undefined}
        css={[styles.content, active?.fullscreen && styles.contentFullscreen]}
        onPointerDownOutside={(e) => {
          if (!contentRef.current) return
          const rect = contentRef.current.getBoundingClientRect()
          const clickedInside =
            e.detail.originalEvent.clientX > rect.left &&
            e.detail.originalEvent.clientX < rect.left + rect.width &&
            e.detail.originalEvent.clientY > rect.top &&
            e.detail.originalEvent.clientY < rect.top + rect.height
          if (clickedInside) e.preventDefault()
        }}
      >
        {active?.headerActionLeft && (
          <div css={styles.headerActionLeft}>
            <TextLink
              type={active.headerActionLeft.destructive ? 'danger' : 'info'}
              size={fontSize * 0.87}
              onPress={active.headerActionLeft.onPress}
            >{active.headerActionLeft.text}
            </TextLink>
          </div>
        )}

        <RadixDialog.Title css={styles.title}>{active?.title}</RadixDialog.Title>

        <RadixDialog.Close asChild={true}>
          <div css={styles.close}>
            <FontAwesomeIcon
              icon={faXmark}
              size={21}
              color={colorWithOpacity(theme.colors.foreground, 0.7)}
            />
          </div>
        </RadixDialog.Close>

        {active?.content}
      </RadixDialog.Content>
    </RadixDialog.Root>
  )
}
