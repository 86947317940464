import dayjs, { Dayjs } from 'dayjs'
import { z } from 'zod'
import { ChargeStatusSchema } from './charge-status'
import { LawFirmContactSchema, LawFirmSchema } from './law-firm'
import { ProcedureSchema } from './procedure'
import { ProviderSchema } from './provider'
import { TagNameSchema } from './tag'
import { UserSchema } from './user'

export const AccidentStatusSchema = z.enum([
  'Open - New Patient',
  'Open - Pending',
  'Open - Treating',
  'Open - Pre-Litigation',
  'Open - Litigation',
  'Open - Prepping Demand',
  'Open - Demand Stage',
  'Open - Negotiation (Insurance)',
  'Open - Negotiation (Provider)',
  'Open - Unresolved',
  'Open - Resolved',
  'Open - Processing',
  'Open - Mediation',
  'Open - Discovery Stage',
  'Open - Trial',
  'Open - Interplead',
  'Settled - Reduction Pending',
  'Settled - Reduction Approved',
  'Settled - Check on the Way',
  'Settled - Check Received',
  'Settled - Unpaid',
  'Settled - Paid',
  'Settled - Partially Paid',
  'Closed - Dropped',
  'Closed - Withdrew',
  'Closed - Fraud',
  'Unknown',
])

export const PatientSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  fullName: z.string(),
  dob: z.string().nullable().transform((v) => v ? dayjs.utc(v) : null),
})

export const AccountSchema = z.object({
  id: z.number(),
  provider: ProviderSchema,
  policyLimitPercentage: z.number().nullable(),
  qbCustomerId: z.string().nullable(),
})

export const EventTypeSchema = z.enum([
  'Call',
  'Email',
  'Fax',
  'Mail',
  'Court System',
  'Note',
  'Other',
  'Account',
  'Document',
])

const EventSchema = z.object({
  id: z.number().optional(),
  accountId: z.number().nullable(),
  user: UserSchema.nullable(),
  public: z.boolean(),
  type: EventTypeSchema,
  status: AccidentStatusSchema.nullish(),
  meta: z.object({ documentId: z.number() }).optional(),
  nextContact: z.string().nullish().transform((v) => v ? dayjs(v) : undefined),
  note: z.string().nullish(),
  action: z.string().nullish(),
  createdAt: z.string().transform((v) => dayjs(v)),
})

export const TransactionTypeSchema = z.enum([
  'Invoice',
  'Payment',
  'Credit Memo',
  'Journal Entry',
  'Deposit',
])

export const TransactionSchema = z.object({
  id: z.number(),
  providerId: z.number(),
  accountId: z.number().nullable(),
  documents: z.object({ id: z.number() }).array(),
  charge: z.object({
    id: z.number(),
    status: ChargeStatusSchema.nullable(),
  }).nullable(),
  replacingAccounts: z.object({ id: z.number() }).array(),
  type: TransactionTypeSchema,
  buy: z.number().nullable(),
  batch: z.number().nullable(),
  dop: z.string().nullable().transform((v) => v ? dayjs.utc(v) : null),
  dos: z.string().nullable().transform((v) => v ? dayjs.utc(v) : null),
  procedure: ProcedureSchema.nullable(),
  note: z.string().nullable(),
  invoice: z.number().nullable(),
  cost: z.number().nullable(),
  amount: z.number().nullable(),
  createdAt: z.string().transform((v) => dayjs(v)),
}).transform((transaction) => ({
  ...transaction,
  documents: transaction.documents.map((d) => d.id),
  replacingAccounts: transaction.replacingAccounts.map((a) => a.id),
}))

export const DocumentTypeSchema = z.enum([
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/tiff',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.ms-excel',
  'text/plain',
])

export const DocumentTagSchema = z.enum([
  'Underwriting Form',
  'Causation Letter',
  'Lien Document',
  'UCC1',
])

export const RequiredDocumentTagSchema = DocumentTagSchema.exclude(['Underwriting Form'])

export const DocumentSchema = z.object({
  id: z.number(),
  accountId: z.number().nullable(),
  user: UserSchema.nullable(),
  transactions: z.object({ id: z.number() }).array(),
  public: z.boolean(),
  type: DocumentTypeSchema,
  name: z.string(),
  url: z.string(),
  thumbnail: z.string().nullable(),
  size: z.number(),
  tags: DocumentTagSchema.array().nullable().transform((v) => v ?? []),
  createdAt: z.string().transform((v) => dayjs(v)),
}).transform((document) => ({
  ...document,
  transactions: document.transactions.map((t) => t.id),
}))

export const MissingDocumentsSchema = z.object({
  'Causation Letter': z.boolean().optional(),
  'Lien Document': z.boolean().optional(),
  'UCC1': z.boolean().optional(),
  'Invoices': z.boolean().optional(),
})

type MissingDocuments = z.infer<typeof MissingDocumentsSchema>

export function missingAnyDocuments(missingDocuments: MissingDocuments | null) {
  return missingDocuments === null
    || (missingDocuments['Causation Letter']
      || missingDocuments['Lien Document']
      || missingDocuments['UCC1']
      || missingDocuments['Invoices'])
}

export const AccidentPreviewSchema = z.object({
  id: z.number(),
  patient: PatientSchema,
  accounts: AccountSchema.array(),
  doa: z.string().nullable().transform((v) => v ? dayjs.utc(v) : null),
})

export const AccidentListItemSchema = AccidentPreviewSchema.extend({
  lawFirm: LawFirmSchema.nullable(),
  status: AccidentStatusSchema.nullable(),
  lastContact: z.string().nullable().transform((v) => v ? dayjs(v) : null),
  nextContact: z.string().nullable().transform((v) => v ? dayjs(v) : null),
  target: z.number().nullish(),
  invoices: z.number().nullish(),
  collected: z.number().nullish(),
  cost: z.number().nullish(),
  open: z.boolean(),
})

export const AccidentSchema = AccidentListItemSchema.extend({
  users: UserSchema.array(),
  lawFirmContact: LawFirmContactSchema.nullable(),
  insuranceCarrier: z.string().nullable(),
  policyLimitAmount: z.number().nullable().transform((v) => v ?? 0),
  policyLimitPercentage: z.number().nullable(),
  tags: TagNameSchema.array().nullable().transform((v) => v ?? []),
  notes: z.string().nullable(),
  events: EventSchema.array(),
  transactions: TransactionSchema.array(),
  documents: DocumentSchema.array(),
  missingDocuments: MissingDocumentsSchema.nullable(),
}).transform((accident) => {
  const invoices = accident.transactions.filter((t) => t.type === 'Invoice' && t.dop)
  const replacements = invoices.filter((t) => t.replacingAccounts.length)
  let replacementStatus: 'full' | 'partial' | undefined
  if (replacements.length && replacements.length < invoices.length) {
    replacementStatus = 'partial'
  } else if (replacements.length) {
    replacementStatus = 'full'
  }
  return {
    ...accident,
    events: [
      ...accident.events,
      ...accident.documents.filter((d) => d.user).map((d): Event => ({
        accountId: d.accountId,
        user: d.user,
        public: d.public,
        type: 'Document',
        meta: {
          documentId: d.id,
        },
        createdAt: d.createdAt,
      })),
    ],
    documents: accident.documents.sort((a, b) => {
      // sort by tags
      let aIndex = DocumentTagSchema.options.indexOf(a.tags[0])
      let bIndex = DocumentTagSchema.options.indexOf(b.tags[0])

      // if document has linked transactions, sort by transaction DOP
      if (a.transactions.length && b.transactions.length) {
        const aDop = accident.transactions.find((t) => t.id === a.transactions[0])?.dop
        const bDop = accident.transactions.find((t) => t.id === b.transactions[0])?.dop
        return aDop && bDop ? bDop.valueOf() - aDop.valueOf() : 0
      } else if (a.transactions.length) {
        aIndex = 3
      } else if (b.transactions.length) {
        bIndex = 3
      }

      // if document has neither tags nor transactions, put it at the end
      if (aIndex === -1) return 1
      if (bIndex === -1) return -1

      return aIndex - bIndex
    }),
    missingAnyDocuments: missingAnyDocuments(accident.missingDocuments),
    replacementStatus,
  }
})

export const AccidentExportSchema = z.object({
  entity: z.enum(['accident', 'account']),
  type: z.enum(['current', 'full']),
  replacements: z.enum(['exclude', 'include']),
})

export const SplitAccidentsSummary = z.object({
  accidents: AccidentPreviewSchema.array(),
  target: z.number(),
  invoices: z.number(),
  collected: z.number(),
  cost: z.number(),
})

export const ReplacementAccidentsSummary = z.object({
  replacingAccidents: AccidentPreviewSchema.array(),
  replacementAccidents: AccidentPreviewSchema.array(),
})

export type Cashflow = {
  date: Dayjs
  amount: number
}[]

export type Patient = z.infer<typeof PatientSchema>
export type EventType = z.infer<typeof EventTypeSchema>
export type Event = z.infer<typeof EventSchema>
export type TransactionType = z.infer<typeof TransactionTypeSchema>
export type Transaction = z.infer<typeof TransactionSchema>
export type DocumentType = z.infer<typeof DocumentTypeSchema>
export type DocumentTag = z.infer<typeof DocumentTagSchema>
export type RequiredDocumentTag = z.infer<typeof RequiredDocumentTagSchema>
export type Document = z.infer<typeof DocumentSchema>
export type Account = z.infer<typeof AccountSchema>
export type AccidentStatus = z.infer<typeof AccidentStatusSchema>
export type AccidentPreview = z.infer<typeof AccidentPreviewSchema>
export type AccidentListItem = z.infer<typeof AccidentListItemSchema>
export type Accident = z.infer<typeof AccidentSchema>
export type AccidentExport = z.infer<typeof AccidentExportSchema>
export type AccidentExportEntity = z.infer<typeof AccidentExportSchema.shape.entity>
export type AccidentExportType = z.infer<typeof AccidentExportSchema.shape.type>
export type AccidentExportReplacements = z.infer<typeof AccidentExportSchema.shape.replacements>

export function statusesFromPortfolioStatus(portfolioStatus: string) {
  let statuses: AccidentStatus[] = []

  switch (portfolioStatus) {
    case 'Total Portfolio':
      statuses = [
        'Open - New Patient',
        'Open - Pending',
        'Open - Treating',
        'Open - Pre-Litigation',
        'Open - Litigation',
        'Open - Demand Stage',
        'Open - Negotiation (Insurance)',
        'Open - Negotiation (Provider)',
        'Open - Unresolved',
        'Open - Resolved',
        'Open - Processing',
        'Settled - Reduction Pending',
        'Settled - Reduction Approved',
        'Settled - Check on the Way',
        'Settled - Unpaid',
        'Settled - Partially Paid',
        'Closed - Dropped',
        'Closed - Withdrew',
        'Closed - Fraud',
        'Unknown',
      ]
      break
    case 'Open - Good Standing':
      statuses = [
        'Open - New Patient',
        'Open - Pending',
        'Open - Treating',
        'Open - Pre-Litigation',
        'Open - Litigation',
        'Open - Demand Stage',
        'Open - Negotiation (Insurance)',
        'Open - Negotiation (Provider)',
        'Open - Resolved',
      ]
      break
    case 'Settled - Check on the Way':
      statuses = ['Settled - Check on the Way']
      break
    case 'Settled - Reductions':
      statuses = [
        'Settled - Reduction Pending',
        'Settled - Reduction Approved',
      ]
      break
    case 'Unknown':
      statuses = ['Unknown']
      break
    case 'Pending Replacement':
      statuses = [
        'Open - Unresolved',
        'Open - Processing',
        'Closed - Dropped',
        'Closed - Fraud',
        'Closed - Withdrew',
      ]
      break
    case 'Settled - Unpaid':
      statuses = ['Settled - Unpaid']
      break
    case 'Settled - Partially Paid':
      statuses = ['Settled - Partially Paid']
      break
  }

  return statuses
}
