/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import * as RadixPopover from '@radix-ui/react-popover'
import { ReactNode, useState } from 'react'
import { useTheme } from '../../hooks/useTheme'

type Props = Omit<RadixPopover.PopoverContentProps, 'content'> & {
  open?: boolean
  content: ReactNode
  children: ReactNode
  onOpenChange?: (open: boolean) => void
}

export function Popover({ open: propOpen, autoFocus = true, content, children, onOpenChange, ...props }: Props) {
  const theme = useTheme()

  const [open, setOpen] = useState(propOpen)
  const [prevOpen, setPrevOpen] = useState(propOpen)
  if (prevOpen !== propOpen) {
    setOpen(propOpen)
    setPrevOpen(propOpen)
  }

  const contentShow = keyframes({
    from: {
      opacity: 0,
      transform: 'scale(0.96)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
  })
  const contentHide = keyframes({
    from: {
      opacity: 1,
      transform: 'scale(1)',
    },
    to: {
      opacity: 0,
      transform: 'scale(0.96)',
    },
  })

  const styles = {
    content: css({
      backgroundColor: theme.colors.surface,
      borderRadius: theme.borderRadius.s + 2,
      boxShadow: theme.web.shadow.overlay,
      animationDuration: '0.2s',
      animationTimingFunction: theme.web.easing.curveLinearToEaseOut,
      transformOrigin: 'var(--radix-popover-content-transform-origin)',
      willChange: 'transform, opacity',
      '&[data-state=open]': {
        animationName: contentShow,
      },
      '&[data-state=closed]': {
        animationName: contentHide,
      },
    })
  }

  return (
    <RadixPopover.Root
      open={open}
      onOpenChange={(open) => {
        setOpen(open)
        if (onOpenChange) onOpenChange(open)
      }}
    >
      <RadixPopover.Trigger asChild={true}>
        <span>{children}</span>
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content css={styles.content} {...props}>
          {!autoFocus && <input type='text' style={{ position: 'absolute', opacity: 0 }} />}
          {content}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  )
}
