import dayjs from 'dayjs'
import z from 'zod'
import { AccidentStatus, AccidentStatusSchema, AccountSchema, PatientSchema } from './accident'
import { BatchListItemSchema } from './batch'
import { StaffDashboardFilter } from './filter'
import { LawFirmSchema } from './law-firm'
import { TagNameSchema } from './tag'
import { UserSchema } from './user'

const StaffDashboardAccidentSchema = z.object({
  id: z.number(),
  patient: PatientSchema,
  lawFirm: LawFirmSchema.nullable(),
  accounts: AccountSchema.array(),
  status: AccidentStatusSchema.nullable(),
  lastContact: z.string().nullable().transform((v) => v ? dayjs(v) : null),
  nextContact: z.string().nullable().transform((v) => v ? dayjs(v) : null),
  target: z.number().nullable(),
  tags: TagNameSchema.array().nullable().transform((v) => v ?? []),
})

export const StaffDashboardSchema = z.object({
  progress: z.object({
    from: z.string().transform((v) => dayjs.utc(v)),
    to: z.string().transform((v) => dayjs.utc(v)),
    statuses: z.object({
      value: z.number(),
      goal: z.number(),
    }),
    totalStatused: z.object({
      value: z.number(),
      goal: z.number(),
    }),
    return: z.object({
      value: z.number(),
      goal: z.number(),
    }),
    callsMade: z.object({
      value: z.number(),
      goal: z.number(),
    }),
    emailsSent: z.object({
      value: z.number(),
      goal: z.number(),
    }),
    score: z.number(),
  }),
  batches: BatchListItemSchema.array(),
  checksReceived: z.object({
    accidentId: z.number(),
    provider: z.string(),
    name: z.string(),
    amount: z.number(),
    ROI: z.number(),
    IRR: z.number(),
  }).array(),
  accidents: StaffDashboardAccidentSchema.array(),
})

export const ExecutiveDashboardSchema = z.object({
  keyNumbers: z.object({
    collected: z.number(),
    cost: z.number(),
    ROI: z.number(),
    IRR: z.number(),
  }),
  portfolio: z.object({
    label: z.string(),
    accidents: z.number(),
    invoices: z.number(),
    cost: z.number(),
    target: z.number(),
  }).array(),
  staffSummary: z.object({
    user: UserSchema,
    statuses: z.object({
      value: z.number(),
      goal: z.number(),
    }),
    totalStatused: z.object({
      value: z.number(),
      goal: z.number(),
    }),
    return: z.object({
      value: z.number(),
      goal: z.number(),
    }),
    callsMade: z.object({
      value: z.number(),
      goal: z.number(),
    }),
    emailsSent: z.object({
      value: z.number(),
      goal: z.number(),
    }),
    score: z.number(),
  }).array(),
})

export type StaffDashboardAccident = z.infer<typeof StaffDashboardAccidentSchema>
export type StaffDashboard = z.infer<typeof StaffDashboardSchema>
export type ExecutiveDashboard = z.infer<typeof ExecutiveDashboardSchema>

export function filterStaffDashboardAccidents(accidents: StaffDashboardAccident[], filter: StaffDashboardFilter) {
  switch (filter) {
    case 'highPriority':
      return accidents.filter((a) => !a.nextContact?.isAfter(dayjs())
        && ((a.target && a.target >= 2000000 && a.lastContact?.isBefore(dayjs().subtract(3, 'months'))) || a.lastContact?.isBefore(dayjs().subtract(4, 'months'))))
    case 'noStatusIn3Months':
      return accidents.filter((a) => a.lastContact?.isBefore(dayjs().subtract(3, 'months')))
    case 'redFlag':
      return accidents.filter((a) => a.tags.includes('Red Flag'))
    case 'settledCheckOnTheWay':
      return accidents.filter((a) => a.status === 'Settled - Check on the Way')
    case 'settledReductions':
      return accidents.filter((a) => a.status === 'Settled - Reduction Pending' || a.status === 'Settled - Reduction Approved')
    case 'settledUnpaid':
      return accidents.filter((a) => a.status === 'Settled - Unpaid')
    case 'settledPartiallyPaid':
      return accidents.filter((a) => a.status === 'Settled - Partially Paid')
    case 'pendingReplacement': {
      const statuses: AccidentStatus[] = ['Open - Unresolved', 'Open - Processing', 'Closed - Dropped', 'Closed - Fraud', 'Closed - Withdrew']
      return accidents.filter((a) => a.status !== null && statuses.includes(a.status))
    }
    case 'unknown':
      return accidents.filter((a) => a.status === 'Unknown' || a.status === null)
    case 'goodStanding': {
      const statuses: AccidentStatus[] = [
        'Open - New Patient',
        'Open - Pending',
        'Open - Treating',
        'Open - Pre-Litigation',
        'Open - Litigation',
        'Open - Demand Stage',
        'Open - Negotiation (Insurance)',
        'Open - Negotiation (Provider)',
        'Open - Resolved',
        'Settled - Check on the Way',
      ]
      return accidents.filter((a) => a.status !== null && statuses.includes(a.status))
    }
    default:
      return accidents
  }
}
