import ExpoHead from 'expo-router/head'
import { ReactNode, useEffect } from 'react'

type Props = {
  title: string
  description?: string
  children?: ReactNode
}

export function Head({ title, description, children }: Props) {
  const titleText = `${title} - Lienstar Portal`

  useEffect(() => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      const viewportMeta = document.querySelector('meta[name="viewport"]')
      if (viewportMeta) {
        viewportMeta.setAttribute(
          'content',
          'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1'
        )
      }
    }
  }, [])

  return (
    <ExpoHead>
      <title>{titleText}</title>
      {description && <meta name='description' content={description} />}
      {children}
    </ExpoHead>
  )
}
