import dayjs from 'dayjs'
import { useState } from 'react'
import { StyleSheet, TextInputProps, View, ViewProps } from 'react-native'
import { Select } from './Select'
import { Spacing } from './Spacing'
import { Text } from './Text'
import { TextInput } from './TextInput'

type Props = ViewProps & {
  value: string
  error?: string | boolean
  compact?: boolean
  onChange: (value: string) => void
}

export function DobInput({ value, error, compact, onChange, ...props }: Props) {
  const date = dayjs(value, 'YYYY-MM-DD')

  const [month, setMonth] = useState(() => {
    return value && date.isValid() ? date.format('MMMM') : ''
  })
  const [day, setDay] = useState(() => {
    return value && date.isValid() ? date.format('D') : ''
  })
  const [year, setYear] = useState(() => {
    return value && date.isValid() ? date.format('YYYY') : ''
  })
  const [prevValue, setPrevValue] = useState(value)
  if (prevValue !== value) {
    setMonth(value && date.isValid() ? date.format('MMMM') : '')
    setDay(value && date.isValid() ? date.format('D') : '')
    setYear(value && date.isValid() ? date.format('YYYY') : '')
    setPrevValue(value)
  }

  function handleChange(month: string, day: string, year: string) {
    if (month && day && year.length === 4) {
      const date = dayjs(`${month} ${day}, ${year}`, 'MMMM D, YYYY')
      if (date.isValid()) onChange(date.format('YYYY-MM-DD'))
    }
  }

  const styles = StyleSheet.create({
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  })

  return (
    <View {...props}>
      <View style={styles.wrapper}>
        <Select
          label='Date of birth'
          value={month}
          placeholder='Month'
          options={[
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ]}
          error={!!error}
          compact={compact}
          style={{ flex: 1 }}
          onChange={(value) => {
            setMonth(value)
            handleChange(value, day, year)
          }}
        />
        <Spacing width={0.5} />
        <Day
          value={day}
          error={!!error}
          compact={compact}
          onChangeText={(value) => {
            setDay(value)
            handleChange(month, value, year)
          }}
        />
        <Spacing width={0.5} />
        <Year
          value={year}
          error={!!error}
          compact={compact}
          onChangeText={(value) => {
            setYear(value)
            handleChange(month, day, value)
          }}
        />
      </View>

      {typeof error === 'string' && <Text type='inputError'>{error}</Text>}
    </View>
  )
}

type DayYearProps = TextInputProps & {
  value: string
  error: boolean
  compact: boolean | undefined
  onChangeText: (text: string) => void
}

function Day({ value: propValue, error, onChangeText, ...props }: DayYearProps) {
  const format = (value: string) => value.replace(/[^0-9]/gi, '')
  return (
    <TextInput
      {...props}
      label='Day'
      value={format(propValue)}
      placeholder='DD'
      autoComplete='birthdate-day'
      clearIcon={false}
      error={error}
      keyboardType='number-pad'
      maxLength={2}
      returnKeyType='done'
      selectTextOnFocus={true}
      containerStyle={{ flex: 0, minWidth: 55 }}
      onChangeText={(value) => onChangeText(format(value))}
    />
  )
}

function Year({ value: propValue, error, onChangeText, ...props }: DayYearProps) {
  const format = (value: string) => value.replace(/[^0-9]/gi, '')
  return (
    <TextInput
      {...props}
      label='Year'
      value={format(propValue)}
      placeholder='YYYY'
      autoComplete='birthdate-year'
      clearIcon={false}
      error={error}
      keyboardType='number-pad'
      maxLength={4}
      returnKeyType='done'
      selectTextOnFocus={true}
      containerStyle={{ flex: 0, minWidth: 75 }}
      onChangeText={(value) => onChangeText(format(value))}
    />
  )
}
