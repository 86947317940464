import { router } from 'expo-router'
import { useStore } from '@/providers/store'
import { useStaffDashboard } from '@/queries/dashboard.queries'
import { AccidentDetails } from '@/screens/accidents/AccidentDetails'
import { filterStaffDashboardAccidents } from '@/types/dashboard'

export default function DashboardAccidentDetailsScreen() {
  const filter = useStore.useStaffDashboardFilter()

  const dashboard = useStaffDashboard()

  const accidents = filterStaffDashboardAccidents(dashboard.data?.accidents ?? [], filter)

  return (
    <AccidentDetails
      list={accidents.length ? {
        items: accidents.map((a) => a.id),
        onNav: (accidentId: number) => router.navigate(`/dashboard/cases/${accidentId}`),
      } : undefined}
    />
  )
}
