import { QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { keys as accidentKeys } from './accident.queries'
import { api } from './api'
import { LawFirm, LawFirmContact, LawFirmContactSchema, LawFirmFilter, LawFirmListItem, LawFirmListItemSchema, LawFirmProfileSchema, LawFirmSchema } from '@/types/law-firm'

export const keys = {
  lawFirms: (locations?: string[], providers?: string[]): QueryKey => {
    return locations && providers ? ['lawFirms', locations, providers]
      : locations ? ['lawFirms', locations]
        : providers ? ['lawFirms', providers] : ['lawFirms']
  },
  allLawFirms: (filter?: LawFirmFilter): QueryKey => filter ? ['allLawFirms', filter] : ['allLawFirms'],
  lawFirmSearch: (input?: string): QueryKey => input ? ['lawFirmSearch'] : ['lawFirmSearch', input],
  lawFirm: (id: number): QueryKey => ['lawFirm', id],
  lawFirmContacts: (lawFirms?: string[]): QueryKey => lawFirms ? ['lawFirmContacts', lawFirms] : ['lawFirmContacts'],
}

export function useLawFirms(locations: string[], providers: string[]) {
  return useQuery({
    queryKey: keys.lawFirms(locations, providers),
    queryFn: async () => {
      const res = await api.get('lawFirms', {
        params: {
          locations: locations.length ? locations.join(',') : undefined,
          providers: providers.length ? providers.join(',') : undefined,
        },
      })
      return res.data.lawFirms.map((item: any) => LawFirmSchema.parse(item)) as LawFirm[]
    },
  })
}

export function useAllLawFirms(filter: LawFirmFilter) {
  return useQuery({
    queryKey: keys.allLawFirms(filter),
    queryFn: async () => {
      const res = await api.get('lawFirms/all', { params: { ...filter } })
      return {
        lawFirms: res.data.lawFirms.map((item: any) => LawFirmListItemSchema.parse(item)) as LawFirmListItem[],
        total: res.data.total,
      }
    },
  })
}

export function useLawFirmsExport() {
  return useMutation({
    mutationFn: ({ filter }: { filter: LawFirmFilter }) => {
      return api.get('lawFirms/all', { responseType: 'blob', params: { ...filter, exportType: 'current' } })
    },
  })
}

export function useLawFirmSearch() {
  return useMutation({
    mutationFn: async (input: string) => {
      const res = await api.get('lawFirms/search', { params: { input } })
      return res.data.lawFirms.map((item: any) => LawFirmSchema.parse(item)) as LawFirm[]
    },
  })
}

export function useCreateLawFirm() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ name, rating }: { name: string, rating: number | undefined }) => {
      const res = await api.post('lawFirms', { name, rating })
      return LawFirmProfileSchema.parse(res.data.lawFirm)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lawFirms() })
      queryClient.invalidateQueries({ queryKey: keys.allLawFirms() })
      queryClient.invalidateQueries({ queryKey: keys.lawFirmSearch() })
    },
  })
}

export function useLawFirm(lawFirmId: number) {
  return useQuery({
    queryKey: keys.lawFirm(lawFirmId),
    queryFn: async () => {
      const res = await api.get(`lawFirms/${lawFirmId}`)
      return res.data.lawFirm ? LawFirmProfileSchema.parse(res.data.lawFirm) : null
    },
  })
}

export function useUpdateLawFirm() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, name, rating, notes }: { id: number, name?: string, rating?: number, notes?: string }) => {
      const res = await api.patch(`lawFirms/${id}`, { name, rating, notes })
      return LawFirmProfileSchema.parse(res.data.lawFirm)
    },
    onSuccess: (lawFirm) => {
      queryClient.setQueryData(keys.lawFirm(lawFirm.id), lawFirm)
      queryClient.invalidateQueries({ queryKey: keys.lawFirms() })
      queryClient.invalidateQueries({ queryKey: keys.allLawFirms() })
      queryClient.invalidateQueries({ queryKey: keys.lawFirmSearch() })
      queryClient.invalidateQueries({ queryKey: accidentKeys.accidents() })
      queryClient.invalidateQueries({ queryKey: accidentKeys.accident() })
    },
  })
}

export function useMergeLawFirms() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ from, into }: { from: number, into: number }) => {
      const res = await api.post('lawFirms/merge', { from, into })
      return LawFirmProfileSchema.parse(res.data.lawFirm)
    },
    onSuccess: (lawFirm) => {
      queryClient.setQueryData(keys.lawFirm(lawFirm.id), lawFirm)
      queryClient.invalidateQueries({ queryKey: keys.lawFirms() })
      queryClient.invalidateQueries({ queryKey: keys.allLawFirms() })
      queryClient.invalidateQueries({ queryKey: keys.lawFirmSearch() })
    },
  })
}

export function useDeleteLawFirm() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }: { id: number }) => api.delete(`lawFirms/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lawFirms() })
      queryClient.invalidateQueries({ queryKey: keys.allLawFirms() })
      queryClient.invalidateQueries({ queryKey: keys.lawFirmSearch() })
    },
  })
}

export function useLawFirmContacts(lawFirms: string[]) {
  return useQuery({
    enabled: lawFirms.length > 0,
    queryKey: keys.lawFirmContacts(lawFirms),
    queryFn: async () => {
      const res = await api.get('lawFirmContacts', {
        params: {
          lawFirms: lawFirms.length ? lawFirms.join(',') : undefined,
        },
      })
      return res.data.lawFirmContacts.map((item: any) => LawFirmContactSchema.parse(item)) as LawFirmContact[]
    },
  })
}

export function useCreateLawFirmContact() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ lawFirmId, name, role, email, phone, notes }: {
      lawFirmId: number
      name: string
      role: string
      email: string
      phone: string
      notes: string
    }) => {
      const res = await api.post('lawFirmContacts', { lawFirmId, name, role, email, phone, notes })
      return LawFirmProfileSchema.parse(res.data.lawFirm)
    },
    onSuccess: (lawFirm) => {
      queryClient.setQueryData(keys.lawFirm(lawFirm.id), lawFirm)
      queryClient.invalidateQueries({ queryKey: keys.lawFirmContacts([lawFirm.id.toString()]) })
    },
  })
}

export function useUpdateLawFirmContact() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, name, role, email, phone, notes }: {
      id: number
      lawFirmId: number
      name: string
      role: string
      email: string
      phone: string
      notes: string
    }) => {
      const res = await api.patch(`lawFirmContacts/${id}`, { name, role, email, phone, notes })
      return LawFirmProfileSchema.parse(res.data.lawFirm)
    },
    onSuccess: (lawFirm) => {
      queryClient.setQueryData(keys.lawFirm(lawFirm.id), lawFirm)
      queryClient.invalidateQueries({ queryKey: keys.lawFirmContacts([lawFirm.id.toString()]) })
      queryClient.invalidateQueries({ queryKey: accidentKeys.accident() })
    },
  })
}

export function useDeleteLawFirmContact() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id }: { id: number, lawFirmId: number }) => {
      const res = await api.delete(`lawFirmContacts/${id}`)
      return LawFirmProfileSchema.parse(res.data.lawFirm)
    },
    onSuccess: (lawFirm) => {
      queryClient.setQueryData(keys.lawFirm(lawFirm.id), lawFirm)
      queryClient.invalidateQueries({ queryKey: keys.lawFirmContacts([lawFirm.id.toString()]) })
      queryClient.invalidateQueries({ queryKey: accidentKeys.accident() })
    },
  })
}
