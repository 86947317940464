/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ComponentProps } from 'react'
import { ColorStatus, colorWithOpacity, useTheme } from 'ui'

type Props = ComponentProps<'div'> & {
  color?: string
  background?: string
  borderColor?: string
  status?: ColorStatus
  compact?: boolean
}

export function Badge({
  color: propColor,
  background: propBackground,
  borderColor: propBorderColor,
  status,
  compact,
  children,
  ...props
}: Props) {
  const theme = useTheme()

  const height = compact ? 22 : 24
  let background = theme.colors.panelBorder
  let textColor = theme.colors.foreground
  let borderColor = propBorderColor

  if (status) {
    const color = theme.colors[status]
    background = colorWithOpacity(color, 0.12)
    borderColor = colorWithOpacity(color, 0.4)
    textColor = color
  }

  const styles = {
    outer: css({
      display: 'inline-block',
      height,
      borderRadius: height,
      background: theme.colors.transparencyBase,
    }),
    inner: css({
      height: '100%',
      background: propBackground ?? background,
      color: propColor ?? textColor,
      border: borderColor ? `1px solid ${borderColor}` : undefined,
      borderRadius: height,
      fontFamily: theme.fonts.body[600],
      fontSize: compact ? 10 : 10.5,
      lineHeight: `${height - (borderColor ? 1 : 0)}px`,
      whiteSpace: 'nowrap',
      textTransform: 'uppercase',
      letterSpacing: 0.4,
      padding: `0 ${compact ? 7 : 8}px`,
    }),
  }

  return (
    <div css={styles.outer} {...props}>
      <div css={styles.inner}>{children}</div>
    </div>
  )
}
