import { Image } from 'expo-image'
import { router, useLocalSearchParams } from 'expo-router'
import { useEffect, useState } from 'react'
import { Pressable, PressableProps, StyleSheet, View } from 'react-native'
import { Toast } from 'react-native-toast-universal'
import { ActivityIndicator, Button } from 'ui'
import { useQuickbooksAuth, useQuickbooksAuthUrl, useQuickbooksDisconnect } from '@/queries/quickbooks.queries'

export function QuickbooksAuth() {
  const params = useLocalSearchParams<{ code?: string, realmId?: string }>()

  const authUrl = useQuickbooksAuthUrl()
  const { mutate: quickbooksAuth } = useQuickbooksAuth()
  const disconnect = useQuickbooksDisconnect()

  useEffect(() => {
    if (params.code && params.realmId) {
      quickbooksAuth(location.href, {
        onSuccess: () => Toast.success('QuickBooks connected!'),
        onSettled: () => router.replace('/quickbooks'),
      })
    }
  }, [params.code, params.realmId, quickbooksAuth])

  const [loading, setLoading] = useState(false)

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      {authUrl.isPending ? (
        <ActivityIndicator />
      ) : authUrl.data ? (
        <QuickbooksButton
          loading={loading}
          onPress={() => {
            setLoading(true)
            location.href = authUrl.data
          }}
        />
      ) : (
        <Button
          text='Disconnect from QuickBooks'
          type='tertiary'
          loading={disconnect.isPending}
          onPress={async () => {
            disconnect.mutate(undefined, {
              onSuccess: () => Toast.info('QuickBooks disconnected'),
            })
          }}
        />
      )}
    </View>
  )
}

type QuickbooksButtonProps = PressableProps & {
  loading?: boolean
}

function QuickbooksButton({ loading, ...props }: QuickbooksButtonProps) {
  const [hovered, setHovered] = useState(false)

  const styles = StyleSheet.create({
    button: {
      width: 274,
      height: 48,
    },
    loading: {
      backgroundColor: hovered ? '#1C900D' : '#2BA01D',
      borderRadius: 4,
      position: 'absolute',
      zIndex: 1,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
  })

  return (
    <Pressable
      {...props}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
    >
      {hovered ? (
        <Image
          source={require(`assets/images/quickbooks/connect-hover.svg`)}
          style={styles.button}
        />
      ) : (
        <Image
          source={require(`assets/images/quickbooks/connect-default.svg`)}
          style={styles.button}
        />
      )}

      {loading && (
        <View style={styles.loading}>
          <ActivityIndicator inverted={true} />
        </View>
      )}
    </Pressable>
  )
}
