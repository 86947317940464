/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Link, router, usePathname } from 'expo-router'
import { useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { Avatar, colorWithOpacity, NavMenu, NavMenuItem, Spacing, useTheme } from 'ui'
import { Logo } from '@/components/Logo'
import { AccidentSearch } from '@/components/web/AccidentSearch'
import { Constants } from '@/constants'
import { useStore } from '@/providers/store'
import { useSignOut } from '@/queries/auth.queries'
import { useUser } from '@/queries/user.queries'
import { Profile } from '@/screens/home/Profile'
import { ProxyUser } from '@/screens/home/ProxyUser'

export function NavHeader() {
  const pathname = usePathname()
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const user = useUser()

  const [openMobileSearch, setOpenMobileSearch] = useState(false)
  const mobileSearchRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (openMobileSearch) mobileSearchRef.current?.focus()
  }, [openMobileSearch])

  const nav: Nav = [
    {
      title: 'Cases',
      active: pathname.startsWith('/cases'),
      href: '/cases',
    },
    {
      title: 'Funding Requests',
      active: pathname.startsWith('/funding-requests'),
      href: '/funding-requests',
    },
  ]

  if (user.data?.role !== 'provider') {
    nav.unshift({
      title: 'Dashboard',
      active: pathname.startsWith('/dashboard'),
      href: '/dashboard',
    })
  }

  const styles = {
    wrapper: css({
      background: theme.colors.headerBackground,
      borderBottom: `1px solid ${theme.colors.panelBorder}`,
      boxShadow: theme.web.shadow.panel,
      padding: `0 ${theme.spacing}px`,
      zIndex: 5,
    }),
    header: css({
      height: Constants.headerHeight,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: theme.spacing,
    }),
    logoWrapper: css({
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-start',
    }),
    logo: css({
      display: 'flex',
      alignItems: 'center',
    }),
    mobileSearch: css({
      marginBottom: theme.spacing,
    }),
    mobileSearchTrigger: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.header}>
        <div css={styles.logoWrapper}>
          <Link href='/' css={styles.logo}><Logo /></Link>
        </div>

        {width >= theme.breakpoints.s ? (
          <AccidentSearch
            context='header'
            onSelect={(accident) => router.navigate(`/cases/${accident.id}`)}
          />
        ) : (
          <div css={styles.mobileSearchTrigger} onClick={() => setOpenMobileSearch(!openMobileSearch)}>
            <FontAwesomeIcon
              icon={['far', 'magnifying-glass']}
              size={20}
              color={colorWithOpacity(theme.colors.foreground, openMobileSearch ? 0.5 : 1)}
            />
          </div>
        )}

        {width >= theme.breakpoints.l ? <DesktopNav nav={nav} /> : <MobileNav nav={nav} />}
      </div>

      {(width < theme.breakpoints.s && openMobileSearch) && (
        <div css={styles.mobileSearch}>
          <AccidentSearch
            ref={mobileSearchRef}
            context='header'
            fullWidth={true}
            onSelect={(accident) => {
              setOpenMobileSearch(false)
              router.navigate(`/cases/${accident.id}`)
            }}
          />
        </div>
      )}
    </div>
  )
}

type Nav = {
  title: string
  active: boolean
  href: string
}[]

function MobileNav({ nav }: { nav: Nav }) {
  const pathname = usePathname()
  const modal = useStore.useModal()
  const theme = useTheme()

  const user = useUser()
  const signOut = useSignOut()

  const items: NavMenuItem[] = [
    {
      type: 'item',
      title: user.data?.fullName ?? undefined,
      subtitle: user.data?.email,
      onClick: () => modal.open({
        title: 'Profile',
        content: <Profile />,
      }),
    },
    { type: 'divider' },
    {
      type: 'item',
      title: 'Profile',
      onClick: () => modal.open({
        title: 'Profile',
        content: <Profile />,
      }),
    },
    ...nav.map<NavMenuItem>((item) => ({
      type: 'item',
      title: item.title,
      active: item.active,
      onClick: () => router.navigate(item.href),
    })),
  ]

  if (user.data?.role === 'executive') {
    items.push({ type: 'divider' })
    items.push({
      type: 'item',
      title: 'Users',
      active: pathname === '/users',
      onClick: () => router.navigate('/users'),
    })
    items.push({
      type: 'item',
      title: 'Providers',
      active: pathname === '/providers',
      onClick: () => router.navigate('/providers'),
    })
    items.push({
      type: 'item',
      title: 'Law Firms',
      active: pathname === '/law-firms',
      onClick: () => router.navigate('/law-firms'),
    })
    items.push({
      type: 'item',
      title: 'Proxy User',
      onClick: () => modal.open({
        title: 'Proxy User',
        content: <ProxyUser />,
      }),
    })
  }

  if (user.data?.role === 'manager' || user.data?.role === 'staff') {
    items.push({
      type: 'item',
      title: 'Law Firms',
      onClick: () => router.navigate('/law-firms'),
    })
  }

  items.push({ type: 'divider' })
  items.push({
    type: 'item',
    title: 'Sign Out',
    onClick: () => signOut.mutate(),
  })

  const styles = {
    navToggle: css({
      cursor: 'pointer',
      lineHeight: 0,
      marginLeft: theme.spacing * 0.3,
    }),
  }

  return (
    <NavMenu items={items} activeColor={theme.colors.accent}>
      <div css={styles.navToggle}>
        <FontAwesomeIcon
          icon={['far', 'bars']}
          size={24}
          color={theme.colors.foreground}
        />
      </div>
    </NavMenu>
  )
}

function DesktopNav({ nav }: { nav: Nav }) {
  const pathname = usePathname()
  const modal = useStore.useModal()
  const theme = useTheme()

  const user = useUser()
  const signOut = useSignOut()

  const items: NavMenuItem[] = [
    {
      type: 'item',
      title: user.data?.fullName ?? undefined,
      subtitle: user.data?.email,
      onClick: () => modal.open({
        title: 'Profile',
        content: <Profile />,
      }),
    },
    { type: 'divider' },
    {
      type: 'item',
      title: 'Profile',
      onClick: () => modal.open({
        title: 'Profile',
        content: <Profile />,
      }),
    },
  ]

  if (user.data?.role === 'executive') {
    items.push({
      type: 'item',
      title: 'Users',
      active: pathname === '/users',
      onClick: () => router.navigate('/users'),
    })
    items.push({
      type: 'item',
      title: 'Providers',
      active: pathname === '/providers',
      onClick: () => router.navigate('/providers'),
    })
    items.push({
      type: 'item',
      title: 'Law Firms',
      active: pathname === '/law-firms',
      onClick: () => router.navigate('/law-firms'),
    })
    items.push({
      type: 'item',
      title: 'Proxy User',
      onClick: () => modal.open({
        title: 'Proxy User',
        content: <ProxyUser />,
      }),
    })
    items.push({ type: 'divider' })
  }

  if (user.data?.role === 'manager' || user.data?.role === 'staff') {
    items.push({
      type: 'item',
      title: 'Law Firms',
      onClick: () => router.navigate('/law-firms'),
    })
    items.push({ type: 'divider' })
  }

  items.push({
    type: 'item',
    title: 'Sign Out',
    onClick: () => signOut.mutate(),
  })

  const styles = {
    wrapper: css({
      flex: 1,
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
    }),
    avatar: css({
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      {nav.map((item) => (
        <DesktopNavLink
          key={item.title}
          title={item.title}
          active={item.active}
          href={item.href}
        />
      ))}
      <Spacing width={0.9} />
      <NavMenu items={items} activeColor={theme.colors.accent}>
        <div css={styles.avatar}>
          <Avatar
            source={user.data?.photoUrl ?? undefined}
            size={28}
            placeholderName={user.data?.fullName ?? undefined}
            placeholderColor={colorWithOpacity(theme.colors.foreground, 0.45)}
          />
        </div>
      </NavMenu>
    </div>
  )
}

type DesktopNavLinkProps = {
  title: string
  active?: boolean
  href: string
}

function DesktopNavLink({ title, active, href }: DesktopNavLinkProps) {
  const theme = useTheme()

  const styles = {
    link: css({
      color: active ? theme.colors.accent : theme.colors.foreground,
      fontFamily: theme.fonts.body[500],
      fontSize: 15.5,
      margin: `0 ${theme.spacing * 0.8}px`,
      padding: '10px 0',
      textWrap: 'nowrap',
      '&:hover': {
        opacity: active ? 1 : 0.6,
      },
    }),
  }

  return (
    <Link href={href} css={styles.link}>{title}</Link>
  )
}
