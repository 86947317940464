import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useHeaderHeight } from '@react-navigation/elements'
import { BarcodeType, CameraView, PermissionStatus, useCameraPermissions } from 'expo-camera'
import * as Haptics from 'expo-haptics'
import * as Linking from 'expo-linking'
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { Platform, Pressable, StyleSheet, View } from 'react-native'
import Animated, { cancelAnimation, Easing, FadeIn, FadeOut, Keyframe, useAnimatedStyle, useSharedValue, withRepeat, withSequence, withTiming } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Path, Svg, SvgProps } from 'react-native-svg'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Button } from './Button'
import { Spacing } from './Spacing'
import { Text } from './Text'

type Feedback = {
  heading: string
  subheading: string
  image: ReactNode
}

type BarcodeScannerProps = {
  height: number
  barcodeTypes: BarcodeType[]
  setPermissionGranted: Dispatch<SetStateAction<boolean>>
  torchEnabled: boolean
  onBarcodeScanned: (data: string) => Promise<Feedback | undefined>
}

export function BarcodeScanner({
  height,
  barcodeTypes,
  setPermissionGranted,
  torchEnabled,
  onBarcodeScanned,
}: BarcodeScannerProps) {
  const insets = useSafeAreaInsets()
  const headerHeight = useHeaderHeight()
  const [permission, requestPermission] = useCameraPermissions()
  const theme = useTheme()

  const [cameraLoaded, setCameraLoaded] = useState(false)
  const [feedback, setFeedback] = useState<Feedback>()

  const processingRef = useRef(false)
  const lastScanRef = useRef<string>()
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    requestPermission()
  }, [requestPermission])

  useEffect(() => {
    if (permission) setPermissionGranted(permission.granted)
  }, [permission, setPermissionGranted])

  const frameAnimation = useSharedValue(1)
  const frameAnimationStyles = useAnimatedStyle(() => ({
    transform: [{ scale: frameAnimation.value }],
  }))

  const startFrameAnimation = useCallback(() => {
    const duration = 550

    if (frameAnimation.value > 1) {
      frameAnimation.value = withTiming(
        1,
        { duration, easing: Easing.out(Easing.cubic) },
        () => {
          frameAnimation.value = withRepeat(
            withSequence(
              withTiming(1.05, { duration, easing: Easing.in(Easing.cubic) }),
              withTiming(1, { duration, easing: Easing.out(Easing.cubic) }),
            ),
            -1,
          )
        },
      )
    } else {
      frameAnimation.value = withRepeat(
        withSequence(
          withTiming(1.05, { duration, easing: Easing.in(Easing.cubic) }),
          withTiming(1, { duration, easing: Easing.out(Easing.cubic) }),
        ),
        -1,
      )
    }
  }, [frameAnimation])

  useEffect(() => {
    startFrameAnimation()
  }, [startFrameAnimation])

  const frameBounds = {
    top: headerHeight + theme.spacing * 2.5,
    right: theme.spacing * 2.5,
    bottom: theme.spacing * 2.5 + theme.borderRadius.l,
    left: theme.spacing * 2.5,
  }

  const feedbackDuration = 250

  const feedbackEnterKeyframe = new Keyframe({
    0: {
      transform: [{ scale: 0.88 }],
      opacity: 0,
    },
    100: {
      transform: [{ scale: 1 }],
      opacity: 1,
      easing: theme.easing.curveLinearToEaseOut,
    },
  }).duration(feedbackDuration)

  const feedbackExitKeyframe = new Keyframe({
    0: {
      transform: [{ scale: 1 }],
      opacity: 1,
    },
    100: {
      transform: [{ scale: 0.88 }],
      opacity: 0,
      easing: theme.easing.curveLinearToEaseOut,
    },
  }).duration(feedbackDuration)

  const styles = StyleSheet.create({
    wrapper: {
      height,
      position: 'relative',
    },
    camera: {
      flex: 1,
    },
    frame: {
      position: 'absolute',
      top: frameBounds.top,
      right: frameBounds.right,
      bottom: frameBounds.bottom,
      left: frameBounds.left,
    },
    frameCorner: {
      position: 'absolute',
    },
    scrim: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: colorWithOpacity('#000000', 0.5),
    },
    feedback: {
      position: 'absolute',
      zIndex: 1,
      top: frameBounds.top,
      right: frameBounds.right,
      bottom: frameBounds.bottom,
      left: frameBounds.left,
      justifyContent: 'center',
      alignItems: 'center',
    },
    feedbackContent: {
      backgroundColor: theme.colors.background,
      borderRadius: theme.borderRadius.s + 2,
      padding: theme.spacing * 0.7,
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: theme.spacing,
    },
    feedbackHeading: {
      fontFamily: theme.fonts.body[600],
      fontSize: 16,
    },
    feedbackSubheading: {
      opacity: 0.85,
      fontFamily: theme.fonts.body[500],
      fontSize: 15,
      marginTop: 2,
    },
    revealer: {
      position: 'absolute',
      zIndex: 2,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: '#000000',
    },
    noCameraAccess: {
      height,
      marginTop: insets.top,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  if (!permission || permission.status === PermissionStatus.UNDETERMINED) {
    return <View />
  } else if (!permission.granted) {
    return (
      <View style={styles.noCameraAccess}>
        <Button
          text='Allow Camera Access'
          type='primary'
          onPress={() => Linking.openSettings()}
        />
      </View>
    )
  }
  return (
    <View style={styles.wrapper}>
      <CameraView
        barcodeScannerSettings={{ barcodeTypes }}
        enableTorch={torchEnabled}
        style={styles.camera}
        onCameraReady={() => setTimeout(() => setCameraLoaded(true), 150)}
        onBarcodeScanned={async (result) => {
          // FIXME: expo camera barcode scanner not working on web: https://github.com/expo/expo/issues/29827
          if (processingRef.current) return
          if (timeoutRef.current) clearTimeout(timeoutRef.current)

          if (result.data !== lastScanRef.current) {
            processingRef.current = true
            const feedback = await onBarcodeScanned(result.data)
            setFeedback(feedback)
            if (feedback) {
              cancelAnimation(frameAnimation)
              if (Platform.OS !== 'web') Haptics.selectionAsync()
            }
            processingRef.current = false
          }

          lastScanRef.current = result.data

          timeoutRef.current = setTimeout(() => {
            setFeedback(undefined)
            startFrameAnimation()
            lastScanRef.current = undefined
          }, 400)
        }}
      />
      <Animated.View style={[styles.frame, frameAnimationStyles]}>
        <FrameCorner style={{ ...styles.frameCorner, top: 0, left: 0, transform: [{ rotate: '-90deg' }] }} />
        <FrameCorner style={{ ...styles.frameCorner, top: 0, right: 0 }} />
        <FrameCorner style={{ ...styles.frameCorner, bottom: 0, right: 0, transform: [{ rotate: '90deg' }] }} />
        <FrameCorner style={{ ...styles.frameCorner, bottom: 0, left: 0, transform: [{ rotate: '180deg' }] }} />
      </Animated.View>

      {feedback && (
        <Animated.View
          entering={FadeIn.duration(feedbackDuration).easing(theme.easing.curveLinearToEaseOut)}
          exiting={FadeOut.duration(feedbackDuration).easing(theme.easing.curveLinearToEaseOut)}
          style={styles.scrim}
        />
      )}

      {feedback && (
        <Animated.View
          entering={feedbackEnterKeyframe}
          exiting={feedbackExitKeyframe}
          style={styles.feedback}
        >
          <View style={styles.feedbackContent}>
            {feedback.image}
            <View>
              <Text style={styles.feedbackHeading}>{feedback.heading}</Text>
              <Text style={styles.feedbackSubheading}>{feedback.subheading}</Text>
            </View>
          </View>
        </Animated.View>
      )}

      {!cameraLoaded && (
        <Animated.View
          exiting={FadeOut.duration(300).easing(theme.easing.curveLinearToEaseOut)}
          style={styles.revealer}
        />
      )}
    </View>
  )
}

function FrameCorner(props: SvgProps) {
  return (
    <Svg {...props} height='33' width='33' viewBox='0 0 33 33'>
      <Path
        d='M15,0.75 C24.4186516,0.75 32.0742423,8.29856064 32.2470168,17.6759264 L32.25,18 L32.25,30 C32.25,31.2426407 31.2426407,32.25 30,32.25 C28.809136,32.25 27.8343551,31.3248384 27.7551908,30.1540488 L27.75,30 L27.75,18 C27.75,11.0619228 22.2082893,5.41818155 15.3097813,5.25368961 L15,5.25 L3,5.25 C1.75735931,5.25 0.75,4.24264069 0.75,3 C0.75,1.80913601 1.67516159,0.834355084 2.84595119,0.755190813 L3,0.75 L15,0.75 Z'
        fill='#FFFFFF'
      />
    </Svg>
  )
}

type BarcodeScannerBottomPanelProps = {
  height: number
  scannedItem?: {
    heading: string
    subheading: string
    image: ReactNode
    tapContinueText: string
  }
  nothingScanned: {
    text: string
    icon: IconProp
  },
  absolutelyPositioned?: boolean
  onContinue: () => Promise<void>
}

export function BarcodeScannerBottomPanel({
  height,
  scannedItem,
  nothingScanned,
  absolutelyPositioned = true,
  onContinue,
}: BarcodeScannerBottomPanelProps) {
  const insets = useSafeAreaInsets()
  const theme = useTheme()

  const [loading, setLoading] = useState(false)
  const [scannedItemPressed, setScannedItemPressed] = useState(false)

  async function handleContinue() {
    setLoading(true)
    await onContinue()
    setLoading(false)
  }

  const styles = StyleSheet.create({
    wrapper: {
      position: absolutelyPositioned ? 'absolute' : undefined,
      zIndex: 3,
      bottom: 0,
      width: '100%',
      height,
      backgroundColor: theme.colors.background,
      borderTopLeftRadius: theme.borderRadius.l,
      borderTopRightRadius: theme.borderRadius.l,
    },
    content: {
      flex: 1,
      paddingHorizontal: theme.spacing,
    },
    scannedItem: {
      flex: 1,
      backgroundColor: theme.colors.surface,
      borderWidth: 2,
      borderColor: theme.colors.success,
      borderRadius: theme.borderRadius.m,
      justifyContent: 'center',
      alignItems: 'center',
    },
    scannedItemContent: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: theme.spacing,
      opacity: scannedItemPressed ? 0.5 : 1,
    },
    scannedItemHeading: {
      fontFamily: theme.fonts.body[600],
      fontSize: 17.5,
    },
    scannedItemSubheading: {
      opacity: 0.85,
      fontFamily: theme.fonts.body[500],
      fontSize: 16,
      marginTop: 5,
    },
    scannedItemTapContinueText: {
      color: theme.colors.success,
      fontFamily: theme.fonts.body[500],
      fontSize: 16.5,
      opacity: scannedItemPressed ? 0.5 : 1,
    },
    nothingScanned: {
      flex: 1,
      borderWidth: 2,
      borderStyle: 'dashed',
      borderRadius: theme.borderRadius.m,
      borderColor: colorWithOpacity(theme.colors.foreground, 0.1),
      justifyContent: 'center',
      alignItems: 'center',
    },
    nothingScannedIcon: {
      paddingHorizontal: 15,
      paddingVertical: 13,
      borderRadius: theme.borderRadius.m,
      backgroundColor: colorWithOpacity(theme.colors.foreground, 0.08),
      justifyContent: 'center',
      alignItems: 'center',
    },
    nothingScannedText: {
      marginTop: theme.spacing * 1.3,
      color: colorWithOpacity(theme.colors.foreground, 0.5),
      fontFamily: theme.fonts.body[500],
      textAlign: 'center',
    },
    button: {
      paddingHorizontal: theme.spacing,
      paddingBottom: insets.bottom + theme.spacing,
    },
  })

  return (
    <View style={styles.wrapper}>
      <Spacing height={1} />
      <View style={styles.content}>
        {scannedItem ? (
          <Pressable
            style={styles.scannedItem}
            onPress={handleContinue}
            onPressIn={() => setScannedItemPressed(true)}
            onPressOut={() => setScannedItemPressed(false)}
          >
            <View style={styles.scannedItemContent}>
              {scannedItem.image}
              <View>
                <Text style={styles.scannedItemHeading}>{scannedItem.heading}</Text>
                <Text style={styles.scannedItemSubheading}>{scannedItem.subheading}</Text>
              </View>
            </View>
            <Spacing height={1.5} />
            <Text style={styles.scannedItemTapContinueText}>{scannedItem.tapContinueText}</Text>
          </Pressable>
        ) : (
          <View style={styles.nothingScanned}>
            <View style={styles.nothingScannedIcon}>
              <FontAwesomeIcon
                icon={nothingScanned.icon}
                size={44}
                color={colorWithOpacity(theme.colors.foreground, 0.22)}
              />
            </View>
            <Text type='body' style={styles.nothingScannedText}>{nothingScanned.text}</Text>
          </View>
        )}
      </View>
      <Spacing height={1.5} />
      <Button
        text='Continue'
        type='primary'
        loading={loading}
        disabled={!scannedItem}
        style={styles.button}
        onPress={handleContinue}
      />
    </View>
  )
}
