/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { router } from 'expo-router'
import { StyleSheet, useWindowDimensions, View } from 'react-native'
import { ActivityIndicator, ButtonGroup, colorWithOpacity, Divider, Panel, ProgressCircle, Table, Text, useTheme, Wrapper } from 'ui'
import { NavButtons } from '../../components/web/NavButtons'
import { BatchStatusBadge } from '../batches/BatchStatusBadge'
import { useStore } from '@/providers/store'
import { useStaffDashboard } from '@/queries/dashboard.queries'
import { filterStaffDashboardAccidents, StaffDashboard } from '@/types/dashboard'
import { StaffDashboardFilter } from '@/types/filter'
import { colorFromIRR, colorFromROI, format } from '@/utils'

export function StaffDashboardScreen() {
  const theme = useTheme()
  const weekOffset = useStore.useStaffDashboardWeekOffset()

  const dashboard = useStaffDashboard(weekOffset)

  const styles = StyleSheet.create({
    loading: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 2.5,
      marginVertical: theme.spacing * 1.5
    },
  })

  return (
    dashboard.isPending ? (
      <View style={styles.loading}><ActivityIndicator /></View>
    ) : dashboard.data ? (
      <Wrapper maxWidth={1400} style={styles.wrapper}>
        <MyProgress
          dashboard={dashboard.data}
          loading={dashboard.isFetching && dashboard.isPlaceholderData}
        />
        <OpenBatches dashboard={dashboard.data} />
        <ChecksReceived dashboard={dashboard.data} />
        <SmartStatusing dashboard={dashboard.data} />
      </Wrapper>
    ) : null
  )
}

function MyProgress({ dashboard, loading }: { dashboard: StaffDashboard, loading: boolean }) {
  const theme = useTheme()
  const weekOffset = useStore.useStaffDashboardWeekOffset()
  const setWeekOffset = useStore.useSetStaffDashboardWeekOffset()

  const timeframe = weekOffset === 0 ? 'This Week' : weekOffset === 1 ? 'Last Week' : `- Week of ${format.date(dashboard.progress.from)}`

  const scoreColor = dashboard.progress.score >= 0.85 ? theme.colors.success
    : dashboard.progress.score >= 0.75 ? theme.colors.warning : theme.colors.danger

  const styles = {
    wrapper: css({
      alignSelf: 'flex-start',
    }),
    header: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: theme.spacing,
      marginBottom: theme.spacing,
    }),
    buttons: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing,
    }),
    metrics: css({
      opacity: loading ? 0.5 : 1,
      minHeight: 130,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      columnGap: theme.spacing * 3,
      rowGap: theme.spacing * 1.2,
      padding: theme.spacing * 1.2,
    }),
    metric: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 0.2,
      '> label': {
        fontFamily: theme.fonts.body[400],
        fontSize: 15,
        color: colorWithOpacity(theme.colors.foreground, 0.7),
      },
      '> .value': {
        fontFamily: theme.fonts.body[600],
        fontSize: 24,
        color: theme.colors.foreground,
      },
      '> .goal': {
        fontFamily: theme.fonts.body[500],
        fontSize: 15,
        color: colorWithOpacity(theme.colors.foreground, 0.7),
      },
    }),
    score: css({
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: theme.spacing,
      '> .progress': {
        position: 'relative',
        display: 'flex',
        '> .score': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: theme.fonts.body[700],
          fontSize: 24,
          color: scoreColor,
        },
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.header}>
        <Text type='heading'>My Progress {timeframe}</Text>
        <div css={styles.buttons}>
          {loading && <ActivityIndicator />}
          <NavButtons
            prev={{
              onClick: () => setWeekOffset(weekOffset + 1),
            }}
            next={{
              disabled: weekOffset === 0,
              onClick: () => setWeekOffset(weekOffset - 1),
            }}
          />
        </div>
      </div>
      <Panel>
        <div css={styles.metrics}>
          <div css={styles.metric}>
            <label># of Statuses</label>
            <div className='value'>{dashboard.progress.statuses.value}</div>
            <div className='goal'>Goal: {dashboard.progress.statuses.goal}</div>
          </div>
          <div css={styles.metric}>
            <label>Total Statused</label>
            <div className='value'>{Math.round(dashboard.progress.totalStatused.value * 100)}%</div>
            <div className='goal'>Goal: {dashboard.progress.totalStatused.goal * 100}%</div>
          </div>
          <div css={styles.metric}>
            <label>Return</label>
            <div className='value'>{Math.round(dashboard.progress.return.value * 100) / 100}x</div>
            <div className='goal'>Goal: {dashboard.progress.return.goal}x</div>
          </div>
          <div css={styles.metric}>
            <label>Calls Made</label>
            <div className='value'>{dashboard.progress.callsMade.value}</div>
            <div className='goal'>Goal: {dashboard.progress.callsMade.goal}</div>
          </div>
          <div css={styles.metric}>
            <label>Emails Sent</label>
            <div className='value'>{dashboard.progress.emailsSent.value}</div>
            <div className='goal'>Goal: {dashboard.progress.emailsSent.goal}</div>
          </div>
          <div css={[styles.metric, styles.score]}>
            <div className='progress'>
              <ProgressCircle
                value={dashboard.progress.score}
                size={80}
                strokeWidth={11}
                pathColor={scoreColor}
                trailColor={colorWithOpacity(scoreColor, 0.12)}
              />
              <div className='score'>{Math.round(dashboard.progress.score * 100)}</div>
            </div>
            <label>Performance<br />Score</label>
          </div>
        </div>
      </Panel>
    </div>
  )
}

function OpenBatches({ dashboard }: { dashboard: StaffDashboard }) {
  const theme = useTheme()

  const styles = {
    heading: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing * 0.5,
      marginBottom: theme.spacing,
    }),
    info: css({
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
    }),
  }

  return (
    <div>
      <div css={styles.heading}>
        <Text type='heading'>Open Funding Requests</Text>
        <div css={styles.info}>({dashboard.batches.length})</div>
      </div>
      <Panel>
        <Table
          columns={[
            {
              data: 'provider',
              title: 'Provider',
              width: 420,
            },
            {
              data: 'buy',
              title: 'Buy',
              width: 150,
            },
            {
              data: 'number',
              title: 'Batch',
              width: 150,
            },
            {
              data: 'status',
              title: 'Status',
              width: 220,
              renderer: (row) => {
                const batch = dashboard.batches[row]
                return <BatchStatusBadge status={batch.status} compact={true} />
              },
            },
            {
              data: 'lastStatus',
              title: 'Last Status',
              width: 170,
            },
          ]}
          data={dashboard.batches.map((batch) => ({
            id: batch.id,
            provider: batch.provider.name,
            buy: batch.buy,
            number: batch.number,
            status: batch.status,
            lastStatus: batch.lastStatus ? format.date(batch.lastStatus) : '',
          }))}
          noDataMessage='No funding requests found'
          minHeight={130}
          fixedLayout={true}
          onRowClick={(row) => router.navigate(`/funding-requests/${row.id}`)}
        />
      </Panel>
    </div>
  )
}

function ChecksReceived({ dashboard }: { dashboard: StaffDashboard }) {
  const theme = useTheme()

  const styles = {
    heading: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing * 0.5,
      marginBottom: theme.spacing,
    }),
    info: css({
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
    }),
  }

  return (
    <div>
      <div css={styles.heading}>
        <Text type='heading'>Checks Received</Text>
        <div css={styles.info}>({dashboard.checksReceived.length})</div>
      </div>
      <Panel>
        <Table
          columns={[
            {
              data: 'provider',
              title: 'Provider',
              width: 420,
            },
            {
              data: 'name',
              title: 'Name',
              width: 300,
            },
            {
              data: 'amount',
              title: 'Amount',
              width: 110,
              alignRight: true,
            },
            {
              data: 'ROI',
              title: 'ROI',
              width: 110,
              alignRight: true,
              renderer: (row) => {
                const ROI = dashboard.checksReceived[row].ROI
                return (
                  <span style={{ color: colorFromROI(ROI, theme), fontFamily: theme.fonts.body[500] }}>{ROI}x</span>
                )
              }
            },
            {
              data: 'IRR',
              title: 'IRR',
              width: 110,
              alignRight: true,
              renderer: (row) => {
                const IRR = dashboard.checksReceived[row].IRR
                return (
                  <span style={{ color: colorFromIRR(IRR, theme), fontFamily: theme.fonts.body[500] }}>{IRR}%</span>
                )
              }
            },
          ]}
          data={dashboard.checksReceived.map((check) => ({
            accidentId: check.accidentId,
            provider: check.provider,
            name: check.name,
            amount: format.currency(check.amount * -1),
            ROI: check.ROI,
            IRR: check.IRR,
          }))}
          noDataMessage='No checks received'
          minHeight={130}
          fixedLayout={true}
          onRowClick={(row) => router.navigate(`/dashboard/checks-received/${row.accidentId}`)}
        />
      </Panel>
    </div>
  )
}

function SmartStatusing({ dashboard }: { dashboard: StaffDashboard }) {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const filter = useStore.useStaffDashboardFilter()
  const setFilter = useStore.useSetStaffDashboardFilter()

  const stats: ({ filter: StaffDashboardFilter, label: string } | 'divider')[] = [
    {
      filter: 'settledCheckOnTheWay',
      label: 'Settled - Check on the Way',
    },
    {
      filter: 'settledReductions',
      label: 'Settled - Reductions',
    },
    {
      filter: 'settledUnpaid',
      label: 'Settled - Unpaid',
    },
    {
      filter: 'settledPartiallyPaid',
      label: 'Settled - Partially Paid',
    },
    {
      filter: 'pendingReplacement',
      label: 'Pending Replacement',
    },
    {
      filter: 'unknown',
      label: 'Unknown',
    },
    {
      filter: 'goodStanding',
      label: 'Good Standing',
    },
    'divider',
    {
      filter: 'all',
      label: 'Total Cases',
    },
  ]

  const styles = {
    heading: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing * 0.5,
      flexWrap: 'wrap',
      marginBottom: theme.spacing,
    }),
    stats: css({
      minHeight: 130,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: theme.spacing,
      rowGap: theme.spacing * 1.2,
      padding: theme.spacing * 1.2,
    }),
    stat: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 0.2,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.7,
      },
      '&.active': {
        opacity: 1,
        '& > div, & > label': {
          color: theme.colors.accent,
        },
      },
      '> div': {
        fontFamily: theme.fonts.body[600],
        fontSize: 24,
        color: theme.colors.foreground,
      },
      '> label': {
        fontFamily: theme.fonts.body[400],
        fontSize: 15,
        color: colorWithOpacity(theme.colors.foreground, 0.7),
        cursor: 'inherit',
      },
    }),
  }

  return <>
    <div>
      <div css={styles.heading}>
        <Text type='heading'>Open Case Overview</Text>
      </div>
      <Panel>
        <div css={styles.stats}>
          {stats.map((stat, index) => (
            stat === 'divider' ? (
              <Divider key={index} vertical={true} style={{ width: 1, height: 50 }} />
            ) : (
              <div
                key={index}
                css={styles.stat}
                className={filter === stat.filter ? 'active' : ''}
                onClick={() => setFilter(stat.filter)}
              >
                <div>{filterStaffDashboardAccidents(dashboard.accidents, stat.filter).length}</div>
                <label>{stat.label}</label>
              </div>
            )
          ))}
        </div>
      </Panel>
    </div>

    <div>
      <div css={styles.heading}>
        <Text type='heading'>Smart Statusing</Text>
        <ButtonGroup
          buttons={[
            {
              text: `High Priority Cases (${filterStaffDashboardAccidents(dashboard.accidents, 'highPriority').length})`,
              active: filter === 'highPriority',
              onPress: () => setFilter('highPriority'),
            },
            {
              text: `No Status in 3 Months (${filterStaffDashboardAccidents(dashboard.accidents, 'noStatusIn3Months').length})`,
              active: filter === 'noStatusIn3Months',
              onPress: () => setFilter('noStatusIn3Months'),
            },
            {
              text: `Red Flag Cases (${filterStaffDashboardAccidents(dashboard.accidents, 'redFlag').length})`,
              active: filter === 'redFlag',
              onPress: () => setFilter('redFlag'),
            },
          ]}
          maxWidth={width < theme.breakpoints.xs ? (width - theme.spacing * 2) : undefined}
          compact={true}
        />
      </div>
      <Panel>
        <Table
          columns={[
            {
              data: 'provider',
              title: 'Provider',
              width: 230,
            },
            {
              data: 'name',
              title: 'Name',
              width: 220,
            },
            {
              data: 'status',
              title: 'Status',
              width: 230,
              style: { whiteSpace: 'nowrap' },
            },
            {
              data: 'lastContact',
              title: 'Last Contact',
              width: 140,
            },
            {
              data: 'nextContact',
              title: 'Next Contact',
              width: 140,
            },
            {
              data: 'lawFirm',
              title: 'Law Firm',
              width: 250,
            },
            {
              data: 'target',
              title: 'Target',
              width: 122,
              alignRight: true,
            },
          ]}
          data={filterStaffDashboardAccidents(dashboard.accidents, filter).map((accident) => ({
            id: accident.id,
            provider: accident.accounts.map((a) => a.provider.name).join(', '),
            name: accident.patient.fullName,
            status: accident.status,
            lastContact: accident.lastContact ? format.date(accident.lastContact) : '',
            nextContact: accident.nextContact ? format.date(accident.nextContact) : '',
            lawFirm: accident.lawFirm?.name ?? '',
            target: accident.target != null ? format.currency(accident.target) : '',
          }))}
          noDataMessage='No cases found'
          fixedLayout={true}
          stickyHeader={true}
          onRowClick={(row) => router.navigate(`/dashboard/cases/${row.id}`)}
        />
      </Panel>
    </div>
  </>
}
