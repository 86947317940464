/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useQuery } from '@tanstack/react-query'
import bytes from 'bytes'
import dayjs from 'dayjs'
import { Link, router } from 'expo-router'
import isEqual from 'lodash/isEqual'
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { Toast } from 'react-native-toast-universal'
import { ActivityIndicator, Autocomplete, Button, ButtonGroup, Checkbox, ColorStatus, colorWithBrightness, colorWithOpacity, EditableValue, EditableValueTooltip, Feedback, Menu, MultiSelect, Panel, Popover, Select, Spacing, Table, Text, Tooltip, useTheme } from 'ui'
import { AccidentSearch, AccidentSearchItem } from '@/components/web/AccidentSearch'
import { Badge } from '@/components/web/Badge'
import { NavButtons } from '@/components/web/NavButtons'
import { TextArea, TextInput } from '@/components/web/TextInput'
import { Constants } from '@/constants'
import { useStore } from '@/providers/store'
import { keys as accidentKeys, accidentQuery, useCreateEvent, useDeleteDocument, useDeleteEvent, useLogEvent, useMergeAccidents, useReplacementAccidentsSummary, useSplitAccident, useSplitAccidentsSummary, useSyncAccountWithQuickbooks, useUpdateAccident, useUpdateAccount, useUpdateDocument, useUpdateEvent, useUpdatePatient, useUpdateTransaction, useUploadDocuments } from '@/queries/accident.queries'
import { fieldErrors } from '@/queries/client'
import { useConfig } from '@/queries/config.queries'
import { useLawFirmContacts, useLawFirmSearch } from '@/queries/law-firm.queries'
import { useUser, useUsers } from '@/queries/user.queries'
import { ReturnsCalculator } from '@/screens/accidents/ReturnsCalculator'
import { Accident, AccidentPreview, AccidentStatusSchema, Account, Cashflow, Document, DocumentTag, DocumentTagSchema, DocumentType, DocumentTypeSchema, Event, EventType, EventTypeSchema, RequiredDocumentTag, RequiredDocumentTagSchema, Transaction } from '@/types/accident'
import { Batch } from '@/types/batch'
import { ProcedureSchema } from '@/types/procedure'
import { TagName, TagNameSchema } from '@/types/tag'
import { statusers, User } from '@/types/user'
import { calculateIRR, calculateROI, colorFromROIAndIRR, format } from '@/utils'

type Totals = {
  target: number
  invoices: number
  collected: number
  cost: number
  cashflow: Cashflow
}

type CollapsibleSection = 'Timeline' | 'Transactions' | 'Documents'

export type AccidentList = {
  items: number[]
  onNav: (accidentId: number) => void
}

type OnAccidentUpdate = ((accident: Accident) => void) | undefined

type Props = {
  accident: Accident
  list?: AccidentList
  batch?: Batch
  onUpdate?: (accident: Accident) => void
}

export function AccidentViewer({ accident, list, batch, onUpdate }: Props) {
  const theme = useTheme()

  const user = useUser()

  const [account, setAccount] = useState(() => {
    return accident.accounts.length === 1 || user.data?.role === 'provider' ? accident.accounts[0] : undefined
  })
  const [prevAccident, setPrevAccident] = useState(accident)
  if (!isEqual(accident, prevAccident)) {
    if (accident.accounts.length === 1 || user.data?.role === 'provider') {
      setAccount(accident.accounts[0])
    } else if (account) {
      const index = accident.accounts.findIndex((a) => a.id === account.id)
      setAccount(accident.accounts[index])
    }
    setPrevAccident(accident)
  }
  const [addNotes, setAddNotes] = useState(false)
  const [pendingTransactions, setPendingTransactions] = useState(!!batch)
  const [replacementTransactions, setReplacementTransactions] = useState(true)

  let transactions = accident.transactions.filter((t) => {
    if (pendingTransactions) {
      const approved = t.charge?.status !== 'On Hold' && t.charge?.status !== 'Rejected'
      if (!replacementTransactions) {
        return approved && t.replacingAccounts.length === 0
      } else {
        return approved
      }
    }
    if (!replacementTransactions) {
      return t.dop && t.replacingAccounts.length === 0
    }
    return t.dop
  })
  transactions = filteredTransactions(transactions, account, batch)

  const cashflow: Cashflow = []
  for (const t of transactions) {
    if (t.type === 'Payment' && t.amount) {
      cashflow.push({ date: t.dop ?? dayjs(), amount: t.amount })
    }
    if (t.cost) {
      cashflow.push({ date: t.dop ?? dayjs(), amount: t.cost })
    }
  }

  const totals: Totals = {
    target: transactions ? transactions.reduce((sum, t) => sum + (t.amount ?? 0), 0) : 0,
    invoices: transactions ? transactions.reduce((sum, t) => sum + (t.invoice ?? 0), 0) : 0,
    collected: transactions ? transactions.reduce((sum, t) => sum + (t.type === 'Payment' ? -(t.amount ?? 0) : 0), 0) : 0,
    cost: transactions ? transactions.reduce((sum, t) => sum + (t.cost ?? 0), 0) : 0,
    cashflow,
  }

  const styles = {
    panelContent: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 2,
      padding: theme.spacing,
    }),
  }

  return <>
    <Header
      accident={accident}
      account={account}
      setAccount={setAccount}
      totals={totals}
      list={list}
      batch={batch}
      pendingTransactions={pendingTransactions}
      setPendingTransactions={setPendingTransactions}
      replacementTransactions={replacementTransactions}
      setReplacementTransactions={setReplacementTransactions}
      onAccidentUpdate={onUpdate}
    />
    <Panel>
      <div css={styles.panelContent}>
        <Overview
          accident={accident}
          account={account}
          transactions={transactions}
          totals={totals}
          onAccidentUpdate={onUpdate}
        />
        <div>
          <Tags accident={accident} addNotes={addNotes} setAddNotes={setAddNotes} />
          {(accident.notes || addNotes) && (
            <Notes accident={accident} batch={batch} addNotes={addNotes} setAddNotes={setAddNotes} />
          )}
        </div>
        <Timeline
          accident={accident}
          account={account}
        />
        <Transactions
          accident={accident}
          account={account}
          batch={batch}
          onAccidentUpdate={onUpdate}
        />
        <Documents
          accident={accident}
          account={account}
          batch={batch}
          onAccidentUpdate={onUpdate}
        />
      </div>
    </Panel>
    {list && <Prefetcher accident={accident} items={list.items} />}
  </>
}

function Header({
  accident,
  account,
  setAccount,
  totals,
  list,
  batch,
  pendingTransactions,
  setPendingTransactions,
  replacementTransactions,
  setReplacementTransactions,
  onAccidentUpdate,
}: {
  accident: Accident
  account: Account | undefined
  setAccount: Dispatch<SetStateAction<Account | undefined>>
  totals: Totals
  list: AccidentList | undefined
  batch: Batch | undefined
  pendingTransactions: boolean
  setPendingTransactions: Dispatch<SetStateAction<boolean>>
  replacementTransactions: boolean
  setReplacementTransactions: Dispatch<SetStateAction<boolean>>
  onAccidentUpdate: OnAccidentUpdate
}) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const user = useUser()
  const updateAccident = useUpdateAccident()
  const updatePatient = useUpdatePatient()
  const logEvent = useLogEvent()

  const [patient, setPatient] = useState({
    firstName: accident.patient.firstName,
    lastName: accident.patient.lastName,
    dob: accident.patient.dob ? format.date(accident.patient.dob, { iso: true }) : '',
  })
  const [doa, setDoa] = useState(accident.doa ? format.date(accident.doa, { iso: true }) : '')
  const [dirty, setDirty] = useState({
    patient: {
      firstName: false,
      lastName: false,
      dob: false,
    },
    doa: false,
  })
  const [prevAccident, setPrevAccident] = useState(accident)
  if (!isEqual(accident, prevAccident)) {
    setPatient({
      firstName: accident.patient.firstName,
      lastName: accident.patient.lastName,
      dob: accident.patient.dob ? format.date(accident.patient.dob, { iso: true }) : '',
    })
    setDoa(accident.doa ? format.date(accident.doa, { iso: true }) : '')
    setDirty({
      patient: {
        firstName: false,
        lastName: false,
        dob: false,
      },
      doa: false,
    })
    setPrevAccident(accident)
  }

  const showPendingTransactionsCheckbox = batch && batch.status !== 'Approved' && batch.status !== 'Rejected'

  const styles = {
    wrapper: css({
      display: 'flex',
      flexDirection: 'column',
      columnGap: theme.spacing,
      rowGap: theme.spacing * 0.7,
      marginBottom: theme.spacing,
      [`@media (min-width: ${theme.breakpoints.m}px)`]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    }),
    info: css({
      display: 'flex',
      alignItems: 'center',
      '.divider': {
        display: 'inline-block',
        width: 1,
        height: 20,
        background: theme.colors.divider,
        margin: `0 ${theme.spacing * 0.8}px`,
      },
    }),
    patientInfo: css({
      display: 'flex',
      flexDirection: 'column',
      columnGap: theme.spacing,
      rowGap: theme.spacing * 0.4,
      [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    }),
    patientName: css({
      fontFamily: theme.fonts.body[600],
      fontSize: 22,
    }),
    patientMeta: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 16.5,
      opacity: 0.9,
    }),
    patientMetaWrapper: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing,
    }),
    patientHeaderAction: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: 6,
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      cursor: 'pointer',
    }),
    patientHeaderDivider: css({
      width: 1,
      height: 16,
      background: theme.colors.divider,
      margin: `0 ${theme.spacing}px`,
    }),
    actions: css({
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing * 0.5,
      flexWrap: 'wrap',
    }),
    resultsCalculatorTrigger: css({
      height: theme.button.compact.height,
      background: theme.colors.surface,
      borderRadius: theme.button.compact.borderRadius,
      border: `1px solid ${theme.input.border}`,
      boxShadow: theme.web.shadow.input,
      padding: '0 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      ':hover': {
        background: colorWithBrightness(theme.colors.surface, -5),
      },
    }),
    singleProvider: css({
      '> div > div > div > div': {
        opacity: 1,
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.info}>
        <EditableValue
          form={{
            fields: [
              <TextInput
                label='First Name'
                value={patient.firstName}
                compact={true}
                onChange={(e) => {
                  setPatient({ ...patient, firstName: e.target.value })
                  setDirty({ ...dirty, patient: { ...dirty.patient, firstName: true } })
                }}
              />,
              <TextInput
                label='Last Name'
                value={patient.lastName}
                compact={true}
                onChange={(e) => {
                  setPatient({ ...patient, lastName: e.target.value })
                  setDirty({ ...dirty, patient: { ...dirty.patient, lastName: true } })
                }}
              />,
              <TextInput
                type='date'
                label='Date of Birth'
                compact={true}
                value={patient.dob}
                onChange={(e) => {
                  setPatient({ ...patient, dob: e.target.value })
                  setDirty({ ...dirty, patient: { ...dirty.patient, dob: true } })
                }}
              />,
              <TextInput
                type='date'
                label='Date of Accident'
                compact={true}
                value={doa}
                onChange={(e) => {
                  setDoa(e.target.value)
                  setDirty({ ...dirty, doa: true })
                }}
              />,
            ],
            loading: updatePatient.isPending || updateAccident.isPending,
            onSubmit: async () => {
              if (Object.values(dirty.patient).every((v) => !v) && !dirty.doa) return

              if (user.data?.role === 'provider') {
                confirmAction({
                  onConfirm: () => {
                    handleSubmit()
                    for (const field of Object.keys(dirty.patient) as (keyof typeof dirty.patient)[]) {
                      if (!dirty.patient[field]) continue
                      const from = field === 'dob' ? (accident.patient.dob ? format.date(accident.patient.dob) : 'null') : accident.patient[field]
                      const to = field === 'dob' ? format.date(dayjs.utc(patient.dob)) : patient[field]
                      const action = `changed the patient's ${format.field(field)} from ${from} to ${to}`
                      logEvent.mutate({ accident, action })
                    }
                    if (dirty.doa) {
                      const from = accident.doa ? format.date(accident.doa) : 'null'
                      const to = format.date(dayjs.utc(doa))
                      const action = `changed the ${format.field('doa')} from ${from} to ${to}`
                      logEvent.mutate({ accident, action })
                    }
                  },
                })
              } else {
                await handleSubmit()
              }

              async function handleSubmit() {
                try {
                  if (dirty.patient.firstName || dirty.patient.lastName || dirty.patient.dob) {
                    const updatedAccident = await updatePatient.mutateAsync({ accident, patient })
                    Toast.success('Patient updated')
                    if (onAccidentUpdate) onAccidentUpdate(updatedAccident)
                  }
                  if (dirty.doa) {
                    const updatedAccident = await updateAccident.mutateAsync({ id: accident.id, doa })
                    Toast.success('DOA updated')
                    if (onAccidentUpdate) onAccidentUpdate(updatedAccident)
                  }
                } catch { }
              }
            },
          }}
          headerAction={<>
            {user.data?.role !== 'provider' && <>
              <div css={styles.patientHeaderDivider} />
              <div
                css={styles.patientHeaderAction}
                onClick={() => modal.open({
                  title: 'Assign Case',
                  content: <AssignAccident accident={accident} onAccidentUpdate={onAccidentUpdate} />,
                  disableOverflow: true,
                })}
              >
                <FontAwesomeIcon
                  icon={['fal', 'user-pen']}
                  size={16}
                  color={colorWithOpacity(theme.colors.foreground, 0.8)}
                />
                Assign Case
              </div>
              <div css={styles.patientHeaderDivider} />
              <div
                css={styles.patientHeaderAction}
                onClick={() => modal.open({
                  title: 'Merge Cases',
                  content: <MergeAccidents accident={accident} />,
                  disableOverflow: true,
                })}
              >
                <FontAwesomeIcon
                  icon={['fal', 'merge']}
                  size={16}
                  color={colorWithOpacity(theme.colors.foreground, 0.8)}
                />
                Merge Cases
              </div>
              <div css={styles.patientHeaderDivider} />
              <div
                css={styles.patientHeaderAction}
                onClick={() => modal.open({
                  title: 'Split Case',
                  content: <SplitAccident accident={accident} />,
                  disableOverflow: true,
                })}
              >
                <FontAwesomeIcon
                  icon={['fal', 'split']}
                  size={16}
                  color={colorWithOpacity(theme.colors.foreground, 0.8)}
                />
                Split Case
              </div>
            </>}
          </>}
          css={styles.patientInfo}
        >
          <div css={styles.patientName}>{accident.patient.fullName}</div>
          <div css={styles.patientMetaWrapper}>
            {accident.patient.dob && (
              <div css={styles.patientMeta}>
                {format.date(accident.patient.dob)}
                {' '}
                ({dayjs.utc().diff(accident.patient.dob, 'years')}y)
              </div>
            )}
            {accident.doa && (
              <div css={styles.patientMeta}>DOA: {format.date(accident.doa)}</div>
            )}
          </div>
        </EditableValue>

        {accident.replacementStatus && (() => {
          const badge = (
            <Badge
              color={Constants.replacementColor}
              background={colorWithOpacity(Constants.replacementColor, 0.12)}
              borderColor={colorWithOpacity(Constants.replacementColor, 0.4)}
            >
              {accident.replacementStatus === 'partial' ? 'Partial ' : ''}Replacement Case
            </Badge>
          )
          return <>
            <div className='divider' />
            {accident.replacementStatus === 'partial' ? (
              <Tooltip
                content={(
                  <Checkbox
                    text='Exclude Replacement Transactions'
                    value={!replacementTransactions}
                    compact={true}
                    onChange={(value) => setReplacementTransactions(!value)}
                  />
                )}
              >{badge}</Tooltip>
            ) : badge}
          </>
        })()}
      </div>

      <div css={styles.actions}>
        {user.data?.role === 'executive' && (
          <div>
            <Menu
              items={[
                {
                  value: 'Sync with QuickBooks',
                  webIcon: ['far', 'arrows-rotate'],
                },
              ]}
              onChange={(value) => {
                switch (value) {
                  case 'Sync with QuickBooks':
                    if (account) {
                      modal.open({
                        title: 'Sync with QuickBooks',
                        content: <SyncWithQuickbooks account={account} />,
                      })
                    } else {
                      Toast.error('Select a provider first')
                    }
                    break
                }
              }}
            >
              <Button
                text=''
                type='tertiary'
                compact={true}
                textLeft={(
                  <FontAwesomeIcon
                    icon={['far', 'ellipsis-vertical']}
                    size={18}
                    color={colorWithOpacity(theme.colors.foreground, 0.9)}
                    style={{ marginHorizontal: -1 }}
                  />
                )}
              />
            </Menu>
          </div>
        )}
        {showPendingTransactionsCheckbox && (
          <Checkbox
            text='Pending'
            value={pendingTransactions}
            compact={true}
            onChange={(value) => setPendingTransactions(value)}
          />
        )}
        {(user.data?.role !== 'provider' && totals.target > 0) && (
          <Popover
            side='left'
            sideOffset={theme.spacing * 0.5}
            align='start'
            autoFocus={false}
            content={(
              <ReturnsCalculator
                invoices={totals.invoices}
                collected={totals.collected}
                cost={totals.cost}
                cashflow={totals.cashflow}
              />
            )}
          >
            <div css={styles.resultsCalculatorTrigger}>
              <FontAwesomeIcon
                icon={['fal', 'calculator']}
                size={19.5}
                color={colorWithOpacity(theme.colors.foreground, 0.7)}
              />
            </div>
          </Popover>
        )}
        {user.data?.role !== 'provider' && (
          <Button
            text=''
            type='tertiary'
            textLeft={(
              <svg width='21' height='21' viewBox='0 0 30 30' style={{ margin: '0 -2px' }}>
                <circle id='Oval' fill='#2CA01C' cx='15' cy='15' r='15' />
                <path d='M4.16666667,15 C4.16666667,18.2333333 6.76666667,20.8333333 10,20.8333333 L10.8333333,20.8333333 L10.8333333,18.6666667 L10,18.6666667 C7.96666667,18.6666667 6.33333333,17.0333333 6.33333333,15 C6.33333333,12.9666667 7.96666667,11.3333333 10,11.3333333 L12,11.3333333 L12,22.6666667 C12,23.8666667 12.9666667,24.8333333 14.1666667,24.8333333 L14.1666667,24.8333333 L14.1666667,9.16666667 L10,9.16666667 C6.76666667,9.16666667 4.16666667,11.7666667 4.16666667,15 Z M20,9.16666667 L19.1666667,9.16666667 L19.1666667,11.3333333 L20,11.3333333 C22.0333333,11.3333333 23.6666667,12.9666667 23.6666667,15 C23.6666667,17.0333333 22.0333333,18.6666667 20,18.6666667 L18,18.6666667 L18,7.33333333 C18,6.13333333 17.0333333,5.16666667 15.8333333,5.16666667 L15.8333333,20.8333333 L20,20.8333333 C23.2333333,20.8333333 25.8333333,18.2333333 25.8333333,15 C25.8333333,11.7666667 23.2333333,9.16666667 20,9.16666667 Z' fill='#FFFFFF' />
              </svg>
            )}
            compact={true}
            disabled={!account?.qbCustomerId}
            onPress={() => {
              window.open(`https://qbo.intuit.com/app/customerdetail?nameId=${account?.qbCustomerId}`, '_blank')
            }}
          />
        )}
        {accident.accounts.length === 1 && account ? (
          <div css={styles.singleProvider}>
            <Button
              text={account.provider.name}
              type='tertiary'
              compact={true}
              disabled={true}
            />
          </div>
        ) : user.data?.role === 'provider' && account ? (
          <Select
            value={account.provider.name}
            options={accident.accounts.map((a) => a.provider.name)}
            compact={true}
            style={{ maxWidth: showPendingTransactionsCheckbox ? 280 : undefined }}
            onChange={(value) => {
              const account = accident.accounts.find((a) => a.provider.name === value)
              if (!account) throw new Error('Account not found')
              setAccount(account)
            }}
          />
        ) : (
          <Select
            value={account ? account.provider.name : 'All Providers'}
            options={['All Providers', ...accident.accounts.map((a) => a.provider.name)]}
            compact={true}
            style={{ maxWidth: showPendingTransactionsCheckbox ? 280 : undefined }}
            onChange={(value) => {
              if (value === 'All Providers') {
                setAccount(undefined)
              } else {
                const account = accident.accounts.find((a) => a.provider.name === value)
                if (!account) throw new Error('Account not found')
                setAccount(account)
              }
            }}
          />
        )}
        {list && (() => {
          const currentIndex = list.items.findIndex((id) => id === accident.id)
          return (
            <NavButtons
              prev={{
                disabled: currentIndex === 0,
                onClick: () => list.onNav(list.items[currentIndex - 1]),
              }}
              next={{
                disabled: currentIndex === list.items.length - 1,
                onClick: () => list.onNav(list.items[currentIndex + 1]),
              }}
            />
          )
        })()}
      </div>
    </div>
  )
}

function AssignAccident({ accident, onAccidentUpdate }: { accident: Accident, onAccidentUpdate: OnAccidentUpdate }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const users = useUsers()
  const updateAccident = useUpdateAccident()

  const [staff, setStaff] = useState(accident.users.map((u) => u.id.toString()))

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
      maxWidth: 380,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <MultiSelect
        label='Staff'
        value={staff}
        options={statusers(users.data ?? []).map((u) => ({ value: u.id.toString(), label: u.fullName ?? '' }))}
        placeholder='Select staff'
        noOptions='Staff not found'
        loading={users.isPending}
        closeMenuOnSelect={true}
        onChange={setStaff}
      />
      <Spacing height={1.4} />
      <Button
        text='Assign Case'
        type='primary'
        loading={updateAccident.isPending}
        onPress={() => {
          updateAccident.mutate({ id: accident.id, users: staff.map((id) => Number(id)) }, {
            onSuccess: (updatedAccident) => {
              modal.close()
              Toast.success('Case assigned')
              if (onAccidentUpdate) onAccidentUpdate(updatedAccident)
            },
          })
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function MergeAccidents({ accident }: { accident: Accident }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const mergeAccidents = useMergeAccidents()

  const [intoAccident, setIntoAccident] = useState<AccidentPreview>()
  const [error, setError] = useState<{ from?: string, into?: string }>()

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    accidentPreview: css({
      background: theme.colors.pageBackground,
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <Text type='inputLabel'>From</Text>
      <AccidentSearchItem accident={accident} css={styles.accidentPreview} />
      <Spacing height={1} />
      <Text type='inputLabel'>Into</Text>
      <AccidentSearch
        context='input'
        placeholder='Search for a case...'
        onSelect={(value) => {
          setIntoAccident(value)
          if (value.id === accident.id) {
            setError({ ...error, into: 'You can\'t merge a case with itself' })
          } else {
            setError({ ...error, into: undefined })
          }
        }}
      />
      {error?.into && <Text type='inputError'>{error.into}</Text>}
      {intoAccident && <>
        <Spacing height={0.5} />
        <AccidentSearchItem accident={intoAccident} css={styles.accidentPreview} />
      </>}
      <Spacing height={1.4} />
      <Button
        text='Merge Cases'
        type='primary'
        loading={mergeAccidents.isPending}
        onPress={() => {
          mergeAccidents.mutate({ from: accident.id, into: intoAccident?.id ?? 0 }, {
            onSuccess: (accident) => {
              modal.close()
              router.replace(`/cases/${accident.id}`)
              Toast.success('Cases merged')
            },
            onError: (error) => setError(fieldErrors(error)),
          })
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function SplitAccident({ accident }: { accident: Accident }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const splitAccident = useSplitAccident()

  const [accountIds, setAccountIds] = useState<number[]>([])
  const [error, setError] = useState<{ accounts?: string }>()

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    prompt: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 15,
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      lineHeight: 1.4,
      maxWidth: 240,
      textAlign: 'center',
      margin: '0 auto',
    }),
    checkboxes: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 0.5,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <div css={styles.prompt}>Which provider(s) should be split off into its own case?</div>
      <Spacing height={1} />
      <div css={styles.checkboxes}>
        {accident.accounts.map((a) => (
          <Checkbox
            key={a.id}
            text={a.provider.name}
            value={accountIds.includes(a.id)}
            onChange={(value) => {
              let newAccountIds = [...accountIds]
              if (value) {
                newAccountIds.push(a.id)
              } else {
                newAccountIds = newAccountIds.filter((id) => id !== a.id)
              }
              setAccountIds(newAccountIds)
            }}
          />
        ))}
      </div>
      {error?.accounts && <Text type='inputError'>{error.accounts}</Text>}
      <Spacing height={1.4} />
      <Button
        text='Split Case'
        type='primary'
        loading={splitAccident.isPending}
        onPress={() => {
          splitAccident.mutate({ accidentId: accident.id, accountIds }, {
            onSuccess: (accident) => {
              modal.close()
              router.navigate(`/cases/${accident.id}`)
              Toast.success('Case split')
            },
            onError: (error) => setError(fieldErrors(error)),
          })
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function SyncWithQuickbooks({ account }: { account: Account }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const syncWithQuickbooks = useSyncAccountWithQuickbooks()

  let info = 'Click on the button below to start sync.'
  if (syncWithQuickbooks.isSuccess) {
    const exported = syncWithQuickbooks.data.exportedTransactions
    const imported = syncWithQuickbooks.data.importedTransactions
    const results = [
      `${exported} transaction${exported === 1 ? ' was' : 's were'} exported to QuickBooks.`,
      `${imported} transaction${imported === 1 ? ' was' : 's were'} imported from QuickBooks.`,
    ]
    info = results.join('\n')
  } else if (syncWithQuickbooks.isPending) {
    info = 'Sync in progress...'
  }

  const styles = {
    wrapper: css({
      minWidth: 460,
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <Feedback
        status={syncWithQuickbooks.isSuccess ? 'success' : syncWithQuickbooks.isError ? 'danger' : 'info'}
        heading={syncWithQuickbooks.isSuccess ? 'Sync Complete' : syncWithQuickbooks.isError ? 'Sync Failed' : undefined}
        body={info}
        compact={true}
        inset={true}
        maxWidth={360}
        style={{ minHeight: 250 }}
      />
      <Spacing height={1.4} />
      {syncWithQuickbooks.isSuccess ? (
        <Button
          text='Continue'
          type='primary'
          onPress={() => modal.close()}
        />
      ) : (
        <Button
          text='Sync with QuickBooks'
          type='primary'
          loading={syncWithQuickbooks.isPending}
          onPress={() => syncWithQuickbooks.mutate({ account })}
        />
      )}
      <Spacing height={1.4} />
    </div>
  )
}

function SectionHeader({ heading, collapsed, setCollapsed, children }: {
  heading: CollapsibleSection
  collapsed: boolean
  setCollapsed: Dispatch<SetStateAction<boolean>>
  children?: ReactNode
}) {
  const theme = useTheme()

  const styles = {
    wrapper: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: theme.spacing,
      rowGap: theme.spacing * 0.6,
      flexWrap: 'wrap',
      marginBottom: theme.spacing * 0.6,
    }),
    headingWrapper: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing * 0.5,
    }),
    collapsibleTrigger: css({
      width: 23,
      height: 23,
      background: colorWithBrightness(theme.colors.surface, -15),
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      ':hover': {
        background: colorWithBrightness(theme.colors.surface, -20),
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.headingWrapper}>
        <Text type='subheading'>{heading}</Text>
        <div
          css={styles.collapsibleTrigger}
          onClick={() => {
            setCollapsed(!collapsed)
            const collapsedSections = JSON.parse(localStorage.getItem('collapsedSections') ?? '{}')
            collapsedSections[heading] = !collapsed
            localStorage.setItem('collapsedSections', JSON.stringify(collapsedSections))
          }}
        >
          <FontAwesomeIcon
            icon={['fal', collapsed ? 'arrows-to-line' : 'arrows-from-line']}
            size={14}
            color={colorWithOpacity(theme.colors.foreground, 0.8)}
          />
        </div>
      </div>
      {children}
    </div>
  )
}

function Overview({ accident, account, transactions, totals, onAccidentUpdate }: {
  accident: Accident,
  account: Account | undefined,
  transactions: Transaction[]
  totals: Totals
  onAccidentUpdate: OnAccidentUpdate
}) {
  const theme = useTheme()

  const user = useUser()
  const updateAccident = useUpdateAccident()
  const lawFirmSearch = useLawFirmSearch()
  const updateAccount = useUpdateAccount()
  const logEvent = useLogEvent()

  const [lawFirm, setLawFirm] = useState(accident.lawFirm ? {
    value: accident.lawFirm.id.toString(),
    label: accident.lawFirm.name,
  } : undefined)
  const [lawFirmContact, setLawFirmContact] = useState(accident.lawFirmContact ? accident.lawFirmContact.id.toString() : undefined)
  const [insuranceCarrier, setInsuranceCarrier] = useState(accident.insuranceCarrier ?? '')
  const [policyLimit, setPolicyLimit] = useState({
    amount: format.currency(accident.policyLimitAmount, { cents: false, editable: true }),
    accidentPercentage: accident.policyLimitPercentage?.toString() ?? '',
    accountPercentage: account?.policyLimitPercentage?.toString() ?? '',
  })
  const [dirty, setDirty] = useState({
    lawFirm: false,
    lawFirmContact: false,
    insuranceCarrier: false,
    policyLimit: {
      amount: false,
      accidentPercentage: false,
      accountPercentage: false,
    },
  })
  const [prevAccident, setPrevAccident] = useState(accident)
  if (!isEqual(accident, prevAccident)) {
    setLawFirm(accident.lawFirm ? {
      value: accident.lawFirm.id.toString(),
      label: accident.lawFirm.name,
    } : undefined)
    setLawFirmContact(accident.lawFirmContact ? accident.lawFirmContact.id.toString() : undefined)
    setInsuranceCarrier(accident.insuranceCarrier ?? '')
    setPolicyLimit({
      amount: format.currency(accident.policyLimitAmount, { cents: false, editable: true }),
      accidentPercentage: accident.policyLimitPercentage?.toString() ?? '',
      accountPercentage: account?.policyLimitPercentage?.toString() ?? '',
    })
    setDirty({
      lawFirm: false,
      lawFirmContact: false,
      insuranceCarrier: false,
      policyLimit: {
        amount: false,
        accidentPercentage: false,
        accountPercentage: false,
      },
    })
    setPrevAccident(accident)
  }

  const lawFirmContacts = useLawFirmContacts(lawFirm ? [lawFirm.value] : [])

  let policyLimitPercentage = Constants.defaultPolicyLimitPercentage
  if (account?.policyLimitPercentage) {
    policyLimitPercentage = account.policyLimitPercentage
  } else if (accident.policyLimitPercentage) {
    policyLimitPercentage = accident.policyLimitPercentage
  }

  const policyLimitValue = accident.policyLimitAmount > 0 ? Math.round(totals.cost / accident.policyLimitAmount * 100) : 0
  const policyLimitOffset = accident.policyLimitAmount > 0 ? Math.min(totals.cost / (accident.policyLimitAmount * (policyLimitPercentage / 100)) * 100, 100) : 0
  const policyLimitGaugeHeight = 6

  const styles = {
    wrapper: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing * 1.5,
      [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'normal',
        flexWrap: 'wrap',
      },
    }),
    meta: css({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, auto)',
      alignContent: 'start',
      columnGap: theme.spacing * 1.5,
      rowGap: theme.spacing,
    }),
    lawFirm: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing,
    }),
    lawFirmHeaderDivider: css({
      width: 1,
      height: 16,
      background: theme.colors.divider,
      margin: `0 ${theme.spacing}px`,
    }),
    lawFirmHeaderAction: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: 6,
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      cursor: 'pointer',
    }),
    lawFirmHeaderActionDisabled: css({
      pointerEvents: 'none',
      opacity: 0.5,
    }),
    infoItem: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: 3,
      '> label': {
        fontFamily: theme.fonts.body[600],
        fontSize: 15,
      },
      '> span': {
        fontFamily: theme.fonts.body[400],
        fontSize: 15,
        opacity: 0.8,
        '&.contact': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 7,
          'a': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 2,
            ':hover svg path': {
              fill: theme.colors.accent,
            },
          },
        },
      },
    }),
    policyLimit: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing,
    }),
    policyLimitGauge: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing * 0.5,
      marginTop: 2,
      '> .gauge': {
        position: 'relative',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        '> .bg': {
          position: 'absolute',
          width: '100%',
          height: policyLimitGaugeHeight,
          background: theme.colors.panelBorder,
          borderRadius: policyLimitGaugeHeight / 2,
        },
        '> .bar': {
          position: 'absolute',
          width: policyLimitOffset + '%',
          height: policyLimitGaugeHeight,
          background: policyLimitValue > policyLimitPercentage ? theme.colors.danger : theme.colors.success,
          borderRadius: policyLimitGaugeHeight / 2,
        },
        ' > .bar-success': {
          position: 'absolute',
          width: policyLimitPercentage / policyLimitValue * 100 + '%',
          height: policyLimitGaugeHeight,
          background: theme.colors.success,
          borderTopLeftRadius: policyLimitGaugeHeight / 2,
          borderBottomLeftRadius: policyLimitGaugeHeight / 2,
        },
        '> .tick': {
          position: 'absolute',
          width: 1,
          height: policyLimitGaugeHeight,
          left: policyLimitPercentage / policyLimitValue * 100 + '%',
          background: '#FFFFFF',
        },
      },
      '> .label': {
        fontFamily: theme.fonts.body[400],
        fontSize: 14,
      },
    }),
    totals: css({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, auto)',
      gridTemplateRows: 'repeat(2, auto)',
      columnGap: theme.spacing * 1.5,
      rowGap: theme.spacing * 0.8,
    }),
    totalsItem: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: 3,
      '> span': {
        fontFamily: theme.fonts.body[600],
        fontSize: 20,
      },
      '> label': {
        fontFamily: theme.fonts.body[400],
        fontSize: 14,
        opacity: 0.7,
      },
    }),
    accountStatus: css({
      gridColumn: 'span 2',
      color: accident.open
        ? colorWithOpacity(theme.colors.foreground, 0.7)
        : theme.colors.success,
      fontFamily: theme.fonts.body[600],
      fontSize: 13.5,
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      textAlign: 'right',
      marginBottom: -theme.spacing,
    }),
    divider: css({
      width: '100%',
      height: 1,
      background: theme.colors.divider,
      [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        width: 1,
        height: 'auto',
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.meta}>
        <div css={styles.infoItem}>
          <label>Status</label>
          <span>{accident.status ? accident.status.split(' - ')[0] : '(status)'}</span>
        </div>
        <div css={styles.infoItem}>
          <label>Stage</label>
          <span>{accident.status ? accident.status.split(' - ')[1] : '(stage)'}</span>
        </div>
        <div css={styles.infoItem}>
          <label>Last Contact</label>
          <span>{accident.lastContact ? format.date(accident.lastContact) : '(last contact)'}</span>
        </div>
        <div css={styles.infoItem}>
          <label>Next Contact</label>
          <span>{accident.nextContact ? format.date(accident.nextContact) : '(next contact)'}</span>
        </div>
      </div>

      <div css={styles.divider} />

      <div css={styles.lawFirm}>
        <EditableValue
          css={styles.infoItem}
          form={{
            fields: [
              <div css={{ minWidth: 220 }}>
                <Autocomplete
                  label='Law Firm'
                  value={lawFirm}
                  loadOptions={async (input) => {
                    try {
                      const lawFirms = await lawFirmSearch.mutateAsync(input)
                      return lawFirms.map((l) => ({ value: l.id.toString(), label: l.name }))
                    } catch {
                      return []
                    }
                  }}
                  placeholder='Search for law firm...'
                  compact={true}
                  noOptions='Law firm not found'
                  onCreateOption={() => router.navigate('/law-firms')}
                  onChange={(value) => {
                    setLawFirm(value)
                    setLawFirmContact(undefined)
                    setDirty({ ...dirty, lawFirm: true })
                  }}
                />
              </div>,
              <Select
                label='Contact'
                value={lawFirmContact ?? ''}
                options={lawFirmContacts.data?.map((c) => ({ value: c.id.toString(), label: c.displayName })) ?? []}
                placeholder='Select contact...'
                disabled={!lawFirm}
                compact={true}
                onChange={(value) => {
                  setLawFirmContact(value)
                  setDirty({ ...dirty, lawFirmContact: true })
                }}
              />
            ],
            loading: updateAccident.isPending,
            onSubmit: async () => {
              if (!dirty.lawFirm && !dirty.lawFirmContact) return

              if (user.data?.role === 'provider') {
                confirmAction({
                  onConfirm: () => {
                    handleSubmit()
                    if (dirty.lawFirm) {
                      const from = accident.lawFirm ? accident.lawFirm.name : 'null'
                      const to = lawFirm?.label
                      const action = `changed the ${format.field('lawFirm')} from ${from} to ${to}`
                      logEvent.mutate({ accident, action })
                    }
                    if (dirty.lawFirmContact) {
                      const action = `changed the ${format.field('lawFirmContact')}`
                      logEvent.mutate({ accident, action })
                    }
                  },
                })
              } else {
                await handleSubmit()
              }

              async function handleSubmit() {
                try {
                  const updatedAccident = await updateAccident.mutateAsync({ id: accident.id, lawFirm: lawFirm?.value, lawFirmContact })
                  Toast.success('Law firm updated')
                  if (onAccidentUpdate) onAccidentUpdate(updatedAccident)
                } catch {
                  return false
                }
              }
            },
          }}
          headerAction={
            user.data?.role !== 'provider' && <>
              <div css={styles.lawFirmHeaderDivider} />
              <Link
                href={`/law-firms/${lawFirm?.value}`}
                css={[styles.lawFirmHeaderAction, !lawFirm && styles.lawFirmHeaderActionDisabled]}
              >
                <FontAwesomeIcon
                  icon={['fal', 'pen-to-square']}
                  size={15}
                  color={colorWithOpacity(theme.colors.foreground, 0.8)}
                />
                Edit Law Firm
              </Link>
            </>
          }
        >
          <label>{accident.lawFirm ? accident.lawFirm.name : '(law firm)'}</label>
          <span className='contact'>
            {accident.lawFirmContact?.email && (
              <Tooltip content={accident.lawFirmContact?.email}>
                <a href={`mailto:${accident.lawFirmContact?.email}`} target='_blank'>
                  <FontAwesomeIcon
                    icon={['fal', 'envelope']}
                    size={15}
                    color={theme.colors.foreground}
                  />
                </a>
              </Tooltip>
            )}
            {accident.lawFirmContact?.phone && (
              <Tooltip content={accident.lawFirmContact?.phone}>
                <a href={`tel:${accident.lawFirmContact?.phone}`}>
                  <FontAwesomeIcon
                    icon={['fal', 'phone']}
                    size={14}
                    color={theme.colors.foreground}
                  />
                </a>
              </Tooltip>
            )}
            {accident.lawFirmContact?.name ?? '(law firm contact)'}
          </span>
        </EditableValue>
        <EditableValue
          css={styles.infoItem}
          form={{
            fields: [
              <TextInput
                label='Insurance Carrier'
                value={insuranceCarrier}
                compact={true}
                onChange={(e) => {
                  setInsuranceCarrier(e.target.value)
                  setDirty({ ...dirty, insuranceCarrier: true })
                }}
              />
            ],
            loading: updateAccident.isPending,
            onSubmit: async () => {
              if (!dirty.insuranceCarrier) return

              if (user.data?.role === 'provider') {
                confirmAction({
                  onConfirm: () => {
                    handleSubmit()
                    const action = `changed the ${format.field('insuranceCarrier')}`
                    logEvent.mutate({ accident, action })
                  },
                })
              } else {
                await handleSubmit()
              }

              async function handleSubmit() {
                try {
                  const updatedAccident = await updateAccident.mutateAsync({ id: accident.id, insuranceCarrier })
                  Toast.success('Insurance carrier updated')
                  if (onAccidentUpdate) onAccidentUpdate(updatedAccident)
                } catch { }
              }
            },
          }}
        >
          <label>Insurance Carrier</label>
          <span>{accident.insuranceCarrier ?? '(insurance carrier)'}</span>
        </EditableValue>
      </div>

      <div css={styles.divider} />

      <div css={styles.policyLimit}>
        <EditableValue
          form={{
            fields: [
              <TextInput
                label='Policy Limit ($)'
                value={policyLimit.amount}
                compact={true}
                onChange={(e) => {
                  setPolicyLimit({ ...policyLimit, amount: e.target.value })
                  setDirty({ ...dirty, policyLimit: { ...dirty.policyLimit, amount: true } })
                }}
              />,
            ],
            loading: updateAccident.isPending,
            onSubmit: async () => {
              if (!dirty.policyLimit.amount) return

              if (user.data?.role === 'provider') {
                confirmAction({
                  onConfirm: () => {
                    handleSubmit()
                    const action = `changed the ${format.field('policyLimitAmount')}`
                    logEvent.mutate({ accident, action })
                  },
                })
              } else {
                await handleSubmit()
              }

              async function handleSubmit() {
                try {
                  const updatedAccident = await updateAccident.mutateAsync({ id: accident.id, policyLimit })
                  Toast.success('Policy limit updated')
                  if (onAccidentUpdate) onAccidentUpdate(updatedAccident)
                } catch { }
              }
            },
          }}
          css={styles.infoItem}
        >
          <label>Policy Limit</label>
          <span>{format.currency(accident.policyLimitAmount, { cents: false })}</span>
        </EditableValue>

        <EditableValue
          disabled={user.data?.role === 'provider'}
          form={{
            fields: [
              <TextInput
                label='Policy Limit % (Accident)'
                value={policyLimit.accidentPercentage}
                compact={true}
                onChange={(e) => {
                  setPolicyLimit({ ...policyLimit, accidentPercentage: e.target.value })
                  setDirty({ ...dirty, policyLimit: { ...dirty.policyLimit, accidentPercentage: true } })
                }}
              />,
              account && (
                <TextInput
                  label='Policy Limit % (Provider)'
                  value={policyLimit.accountPercentage}
                  compact={true}
                  onChange={(e) => {
                    setPolicyLimit({ ...policyLimit, accountPercentage: e.target.value })
                    setDirty({ ...dirty, policyLimit: { ...dirty.policyLimit, accountPercentage: true } })
                  }}
                />
              ),
            ],
            loading: updateAccident.isPending,
            onSubmit: async () => {
              if (!dirty.policyLimit.accidentPercentage && !dirty.policyLimit.accountPercentage) return

              try {
                if (dirty.policyLimit.accidentPercentage) {
                  await updateAccident.mutateAsync({ id: accident.id, policyLimit })
                }
                if (account && dirty.policyLimit.accountPercentage) {
                  await updateAccount.mutateAsync({ account, policyLimit })
                }
                Toast.success('Policy limit updated')
              } catch { }
            },
          }}
          css={styles.infoItem}
        >
          <label>Current (Max {policyLimitPercentage}%)</label>
          <div css={styles.policyLimitGauge}>
            <div className='gauge'>
              <div className='bg' />
              <div className='bar' />
              {policyLimitValue > policyLimitPercentage && <>
                <div className='bar-success' />
                <div className='tick' />
              </>}
            </div>
            <div className='label'>{policyLimitValue}%</div>
          </div>
        </EditableValue>
      </div>

      <div css={styles.divider} />

      <div css={styles.totals}>
        {user.data?.role !== 'provider' && (
          transactions.length && totals.target === 0 ? (() => {
            const ROI = calculateROI(totals.collected, totals.cost)
            const IRR = calculateIRR(totals.cashflow)
            const color = colorFromROIAndIRR(ROI, IRR, theme)

            return (
              <div css={styles.totalsItem}>
                <span style={{ color }}>{ROI}x - {IRR}%</span>
                <label>Return</label>
              </div>
            )
          })() : (
            <div css={styles.totalsItem}>
              <span>{format.currency(totals.target)}</span>
              <label>Target</label>
            </div>
          )
        )}
        {user.data?.role === 'provider' && (
          <div css={styles.accountStatus}>
            {accident.open ? 'Open Case' : 'Settled Case'}
          </div>
        )}
        <div css={styles.totalsItem}>
          <span>{format.currency(totals.invoices)}</span>
          <label>Invoices</label>
        </div>
        <div css={styles.totalsItem}>
          <span>{format.currency(totals.collected)}</span>
          <label>Collected</label>
        </div>
        {user.data?.role !== 'provider' && (
          <div css={styles.totalsItem}>
            <span>{format.currency(totals.cost)}</span>
            <label>Cost</label>
          </div>
        )}
      </div>
    </div>
  )
}

function Tags({ accident, addNotes, setAddNotes }: {
  accident: Accident
  addNotes: boolean
  setAddNotes: Dispatch<SetStateAction<boolean>>
}) {
  const TAG_HEIGHT = 27

  const modal = useStore.useModal()
  const theme = useTheme()

  const user = useUser()
  const config = useConfig()
  const updateAccident = useUpdateAccident()

  const systemTags: TagName[] = ['Split Case', 'Replacement Case']

  const styles = {
    wrapper: css({
      borderTop: `1px solid ${colorWithBrightness(theme.colors.panelBorder, 5)}`,
      borderBottom: `1px solid ${colorWithBrightness(theme.colors.panelBorder, 5)}`,
      margin: `0 -${theme.spacing}px`,
      padding: `${theme.spacing * 0.5}px ${theme.spacing}px`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }),
    tags: css({
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing * 0.5,
    }),
    tag: css({
      height: TAG_HEIGHT,
      borderRadius: TAG_HEIGHT,
      background: theme.colors.primary,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      '> .name': {
        height: '100%',
        padding: '0 11px',
        color: '#FFFFFF',
        fontFamily: theme.fonts.body[600],
        fontSize: 11,
        letterSpacing: 0.2,
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        pointerEvents: 'none',
        '&.clickable': {
          pointerEvents: 'auto',
          cursor: 'pointer',
          ':hover': {
            background: colorWithOpacity('#000000', 0.08),
          },
        },
      },
      '> .remove': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        marginLeft: -5,
        paddingLeft: 4,
        paddingRight: 8,
        cursor: 'pointer',
        'svg path': {
          fill: colorWithOpacity('#FFFFFF', 0.8),
        },
        ':hover': {
          background: colorWithOpacity('#000000', 0.08),
          'svg path': {
            fill: '#FFFFFF',
          },
        },
      },
    }),
    addTag: css({
      height: TAG_HEIGHT,
      border: `1px dashed ${theme.colors.panelBorder}`,
      borderRadius: TAG_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      color: colorWithOpacity(theme.colors.foreground, 0.5),
      fontFamily: theme.fonts.body[500],
      fontSize: 13,
      columnGap: 5,
      padding: '0 11px 0 9px',
      cursor: 'pointer',
      '&.disabled': {
        pointerEvents: 'none',
        opacity: 0.5,
      },
      ':hover': {
        borderColor: colorWithBrightness(theme.colors.panelBorder, -25),
      },
    }),
    addNotes: css({
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.fonts.body[500],
      fontSize: 13,
      columnGap: 5,
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      background: colorWithBrightness(theme.colors.surface, -15),
      height: TAG_HEIGHT,
      borderRadius: TAG_HEIGHT,
      padding: '0 11px 0 9px',
      cursor: 'pointer',
      ':hover': {
        background: colorWithBrightness(theme.colors.surface, -20),
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.tags}>
        {accident.tags.map((tag) => {
          const configTag = config.data?.tags.find((t) => t.name === tag)
          const clickable = systemTags.includes(tag) && user.data?.role !== 'provider'
          const removable = !systemTags.includes(tag)
          return (
            <div
              key={tag}
              css={styles.tag}
              style={{ background: configTag?.color }}
              onClick={() => {
                switch (tag) {
                  case 'Split Case':
                    modal.open({
                      title: 'Split Cases Summary',
                      content: <SplitAccidentsSummary accident={accident} />,
                    })
                    break
                  case 'Replacement Case':
                    modal.open({
                      title: 'Replacement Cases Summary',
                      content: <ReplacementAccidentsSummary accident={accident} />,
                    })
                    break
                }
              }}
            >
              <span className={`name ${clickable && 'clickable'}`}>{tag}</span>
              {removable && (
                <span
                  className='remove'
                  onClick={(e) => {
                    e.stopPropagation()
                    const tags = accident.tags.filter((t) => t !== tag)
                    updateAccident.mutate({ id: accident.id, tags }, {
                      onSuccess: () => Toast.success('Tag removed'),
                    })
                  }}
                >
                  <FontAwesomeIcon icon={['far', 'xmark']} size={13} />
                </span>
              )}
            </div>
          )
        })}

        <div
          css={styles.addTag}
          className={accident.tags.length === config.data?.tags.length ? 'disabled' : ''}
          onClick={() => modal.open({
            title: 'Add Tag',
            content: <AddTag accident={accident} systemTags={systemTags} />,
          })}
        >
          <FontAwesomeIcon
            icon={['fas', 'plus']}
            size={13}
            color={colorWithOpacity(theme.colors.foreground, 0.5)}
          />
          Tag
        </div>
      </div>

      {(!accident.notes && !addNotes) && (
        <div css={styles.addNotes} onClick={() => setAddNotes(true)}>
          <FontAwesomeIcon
            icon={['fas', 'plus']}
            size={13}
            color={colorWithOpacity(theme.colors.foreground, 0.8)}
          />
          Notes
        </div>
      )}
    </div>
  )
}

function AddTag({ accident, systemTags }: { accident: Accident, systemTags: TagName[] }) {
  const modal = useStore.useModal()
  const theme = useTheme()

  const updateAccident = useUpdateAccident()

  const [tag, setTag] = useState('')
  const [error, setError] = useState<{ tag?: string }>()

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <Select
        value={tag}
        options={TagNameSchema.options.filter((t) => !accident.tags.includes(t) && !systemTags.includes(t))}
        placeholder='Select tag'
        error={error?.tag}
        onChange={setTag}
      />
      <Spacing height={1.4} />
      <Button
        text='Add Tag'
        type='primary'
        loading={updateAccident.isPending}
        onPress={() => {
          if (!tag) {
            setError({ tag: 'Select tag' })
            return
          }

          const tags = [...accident.tags, tag as TagName]
          updateAccident.mutate({ id: accident.id, tags }, {
            onSuccess: () => {
              modal.close()
              Toast.success('Tag added')
            },
          })
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function Notes({ accident, batch, addNotes, setAddNotes }: {
  accident: Accident
  batch: Batch | undefined
  addNotes: boolean
  setAddNotes: Dispatch<SetStateAction<boolean>>
}) {
  const theme = useTheme()

  const updateAccident = useUpdateAccident()

  const [notes, setNotes] = useState(accident.notes ?? '')
  const [editMode, setEditMode] = useState(addNotes)

  const [prevNotes, setPrevNotes] = useState(accident.notes)
  if (prevNotes !== accident.notes) {
    setNotes(accident.notes ?? '')
    setPrevNotes(accident.notes)
  }

  let displayNotes = accident.notes
  if (batch) {
    for (const charge of batch.charges.filter((c) => c.accident?.id === accident.id)) {
      if (charge.accidentNote && !displayNotes?.includes(charge.accidentNote)) {
        displayNotes += '\n\n' + charge.accidentNote
      }
    }
  }

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const cancel = useCallback(() => {
    setNotes(accident.notes ?? '')
    setAddNotes(false)
    setEditMode(false)
  }, [accident.notes, setAddNotes])

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') cancel()
    }

    if (editMode) {
      textareaRef.current?.focus()
      const notesLength = textareaRef.current?.value.length ?? 0
      textareaRef.current?.setSelectionRange(notesLength, notesLength)
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [editMode, cancel])

  const styles = {
    wrapper: css({
      borderBottom: `1px solid ${colorWithBrightness(theme.colors.panelBorder, 5)}`,
      margin: `0 -${theme.spacing}px`,
      padding: theme.spacing,
    }),
    preview: css({
      color: colorWithOpacity(theme.colors.foreground, 0.9),
      fontFamily: theme.fonts.body[400],
      fontSize: 14,
      lineHeight: 1.4,
      whiteSpace: 'pre-wrap',
      margin: 0,
    }),
    editMode: css({
      marginTop: -11,
      marginLeft: -11,
      marginRight: -11,
      'textarea': {
        padding: '10px !important',
      },
    }),
    actions: css({
      display: 'flex',
      justifyContent: 'center',
      columnGap: theme.spacing * 0.8,
      marginTop: theme.spacing * 0.5,
      '> div': {
        fontFamily: theme.fonts.body[500],
        fontSize: 14.5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        columnGap: 6,
        '&.disabled': {
          pointerEvents: 'none',
          opacity: 0.5,
        },
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      {editMode ? (
        <div css={styles.editMode}>
          <TextArea
            ref={textareaRef}
            value={notes}
            rows={3}
            compact={true}
            fontSize={14}
            placeholder='Enter notes here...'
            onChange={(e) => setNotes(e.target.value)}
          />
          <div css={styles.actions}>
            <div
              className={!notes && !accident.notes ? 'disabled' : ''}
              style={{ color: theme.colors.success }}
              onClick={() => {
                updateAccident.mutate({ id: accident.id, notes }, {
                  onSuccess: () => {
                    setAddNotes(false)
                    setEditMode(false)
                    Toast.success('Notes updated')
                  },
                })
              }}
            >
              <FontAwesomeIcon
                icon={['fal', 'floppy-disk']}
                size={16}
                color={theme.colors.success}
              />
              Save
            </div>
            <div style={{ opacity: 0.7 }} onClick={cancel}>Cancel</div>
          </div>
        </div>
      ) : (
        <EditableValueTooltip transparent={false} onIconClick={() => setEditMode(true)}>
          <pre css={styles.preview}>{displayNotes}</pre>
        </EditableValueTooltip>
      )}
    </div>
  )
}

function SplitAccidentsSummary({ accident }: { accident: Accident }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const summary = useSplitAccidentsSummary(accident.id)

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    accidentList: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 0.5,
    }),
    accidentItem: css({
      background: theme.colors.pageBackground,
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s,
      display: 'flex',
      flexDirection: 'column',
      padding: '12px 15px',
      '&.clickable': {
        cursor: 'pointer',
        ':hover': {
          background: colorWithBrightness(theme.colors.pageBackground, -5),
        },
      },
    }),
    accidentItemText: css({
      fontFamily: theme.fonts.body[500],
      fontSize: 15,
      marginBottom: theme.spacing * 0.1,
    }),
    accidentItemMeta: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 14,
      opacity: 0.8,
      marginTop: theme.spacing * 0.2,
    }),
    totals: css({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, auto)',
      gridTemplateRows: 'repeat(2, auto)',
      columnGap: theme.spacing * 1.5,
      rowGap: theme.spacing * 0.8,
    }),
    totalsItem: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: 3,
      '> span': {
        fontFamily: theme.fonts.body[600],
        fontSize: 20,
      },
      '> label': {
        fontFamily: theme.fonts.body[400],
        fontSize: 14,
        opacity: 0.7,
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      {summary.isPending ? (
        <ActivityIndicator style={{ minHeight: theme.spacing * 5, marginHorizontal: 'auto' }} />
      ) : summary.data ? <>
        <div css={styles.accidentList}>
          {summary.data.accidents.map((a) => {
            const clickable = a.id !== accident.id
            return (
              <div
                key={a.id}
                css={styles.accidentItem}
                className={clickable ? 'clickable' : undefined}
                onClick={clickable ? () => {
                  router.navigate(`/cases/${a.id}`)
                  modal.close()
                } : undefined}
              >
                <div css={styles.accidentItemText}>{a.patient.fullName}{a.id === accident.id && ' (this case)'}</div>
                <div css={styles.accidentItemMeta}>{a.accounts.map((a) => a.provider.name).join(', ')}</div>
              </div>
            )
          })}
        </div>
        <Spacing height={1.4} />
        <div css={styles.totals}>
          <div css={styles.totalsItem}>
            <span>{format.currency(summary.data.target)}</span>
            <label>Target</label>
          </div>
          <div css={styles.totalsItem}>
            <span>{format.currency(summary.data.invoices)}</span>
            <label>Invoices</label>
          </div>
          <div css={styles.totalsItem}>
            <span>{format.currency(summary.data.collected)}</span>
            <label>Collected</label>
          </div>
          <div css={styles.totalsItem}>
            <span>{format.currency(summary.data.cost)}</span>
            <label>Cost</label>
          </div>
        </div>
      </> : null}
      <Spacing height={1.4} />
    </div>
  )
}

function ReplacementAccidentsSummary({ accident }: { accident: Accident }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const summary = useReplacementAccidentsSummary(accident.id)

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    heading: css({

    }),
    accidentList: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 0.5,
    }),
    accidentItem: css({
      background: theme.colors.pageBackground,
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s,
      display: 'flex',
      flexDirection: 'column',
      padding: '12px 15px',
      cursor: 'pointer',
      ':hover': {
        background: colorWithBrightness(theme.colors.pageBackground, -5),
      },
    }),
    accidentItemText: css({
      fontFamily: theme.fonts.body[500],
      fontSize: 15,
      marginBottom: theme.spacing * 0.1,
    }),
    accidentItemMeta: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 14,
      opacity: 0.8,
      marginTop: theme.spacing * 0.2,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      {summary.isPending ? (
        <ActivityIndicator style={{ minHeight: theme.spacing * 5, marginHorizontal: 'auto' }} />
      ) : summary.data ? <>
        {summary.data.replacingAccidents.length > 0 && <>
          <Text type='inputLabel'>Assigned To</Text>
          <div css={styles.accidentList}>
            {summary.data.replacingAccidents.map((a) => (
              <div
                key={a.id}
                css={styles.accidentItem}
                onClick={() => {
                  router.navigate(`/cases/${a.id}`)
                  modal.close()
                }}
              >
                <div css={styles.accidentItemText}>{a.patient.fullName}</div>
                <div css={styles.accidentItemMeta}>{a.accounts.map((a) => a.provider.name).join(', ')}</div>
              </div>
            ))}
          </div>
        </>}
        {(summary.data.replacingAccidents.length > 0 && summary.data.replacementAccidents.length > 0) && <Spacing height={1.4} />}
        {summary.data.replacementAccidents.length > 0 && <>
          <Text type='inputLabel'>Replaced By</Text>
          <div css={styles.accidentList}>
            {summary.data.replacementAccidents.map((a) => (
              <div
                key={a.id}
                css={styles.accidentItem}
                onClick={() => {
                  router.navigate(`/cases/${a.id}`)
                  modal.close()
                }}
              >
                <div css={styles.accidentItemText}>{a.patient.fullName}</div>
                <div css={styles.accidentItemMeta}>{a.accounts.map((a) => a.provider.name).join(', ')}</div>
              </div>
            ))}
          </div>
        </>}
      </> : null}
      <Spacing height={1.4} />
    </div>
  )
}

function Timeline({ accident, account }: { accident: Accident, account: Account | undefined }) {
  const FILTER_OPTIONS = ['All', 'Communications', 'Account', 'Documents', 'Notes'] as const
  const DOT_SIZE = 11

  const { width } = useWindowDimensions()
  const modal = useStore.useModal()
  const alert = useStore.useAlert()
  const theme = useTheme()

  const user = useUser()
  const deleteEvent = useDeleteEvent()

  const [collapsed, setCollapsed] = useState(savedCollapsibleSection('Timeline'))
  const [filter, setFilter] = useState<typeof FILTER_OPTIONS[number]>('All')
  const [showAll, setShowAll] = useState(false)

  let events = filteredEvents(accident.events, account, user.data)

  events = events.sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf())
  events = events.filter((e) => {
    switch (filter) {
      case 'All':
        return e
      case 'Communications': {
        const types: EventType[] = ['Call', 'Email', 'Fax', 'Mail', 'Court System', 'Other']
        return types.includes(e.type)
      }
      case 'Account': {
        return e.type === 'Account'
      }
      case 'Documents':
        return e.type === 'Document'
      case 'Notes':
        return e.type === 'Note'
    }
  })

  let hiddenEvents = 0
  if (!showAll) {
    const eventsCount = events.length
    events = events.filter((e) => e.createdAt.isAfter(dayjs().subtract(30, 'days')))
    if (eventsCount > events.length) hiddenEvents = eventsCount - events.length
  }

  const eventsByDate = events.reduce((groups: Record<string, Event[]>, event) => {
    const date = format.date(event.createdAt, { iso: true })
    if (!groups[date]) groups[date] = []
    groups[date].push(event)
    return groups
  }, {})

  const styles = {
    wrapper: css({
      padding: theme.spacing,
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s + 2,
      background: theme.colors.pageBackground,
    }),
    timeline: css({
      display: 'grid',
      rowGap: theme.spacing * 0.8,
      position: 'relative',
      fontSize: 13.5,
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 1,
        top: 7,
        left: 5,
        bottom: 10,
        background: theme.colors.panelBorder,
      },
    }),
    group: css({
      display: 'grid',
      gridTemplateColumns: `${DOT_SIZE}px auto min-content`,
      columnGap: theme.spacing,
      rowGap: theme.spacing * 0.8,
      alignItems: 'start',
      color: theme.colors.foreground,
    }),
    dot: css({
      position: 'relative',
      '&::after': {
        content: '""',
        display: 'block',
        width: DOT_SIZE,
        height: DOT_SIZE,
        background: colorWithBrightness(theme.colors.panelBorder, -50),
        borderRadius: '50%',
        position: 'absolute',
        bottom: -13,
      },
      '&.date-dot': {
        '&::before': {
          height: 50,
        },
        '&::after': {
          background: theme.colors.panelBorder,
        },
      },
    }),
    date: css({
      gridColumn: '2 / span 2',
      display: 'flex',
      justifyContent: 'center',
      color: colorWithOpacity(theme.colors.foreground, 0.7),
      fontFamily: theme.fonts.body[600],
      alignItems: 'center',
      '> span': {
        padding: `0 ${theme.spacing}px`,
        whiteSpace: 'nowrap',
      },
      '&::before, &::after': {
        content: '""',
        display: 'block',
        borderTop: `1px solid ${theme.colors.panelBorder}`,
        height: 1,
        flexGrow: 1,
      },
      '&::before': {
        width: '41.5%',
      },
      '&::after': {
        width: '45%',
      },
    }),
    event: css({
      gridColumn: '1 / span 3',
      display: 'grid',
      gridTemplateColumns: `${DOT_SIZE}px auto`,
      gridColumnGap: theme.spacing,
      alignItems: 'start',
      fontFamily: theme.fonts.body[400],
      '> .details': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: theme.spacing,
        rowGap: theme.spacing * 0.5,
        flexWrap: 'wrap',
        [`@media (min-width: ${theme.breakpoints.m}px)`]: {
          flexWrap: 'nowrap',
        },
        '> .body': {
          'strong': {
            fontFamily: theme.fonts.body[500],
            fontWeight: 500,
          },
          '> .note': {
            color: colorWithOpacity(theme.colors.foreground, 0.8),
            fontFamily: theme.fonts.body[400],
            fontSize: 13,
            lineHeight: 1.4,
            marginTop: 3,
            marginBottom: 0,
            whiteSpace: 'pre-wrap',
          },
        },
        '> .meta': {
          display: 'flex',
          alignItems: 'center',
          columnGap: theme.spacing,
          rowGap: theme.spacing * 0.5,
          flexWrap: 'wrap',
          [`@media (min-width: ${theme.breakpoints.m}px)`]: {
            flexWrap: 'nowrap',
          },
          '> .badges': {
            display: 'flex',
            gap: theme.spacing * 0.5,
            flexWrap: 'wrap',
            [`@media (min-width: ${theme.breakpoints.m}px)`]: {
              flexWrap: 'nowrap',
            },
          },
          '> .time': {
            color: colorWithOpacity(theme.colors.foreground, 0.6),
            [`@media (min-width: ${theme.breakpoints.m}px)`]: {
              minWidth: 61,
              textAlign: 'right',
            },
          },
        },
      },
    }),
    noEvents: css({
      height: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      opacity: 0.7,
    }),
    showAll: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing * 0.5,
      '> span': {
        color: theme.colors.foreground,
        fontFamily: theme.fonts.body[600],
        fontSize: 11.5,
        textTransform: 'uppercase',
        letterSpacing: 0.2,
        cursor: 'pointer',
        ':hover': {
          opacity: 0.7,
        },
      },
    }),
  }

  return (
    <div>
      <SectionHeader
        heading='Timeline'
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      >
        <ButtonGroup
          buttons={FILTER_OPTIONS.map((value) => ({
            text: value,
            active: filter === value,
            onPress: () => setFilter(value),
          }))}
          maxWidth={width < theme.breakpoints.xs ? (width - theme.spacing * 4) : undefined}
          compact={true}
          disabled={collapsed}
        />
        <Button
          text='Add Event'
          type='tertiary'
          compact={true}
          disabled={collapsed}
          onPress={() => modal.open({
            title: 'Add Event',
            content: <EventDialog accident={accident} account={account} />
          })}
        />
      </SectionHeader>

      {!collapsed && (
        <div css={styles.wrapper}>
          {events.length > 0 ? (
            <div css={styles.timeline}>
              {Object.keys(eventsByDate).map((date) => (
                <div key={date} css={styles.group}>
                  <div css={styles.dot} className='date-dot' />
                  <div css={styles.date}>
                    <span>{format.date(dayjs(date), { relative: true })}</span>
                  </div>
                  {eventsByDate[date].map((event, index) => {
                    const article = 'aeiou'.includes(event.type[0].toLowerCase()) ? 'an' : 'a'
                    let action = ''
                    switch (event.type) {
                      case 'Call':
                      case 'Email':
                      case 'Fax':
                      case 'Mail':
                      case 'Court System':
                        action = `added ${article} <strong>${event.type}</strong> event`
                        break
                      case 'Note':
                        action = `added a <strong>Note</strong>${event.action ? ': ' + event.action : ''}`
                        break
                      case 'Other':
                        action = 'added an event'
                        break
                      case 'Account':
                        action = event.action ?? ''
                        break
                      case 'Document': {
                        const document = accident.documents.find((d) => d.id === event.meta?.documentId)
                        action = document
                          ? `uploaded the document <strong>${document.name}</strong>`
                          : 'uploaded a document'
                        break
                      }
                    }

                    const visibilityTag = computeVisibilityTag({
                      accident,
                      account,
                      user: user.data,
                      entity: {
                        accountId: event.accountId,
                        public: event.public,
                      },
                    })

                    return (
                      <div
                        key={index}
                        css={styles.event}
                        style={{ cursor: event.status ? 'pointer' : undefined }}
                        onClick={() => {
                          if (!event.status) return

                          modal.open({
                            title: 'Edit Event',
                            content: <EventDialog accident={accident} account={account} event={event} />,
                            headerActionLeft: {
                              text: 'Delete',
                              destructive: true,
                              onPress: () => alert.open({
                                title: 'Delete Event',
                                message: 'Do you really want to delete this event? This can not be undone.',
                                buttons: [
                                  {
                                    text: 'Cancel',
                                    onPress: () => alert.close(),
                                  },
                                  {
                                    text: 'Delete Event',
                                    style: 'destructive',
                                    onPress: () => {
                                      deleteEvent.mutate({ accident, event }, {
                                        onSuccess: () => {
                                          alert.close()
                                          modal.close()
                                          Toast.success('Event deleted')
                                        },
                                      })
                                    },
                                  },
                                ],
                              }),
                            },
                          })
                        }}
                      >
                        <div css={styles.dot} />
                        <div className='details'>
                          <div className='body'>
                            <div>
                              <strong>{event.user?.fullName ?? 'System User'}</strong>
                              {' '}
                              <span dangerouslySetInnerHTML={{ __html: action }} />
                            </div>
                            {event.note && (
                              <pre className='note'>{event.note}</pre>
                            )}
                          </div>
                          <div className='meta'>
                            <div className='badges'>
                              {event.status && (
                                <Badge compact={true}>{event.status}</Badge>
                              )}
                              {visibilityTag && (
                                <Badge
                                  background={event.accountId
                                    ? colorFromAccountIndex(accident.accounts.findIndex((a) => a.id === event.accountId))
                                    : undefined}
                                  compact={true}
                                >{visibilityTag}</Badge>
                              )}
                            </div>
                            <div className='time'>{format.time(event.createdAt)}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          ) : (
            <div css={styles.noEvents}>
              {`No events found${hiddenEvents > 0 ? ' in the last 30 days' : ''}`}
            </div>
          )}
          {(hiddenEvents > 0 || showAll) && (
            <div css={styles.showAll}>
              <span onClick={() => setShowAll(!showAll)}>{`Show ${hiddenEvents > 0 ? 'All (' + hiddenEvents + ' more)' : 'Less'}`}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

function EventDialog({ accident, account, event }: {
  accident: Accident
  account: Account | undefined
  event?: Event
}) {
  const modal = useStore.useModal()
  const theme = useTheme()

  const user = useUser()
  const addEvent = useCreateEvent()
  const updateEvent = useUpdateEvent()

  const [visibility, setVisibility] = useState(() => {
    if (event) {
      return computeVisibility({
        accident,
        entity: {
          accountId: event.accountId,
          public: event.public,
        },
      })
    } else {
      return account ? account.provider.name : 'Staff Only'
    }
  })
  const [type, setType] = useState(event?.type ?? '')
  const [status, setStatus] = useState(event?.status ?? '')
  const [date, setDate] = useState(format.date(event?.createdAt ?? dayjs(), { iso: true }))
  const [nextContact, setNextContact] = useState(event?.nextContact ? format.date(event.nextContact, { iso: true }) : '')
  const [note, setNote] = useState(event?.note ?? '')
  const [error, setError] = useState<{
    type?: string
    status?: string
    date?: string
    nextContact?: string
  }>()

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    fields: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing * 0.8,
      [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 200px)',
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <div css={styles.fields}>
        {user.data?.role !== 'provider' && (
          <Select
            label='Visibility'
            value={visibility}
            options={['Staff Only', 'Public', ...accident.accounts.map((a) => a.provider.name)]}
            placeholder='Select visibility'
            compact={true}
            onChange={setVisibility}
          />
        )}
        <Select
          label='Type'
          value={type}
          options={EventTypeSchema.options.filter((t) => t !== 'Note' && t !== 'Account' && t !== 'Document')}
          placeholder='Select type'
          compact={true}
          error={error?.type}
          onChange={setType}
        />
        <Select
          label='Status'
          value={status}
          options={AccidentStatusSchema.options}
          placeholder='Select status'
          compact={true}
          error={error?.status}
          onChange={setStatus}
        />
        <TextInput
          type='date'
          label='Date'
          value={date}
          compact={true}
          max={format.date(dayjs(), { iso: true })}
          error={error?.date}
          onChange={(e) => setDate(e.target.value)}
        />
        <TextInput
          type='date'
          label='Next Contact'
          value={nextContact}
          compact={true}
          min={format.date(dayjs(), { iso: true })}
          error={error?.nextContact}
          onChange={(e) => setNextContact(e.target.value)}
        />
        <div css={{ gridColumn: 'span 2' }}>
          <TextArea
            label='Note'
            value={note}
            compact={true}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
      </div>
      <Spacing height={1.4} />
      <Button
        text={`${event ? 'Update' : 'Add'} Event`}
        type='primary'
        loading={addEvent.isPending || updateEvent.isPending}
        onPress={() => {
          const time = dayjs(event?.createdAt ?? new Date()).format('HH:mm:ss')
          const createdAt = dayjs(`${date} ${time}`).toISOString()
          if (event) {
            updateEvent.mutate({ accident, event, visibility, type, status, createdAt, nextContact, note }, {
              onSuccess: () => {
                modal.close()
                Toast.success('Event updated')
              },
              onError: (error) => setError(fieldErrors(error)),
            })
          } else {
            addEvent.mutate({ accident, visibility, type, status, createdAt, nextContact, note }, {
              onSuccess: () => {
                modal.close()
                Toast.success('Event added')
              },
              onError: (error) => setError(fieldErrors(error)),
            })
          }
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function Transactions({ accident, account, batch, onAccidentUpdate }: {
  accident: Accident
  account: Account | undefined
  batch: Batch | undefined
  onAccidentUpdate: OnAccidentUpdate
}) {
  const theme = useTheme()

  const user = useUser()

  const [collapsed, setCollapsed] = useState(savedCollapsibleSection('Transactions'))

  const transactions = filteredTransactions(accident.transactions, account, batch)

  const styles = {
    table: css({
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s + 2,
      overflow: 'hidden',
      'table tr.replacement': {
        background: colorWithOpacity(Constants.replacementColor, 0.1),
      },
    }),
  }

  return (
    <div>
      <SectionHeader
        heading='Transactions'
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />

      {!collapsed && (
        <div css={styles.table}>
          <Table
            columns={[
              {
                data: 'provider',
                title: 'Provider',
                width: 220,
              },
              {
                data: 'document',
                title: '',
                width: 1,
                alignRight: true,
                style: { padding: 0, paddingTop: 3 },
                renderer: (row) => {
                  const transaction = transactions[row]
                  const document = accident.documents.find((d) => d.id === transaction.documents[0])
                  if (document || (transaction.type === 'Invoice' && transaction.dos)) {
                    return (
                      <a
                        style={{ cursor: document ? 'pointer' : undefined }}
                        onClick={() => {
                          if (document) openDocumentDetailsModal({ accident, account, batch, document, onAccidentUpdate })
                        }}
                      >
                        <FontAwesomeIcon
                          icon={['fal', iconNameFromDocumentTag(transaction.type === 'Invoice' ? 'Invoices' : 'Payment')]}
                          size={16.5}
                          style={{ marginRight: transaction.type === 'Payment' ? 2 : 0 }}
                          color={transaction.type !== 'Invoice' ? colorWithOpacity(theme.colors.foreground, 0.7)
                            : transaction.documents.length > 0 ? theme.colors.success : theme.colors.warning}
                        />
                      </a>
                    )
                  }
                }
              },
              {
                data: 'type',
                title: 'Type',
                width: 115,
              },
              {
                data: 'dop',
                title: 'DOP',
                width: 105,
                renderer: (row) => {
                  const transaction = transactions[row]
                  if (transaction.dop) {
                    return format.date(transaction.dop)
                  } else {
                    let status: ColorStatus = 'info'
                    const text = transaction.charge?.status ?? 'Pending'
                    switch (transaction.charge?.status) {
                      case 'On Hold':
                        status = 'warning'
                        break
                      case 'Rejected':
                        status = 'danger'
                        break
                    }
                    return <Badge status={status} compact={true}>{text}</Badge>
                  }
                },
              },
              {
                data: 'dos',
                title: 'DOS',
                width: 105,
              },
              {
                data: 'notes',
                title: 'Notes',
                width: 240,
                renderer: (row) => {
                  const transaction = transactions[row]
                  return <TransactionNotes transaction={transaction} accident={accident} />
                },
              },
              {
                data: 'invoice',
                title: 'Invoice',
                width: 110,
                alignRight: true,
                hidden: user.data?.role === 'provider',
              },
              {
                data: 'cost',
                title: 'Cost',
                width: 110,
                alignRight: true,
                hidden: user.data?.role === 'provider',
              },
              {
                data: 'amount',
                title: 'Amount',
                width: 110,
                alignRight: true,
              },
            ]}
            data={transactions.map((transaction) => {
              const account = accident.accounts.find((a) => a.id === transaction.accountId)
              let amount = transaction.amount
              if (user.data?.role === 'provider' && transaction.type === 'Invoice') {
                amount = transaction.invoice
              }
              return {
                provider: account?.provider.name,
                type: transaction.type,
                dop: transaction.dop,
                dos: transaction.dos && format.date(transaction.dos),
                invoice: transaction.invoice !== null && format.currency(transaction.invoice),
                cost: transaction.cost !== null && format.currency(transaction.cost),
                amount: amount !== null && format.currency(amount),
              }
            })}
            noDataMessage='No transactions found'
            fixedLayout={true}
            compact={true}
            rowClasses={(row) => {
              const transaction = transactions[row]
              return transaction.replacingAccounts.length ? ['replacement'] : []
            }}
          />
        </div>
      )}
    </div>
  )
}

function TransactionNotes({ transaction, accident }: { transaction: Transaction, accident: Accident }) {
  const [procedure, setProcedure] = useState(transaction.procedure ?? '')
  const [note, setNote] = useState(transaction.note ?? '')

  const user = useUser()
  const updateTransaction = useUpdateTransaction()

  const fields: ReactNode[] = [
    <TextArea
      label='Note'
      value={note}
      compact={true}
      onChange={(e) => setNote(e.target.value)}
    />
  ]
  if (transaction.type === 'Invoice') {
    fields.unshift(
      <Select
        label='Procedure'
        value={procedure}
        options={ProcedureSchema.options}
        placeholder='Select procedure'
        compact={true}
        onChange={(value) => setProcedure(value)}
      />
    )
  }

  return (
    <EditableValue
      disabled={user.data?.role === 'provider'}
      form={{
        fields,
        onSubmit: async () => {
          try {
            await updateTransaction.mutateAsync({ accident, transaction, procedure, note })
            Toast.success('Transaction updated')
          } catch { }
        },
      }}
    >
      {transaction.buy && `Buy ${transaction.buy}`}
      {(transaction.buy && transaction.batch) && ' '}
      {transaction.batch && `(Batch #${transaction.batch})`}
      {transaction.procedure && ` [${transaction.procedure}]`}
      {((transaction.buy || transaction.batch) && transaction.note) && ': '}
      {transaction.note ?? <span style={{ opacity: 0 }}>%</span>}
    </EditableValue>
  )
}

function Documents({ accident, account, batch, onAccidentUpdate }: {
  accident: Accident
  account: Account | undefined
  batch: Batch | undefined
  onAccidentUpdate: OnAccidentUpdate
}) {
  type Upload = Pick<Document, 'name'> & { progress?: number }

  const theme = useTheme()

  const user = useUser()
  const uploadDocuments = useUploadDocuments()

  const [collapsed, setCollapsed] = useState(savedCollapsibleSection('Documents'))
  const [uploads, setUploads] = useState<Upload[]>([])

  const documents = filteredDocuments(accident.documents, account, user.data)
  const [prevDocuments, setPrevDocuments] = useState(accident.documents)
  if (!isEqual(accident.documents, prevDocuments)) {
    setUploads([])
    setPrevDocuments(accident.documents)
  }

  const dropzoneRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  async function handleFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (!DocumentTypeSchema.options.includes(file.type as DocumentType)) {
        Toast.error('File type not supported')
        return
      }

      if (file.size > 50 * 1024 * 1024) {
        Toast.error('Files larger than 50 MB are not supported')
        return
      }

      setUploads((uploads) => [...uploads, { name: file.name }])
    }

    uploadDocuments.mutate({
      accident,
      account,
      files,
      onProgress: (progress) => {
        setUploads((uploads) => uploads.map((d) => ({ ...d, progress })))
      },
    }, {
      onSettled: () => {
        inputRef.current!.value = ''
      },
    })
  }

  const styles = {
    status: css({
      display: 'flex',
      columnGap: theme.spacing * 0.4,
      opacity: collapsed ? 0.5 : 1,
    }),
    dropzone: css({
      background: theme.colors.pageBackground,
      border: `2px dashed ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s + 2,
      cursor: 'pointer',
      '&.over': {
        borderColor: theme.colors.success,
      },
    }),
    prompt: css({
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing * 0.5,
    }),
    promptContent: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      opacity: 0.6,
      height: 140,
    }),
    documents: css({
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: theme.spacing * 0.5,
      rowGap: theme.spacing * 0.5,
      padding: theme.spacing * 0.5,
    }),
    document: css({
      width: 133,
      height: 140,
      background: theme.colors.surface,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      borderRadius: theme.borderRadius.s - 1,
      border: `1px solid ${theme.colors.panelBorder}`,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
      ':hover, :focus': {
        borderColor: colorWithBrightness(theme.colors.panelBorder, -25),
      },
    }),
    documentPreview: css({
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '> div': {
        position: 'absolute',
        transition: 'clip-path 0.2s linear',
      },
    }),
    documentTags: css({
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 5,
      background: theme.colors.surface,
      border: `1px solid ${theme.colors.panelBorder}`,
      marginTop: -1,
      marginRight: -1,
      borderBottomLeftRadius: theme.borderRadius.s - 1,
      display: 'flex',
      alignItems: 'center',
      columnGap: 3,
      '.visibility': {
        height: 16.5,
        borderRadius: 16.5,
        color: theme.colors.foreground,
        background: theme.colors.panelBorder,
        fontFamily: theme.fonts.body[600],
        fontSize: 9,
        textTransform: 'uppercase',
        letterSpacing: 0.3,
        padding: '0 6px',
        lineHeight: 16.5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }),
    documentName: css({
      background: theme.colors.surface,
      textAlign: 'center',
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      fontFamily: theme.fonts.body[400],
      fontSize: 13,
      lineHeight: 1.2,
      padding: theme.spacing * 0.4,
    }),
    addDocument: css({
      background: 'none',
      borderStyle: 'dashed',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ':hover, :focus': {
        borderColor: colorWithBrightness(theme.colors.panelBorder, -25),
      },
    }),
  }

  return (
    <div>
      <SectionHeader
        heading='Documents'
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      >
        <div css={styles.status}>
          {[...RequiredDocumentTagSchema.options, 'Invoices' as const].map((tag) => {
            let color = colorWithOpacity(theme.colors.foreground, 0.3)
            let tooltip = ''

            if (accident.missingDocuments && accident.missingDocuments[tag] === false) {
              color = theme.colors.success
              tooltip = `${tag} Uploaded`
              const notAlwaysRequired: (RequiredDocumentTag | 'Invoices')[] = ['Causation Letter', 'UCC1']
              if (notAlwaysRequired.includes(tag)) tooltip += ' / Not Required'
            } else {
              tooltip = `${tag} Required`
            }

            return (
              <Tooltip key={tag} content={tooltip}>
                <FontAwesomeIcon
                  icon={['fas', iconNameFromDocumentTag(tag)]}
                  size={19}
                  color={color}
                />
              </Tooltip>
            )
          })}
        </div>
      </SectionHeader>

      {!collapsed && (
        <div
          ref={dropzoneRef}
          css={styles.dropzone}
          onClick={() => inputRef.current?.click()}
          onDragOver={(e) => {
            e.preventDefault()
            e.stopPropagation()
            dropzoneRef.current?.classList.add('over')
          }}
          onDragLeave={() => {
            dropzoneRef.current?.classList.remove('over')
          }}
          onDrop={(e) => {
            e.preventDefault()
            e.stopPropagation()
            dropzoneRef.current?.classList.remove('over')
            if (e.dataTransfer.files) handleFiles(e.dataTransfer.files)
          }}
        >
          {documents.length || uploads.length ? (
            <div css={styles.documents}>
              {documents.map((document) => {
                const visibilityTag = computeVisibilityTag({
                  accident,
                  account,
                  user: user.data,
                  entity: {
                    accountId: document.accountId,
                    public: document.public,
                  },
                })

                const transactions = accident.transactions.filter((t) => {
                  return t.documents.find((d) => d === document.id)
                })

                return (
                  <div
                    key={document.id}
                    css={styles.document}
                    style={{ backgroundImage: `url(${document.thumbnail})` }}
                    onClick={(e) => {
                      e.stopPropagation()
                      openDocumentDetailsModal({ accident, account, batch, document, onAccidentUpdate })
                    }}
                  >
                    <div css={styles.documentPreview} />
                    {(!account || document.tags.length > 0 || transactions.length > 0) && (
                      <div css={styles.documentTags}>
                        {visibilityTag ? (
                          ['Staff Only', 'Public'].includes(visibilityTag) ? (
                            <div className='visibility'>{visibilityTag}</div>
                          ) : (
                            <Tooltip content={visibilityTag}>
                              <div className='visibility' style={{ background: colorFromAccountIndex(accident.accounts.findIndex((a) => a.id === document.accountId)) }}>
                                {visibilityTag.split(' ').map((w) => w[0])}
                              </div>
                            </Tooltip>
                          )
                        ) : null}
                        {document.tags.map((tag) => (
                          <Tooltip key={tag} content={tag}>
                            <FontAwesomeIcon
                              icon={['fal', iconNameFromDocumentTag(tag)]}
                              size={16.5}
                              color={colorWithOpacity(theme.colors.foreground, 0.7)}
                            />
                          </Tooltip>
                        ))}
                        {(transactions.length > 0 && transactions[0].type === 'Invoice') && (
                          <Tooltip content='Invoice'>
                            <FontAwesomeIcon
                              icon={['fal', iconNameFromDocumentTag('Invoices')]}
                              size={16.5}
                              color={colorWithOpacity(theme.colors.foreground, 0.7)}
                            />
                          </Tooltip>
                        )}
                        {(transactions.length > 0 && transactions[0].type === 'Payment') && (
                          <Tooltip content='Payment'>
                            <FontAwesomeIcon
                              icon={['fal', iconNameFromDocumentTag('Payment')]}
                              size={16.5}
                              color={colorWithOpacity(theme.colors.foreground, 0.7)}
                            />
                          </Tooltip>
                        )}
                      </div>
                    )}
                    <div css={styles.documentName}>{document.name}</div>
                  </div>
                )
              })}
              {uploads.map((upload, index) => (
                <div key={index} css={styles.document}>
                  <div css={styles.documentPreview}>
                    <div>
                      <FontAwesomeIcon
                        icon={['fas', 'file']}
                        size={45}
                        color={colorWithOpacity('#666666', 0.5)}
                      />
                    </div>
                    <div style={{ clipPath: `inset(0 ${100 - (upload.progress ?? 0)}% 0 0` }}>
                      <FontAwesomeIcon
                        icon={['fas', 'file']}
                        size={45}
                        color='#666666'
                      />
                    </div>
                  </div>
                  <div css={styles.documentName}>{upload.name}</div>
                </div>
              ))}
              <a
                css={[styles.document, styles.addDocument]}
                onClick={(e) => {
                  e.stopPropagation()
                  inputRef.current?.click()
                }}
              >
                <FontAwesomeIcon
                  icon={['fas', 'file-plus']}
                  size={45}
                  color={colorWithOpacity('#666666', 0.15)}
                />
              </a>
            </div>
          ) : (
            <div css={styles.prompt}>
              <div css={styles.promptContent}>
                <FontAwesomeIcon icon={['fas', 'files']} size={21} />
                <Text style={{ fontSize: 15 }}>Drop files here to upload</Text>
              </div>
            </div>
          )}

          <input
            ref={inputRef}
            type='file'
            accept={DocumentTypeSchema.options.join(',')}
            multiple={true}
            style={{ display: 'none' }}
            onChange={(e) => {
              if (e.target.files?.length) handleFiles(e.target.files)
            }}
          />
        </div>
      )}
    </div>
  )
}

function openDocumentDetailsModal({ accident, account, batch, document, onAccidentUpdate }: {
  accident: Accident
  account: Account | undefined
  batch: Batch | undefined
  document: Document
  onAccidentUpdate: OnAccidentUpdate
}) {
  useStore.getState().modal.open({
    title: '',
    fullscreen: true,
    content: (
      <DocumentDetails
        accident={accident}
        account={account}
        batch={batch}
        document={document}
        onAccidentUpdate={onAccidentUpdate}
      />
    ),
  })
}

function DocumentDetails({ accident, account, batch, document: propDocument, onAccidentUpdate }: {
  accident: Accident
  account: Account | undefined
  batch: Batch | undefined
  document: Document
  onAccidentUpdate: OnAccidentUpdate
}) {
  const modal = useStore.useModal()
  const alert = useStore.useAlert()
  const theme = useTheme()

  const user = useUser()
  const updateDocument = useUpdateDocument()
  const deleteDocument = useDeleteDocument()

  const [document, setDocument] = useState(propDocument)
  const [documents, setDocuments] = useState(filteredDocuments(accident.documents, account, user.data))
  const [name, setName] = useState(document.name)
  const [visibility, setVisibility] = useState(() => {
    if (document) {
      return computeVisibility({
        accident,
        entity: {
          accountId: document.accountId,
          public: document.public,
        },
      })
    } else {
      return account ? account.provider.name : 'Staff Only'
    }
  })
  const [tags, setTags] = useState(document.tags)
  const [transactions, setTransactions] = useState(document.transactions)

  const [prevDocument, setPrevDocument] = useState(document)
  if (!isEqual(document, prevDocument)) {
    setDocuments(documents.map((d) => d.id === document.id ? document : d))
    setName(document.name)
    setVisibility(() => {
      if (document) {
        return computeVisibility({
          accident,
          entity: {
            accountId: document.accountId,
            public: document.public,
          },
        })
      } else {
        return account ? account.provider.name : 'Staff Only'
      }
    })
    setTags(document.tags)
    setTransactions(document.transactions)
    setPrevDocument(document)
  }

  const navPrev = useCallback(() => {
    const index = documents.findIndex((d) => d.id === document.id)
    if (index > 0) {
      setDocument(documents[index - 1])
    } else {
      setDocument(documents[documents.length - 1])
    }
  }, [document.id, documents])

  const navNext = useCallback(() => {
    const index = documents.findIndex((d) => d.id === document.id)
    if (index < documents.length - 1) {
      setDocument(documents[index + 1])
    } else {
      setDocument(documents[0])
    }
  }, [document.id, documents])

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      switch (e.key) {
        case 'ArrowLeft': navPrev(); break
        case 'ArrowRight': navNext(); break
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [navPrev, navNext])

  const imageFileTypes: DocumentType[] = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
  ]

  function formatFileType(fileType: DocumentType) {
    switch (fileType) {
      case 'application/pdf': return 'PDF document'
      case 'image/jpeg': return 'JPEG image'
      case 'image/png': return 'PNG image'
      case 'image/gif': return 'GIF image'
      case 'image/webp': return 'WebP image'
      case 'image/tiff': return 'TIFF image'
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': return 'Word Document'
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': return 'Excel Workbook'
      case 'application/msword': return 'Word 97-2004 Document'
      case 'application/vnd.ms-excel': return 'Excel 97-2004 Workbook'
      case 'text/plain': return 'Plain Text Document'
    }
  }

  const styles = {
    wrapper: css({
      flex: 1,
      display: 'flex',
      borderTop: `1px solid ${colorWithBrightness(theme.colors.panelBorder, 5)}`,
      marginTop: theme.spacing * 0.9,
    }),
    preview: css({
      flex: 1,
      '> object': {
        display: 'block',
        width: '100%',
        height: '100%',
      },
      '> img': {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
      },
    }),
    previewIcon: css({
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    info: css({
      display: 'none',
      [`@media (min-width: ${theme.breakpoints.m}px)`]: {
        minWidth: 440,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        rowGap: theme.spacing,
        background: theme.colors.pageBackground,
        borderLeft: `1px solid ${colorWithBrightness(theme.colors.panelBorder, 5)}`,
        padding: theme.spacing,
      },
    }),
    stats: css({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, max-content)',
      alignContent: 'flex-start',
      columnGap: theme.spacing,
      rowGap: theme.spacing * 0.4,
    }),
    label: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      color: colorWithOpacity(theme.colors.foreground, 0.7),
    }),
    value: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      color: theme.colors.foreground,
      maxWidth: 200,
    }),
    valueLink: css({
      display: 'block',
      textDecoration: 'none',
      ':hover': {
        color: theme.colors.accent,
      },
    }),
    tags: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 0.5,
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s,
      padding: theme.spacing * 0.5,
      background: colorWithBrightness(theme.colors.pageBackground, -8),
    }),
    delete: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: 6,
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      color: theme.colors.danger,
      marginTop: theme.spacing * 0.5,
      cursor: 'pointer',
      ':hover': {
        opacity: 0.7,
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.preview}>
        {document.type === 'application/pdf' ? (
          <object data={document.url} type='application/pdf' />
        ) : imageFileTypes.includes(document.type) ? (
          <img src={document.url} />
        ) : (() => {
          let iconName: IconName = 'file'
          switch (document.type) {
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/msword':
              iconName = 'file-word'
              break
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
              iconName = 'file-spreadsheet'
              break
            case 'image/tiff':
              iconName = 'file-image'
              break
            case 'text/plain':
              iconName = 'file-lines'
              break
          }

          return (
            <div css={styles.previewIcon}>
              <FontAwesomeIcon
                icon={['fas', iconName]}
                size={150}
                color='#666666'
              />
            </div>
          )
        })()}
      </div>

      <div css={styles.info}>
        <div>
          <div css={{ display: 'flex', justifyContent: 'space-between', columnGap: theme.spacing }}>
            <div css={styles.stats}>
              <div css={styles.label}>File name:</div>
              <EditableValue
                form={{
                  fields: [
                    <TextInput
                      label='File name'
                      value={name}
                      compact={true}
                      onChange={(e) => setName(e.target.value)}
                    />
                  ],
                  onSubmit: async () => {
                    try {
                      const res = await updateDocument.mutateAsync({ accident, document, name })
                      setDocument(res.document)
                      Toast.success('Document updated')
                    } catch { }
                  },
                }}
              >
                <a href={document.url} target='_blank' css={[styles.value, styles.valueLink]}>{document.name}</a>
              </EditableValue>

              <div css={styles.label}>File type:</div>
              <div css={styles.value}>{formatFileType(document.type)}</div>

              <div css={styles.label}>Uploaded on:</div>
              <div css={styles.value}>{format.date(document.createdAt)}</div>

              <div css={styles.label}>Size:</div>
              <div css={styles.value}>{bytes(document.size, { decimalPlaces: 1, unitSeparator: ' ' })}</div>
            </div>

            <NavButtons
              height={38}
              // TODO: disable prev/next if no more documents to show
              prev={{ onClick: navPrev }}
              next={{ onClick: navNext }}
            />
          </div>

          <Spacing height={1.5} />

          {user.data?.role !== 'provider' && (
            <Select
              label='Visibility'
              value={visibility}
              options={['Staff Only', 'Public', ...accident.accounts.map((a) => a.provider.name)]}
              placeholder='Select visibility'
              compact={true}
              style={{ marginBottom: theme.spacing }}
              onChange={async (value) => {
                setVisibility(value)
                updateDocument.mutate({ accident, document, visibility: value }, {
                  onSuccess: (res) => {
                    setDocument(res.document)
                    if (onAccidentUpdate) onAccidentUpdate(res.accident)
                  },
                })
              }}
            />
          )}

          <div css={styles.tags}>
            {DocumentTagSchema.options.map((tag) => (
              <DocumentDetailsTag
                key={tag}
                text={tag}
                iconName={iconNameFromDocumentTag(tag)}
                checked={tags.includes(tag) ?? false}
                onChange={() => {
                  let newTags = [...tags]
                  if (newTags.includes(tag)) {
                    newTags = newTags.filter((t) => t !== tag)
                  } else {
                    newTags = [...newTags, tag]
                  }
                  setTags(newTags)
                  updateDocument.mutate({ accident, document, tags: newTags }, {
                    onSuccess: (res) => {
                      setDocument(res.document)
                      if (onAccidentUpdate) onAccidentUpdate(res.accident)
                    },
                  })
                }}
              />
            ))}

            {accident.transactions.filter((t) => t.accountId === document.accountId).map((transaction) => {
              if (transaction.type !== 'Invoice' || !transaction.invoice || (!transaction.dop && transaction.batch !== batch?.number)) return null
              return (
                <DocumentDetailsTag
                  key={transaction.id}
                  text={`${transaction.dos ? `DOS: ${format.date(transaction.dos)} | ` : ''}Invoice: ${format.currency(transaction.invoice)}`}
                  iconName='file-invoice-dollar'
                  checked={transactions.includes(transaction.id)}
                  onChange={() => {
                    let newTransactions = [...transactions]
                    if (newTransactions.includes(transaction.id)) {
                      newTransactions = newTransactions.filter((id) => id !== transaction.id)
                    } else {
                      newTransactions = [...newTransactions, transaction.id]
                    }
                    setTransactions(newTransactions)
                    updateDocument.mutate({ accident, document, transactions: newTransactions }, {
                      onSuccess: (res) => {
                        setDocument(res.document)
                        if (onAccidentUpdate) onAccidentUpdate(res.accident)
                      },
                    })
                  }}
                />
              )
            })}
          </div>
        </div>

        <a
          css={styles.delete}
          onClick={() => {
            alert.open({
              title: 'Delete Document',
              message: 'Do you really want to delete this document? This can not be undone.',
              buttons: [
                {
                  text: 'Cancel',
                  onPress: () => alert.close(),
                },
                {
                  text: 'Delete Document',
                  style: 'destructive',
                  onPress: () => {
                    deleteDocument.mutate({ document }, {
                      onSuccess: (accident) => {
                        alert.close()
                        modal.close()
                        Toast.success('Document deleted')
                        if (onAccidentUpdate) onAccidentUpdate(accident)
                      },
                    })
                  },
                },
              ],
            })
          }}
        >
          <FontAwesomeIcon
            icon={['far', 'trash']}
            size={14.5}
            color={theme.colors.danger}
          />
          Delete
        </a>
      </div>
    </div>
  )
}

function DocumentDetailsTag({ text, iconName, checked, onChange }: {
  text: string
  iconName: IconName
  checked: boolean
  onChange: () => void
}) {
  const theme = useTheme()

  const styles = {
    tag: css({
      background: checked ? theme.colors.surface : undefined,
      border: `1px solid ${colorWithOpacity(theme.colors.foreground, 0.1)}`,
      borderRadius: theme.borderRadius.s - 1,
      padding: theme.spacing * 0.5,
      color: colorWithOpacity(theme.colors.foreground, checked ? 1 : 0.8),
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: theme.spacing * 0.7,
      cursor: 'pointer',
      userSelect: 'none',
      ':hover': {
        background: checked ? theme.colors.surface : colorWithOpacity(theme.colors.surface, 0.4),
      },
      '> div': {
        display: 'flex',
        alignItems: 'center',
        columnGap: 6,
      },
    }),
    checkbox: css({
      width: 19,
      height: 19,
      border: `1px solid ${colorWithOpacity(theme.colors.foreground, checked ? 0.2 : 0.1)}`,
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }

  return (
    <div css={styles.tag} onClick={onChange}>
      <div>
        <FontAwesomeIcon
          icon={['fal', iconName]}
          size={16.5}
          color={colorWithOpacity(theme.colors.foreground, checked ? 1 : 0.8)}
        />
        {text}
      </div>
      <div css={styles.checkbox}>
        {checked && (
          <FontAwesomeIcon
            icon={['fas', 'check']}
            size={13}
            color={theme.colors.foreground}
          />
        )}
      </div>
    </div>
  )
}

function Prefetcher({ accident, items }: { accident: Accident, items: number[] }) {
  const currentIndex = items.findIndex((id) => id === accident.id)
  const prevPrefetch = items[currentIndex - 1]
  const nextPrefetch = items[currentIndex + 1]

  useQuery({
    enabled: !!prevPrefetch,
    queryKey: accidentKeys.accident(prevPrefetch),
    queryFn: () => accidentQuery(prevPrefetch),
    notifyOnChangeProps: [],
  })

  useQuery({
    enabled: !!nextPrefetch,
    queryKey: accidentKeys.accident(nextPrefetch),
    queryFn: () => accidentQuery(nextPrefetch),
    notifyOnChangeProps: [],
  })

  return null
}

function confirmAction({ onConfirm }: { onConfirm: () => void }) {
  useStore.getState().alert.open({
    title: 'Are you sure?',
    message: 'Are you sure you want to update all matching records? Results will be logged in the timeline.',
    buttons: [
      {
        text: 'Cancel',
        onPress: () => useStore.getState().alert.close(),
      },
      {
        text: 'Yes',
        onPress: () => {
          onConfirm()
          useStore.getState().alert.close()
        },
      },
    ],
  })
}

function savedCollapsibleSection(section: CollapsibleSection) {
  const collapsedSections = JSON.parse(localStorage.getItem('collapsedSections') ?? '{}')
  return collapsedSections[section] ? collapsedSections[section] as boolean : false
}

function filteredEvents(events: Event[], account: Account | undefined, user: User | undefined) {
  if (account) {
    return events.filter((e) => e.accountId === account.id || e.public || (user?.role !== 'provider' && !e.accountId))
  }
  return events
}

function filteredTransactions(transactions: Transaction[], account: Account | undefined, batch: Batch | undefined) {
  let filtered = transactions
  filtered = filtered.filter((t) => {
    return (!t.dop && t.batch !== batch?.number && t.charge?.status !== 'Rejected') ? undefined : t
  })
  if (account) filtered = filtered.filter((t) => t.accountId === account.id)
  return filtered
}

function filteredDocuments(documents: Document[], account: Account | undefined, user: User | undefined) {
  if (account) {
    return documents.filter((d) => d.accountId === account.id || d.public || (user?.role !== 'provider' && !d.accountId))
  }
  return documents
}

function computeVisibility({ accident, entity }: {
  accident: Accident
  entity: {
    accountId: number | null
    public: boolean
  }
}) {
  if (entity.accountId) {
    const account = accident.accounts.find((a) => a.id === entity.accountId)
    if (!account) throw new Error('Account not found')
    return account.provider.name
  } else if (entity.public) {
    return 'Public'
  } else {
    return 'Staff Only'
  }
}

function computeVisibilityTag({ accident, account, user, entity }: {
  accident: Accident
  account: Account | undefined
  user: User | undefined
  entity: {
    accountId: number | null
    public: boolean
  }
}) {
  let visibility: string | undefined = computeVisibility({ accident, entity })

  if (account && !['Staff Only', 'Public'].includes(visibility)) visibility = undefined
  if (user?.role === 'provider') visibility = undefined

  return visibility
}

function iconNameFromDocumentTag(tag: DocumentTag | 'Invoices' | 'Payment'): IconName {
  switch (tag) {
    case 'Underwriting Form': return 'file-lines'
    case 'Causation Letter': return 'file-medical'
    case 'Lien Document': return 'file-contract'
    case 'UCC1': return 'file-lines'
    case 'Invoices': return 'file-invoice-dollar'
    case 'Payment': return 'money-check-dollar'
  }
}

function colorFromAccountIndex(index: number) {
  const colors = [
    '#C6E0B4',
    '#B4C6E7',
    '#FFE699',
    '#F8CBAD',
    '#D2BAFE',
  ]
  return colors[index]
}
