// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(app)/_layout.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/_layout.tsx"); } },
  "./(app)/cases/[accidentId].tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/cases/[accidentId].tsx"); } },
  "./(app)/cases/index.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/cases/index.tsx"); } },
  "./(app)/dashboard/cases/[accidentId].tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/dashboard/cases/[accidentId].tsx"); } },
  "./(app)/dashboard/checks-received/[accidentId].tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/dashboard/checks-received/[accidentId].tsx"); } },
  "./(app)/dashboard/index.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/dashboard/index.tsx"); } },
  "./(app)/funding-requests/[batchId].tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/funding-requests/[batchId].tsx"); } },
  "./(app)/funding-requests/index.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/funding-requests/index.tsx"); } },
  "./(app)/index.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/index.tsx"); } },
  "./(app)/law-firms/[lawFirmId].tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/law-firms/[lawFirmId].tsx"); } },
  "./(app)/law-firms/index.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/law-firms/index.tsx"); } },
  "./(app)/providers.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/providers.tsx"); } },
  "./(app)/quickbooks.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/quickbooks.tsx"); } },
  "./(app)/users.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/(app)/users.tsx"); } },
  "./+not-found.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/+not-found.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/_layout.tsx"); } },
  "./approve-funding-request/[slug].tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/approve-funding-request/[slug].tsx"); } },
  "./auth.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/auth.tsx"); } },
  "./signin/[slug].tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/signin/[slug].tsx"); } },
  "./submit-funding-request/[slug].tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/submit-funding-request/[slug].tsx"); } },
  "./welcome.tsx": { enumerable: true, get() { return require("/home/shreeve/src/trustlabs/apps/lienstar/src/app/welcome.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;