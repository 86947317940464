import RNSegmentedControl, { SegmentedControlProps } from '@react-native-segmented-control/segmented-control'
import { Platform, StyleSheet } from 'react-native'
import { useColorScheme } from '../hooks/useColorScheme'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

export function SegmentedControl(props: SegmentedControlProps) {
  const theme = useTheme()
  const colorScheme = useColorScheme()

  const styles = StyleSheet.create({
    wrapper: {
      backgroundColor: colorWithOpacity(theme.colors.foreground, Platform.OS === 'ios' ? 0.03 : 0.09),
    },
  })

  return (
    <RNSegmentedControl
      {...props}
      fontStyle={{
        color: theme.colors.foreground,
        fontFamily: Platform.OS === 'ios' ? undefined : theme.fonts.body[400],
        fontSize: 14,
      }}
      activeFontStyle={{
        color: theme.colors.foreground,
        fontFamily: Platform.OS === 'ios' ? undefined : theme.fonts.body[600],
        fontWeight: '600',
        fontSize: Platform.OS === 'android' ? 14.5 : 14,
      }}
      appearance={colorScheme === 'dark' ? 'dark' : 'light'}
      style={[styles.wrapper, Platform.OS === 'web' && theme.input.shadow, props.style]}
    />
  )
}
