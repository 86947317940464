import { Platform, Pressable, PressableProps, StyleSheet, View } from 'react-native'
import { ColorStatus, colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Text } from './Text'

type Props = PressableProps & {
  text: string
  status: ColorStatus
}

export function Chip({ text, status, ...props }: Props) {
  const theme = useTheme()

  const height = 30
  const color = theme.colors[status]

  const styles = StyleSheet.create({
    outer: {
      height,
      borderRadius: height / 2,
      backgroundColor: theme.colors.transparencyBase,
      alignSelf: 'flex-start',
    },
    inner: {
      flex: 1,
      justifyContent: 'center',
      borderWidth: 1,
      borderColor: colorWithOpacity(color, 0.7),
      borderRadius: height / 2,
      paddingHorizontal: height * 0.35,
    },
    text: {
      color,
      fontFamily: theme.fonts.body[600],
      fontSize: 13.5,
    },
  })

  return (
    <View style={styles.outer}>
      <Pressable
        {...props}
        disabled={!props.onPress}
        style={({ pressed, hovered }) => ([styles.inner, {
          backgroundColor: colorWithOpacity(color, pressed && Platform.OS !== 'web' ? 0.4 : hovered ? 0.2 : 0.15),
        }])}
      >
        <Text style={styles.text}>{text}</Text>
      </Pressable>
    </View>
  )
}
