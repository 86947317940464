/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ReactNode, useState } from 'react'
import { colorWithOpacity, useTheme } from '../../hooks/useTheme'
import { Divider } from '../Divider'
import { Popover } from './Popover'

export type NavMenuItem = {
  type: 'item' | 'divider'
  title?: string
  subtitle?: string
  active?: boolean
  onClick?: () => void
}

type Props = {
  items: NavMenuItem[]
  activeColor?: string
  children: ReactNode
}

export function NavMenu({ items, activeColor, children }: Props) {
  const theme = useTheme()

  const [open, setOpen] = useState(false)

  const styles = {
    menu: css({
      minWidth: 250,
      padding: 4,
    }),
    item: css({
      display: 'block',
      padding: '10px 16px',
      borderRadius: theme.borderRadius.s,
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        background: colorWithOpacity(theme.colors.foreground, 0.07),
      },
    }),
    title: css({
      fontFamily: theme.fonts.body[500],
      fontWeight: 500,
      fontSize: 15.5,
    }),
    subtitle: css({
      fontFamily: theme.fonts.body[400],
      fontWeight: 400,
      fontSize: 15,
      color: colorWithOpacity(theme.colors.foreground, 0.7),
      marginTop: 2,
      marginBottom: -1,
    }),
  }

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      sideOffset={theme.spacing * 0.5}
      collisionPadding={theme.spacing * 0.5}
      content={(
        <div css={styles.menu}>
          {items.map((item, index) => (
            item.type === 'item' ? (
              <a
                key={index}
                css={styles.item}
                style={{ color: item.active ? (activeColor ?? theme.colors.primary) : theme.colors.foreground }}
                onClick={() => {
                  setOpen(false)
                  if (item.onClick) item.onClick()
                }}
              >
                {item.title && <div css={styles.title}>{item.title}</div>}
                {item.subtitle && <div css={styles.subtitle}>{item.subtitle}</div>}
              </a>
            ) : (
              <Divider key={index} style={{ marginVertical: 6, marginHorizontal: -4 }} />
            )
          ))}
        </div>
      )}
    >
      {children}
    </Popover>
  )
}
