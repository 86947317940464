import { forwardRef } from 'react'
import { Platform, ScrollView, ScrollViewProps, View } from 'react-native'

export const DynamicScrollView = forwardRef<ScrollView, ScrollViewProps>(({ children, ...props }, ref) => {
  return (
    Platform.OS === 'web' ? (
      <View style={[props.style, props.contentContainerStyle]}>{children}</View>
    ) : (
      <ScrollView {...props} ref={ref}>{children}</ScrollView>
    )
  )
})
