import { ReactNode, useState } from 'react'
import { Platform, Pressable, PressableProps, StyleSheet, Text, View } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { colorWithBrightness, colorWithOpacity, useTheme } from '../hooks/useTheme'
import { ActivityIndicator } from './ActivityIndicator'

type Props = PressableProps & {
  text: string
  type: 'primary' | 'secondary' | 'tertiary'
  compact?: boolean
  fontFamily?: string
  textLeft?: ReactNode
  textRight?: ReactNode
  loading?: boolean
}

export function Button({
  text,
  type,
  compact,
  fontFamily,
  textLeft,
  textRight,
  disabled,
  loading,
  onPress,
  ...props
}: Props) {
  const theme = useTheme()

  const [hovered, setHovered] = useState(false)

  const scale = useSharedValue(1)
  const animatedStyles = useAnimatedStyle(() => ({
    transform: [{
      scale: withTiming(scale.value, {
        duration: compact ? 200 : 250,
        easing: theme.easing.curveLinearToEaseOut,
      }),
    }],
  }))

  const height = compact ? theme.button.compact.height : theme.button.height
  let backgroundColor: string
  let borderWidth = 0
  let borderColor = 'none'
  const borderRadius = compact ? theme.button.compact.borderRadius : theme.button.borderRadius
  let textColor: string

  switch (type) {
    case 'primary':
      backgroundColor = colorWithBrightness(theme.colors.primary, hovered ? 10 : 0)
      textColor = '#FFFFFF'
      break
    case 'secondary':
      backgroundColor = colorWithOpacity(theme.colors.primary, 0.12)
      borderWidth = 1
      borderColor = colorWithOpacity(theme.colors.primary, hovered ? 0.65 : 0.3)
      textColor = theme.colors.primary
      break
    case 'tertiary':
      backgroundColor = colorWithBrightness(theme.colors.surface, hovered ? -5 : 0)
      borderWidth = 1
      borderColor = theme.input.border
      textColor = colorWithOpacity(theme.colors.foreground, 0.9)
      break
  }

  const styles = StyleSheet.create({
    outer: {
      height,
      backgroundColor: theme.colors.transparencyBase,
      borderRadius,
      ...theme.input.shadow,
    },
    inner: {
      flex: 1,
      paddingHorizontal: height * 0.3,
      backgroundColor,
      borderRadius,
      borderWidth,
      borderColor,
      justifyContent: 'center',
    },
    textWrapper: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: disabled ? 0.5 : 1
    },
    text: {
      color: textColor,
      fontFamily: fontFamily ?? (compact ? theme.button.compact.fontFamily : theme.button.fontFamily),
      fontSize: compact ? theme.button.compact.fontSize : theme.button.fontSize,
      userSelect: 'none',
    },
    loading: {
      backgroundColor,
      borderRadius,
      position: 'absolute',
      zIndex: 1,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
  })

  return (
    <Pressable
      {...props}
      disabled={disabled || loading}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onPress={(e) => {
        scale.value = 1
        if (onPress) onPress(e)
      }}
      onPressIn={() => (scale.value = Platform.OS === 'web' ? 1 : compact ? 0.96 : 0.97)}
      onPressOut={() => (scale.value = 1)}
    >
      <Animated.View style={[animatedStyles, styles.outer]}>
        <View style={styles.inner}>
          <View style={styles.textWrapper}>
            {textLeft}
            <Text style={styles.text}>{text}</Text>
            {textRight}
          </View>

          {loading && (
            <View style={styles.loading}>
              <ActivityIndicator inverted={type === 'primary'} scale={compact ? 0.9 : 1} />
            </View>
          )}
        </View>
      </Animated.View>
    </Pressable>
  )
}
