import { TextInputProps } from 'react-native'
import { TextInput } from './TextInput'

type Props = TextInputProps & {
  label?: string
  compact?: boolean
  error?: string | boolean
  onChangeText: (text: string) => void
}

export function PhoneInput({ value: propValue, onChangeText, ...props }: Props) {
  function format(value: string) {
    let newValue = value

    // auto format phone number (e.g. "(123) 456-7890")
    if (newValue && newValue[0] !== '+') {
      newValue = value.replace(/[^0-9]/gi, '')

      if (newValue.startsWith('1')) newValue = newValue.substring(1)

      if (newValue.length > 0 && newValue.length <= 3) {
        newValue = `(${newValue}`
      } else if (newValue.length > 3 && newValue.length <= 6) {
        newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3)}`
      } else if (newValue.length > 6 && newValue.length <= 10) {
        newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3, 6)}-${newValue.slice(6)}`
      } else if (newValue.length > 10) {
        newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3, 6)}-${newValue.slice(6, 10)}, ext. ${newValue.slice(10)}`
      }
    }

    return newValue
  }

  return (
    <TextInput
      {...props}
      value={format(propValue ?? '')}
      autoComplete='tel'
      keyboardType='phone-pad'
      returnKeyType='done'
      textContentType='telephoneNumber'
      onChangeText={(value) => onChangeText(format(value))}
    />
  )
}
