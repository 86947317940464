/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { router } from 'expo-router'
import isEqual from 'lodash/isEqual'
import numeral from 'numeral'
import { useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { states } from 'shared'
import { Button, colorWithOpacity, MultiSelect, Panel, Select, Spacing, Table, Text, useTheme, Wrapper } from 'ui'
import { NavButtons } from '../../components/web/NavButtons'
import { Head } from '@/components/web/Head'
import { Constants } from '@/constants'
import { defaultBatchFilter, useStore } from '@/providers/store'
import { useBatches, useCreateBatch } from '@/queries/batch.queries'
import { useLocations, useProviders } from '@/queries/provider.queries'
import { useSaveBatchFilter, useUser } from '@/queries/user.queries'
import { BatchStatusBadge } from '@/screens/batches/BatchStatusBadge'
import { BatchListItem, BatchStatusSchema } from '@/types/batch'
import { BatchFilter } from '@/types/filter'
import { format } from '@/utils'

export function Batches() {
  const theme = useTheme()
  const filter = useStore.useBatchFilter()
  const setFilter = useStore.useSetBatchFilter()

  const batches = useBatches(filter)
  const saveBatchFilter = useSaveBatchFilter()

  function updateFilter(filter: BatchFilter) {
    setFilter(filter)
    saveBatchFilter.mutate(filter)
  }

  const styles = {
    wrapper: css({
      display: 'flex',
    }),
    content: css({
      flex: 1,
      margin: `${theme.spacing * 1.5}px ${theme.spacing}px`,
      overflowX: 'auto',
      [`@media (min-width: ${theme.breakpoints.m}px)`]: {
        margin: theme.spacing * 1.5,
      },
    }),
  }

  return <>
    <Head title='Funding Requests' />

    <div css={styles.wrapper}>
      <Sidebar onUpdateFilter={updateFilter} />

      <div css={styles.content}>
        <Wrapper maxWidth={1200} margin={false}>
          <Header
            count={batches.data ? batches.data.batches.length : undefined}
            total={batches.data ? batches.data.total : undefined}
            onUpdateFilter={updateFilter}
          />
          <Panel>
            <BatchesTable
              batches={batches.data?.batches ?? []}
              loading={batches.isPending}
              onUpdateFilter={updateFilter}
            />
          </Panel>
        </Wrapper>
      </div>
    </div>
  </>
}

function Header({ count: propCount, total: propTotal, onUpdateFilter }: {
  count: number | undefined
  total: number | undefined
  onUpdateFilter: (filter: BatchFilter) => void
}) {
  const theme = useTheme()
  const modal = useStore.useModal()
  const filter = useStore.useBatchFilter()

  const user = useUser()
  const createBatch = useCreateBatch()

  const [count, setCount] = useState(propCount)
  const [prevCount, setPrevCount] = useState(propCount)
  if (propCount !== prevCount && propCount !== undefined) {
    setCount(propCount)
    setPrevCount(propCount)
  }
  const [total, setTotal] = useState(propTotal)
  const [prevTotal, setPrevTotal] = useState(propTotal)
  if (propTotal !== prevTotal && propTotal !== undefined) {
    setTotal(propTotal)
    setPrevTotal(propTotal)
  }

  const offset = (filter.page - 1) * filter.show

  const styles = {
    wrapper: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing,
      flexWrap: 'wrap',
      marginBottom: theme.spacing,
    }),
    heading: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing,
      rowGap: theme.spacing * 0.2,
      flexWrap: 'wrap',
    }),
    info: css({
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
    }),
    buttons: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing * 0.5,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.heading}>
        <Text type='heading'>Funding Requests</Text>
        <div css={styles.info}>
          {count !== undefined ? `Showing ${count > 1 ? `${offset + 1} - ${Math.min(offset + count, total ?? 0)}` : count}` : ''}
          {(count !== undefined && total !== undefined && total > count) ? ` (of ${numeral(total).format()})` : ''}
        </div>
      </div>

      <div css={styles.buttons}>
        <Button
          text='New Funding Request'
          type='primary'
          compact={true}
          loading={createBatch.isPending}
          disabled={!user.data}
          fontFamily={theme.fonts.body[500]}
          onPress={() => {
            if (!user.data) return

            if (user.data.role !== 'provider' || user.data.providers.length > 1) {
              modal.open({
                title: 'New Funding Request',
                content: <NewBatch />,
              })
            } else {
              createBatch.mutate(user.data.providers[0].id, {
                onSuccess: (batch) => router.navigate(`/funding-requests/${batch.id}`),
              })
            }
          }}
        />
        <NavButtons
          prev={{
            disabled: filter.page === 1,
            onClick: () => onUpdateFilter({ ...filter, page: filter.page - 1 }),
          }}
          next={{
            disabled: count === undefined || total === undefined ? true : offset + count >= total,
            onClick: () => onUpdateFilter({ ...filter, page: filter.page + 1 }),
          }}
        />
      </div>
    </div>
  )
}

function NewBatch() {
  const theme = useTheme()
  const modal = useStore.useModal()

  const providers = useProviders()
  const createBatch = useCreateBatch()

  const [provider, setProvider] = useState('')
  const [error, setError] = useState('')

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <Select
        label='Provider'
        value={provider}
        options={providers.data ? providers.data.map((p) => ({ value: p.id.toString(), label: p.name })) : []}
        placeholder='Select provider'
        error={error}
        style={{ maxWidth: 330 }}
        onChange={(value) => setProvider(value)}
      />
      <Spacing height={1.4} />
      <Button
        text='Create Funding Request'
        type='primary'
        loading={createBatch.isPending}
        disabled={!providers.data}
        onPress={() => {
          if (provider) {
            createBatch.mutate(Number(provider), {
              onSuccess: (batch) => {
                modal.close()
                router.navigate(`/funding-requests/${batch.id}`)
              },
            })
          } else {
            setError('Select provider')
          }
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function BatchesTable({ batches, loading, onUpdateFilter }: {
  batches: BatchListItem[]
  loading: boolean
  onUpdateFilter: (filter: BatchFilter) => void
}) {
  const { width } = useWindowDimensions()
  const filter = useStore.useBatchFilter()
  const clearFilter = useStore.useClearBatchFilter()

  const user = useUser()
  const saveBatchFilter = useSaveBatchFilter()

  return (
    <Table
      columns={[
        {
          data: 'provider',
          title: 'Provider',
          width: width >= 1300 ? 'auto' : 230,
          hidden: user.data?.role === 'provider' && user.data?.providers.length === 1,
          sortable: true,
        },
        {
          data: 'buy',
          title: 'Buy',
          width: 150,
          sortable: true,
        },
        {
          data: 'number',
          title: 'Batch',
          width: 150,
          sortable: true,
        },
        {
          data: 'status',
          title: 'Status',
          width: 220,
          sortable: true,
          renderer: (row) => {
            const batch = batches[row]
            return <BatchStatusBadge status={batch.status} compact={true} />
          },
        },
        {
          data: 'lastStatus',
          title: 'Last Status',
          width: 170,
          sortable: true,
        },
      ]}
      data={batches.map((batch) => ({
        id: batch.id,
        provider: batch.provider.name,
        buy: batch.buy,
        number: batch.number,
        status: batch.status,
        lastStatus: batch.lastStatus ? format.date(batch.lastStatus) : '',
      }))}
      loading={loading}
      noDataMessage='No funding requests found'
      noDataButton={!isEqual(filter, defaultBatchFilter) && (
        (
          <Button
            text='Clear Filters'
            type='tertiary'
            compact={true}
            onPress={() => {
              const cleared = clearFilter(filter)
              saveBatchFilter.mutate(cleared)
            }}
          />
        )
      )}
      fixedLayout={true}
      stickyHeader={true}
      orderBy={filter.orderBy}
      sortOrder={filter.sortOrder}
      onUpdateSort={({ orderBy, sortOrder }) => onUpdateFilter({ ...filter, orderBy, sortOrder, page: 1 })}
      onRowClick={(row) => router.navigate(`/funding-requests/${row.id}`)}
    />
  )
}

function Sidebar({ onUpdateFilter }: { onUpdateFilter: (filter: BatchFilter) => void }) {
  const theme = useTheme()
  const filter = useStore.useBatchFilter()

  const user = useUser()
  const locations = useLocations()
  const providers = useProviders(filter.locations)
  const clearFilter = useStore.useClearBatchFilter()
  const saveAccidentFilter = useSaveBatchFilter()

  const styles = {
    sidebar: css({
      width: 320,
      height: '100%',
      minHeight: `calc(100vh - ${Constants.headerHeight}px)`,
      background: theme.colors.headerBackground,
      borderRight: `1px solid ${theme.colors.panelBorder}`,
      padding: `${theme.spacing * 1.5}px ${theme.spacing}px`,
      display: 'none',
      flexDirection: 'column',
      rowGap: theme.spacing,
      [`@media (min-width: ${theme.breakpoints.m}px)`]: {
        display: 'flex',
      },
    }),
    divider: css({
      height: 1,
      background: theme.colors.panelBorder,
      margin: `0 -${theme.spacing}px`,
    }),
  }

  return (
    <div css={styles.sidebar}>
      {user.data?.role !== 'provider' && (
        <MultiSelect
          label='State'
          value={filter.locations}
          options={locations.data?.map((l) => ({ value: l, label: states[l] })) ?? []}
          placeholder='Select state(s)'
          noOptions='State not found'
          compact={true}
          onChange={(value) => onUpdateFilter({ ...filter, locations: value, providers: [], page: 1 })}
        />
      )}
      {(user.data?.role !== 'provider' || (providers.data && providers.data?.length > 1)) && (
        <MultiSelect
          label='Provider'
          value={filter.providers}
          options={providers.data?.map((p) => ({ value: p.id.toString(), label: p.name })) ?? []}
          placeholder='Select provider(s)'
          noOptions='Provider not found'
          compact={true}
          onChange={(value) => onUpdateFilter({ ...filter, providers: value, page: 1 })}
        />
      )}
      <MultiSelect
        label='Status'
        value={filter.statuses}
        options={BatchStatusSchema.options}
        placeholder='Select status(es)'
        noOptions='Status not found'
        compact={true}
        onChange={(value) => onUpdateFilter({ ...filter, statuses: value, page: 1 })}
      />
      <div css={styles.divider} />
      <Button
        text='Clear Filters'
        type='tertiary'
        compact={true}
        disabled={isEqual(filter, defaultBatchFilter)}
        onPress={() => {
          const cleared = clearFilter(filter)
          saveAccidentFilter.mutate(cleared)
        }}
      />
    </div>
  )
}
