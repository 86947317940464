import { QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { api } from '@/queries/api'
import { Provider, ProviderListItem, ProviderListItemSchema, ProviderSchema } from '@/types/provider'

export const keys = {
  locations: (): QueryKey => ['locations'],
  providers: (locations?: string[]): QueryKey => {
    return locations ? ['providers', locations] : ['providers']
  },
  allProviders: (orderBy?: string, sortOrder?: string): QueryKey => {
    return orderBy && sortOrder ? ['allProviders', orderBy, sortOrder] : ['allProviders']
  },
}

export function useLocations() {
  return useQuery({
    queryKey: keys.locations(),
    queryFn: async () => {
      const res = await api.get('locations')
      return res.data.locations as string[]
    },
  })
}

export function useProviders(locations?: string[]) {
  return useQuery({
    queryKey: keys.providers(locations),
    queryFn: async () => {
      const res = await api.get('providers', {
        params: {
          locations: locations && locations.length ? locations.join(',') : undefined,
        },
      })
      return res.data.providers.map((item: any) => ProviderSchema.parse(item)) as Provider[]
    },
  })
}

export function useAllProviders(orderBy?: string, sortOrder?: string) {
  return useQuery({
    queryKey: keys.allProviders(orderBy, sortOrder),
    queryFn: async () => {
      const res = await api.get('providers/all', { params: { orderBy, sortOrder } })
      return res.data.providers.map((item: any) => ProviderListItemSchema.parse(item)) as ProviderListItem[]
    },
  })
}

export function useCreateProvider() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ name, state, procedure, manager, staff, rate, classification }: {
      name: string
      state: string
      procedure: string
      manager: string
      staff: string
      rate: string
      classification: string
    }) => {
      return api.post('providers', { name, state, procedure, manager, staff, rate, classification })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.locations() })
      queryClient.invalidateQueries({ queryKey: keys.providers() })
      queryClient.invalidateQueries({ queryKey: keys.allProviders() })
    },
  })
}

export function useUpdateProvider() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, name, state, procedure, manager, staff, rate, classification }: {
      id: number
      name: string
      state: string
      procedure: string
      manager: string
      staff: string
      rate: string
      classification: string
    }) => {
      return api.patch(`providers/${id}`, { name, state, procedure, manager, staff, rate, classification })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.locations() })
      queryClient.invalidateQueries({ queryKey: keys.providers() })
      queryClient.invalidateQueries({ queryKey: keys.allProviders() })
    },
  })
}
