import z from 'zod'

export const AccidentFilterSchema = z.object({
  locations: z.string().array().default([]),
  providers: z.string().array().default([]),
  lawFirms: z.string().array().default([]),
  lawFirmContacts: z.string().array().default([]),
  statuses: z.string().array().default([]),
  openSettled: z.string().default('All Cases'),
  missingDocuments: z.boolean().default(false),
  statusers: z.string().array().default([]),
  tags: z.string().array().default([]),
  orderBy: z.string().default('name'),
  sortOrder: z.enum(['asc', 'desc']).default('asc'),
  page: z.number().default(1),
  show: z.number().default(50),
})

export const BatchFilterSchema = z.object({
  locations: z.string().array().default([]),
  providers: z.string().array().default([]),
  statuses: z.string().array().default([]),
  orderBy: z.string().default('number'),
  sortOrder: z.enum(['asc', 'desc']).default('desc'),
  page: z.number().default(1),
  show: z.number().default(50),
})

export type StaffDashboardFilter =
  'highPriority'
  | 'noStatusIn3Months'
  | 'redFlag'
  | 'settledCheckOnTheWay'
  | 'settledReductions'
  | 'settledUnpaid'
  | 'settledPartiallyPaid'
  | 'pendingReplacement'
  | 'unknown'
  | 'goodStanding'
  | 'all'

export type AccidentFilter = z.infer<typeof AccidentFilterSchema>
export type BatchFilter = z.infer<typeof BatchFilterSchema>
