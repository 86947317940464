/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useLocalSearchParams } from 'expo-router'
import { ActivityIndicator, NotFound, Panel, useTheme, Wrapper } from 'ui'
import { Head } from '@/components/web/Head'
import { useAccident } from '@/queries/accident.queries'
import { AccidentList, AccidentViewer } from '@/screens/accidents/AccidentViewer'

export function AccidentDetails({ list }: { list: AccidentList | undefined }) {
  const params = useLocalSearchParams<{ accidentId?: string }>()
  const theme = useTheme()

  const accident = useAccident(Number(params.accidentId))

  const styles = {
    loading: css({
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }

  return <>
    <Head title='Case Viewer' />

    {accident.isPending ? (
      <div css={styles.loading}><ActivityIndicator /></div>
    ) : (
      <Wrapper maxWidth={1200} style={{ marginVertical: theme.spacing * 1.5 }}>
        {accident.data ? (
          <AccidentViewer accident={accident.data} list={list} />
        ) : (
          <Panel style={{ paddingHorizontal: theme.spacing }}>
            <NotFound item='Case' style={{ marginVertical: theme.spacing * 3 }} />
          </Panel>
        )}
      </Wrapper>
    )}
  </>
}
