/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { colorWithBrightness, colorWithOpacity, useTheme } from 'ui'

type Props = {
  height?: number
  prev: {
    disabled?: boolean
    onClick: () => void
  }
  next: {
    disabled?: boolean
    onClick: () => void
  }
}

export function NavButtons({ height: propHeight, prev, next }: Props) {
  const theme = useTheme()

  const height = propHeight ?? theme.button.compact.height
  const iconSize = height / 2.35

  const styles = {
    nav: css({
      display: 'flex',
      height,
      background: theme.colors.surface,
      border: `1px solid ${theme.input.border}`,
      boxShadow: theme.web.shadow.input,
      borderRadius: theme.borderRadius.s,
      cursor: 'pointer',
      overflow: 'hidden',
    }),
    button: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `0 ${height * 0.24}px`,
      background: theme.colors.surface,
      border: 'none',
      cursor: 'inherit',
      ':disabled': {
        opacity: 0.5,
        cursor: 'auto',
      },
      ':not(:disabled):hover': {
        background: colorWithBrightness(theme.colors.surface, -5),
      },
    }),
    divider: css({
      width: 1,
      background: theme.colors.divider,
    }),
  }

  return (
    <div css={styles.nav}>
      <button
        disabled={prev.disabled}
        css={styles.button}
        onClick={prev.onClick}
      >
        <FontAwesomeIcon
          icon={['far', 'angle-left']}
          size={iconSize}
          color={colorWithOpacity(theme.colors.foreground, 0.9)}
        />
      </button>
      <div css={styles.divider} />
      <button
        disabled={next.disabled}
        css={styles.button}
        onClick={next.onClick}
      >
        <FontAwesomeIcon
          icon={['far', 'angle-right']}
          size={iconSize}
          color={colorWithOpacity(theme.colors.foreground, 0.9)}
        />
      </button>
    </div>
  )
}
