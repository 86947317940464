import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { StyleSheet, View, ViewProps } from 'react-native'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

export function Placeholder({ height, ...props }: { height: number } & ViewProps) {
  const theme = useTheme()

  const styles = StyleSheet.create({
    outer: {
      height,
      backgroundColor: theme.colors.background,
    },
    inner: {
      flex: 1,
      backgroundColor: colorWithOpacity(theme.colors.foreground, 0.15),
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  return (
    <View {...props} style={[styles.outer, props.style]}>
      <View style={styles.inner}>
        <FontAwesomeIcon
          icon={faImage}
          color={colorWithOpacity(theme.colors.foreground, 0.15)}
          size={Math.min(height / 2, 120)}
        />
      </View>
    </View>
  )
}
