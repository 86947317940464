import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { router, Stack } from 'expo-router'
import { StyleSheet, View, ViewProps } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Button } from './Button'
import { Spacing } from './Spacing'
import { Text } from './Text'

const ICON_SIZE = 70

type Props = ViewProps & {
  item?: string
}

export function NotFound({ item, ...props }: Props) {
  const insets = useSafeAreaInsets()
  const theme = useTheme()

  const styles = StyleSheet.create({
    wrapper: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      borderRadius: ICON_SIZE / 2,
      backgroundColor: colorWithOpacity(theme.colors.warning, 0.18),
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  return <>
    <Stack.Screen options={{ title: 'Not Found' }} />

    <View {...props} style={[styles.wrapper, props.style]}>
      <View style={styles.icon}>
        <FontAwesomeIcon
          icon={faExclamation}
          size={ICON_SIZE / 2.2}
          color={theme.colors.warning}
        />
      </View>
      <Spacing height={2} />
      <Text type='subheading'>{item ? item + ' ' : ''}Not Found</Text>
      <Spacing height={2} />
      <Button
        text='Go Back'
        type='primary'
        style={{ minWidth: 180 }}
        onPress={() => {
          if (router.canGoBack()) {
            router.back()
          } else {
            router.replace('/')
          }
        }}
      />
      <View style={{ height: insets.bottom }} />
    </View>
  </>
}
