import { ReactNode } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { Easing } from 'react-native-reanimated'
import { colorWithOpacity, Theme, ThemeContext } from 'ui'

export function ThemeProvider(props: { children: ReactNode }) {
  const { width } = useWindowDimensions()

  const foregroundColor = '#000000'

  const breakpoints = {
    xs: 550,
    s: 768,
    m: 992,
    l: 1100,
  }

  const theme: Theme = {
    colors: {
      foreground: foregroundColor,
      background: '#FFFFFF',
      pageBackground: '#FAFAFA',
      primary: '#4C4C4C',
      accent: '#EC1D23',
      success: '#52BD56',
      warning: '#EA9700',
      danger: '#EB3434',
      info: '#479dfc',
      surface: '#FFFFFF',
      transparencyBase: '#FFFFFF',
      divider: Platform.OS === 'web' ? '#E6E6E6' : '#B3B3B3',
      headerBackground: '#F5F5F5',
      panelBorder: '#E6E6E6',
    },
    fonts: {
      body: {
        400: 'Inter400',
        500: 'Inter500',
        600: 'Inter600',
        700: 'Inter700',
      },
      mono: 'Roboto Mono',
    },
    spacing: width >= breakpoints.l ? 20 : width >= breakpoints.s ? 19 : 18,
    gap: width >= breakpoints.l ? 22 : width >= breakpoints.s ? 21 : 18,
    borderRadius: {
      s: 7,
      m: 11,
      l: 15,
    },
    breakpoints,
    easing: {
      curveLinearToEaseOut: Easing.bezierFn(0.35, 0.91, 0.33, 0.97),
    },
    input: {
      height: 46,
      fontFamily: 'Inter400',
      fontSize: 15.5,
      placeholder: colorWithOpacity(foregroundColor, 0.55),
      border: colorWithOpacity(foregroundColor, 0.14),
      borderHover: colorWithOpacity(foregroundColor, 0.24),
      shadow: {
        shadowColor: colorWithOpacity('#000000', 0.15),
        shadowOffset: { width: 0, height: 1 },
        shadowRadius: 2,
        shadowOpacity: 0.3,
        elevation: 2,
      },
      compact: {
        height: 40,
        fontSize: 15,
      },
    },
    button: {
      height: 48,
      fontFamily: 'Inter600',
      fontSize: 16,
      borderRadius: 8,
      compact: {
        height: 40,
        fontFamily: 'Inter400',
        fontSize: 15,
        borderRadius: 7,
      },
    },
    dialog: {
      fontSize: 17,
    },
    web: {
      easing: {
        curveLinearToEaseOut: 'cubic-bezier(0.35, 0.91, 0.33, 0.97)',
      },
      shadow: {
        input: '0 1px 2px rgba(0, 0, 0, 0.04)',
        panel: '0 2px 3px rgba(0, 0, 0, 0.04)',
        overlay: `0 0 0 1px  rgba(0, 0, 0, 0.03),
          0 5px 15px 0  rgba(0, 0, 0, 0.06),
          0 15px 35px 0  rgba(0, 0, 0, 0.07)`,
      },
    },
  }

  return <ThemeContext.Provider value={theme} {...props} />
}
