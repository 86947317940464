import * as Sentry from '@sentry/react-native'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import * as Crypto from 'expo-crypto'
import { router, useLocalSearchParams } from 'expo-router'
import { usePostHog } from 'posthog-react-native'
import { Platform } from 'react-native'
import { AuthModule } from 'ui'
import z from 'zod'
import { api } from '@/queries/api'
import { keys as userKeys } from '@/queries/user.queries'

export function useSignInWithEmail() {
  return useMutation({
    mutationFn: async ({ email, redirect }: { email: string, redirect: string | undefined }) => {
      const res = await api.post('auth/access', { email, redirect })
      return !!res.data.isUser
    },
  })
}

export function useSignInWithCode() {
  const signInSuccess = useSignInSuccess()

  return useMutation({
    mutationFn: async (code: string) => {
      const time = (new Date()).toISOString().replace(/\D/g, '').substring(0, 14)
      const burp = (await Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA1,
        `${time}-${code}-${time.substring(1)}`
      )).substring(0, 8)
      const slug = `${time}-${code}-${burp}`

      return api.post('auth/signin', { slug })
    },
    onSuccess: () => setTimeout(() => signInSuccess(), 300),
  })
}

export function useSignInWithSlug() {
  const signInSuccess = useSignInSuccess()

  return useMutation({
    mutationFn: async (slug?: string) => api.post('auth/signin', { slug }),
    onSuccess: () => signInSuccess(),
  })
}

export function useSignInWithGoogle() {
  const signInSuccess = useSignInSuccess()

  return useMutation({
    mutationFn: async (idToken: string) => api.post('auth/google', { idToken }),
    onSuccess: (res) => {
      if (!res.data.form) signInSuccess()
    },
  })
}

export function useSignInAsTestUser() {
  const signInSuccess = useSignInSuccess()

  return useMutation({
    mutationFn: (email: string) => api.post('auth/dev', { email }),
    onSuccess: () => signInSuccess(),
  })
}

export function useSignOut() {
  const queryClient = useQueryClient()
  const posthog = usePostHog()

  return useMutation({
    mutationFn: async () => {
      const res = await api.post('auth/signout')
      return z.object({ adminNoLongerProxying: z.boolean() }).parse(res.data)
    },
    onSuccess: ({ adminNoLongerProxying }) => {
      if (adminNoLongerProxying) {
        queryClient.clear()
        router.replace('/')
      } else {
        AuthModule.signOut()
        queryClient.setQueryData(userKeys.user(), undefined)
        Sentry.setUser(null)
        posthog?.reset()
        router.replace(Platform.OS === 'web' ? '/auth' : '/welcome')
      }
    },
  })
}

function useSignInSuccess() {
  const params = useLocalSearchParams<{ redirect?: string }>()
  const queryClient = useQueryClient()

  return () => {
    queryClient.clear()

    if (Platform.OS !== 'web') router.back()
    router.replace(params.redirect ? params.redirect : '/')
  }
}
