import { StyleSheet, View, ViewProps } from 'react-native'
import { ColorStatus, colorWithOpacity, useTheme } from '../hooks/useTheme'

type Props = ViewProps & {
  status: ColorStatus
  compact?: boolean
}

export function Message({ status, compact, style, children, ...props }: Props) {
  const theme = useTheme()

  const backgroundColor = colorWithOpacity(theme.colors[status], 0.12)
  const borderColor = colorWithOpacity(theme.colors[status], 0.3)

  const styles = StyleSheet.create({
    outer: {
      backgroundColor: theme.colors.transparencyBase,
      borderRadius: theme.borderRadius.m,
    },
    inner: {
      backgroundColor,
      borderWidth: 1,
      borderColor,
      borderRadius: theme.borderRadius.m,
      paddingHorizontal: theme.spacing * (compact ? 0.7 : 1),
      paddingVertical: theme.spacing * (compact ? 0.6 : 0.9),
    },
  })

  return (
    <View {...props} style={[styles.outer, style]}>
      <View style={styles.inner}>
        {children}
      </View>
    </View>
  )
}
