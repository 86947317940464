import { Image, ImageProps } from 'expo-image'

type Props = ImageProps & {
  vertical?: boolean
}

export function Logo({ vertical, style, ...props }: Props) {
  return (
    vertical ? (
      <Image
        {...props}
        source={require('assets/images/lienstar-portal-logo-vertical.svg')}
        contentFit='contain'
        style={[{ width: 202, height: 99 }, style]}
      />
    ) : (
      <Image
        {...props}
        source={require('assets/images/lienstar-portal-logo.svg')}
        contentFit='contain'
        style={[{ width: 199, height: 37 }, style]}
      />
    )
  )
}
