/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Select from 'react-select'
import { colorWithOpacity, useTheme } from '../../hooks/useTheme'
import { Text } from '../Text'

type TOptionShape = string | { value: string, label: string }

type Props<TOption extends TOptionShape> = {
  label?: string
  value: string[]
  options: TOption[]
  placeholder?: string
  noOptions?: string
  compact?: boolean
  loading?: boolean
  disabled?: boolean
  clearable?: boolean
  closeMenuOnSelect?: boolean
  onChange: (value: string[]) => void
}

export function MultiSelect<TOption extends TOptionShape>({
  label,
  value: propValue,
  options: propOptions,
  placeholder,
  noOptions,
  compact,
  loading,
  disabled,
  clearable = false,
  closeMenuOnSelect = false,
  onChange,
}: Props<TOption>) {
  const theme = useTheme()

  const value = propValue.map((v) => {
    const option = propOptions.find((o) => typeof o === 'string' ? o === v : o.value === v)
    return {
      value: v,
      label: option === undefined || typeof option === 'string' ? option : option.label,
    }
  })
  const options = propOptions.map((o) => ({
    value: typeof o === 'string' ? o : o.value,
    label: typeof o === 'string' ? o : o.label,
  }))
  const height = compact ? theme.input.compact.height : theme.input.height

  const styles = {
    icon: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    })
  }

  return (
    <div>
      {label && <Text type='inputLabel' compact={compact}>{label}</Text>}

      <Select
        value={value}
        options={options}
        isMulti={true}
        placeholder={placeholder}
        noOptionsMessage={() => noOptions}
        isLoading={loading}
        isDisabled={disabled}
        isClearable={clearable}
        closeMenuOnSelect={closeMenuOnSelect}
        maxMenuHeight={350}
        components={{
          DropdownIndicator: ({ innerProps }) => (
            <div {...innerProps} css={styles.icon}>
              <FontAwesomeIcon
                icon={faAngleDown}
                size={compact ? 17 : 19}
                color={colorWithOpacity(theme.colors.foreground, 0.7)}
                style={{
                  marginLeft: height * 0.2,
                  marginRight: height * 0.3 - 1,
                }}
              />
            </div>
          ),
          ClearIndicator: ({ innerProps }) => (
            <div {...innerProps} css={styles.icon}>
              <FontAwesomeIcon
                icon={faXmark}
                size={compact ? 16 : 18}
                color={colorWithOpacity(theme.colors.foreground, 0.7)}
                style={{
                  marginHorizontal: height * 0.2,
                }}
              />
            </div>
          ),
          MultiValueRemove: ({ innerProps }) => (
            <div {...innerProps}>
              <FontAwesomeIcon
                icon={faXmark}
                size={compact ? 12 : 14}
                color={colorWithOpacity(theme.colors.foreground, 0.7)}
              />
            </div>
          )
        }}
        styles={{
          container: (base) => ({
            ...base,
            fontFamily: theme.input.fontFamily,
            fontSize: compact ? theme.input.compact.fontSize : theme.input.fontSize,
          }),
          control: (base, props) => ({
            ...base,
            minHeight: height,
            borderColor: props.isFocused ? theme.colors.primary : theme.input.border,
            borderRadius: theme.borderRadius.s,
            boxShadow: theme.web.shadow.input,
            outline: 'none',
            transition: 'none',
            cursor: 'pointer',
            ':hover': {
              borderColor: props.isFocused ? theme.colors.primary : theme.input.borderHover,
            },
          }),
          valueContainer: (base) => ({
            ...base,
            padding: `4px 9px`,
          }),
          input: (base) => ({
            ...base,
            color: theme.colors.foreground,
          }),
          placeholder: (base) => ({
            ...base,
            color: theme.input.placeholder,
          }),
          indicatorSeparator: (base) => ({
            ...base,
            backgroundColor: theme.colors.divider,
          }),
          menu: (base) => ({
            ...base,
            borderRadius: theme.borderRadius.s,
            boxShadow: theme.web.shadow.overlay,
          }),
          menuList: (base) => ({
            ...base,
            padding: 4,
          }),
          option: (base, props) => ({
            ...base,
            fontSize: 14,
            padding: '8px 10px',
            borderRadius: theme.borderRadius.s - 2,
            background: props.isSelected ? theme.colors.primary
              : props.isFocused ? colorWithOpacity(theme.colors.foreground, 0.07) : undefined,
            color: props.isSelected ? '#FFFFFF' : theme.colors.foreground,
            cursor: 'pointer',
            ':active': {
              background: colorWithOpacity(theme.colors.foreground, 0.1),
            },
          }),
          multiValue: (base) => ({
            ...base,
            borderRadius: theme.borderRadius.s - 2,
            background: colorWithOpacity(theme.colors.foreground, 0.1),
            overflow: 'hidden',
          }),
          multiValueLabel: (base) => ({
            ...base,
            fontFamily: theme.fonts.body[500],
            fontSize: 12.5,
          }),
          multiValueRemove: (base) => ({
            ...base,
            borderRadius: 0,
            ':hover': {
              background: colorWithOpacity(theme.colors.foreground, 0.05),
            },
          }),
          noOptionsMessage: (base) => ({
            ...base,
            color: colorWithOpacity(theme.colors.foreground, 0.8),
            fontSize: 14,
            textAlign: 'left',
          }),
        }}
        onChange={(value) => onChange(value.map((v) => v.value))}
      />
    </div>
  )
}
