import * as Sentry from '@sentry/react-native'
import { Tabs } from 'expo-router'
import { usePostHog } from 'posthog-react-native'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import { CustomStack, TabBar, TabBarIcon } from 'ui'
import { NavHeader } from '@/components/web/NavHeader'
import { useStore } from '@/providers/store'
import { useUser } from '@/queries/user.queries'

export default function AppLayout() {
  const setAccidentFilter = useStore.useSetAccidentFilter()
  const clearAccidentFilter = useStore.useClearAccidentFilter()
  const setBatchFilter = useStore.useSetBatchFilter()
  const clearBatchFilter = useStore.useClearBatchFilter()

  const user = useUser()
  const posthog = usePostHog()

  useEffect(() => {
    if (user.data?.id) {
      Sentry.setUser({ id: user.data.id })
      posthog?.identify(user.data.id.toString(), { email: user.data.email })
    }
  }, [user.data?.id, user.data?.email, posthog])

  useEffect(() => {
    if (user.data?.filter?.accident) {
      setAccidentFilter(user.data.filter.accident)
    } else {
      clearAccidentFilter()
    }
  }, [setAccidentFilter, clearAccidentFilter, user.data?.filter])

  useEffect(() => {
    if (user.data?.filter?.batch) {
      setBatchFilter(user.data.filter.batch)
    } else {
      clearBatchFilter()
    }
  }, [setBatchFilter, clearBatchFilter, user.data?.filter])

  if (Platform.OS === 'web') {
    return <>
      <NavHeader />
      <CustomStack screenOptions={{ headerShown: false }} />
    </>
  }
  return (
    <TabBar>
      <Tabs.Screen
        name='(home)'
        options={{
          tabBarLabel: 'Home',
          tabBarIcon: ({ color }) => <TabBarIcon icon={['fas', 'house']} size={22} color={color} />,
        }}
      />
    </TabBar>
  )
}
