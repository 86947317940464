import dayjs, { Dayjs } from 'dayjs'
import { Finance } from 'financejs'
import numeral from 'numeral'
import { Theme } from 'ui'
import { Cashflow } from '@/types/accident'

export const format = {
  date: (value: Dayjs, options?: { iso?: boolean, relative?: boolean }) => {
    let { iso = false, relative = false } = options ?? {}

    if (relative) {
      const now = dayjs()
      if (now.diff(value, 'hours') <= 24) return 'Today'
      if (now.diff(value, 'hours') <= 48) return 'Yesterday'
      if (value.year() === now.year()) return value.format('MMM Do')
      return value.format('MMM Do, YYYY')
    }

    return value.format(iso ? 'YYYY-MM-DD' : 'M/D/YYYY')
  },
  time: (value: Dayjs) => {
    return value.format('h:mm A')
  },
  currency: (value: number, options?: { cents?: boolean, editable?: boolean }) => {
    const { cents = true, editable = false } = options ?? {}

    let format = editable ? '0' : '$0,0'
    format = cents ? format + '.00' : format
    return numeral(value / 100).format(format)
  },
  number: (value: number) => {
    return numeral(value).format('0,0')
  },
  field: (value: string) => {
    switch (value) {
      case 'dob': return 'DOB'
      case 'doa': return 'DOA'
      default: return value.replace(/([A-Z])/g, ' $1').toLowerCase()
    }
  },
}

export function calculateROI(collected: number, cost: number) {
  return Math.round(collected / cost * 100) / 100
}

export function calculateIRR(cashflow: Cashflow) {
  try {
    const finance = new Finance()
    const sorted = cashflow.sort((a, b) => a.date.valueOf() - b.date.valueOf())
    // FIXME: finance.XIRR() not defined in finance.d.ts: https://github.com/ebradyjobory/finance.js/pull/46
    const IRR = finance.XIRR(sorted.map((e) => e.amount), sorted.map((e) => e.date.toDate()), 0.5)
    return Number.isNaN(IRR) || !Number.isFinite(IRR) ? 0 : Math.round(IRR)
  } catch {
    return 0
  }
}

export function colorFromROI(ROI: number, theme: Theme) {
  return ROI > 1.9 ? theme.colors.success : ROI >= 1.5 ? theme.colors.warning : theme.colors.danger
}

export function colorFromIRR(IRR: number, theme: Theme) {
  return IRR > 50 ? theme.colors.success : IRR >= 25 ? theme.colors.warning : theme.colors.danger
}

export function colorFromROIAndIRR(ROI: number, IRR: number, theme: Theme) {
  const ROIColor = colorFromROI(ROI, theme)
  const IRRColor = colorFromIRR(IRR, theme)

  return [ROIColor, IRRColor].includes(theme.colors.success) ? theme.colors.success
    : [ROIColor, IRRColor].includes(theme.colors.warning) ? theme.colors.warning : theme.colors.danger
}
