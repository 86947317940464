import dayjs from 'dayjs'
import { z } from 'zod'
import { Constants } from '../constants'
import { AccidentPreviewSchema, missingAnyDocuments, MissingDocumentsSchema, PatientSchema, TransactionSchema } from './accident'
import { ChargeStatusSchema } from './charge-status'
import { LawFirmContactSchema, LawFirmSchema } from './law-firm'
import { ProcedureSchema } from './procedure'
import { ProviderSchema } from './provider'
import { UserSchema } from './user'

const MatchedAccidentSchema = AccidentPreviewSchema.extend({
  lawFirm: LawFirmSchema.nullable(),
  lawFirmContact: LawFirmContactSchema.nullable(),
  policyLimitAmount: z.number().nullable().transform((v) => v ?? 0),
  policyLimitPercentage: z.number().nullable(),
  transactions: TransactionSchema.array(),
  missingDocuments: MissingDocumentsSchema.nullable(),
}).transform((accident) => ({
  ...accident,
  missingAnyDocuments: missingAnyDocuments(accident.missingDocuments),
}))

export const ChargeSchema = z.object({
  id: z.number(),
  accident: MatchedAccidentSchema.nullable(),
  transaction: TransactionSchema.nullable(),
  status: ChargeStatusSchema.nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  dob: z.string().nullable().transform((v) => v ? dayjs.utc(v) : null),
  doa: z.string().nullable().transform((v) => v ? dayjs.utc(v) : null),
  dos: z.string().nullable().transform((v) => v ? dayjs.utc(v) : null),
  procedure: ProcedureSchema.nullable(),
  invoice: z.number().nullable(),
  rate: z.number().nullable(),
  lawFirm: z.string().nullable(),
  contactName: z.string().nullable(),
  email: z.string().nullable(),
  phone: z.string().nullable(),
  insuranceCarrier: z.string().nullable(),
  policyLimit: z.number().nullable(),
  accidentNote: z.string().nullable(),
  transactionNote: z.string().nullable(),
  updatedAt: z.string().transform((v) => dayjs(v)),
})

export const BatchStatusSchema = z.enum([
  'Provider - Pending',
  'Provider - Review',
  'Staff - Review',
  'Manager - Review',
  'Executive - Review',
  'Approved',
  'Rejected',
])

const BatchEventSchema = z.object({
  user: UserSchema,
  status: BatchStatusSchema,
  note: z.string().nullable(),
  createdAt: z.string().transform((v) => dayjs(v)),
})

export const BatchListItemSchema = z.object({
  id: z.number(),
  provider: ProviderSchema,
  buy: z.number(),
  number: z.number(),
  status: BatchStatusSchema,
  lastStatus: z.string().nullable().transform((v) => v ? dayjs(v) : null),
})

export const BatchSchema = BatchListItemSchema.extend({
  provider: ProviderSchema.extend({
    accounts: z.object({
      id: z.number(),
      target: z.number().nullable(),
      accident: z.object({
        patient: PatientSchema,
      }),
    }).array(),
  }),
  charges: ChargeSchema.array(),
  events: BatchEventSchema.array(),
}).transform((batch) => ({
  ...batch,
  rate: batch.provider.rate ?? Constants.defaultRate,
  pendingReplacementAccounts: batch.provider.accounts,
  isComplete: batch.status === 'Approved' || batch.status === 'Rejected',
}))

export type Charge = z.infer<typeof ChargeSchema> & { matchedAccidentId?: number }
export type NewCharge = Omit<Charge, 'id' | 'accident' | 'transaction' | 'status' | 'rate' | 'updatedAt'>
export type BatchStatus = z.infer<typeof BatchStatusSchema>
export type BatchListItem = z.infer<typeof BatchListItemSchema>
export type Batch = z.infer<typeof BatchSchema>
