/** @jsxImportSource @emotion/react */
import 'handsontable/dist/handsontable.full.min.css'
import { css, Global } from '@emotion/react'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlass'
import { faTableList } from '@fortawesome/pro-solid-svg-icons/faTableList'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { HotTable, HotTableClass } from '@handsontable/react'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import excel from 'exceljs'
import { Link, router, useLocalSearchParams } from 'expo-router'
import Handsontable from 'handsontable'
import { ContextMenu } from 'handsontable/plugins'
import { registerAllModules } from 'handsontable/registry'
import camelCase from 'lodash/camelCase'
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import { Dispatch, Fragment, RefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { Toast } from 'react-native-toast-universal'
import { ActivityIndicator, Button, colorWithBrightness, colorWithOpacity, EditableValue, Feedback, Menu, MenuItem, Message, MultiSelect, NotFound, Panel, Select, Spacing, Table, Text, Tooltip, useTheme, Wrapper } from 'ui'
import z from 'zod'
import { Head } from '@/components/web/Head'
import { TextArea, TextInput } from '@/components/web/TextInput'
import { Constants } from '@/constants'
import { useStore } from '@/providers/store'
import { keys as accidentKeys, useAccident, useUpdateAccident, useUpdatePatient } from '@/queries/accident.queries'
import { keys as batchKeys, useBatch, useCreateAccidentFromCharge, useDeleteBatch, useDeleteCharges, useMatchCharge, useUpdateAssignedAccountsForReplacementTransaction, useUpdateBatch, useUpdateBatchNumber, useUpdateCharges } from '@/queries/batch.queries'
import { alertError, fieldErrors } from '@/queries/client'
import { useUser } from '@/queries/user.queries'
import { AccidentViewer } from '@/screens/accidents/AccidentViewer'
import { BatchStatusBadge } from '@/screens/batches/BatchStatusBadge'
import { Accident } from '@/types/accident'
import { Batch, BatchStatus, Charge, NewCharge } from '@/types/batch'
import { ChargeStatus } from '@/types/charge-status'
import { ProcedureSchema } from '@/types/procedure'
import { format } from '@/utils'

type Totals = {
  unmatched: number
  missingDocuments: number | undefined
  policyLimit: {
    over: number
    missing: number
  } | undefined
  changes: number | undefined
  approved: number
  sameDos: number
  invoices: number
  cost: number
  replacementInvoices: number
}

type ChargeStatusOption = ChargeStatus | 'All Charges' | 'Changed'

export function BatchDetails() {
  const params = useLocalSearchParams<{ batchId?: string }>()
  const theme = useTheme()

  const batch = useBatch(Number(params.batchId))

  const styles = {
    loading: css({
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }

  return <>
    <Head title='Funding Request' />

    {batch.isPending ? (
      <div css={styles.loading}><ActivityIndicator /></div>
    ) : batch.data ? (
      <BatchViewer batch={batch.data} />
    ) : (
      <Wrapper maxWidth={1200} style={{ marginVertical: theme.spacing * 1.5 }}>
        <Panel>
          <NotFound item='Funding Request' style={{ marginVertical: theme.spacing * 3 }} />
        </Panel>
      </Wrapper>
    )}
  </>
}

function BatchViewer({ batch }: { batch: Batch }) {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const modal = useStore.useModal()

  const user = useUser()

  const updateCharges = useUpdateCharges()
  const deleteCharges = useDeleteCharges()

  const [selectedCharge, setSelectedCharge] = useState<Charge>()
  const [status, setStatus] = useState<ChargeStatusOption>('All Charges')
  const [wideLayout, setWideLayout] = useState(() => {
    const savedState = localStorage.getItem('wideLayout')
    return savedState !== null ? JSON.parse(savedState) : false
  })

  const spreadsheetRef = useRef<HotTableClass>(null)

  const charges = approvedCharges(batch.charges)

  const unmatched = charges.filter((c) => !c.accident).length
  const missingDocuments = charges.filter((c) => {
    if (c.accident) {
      return c.accident.missingAnyDocuments ? c.accident : undefined
    } else {
      return undefined
    }
  }).length
  let policyLimitOver = 0
  let policyLimitMissing = 0
  let changes = 0
  let sameDos = 0
  for (const charge of charges) {
    if (charge.accident) {
      if (charge.accident.policyLimitAmount === 0) {
        policyLimitMissing++
      } else {
        for (const account of charge.accident.accounts) {
          let policyLimitPercentage = Constants.defaultPolicyLimitPercentage
          if (account?.policyLimitPercentage) {
            policyLimitPercentage = account.policyLimitPercentage
          } else if (charge.accident.policyLimitPercentage) {
            policyLimitPercentage = charge.accident.policyLimitPercentage
          }

          const transactions = charge.accident.transactions.filter((t) => t.accountId === account.id)
          const cost = transactions.reduce((sum, t) => sum + (t.cost ?? 0), 0)
          const policyLimitValue = Math.round(cost / charge.accident.policyLimitAmount * 100)

          if (policyLimitValue > policyLimitPercentage) policyLimitOver++
        }
      }

      if (!batch.isComplete) {
        for (const transaction of charge.accident.transactions.filter((t) => t.providerId === batch.provider.id)) {
          if (charge.id === transaction.charge?.id) continue
          if (charge.dos && charge.dos.isSame(transaction.dos, 'date')) sameDos++
        }
      }
    }

    const lastReviewed = batch.events.findLast((e) => e.user.role === user.data?.role)
    if (lastReviewed && charge.updatedAt.isAfter(lastReviewed.createdAt)) changes++
  }

  const totals: Totals = {
    unmatched,
    missingDocuments: unmatched === batch.charges.length ? undefined : missingDocuments,
    policyLimit: unmatched === batch.charges.length ? undefined : {
      over: policyLimitOver,
      missing: policyLimitMissing,
    },
    changes: unmatched === batch.charges.length ? undefined : changes,
    approved: charges.length,
    sameDos,
    invoices: charges.filter((c) => c.status !== 'Replacement').reduce((sum, c) => sum + (c.invoice ?? 0), 0),
    cost: charges.filter((c) => c.status !== 'Replacement').reduce((sum, c) => sum + ((c.invoice ?? 0) * (c.rate ?? batch.rate) / 100), 0),
    replacementInvoices: charges.filter((c) => c.status === 'Replacement').reduce((sum, c) => sum + (c.invoice ?? 0), 0),
  }

  let locked = false
  if (batch.status !== 'Provider - Pending') {
    switch (user.data?.role) {
      case 'provider': {
        if (batch.status !== 'Provider - Review' || !user.data.canSubmit) locked = true
        break
      }
      case 'staff':
        if (batch.status !== 'Staff - Review') locked = true
        break
      case 'manager':
        if (batch.status !== 'Manager - Review') locked = true
        break
      case 'executive':
        if (batch.isComplete) locked = true
        break
    }
  }

  useEffect(() => {
    localStorage.setItem('wideLayout', JSON.stringify(wideLayout))
  }, [wideLayout])

  const styles = {
    outer: css({
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      margin: `${theme.spacing * 1.5}px ${theme.spacing}px`,
    }),
    inner: css({
      width: '100%',
      height: '100%',
      maxWidth: wideLayout ? undefined : 1200,
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing,
    }),
    panels: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 1.5,
      paddingBottom: theme.spacing * 1.5,
    }),
    startButtons: css({
      display: 'flex',
      columnGap: theme.spacing * 0.5,
    }),
  }

  return (
    <div css={styles.outer}>
      <div css={styles.inner}>
        <Header
          batch={batch}
          totals={totals}
          locked={locked}
          setSelectedCharge={setSelectedCharge}
          status={status}
          setStatus={setStatus}
          wideLayout={wideLayout}
          setWideLayout={setWideLayout}
          spreadsheetRef={spreadsheetRef}
        />

        <BatchNote batch={batch} />

        <div css={styles.panels}>
          <Panel>
            {batch.charges.length ? (
              <Spreadsheet
                spreadsheetRef={spreadsheetRef}
                batch={batch}
                totals={totals}
                locked={locked}
                status={status}
                selectedCharge={selectedCharge}
                onChange={async (charges) => {
                  updateCharges.mutate({ batch, charges }, {
                    onSuccess: (batch) => {
                      if (selectedCharge) {
                        setSelectedCharge(batch.charges.find((c) => c.id === selectedCharge.id))
                        queryClient.invalidateQueries({ queryKey: batchKeys.matchCharge(selectedCharge.id) })
                      }
                      if (selectedCharge?.accident) {
                        queryClient.invalidateQueries({ queryKey: accidentKeys.accident(selectedCharge.accident.id) })
                      }
                    },
                  })
                }}
                onDelete={async (charges) => {
                  deleteCharges.mutate({ batch, charges })
                  setSelectedCharge(undefined)
                }}
                onChargeSelected={(charge) => setSelectedCharge(charge)}
              />
            ) : (
              <Feedback
                status='info'
                icon={faTableList}
                body='Import charges from Excel or enter them manually'
                maxWidth={200}
                compact={true}
                style={{ paddingVertical: theme.spacing * 4 }}
                action={(
                  <div css={styles.startButtons}>
                    <Button
                      text='Import from Excel'
                      type='tertiary'
                      compact={true}
                      textLeft={(
                        <FontAwesomeIcon
                          icon={['far', 'file-import']}
                          size={15}
                          color={colorWithOpacity(theme.colors.foreground, 0.9)}
                          style={{ marginRight: 8 }}
                        />
                      )}
                      disabled={locked}
                      onPress={() => modal.open({
                        title: 'Import from Excel',
                        content: <FileImporter batch={batch} />,
                      })}
                    />
                    <Button
                      text='Enter Manually'
                      type='tertiary'
                      compact={true}
                      textLeft={(
                        <FontAwesomeIcon
                          icon={['far', 'table-list']}
                          size={15}
                          style={{ marginRight: 8 }}
                        />
                      )}
                      disabled={locked}
                      onPress={() => {
                        const charges: Charge[] = []
                        for (let i = 0; i < 5; i++) {
                          charges.push({} as Charge)
                        }
                        updateCharges.mutate({ batch, charges })
                      }}
                    />
                  </div>
                )}
              />
            )}
          </Panel>

          {batch.charges.length > 0 && (
            <Wrapper maxWidth={1200} margin={false}>
              {selectedCharge?.accident ? (
                <AccidentDetails
                  accidentId={selectedCharge.accident.id}
                  batch={batch}
                  onNav={(accidentId) => {
                    const charge = batch.charges.find((c) => c.accident?.id === accidentId)
                    if (charge) setSelectedCharge(charge)
                  }}
                  onUpdate={async (accident) => {
                    const charges = cloneDeep(batch.charges)
                    for (const charge of charges) {
                      if (charge.accident?.id === accident.id) {
                        charge.doa = accident.doa
                        charge.insuranceCarrier = accident.insuranceCarrier
                        charge.policyLimit = accident.policyLimitAmount
                      }
                      if (charge.accident?.patient.id === accident.patient.id) {
                        charge.firstName = accident.patient.firstName
                        charge.lastName = accident.patient.lastName
                        charge.dob = accident.patient.dob
                      }
                      if (charge.accident?.lawFirm?.id === accident.lawFirm?.id) {
                        charge.lawFirm = accident.lawFirm?.name ?? null
                      }
                      if (charge.accident?.lawFirmContact?.id === accident.lawFirmContact?.id) {
                        charge.contactName = accident.lawFirmContact?.name ?? null
                        charge.email = accident.lawFirmContact?.email ?? null
                        charge.phone = accident.lawFirmContact?.phone ?? null
                      }
                    }

                    const updatedCharges = differenceWith(charges, batch.charges, isEqual)
                    updateCharges.mutate({ batch, charges: updatedCharges })
                  }}
                />
              ) : !locked ? (
                <Panel>
                  {selectedCharge?.id && (selectedCharge.firstName || selectedCharge.lastName || selectedCharge.dob || selectedCharge.doa) ? (
                    <ChargeMatcher
                      batch={batch}
                      charge={selectedCharge}
                      onMatch={async (batch) => {
                        setSelectedCharge(batch.charges.find((c) => c.id === selectedCharge.id))
                      }}
                      onCreateAccident={(batch) => {
                        setSelectedCharge(batch.charges.find((c) => c.id === selectedCharge.id))
                      }}
                    />
                  ) : (
                    <Feedback
                      status='info'
                      icon={faTableList}
                      body='Select a row above to view more details'
                      maxWidth={180}
                      compact={true}
                      style={{ paddingVertical: theme.spacing * 5 }}
                    />
                  )}
                </Panel>
              ) : null}
            </Wrapper>
          )}
        </div>
      </div>
    </div>
  )
}

function Header({
  batch,
  totals,
  locked,
  setSelectedCharge,
  status,
  setStatus,
  wideLayout,
  setWideLayout,
  spreadsheetRef,
}: {
  batch: Batch
  totals: Totals
  locked: boolean
  setSelectedCharge: Dispatch<SetStateAction<Charge | undefined>>
  status: ChargeStatusOption
  setStatus: Dispatch<SetStateAction<ChargeStatusOption>>
  wideLayout: boolean
  setWideLayout: Dispatch<SetStateAction<boolean>>
  spreadsheetRef: RefObject<HotTableClass>
}) {
  const modal = useStore.useModal()
  const alert = useStore.useAlert()
  const theme = useTheme()

  const user = useUser()
  const updateBatchNumber = useUpdateBatchNumber()
  const deleteBatch = useDeleteBatch()

  const lastReviewed = batch.events.findLast((e) => e.user.role === user.data?.role)

  const [buy, setBuy] = useState(batch.buy.toString())
  const [number, setNumber] = useState(batch.number.toString())
  const [dirty, setDirty] = useState({
    buy: false,
    number: false,
  })
  const [prevBatch, setPrevBatch] = useState(batch)
  if (!isEqual(batch, prevBatch)) {
    setBuy(batch.buy.toString())
    setNumber(batch.number.toString())
    setDirty({
      buy: false,
      number: false,
    })
    setPrevBatch(batch)
  }

  const styles = {
    header: css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing,
      flexWrap: 'wrap',
    }),
    heading: css({
      display: 'flex',
      flexDirection: 'column',
      columnGap: theme.spacing * 0.8,
      rowGap: theme.spacing * 0.4,
      [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      '.info': {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing * 0.8,
        rowGap: theme.spacing * 0.4,
        flexWrap: 'wrap',
      },
      '.text': {
        fontFamily: theme.fonts.body[400],
        fontSize: 15.5,
        opacity: 0.9,
      },
      '.divider': {
        display: 'inline-block',
        width: 1,
        height: 20,
        background: theme.colors.divider,
        ':first-child': {
          display: 'none',
          [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
            display: 'inline-block',
          },
        },
      },
    }),
    buttons: css({
      display: 'flex',
      gap: theme.spacing * 0.5,
      flexWrap: 'wrap',
    }),
  }

  return (
    <div css={styles.header}>
      <div css={styles.heading}>
        <EditableValue
          form={{
            fields: [
              <TextInput
                label='Buy Number'
                value={buy}
                compact={true}
                onChange={(e) => {
                  setBuy(e.target.value)
                  setDirty({ ...dirty, buy: true })
                }}
              />,
            ],
            loading: updateBatchNumber.isPending,
            onSubmit: async () => {
              if (!dirty.buy) return

              try {
                await updateBatchNumber.mutateAsync({ batch, buy })
                Toast.success('Buy number updated')
              } catch {
                return false
              }
            },
          }}
          disabled={user.data?.role !== 'executive'}
          transparentTooltip={false}
        >
          <Text type='heading'>Buy {batch.buy}</Text>
        </EditableValue>
        <div className='info'>
          <div className='divider' />
          <EditableValue
            form={{
              fields: [
                <TextInput
                  label='Batch Number'
                  value={number}
                  compact={true}
                  onChange={(e) => {
                    setNumber(e.target.value)
                    setDirty({ ...dirty, number: true })
                  }}
                />,
              ],
              loading: updateBatchNumber.isPending,
              onSubmit: async () => {
                if (!dirty.number) return

                try {
                  await updateBatchNumber.mutateAsync({ batch, number })
                  Toast.success('Batch number updated')
                } catch {
                  return false
                }
              },
            }}
            disabled={user.data?.role !== 'executive'}
            transparentTooltip={false}
          >
            <div className='text'>Batch {batch.number}</div>
          </EditableValue>
          {(user.data?.role !== 'provider' || user?.data?.providers.length > 1) && <>
            <div className='divider' />
            <div className='text'>{batch.provider.name}</div>
          </>}
          <div className='divider' />
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => modal.open({
              title: 'Timeline',
              content: <BatchTimeline batch={batch} />,
            })}
          >
            <BatchStatusBadge status={batch.status} />
          </div>
        </div>
      </div>

      <div css={styles.buttons}>
        {(() => {
          const items: MenuItem[] = [
            {
              value: 'Import from Excel',
              webIcon: ['far', 'file-import'],
              disabled: locked,
            },
            {
              value: wideLayout ? 'Minimize' : 'Maximize',
              webIcon: ['far', wideLayout ? 'compress' : 'expand'],
            },
          ]

          if (user.data?.role !== 'provider') {
            items.splice(1, 0, {
              value: 'Replacement Cases',
              webIcon: ['far', 'arrows-rotate'],
              disabled: locked,
            })
          }

          if (batch.status === 'Provider - Pending') {
            items.push({
              value: 'Delete',
              webIcon: ['far', 'trash'],
              destructive: true,
            })
          }

          return (
            <Menu
              items={items}
              onChange={(value) => {
                switch (value) {
                  case 'Import from Excel':
                    modal.open({
                      title: 'Import from Excel',
                      content: <FileImporter batch={batch} />,
                    })
                    break
                  case 'Replacement Cases':
                    modal.open({
                      title: 'Replacement Cases',
                      content: <ReplacementAccidents batch={batch} />,
                    })
                    break
                  case 'Maximize':
                  case 'Minimize':
                    setWideLayout(!wideLayout)
                    break
                  case 'Delete':
                    alert.open({
                      title: 'Delete Funding Request',
                      message: 'Do you really want to delete this funding request? This can not be undone.',
                      buttons: [
                        {
                          text: 'Cancel',
                          onPress: () => alert.close(),
                        },
                        {
                          text: 'Delete',
                          style: 'destructive',
                          onPress: () => {
                            deleteBatch.mutate({ batch }, {
                              onSuccess: () => {
                                alert.close()
                                Toast.success('Funding request deleted')
                                router.navigate('/funding-requests')
                              },
                            })
                          },
                        },
                      ],
                    })
                    break
                }
              }}
            >
              <Button
                text=''
                type='tertiary'
                compact={true}
                textLeft={(
                  <FontAwesomeIcon
                    icon={['far', 'ellipsis-vertical']}
                    size={18}
                    color={colorWithOpacity(theme.colors.foreground, 0.9)}
                    style={{ marginHorizontal: -1 }}
                  />
                )}
              />
            </Menu>
          )
        })()}
        {(batch.pendingReplacementAccounts.length > 0 && !batch.isComplete && user.data?.role !== 'provider') && (
          <Tooltip content={`${batch.pendingReplacementAccounts.length} Pending Replacement Case${batch.pendingReplacementAccounts.length === 1 ? '' : 's'}`}>
            <Button
              text=''
              type='tertiary'
              compact={true}
              textLeft={(
                <FontAwesomeIcon
                  icon={['fas', 'circle-info']}
                  size={18}
                  color={theme.colors.info}
                  style={{ marginHorizontal: -1 }}
                />
              )}
              onPress={() => modal.open({
                title: 'Replacement Cases',
                content: <ReplacementAccidents batch={batch} />,
              })}
            />
          </Tooltip>
        )}
        <Select
          value={status}
          options={[
            {
              value: 'All Charges',
              label: 'All Charges',
            },
            {
              value: 'On Hold',
              label: `On Hold (${batch.charges.filter((c) => c.status === 'On Hold').length})`,
            },
            {
              value: 'Rejected',
              label: `Rejected (${batch.charges.filter((c) => c.status === 'Rejected').length})`,
            },
            {
              value: 'Changed',
              label: `Changed (${batch.charges.filter((c) => lastReviewed ? c.updatedAt.isAfter(lastReviewed.createdAt) : undefined).length})`,
            },
            {
              value: 'Replacement',
              label: `Replacement (${batch.charges.filter((c) => c.status === 'Replacement').length})`,
            },
          ]}
          compact={true}
          onChange={(value) => {
            setStatus(value as ChargeStatusOption)
            setSelectedCharge(undefined)
          }}
        />
        {user.data && user.data.role === 'provider' ? (
          <Button
            text='Submit'
            type='primary'
            compact={true}
            fontFamily={theme.fonts.body[500]}
            style={{ minWidth: 120 }}
            disabled={batch.charges.length === 0 || locked}
            onPress={() => modal.open({
              title: 'Submit Funding Request',
              content: (
                <Submit
                  batch={batch}
                  totals={totals}
                  canSubmit={user.data.canSubmit}
                  spreadsheetRef={spreadsheetRef}
                  onSuccess={() => setSelectedCharge(undefined)}
                />
              ),
            })}
          />
        ) : (
          <Button
            text='Update Status'
            type='primary'
            compact={true}
            fontFamily={theme.fonts.body[500]}
            style={{ minWidth: 120 }}
            disabled={batch.charges.length === 0 || locked}
            onPress={() => modal.open({
              title: 'Update Status',
              content: (
                <UpdateStatus
                  batch={batch}
                  totals={totals}
                  spreadsheetRef={spreadsheetRef}
                  onSuccess={() => setSelectedCharge(undefined)}
                />
              ),
            })}
          />
        )}
      </div>
    </div>
  )
}

function FileImporter({ batch }: { batch: Batch }) {
  const COLUMNS = [
    'First Name',
    'Last Name',
    'Date of Birth',
    'DOA',
    'DOS',
    'Procedure',
    'Invoice',
    'Law Firm',
    'Contact Name',
    'Email',
    'Phone',
    'Insurance Carrier',
    'Policy Limit',
    'Note (Case)',
    'Note (Transaction)',
  ] as const
  type Column = typeof COLUMNS[number]

  const modal = useStore.useModal()
  const theme = useTheme()

  const updateCharges = useUpdateCharges()

  const [error, setError] = useState<string>()

  const dropzoneRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const supportedFileTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ]

  async function handleFile(file: File) {
    if (!supportedFileTypes.includes(file.type)) {
      Toast.error('Only Excel files (.xlsx) are supported')
      return
    }

    const workbook = new excel.Workbook()
    await workbook.xlsx.load(await file.arrayBuffer())
    const worksheet = workbook.worksheets[0]
    inputRef.current!.value = ''

    const cols: string[] = []
    worksheet.getRow(1).eachCell((cell) => cols.push(cell.value!.toString()))

    const rows: excel.Row[] = []
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) rows.push(row)
    })

    setError(undefined)
    const missingCols: Column[] = []
    for (const col of COLUMNS) {
      if (!cols.includes(col)) missingCols.push(col)
    }
    if (missingCols.length) {
      const items = missingCols.map((item) => `<li>${item}</li>`)
      setError(`<strong>${worksheet.name}</strong> is missing the following column${items.length > 1 ? 's' : ''}: <ul>${items.join('')}</ul>`)
      return
    }
    if (rows.length === 0) {
      setError(`<strong>${worksheet.name}</strong> contains no rows`)
      return
    }

    const colToCell: Record<Column, number> = {} as Record<Column, number>
    worksheet.getRow(1).eachCell((cell, colNumber) => {
      const value = cell.value as Column
      colToCell[value] = colNumber
    })

    const charges: NewCharge[] = []
    const invalidRows: { row: number, col: Column }[] = []
    for (const row of rows) {
      const firstName = z.string().nullable().safeParse(row.getCell(colToCell['First Name']).value)
      const lastName = z.string().nullable().safeParse(row.getCell(colToCell['Last Name']).value)
      const dob = z.date().nullable().safeParse(row.getCell(colToCell['Date of Birth']).value)
      const doa = z.date().nullable().safeParse(row.getCell(colToCell['DOA']).value)
      const dos = z.date().nullable().safeParse(row.getCell(colToCell['DOS']).value)
      const procedure = ProcedureSchema.nullable().safeParse(row.getCell(colToCell['Procedure']).value)
      const invoice = z.number().nullable().safeParse(row.getCell(colToCell['Invoice']).value)
      const lawFirm = z.string().nullable().safeParse(row.getCell(colToCell['Law Firm']).value)
      const contactName = z.string().nullable().safeParse(row.getCell(colToCell['Contact Name']).value)
      const email = z.string().nullable().safeParse(row.getCell(colToCell['Email']).value)
      const phone = z.string().nullable().safeParse(row.getCell(colToCell['Phone']).value)
      const insuranceCarrier = z.string().nullable().safeParse(row.getCell(colToCell['Insurance Carrier']).value)
      const policyLimit = z.number().nullable().safeParse(row.getCell(colToCell['Policy Limit']).value)
      const accidentNote = z.string().nullable().safeParse(row.getCell(colToCell['Note (Case)']).value)
      const transactionNote = z.string().nullable().safeParse(row.getCell(colToCell['Note (Transaction)']).value)

      if (!firstName.success) invalidRows.push({ row: row.number, col: 'First Name' })
      if (!lastName.success) invalidRows.push({ row: row.number, col: 'Last Name' })
      if (!dob.success) invalidRows.push({ row: row.number, col: 'Date of Birth' })
      if (!doa.success) invalidRows.push({ row: row.number, col: 'DOA' })
      if (!dos.success) invalidRows.push({ row: row.number, col: 'DOS' })
      if (!procedure.success) invalidRows.push({ row: row.number, col: 'Procedure' })
      if (!invoice.success) invalidRows.push({ row: row.number, col: 'Invoice' })
      if (!lawFirm.success) invalidRows.push({ row: row.number, col: 'Law Firm' })
      if (!contactName.success) invalidRows.push({ row: row.number, col: 'Contact Name' })
      if (!email.success) invalidRows.push({ row: row.number, col: 'Email' })
      if (!phone.success) invalidRows.push({ row: row.number, col: 'Phone' })
      if (!insuranceCarrier.success) invalidRows.push({ row: row.number, col: 'Insurance Carrier' })
      if (!policyLimit.success) invalidRows.push({ row: row.number, col: 'Policy Limit' })
      if (!accidentNote.success) invalidRows.push({ row: row.number, col: 'Note (Case)' })
      if (!transactionNote.success) invalidRows.push({ row: row.number, col: 'Note (Transaction)' })

      if (firstName.success
        && lastName.success
        && dob.success
        && doa.success
        && dos.success
        && procedure.success
        && invoice.success
        && lawFirm.success
        && contactName.success
        && email.success
        && phone.success
        && insuranceCarrier.success
        && policyLimit.success
        && accidentNote.success
        && transactionNote.success) {
        charges.push({
          firstName: firstName.data,
          lastName: lastName.data,
          dob: dob.data ? dayjs.utc(dob.data) : null,
          doa: doa.data ? dayjs.utc(doa.data) : null,
          dos: dos.data ? dayjs.utc(dos.data) : null,
          procedure: procedure.data ?? batch.provider.procedure,
          invoice: invoice.data ? Math.round(invoice.data * 100) : null,
          lawFirm: lawFirm.data,
          contactName: contactName.data,
          email: email.data,
          phone: phone.data,
          insuranceCarrier: insuranceCarrier.data,
          policyLimit: policyLimit.data ? Math.round(policyLimit.data * 100) : null,
          accidentNote: accidentNote.data,
          transactionNote: transactionNote.data,
        })
      }
    }

    if (invalidRows.length) {
      const items = invalidRows.map((item) => `<li>Row: ${item.row}, Column: ${item.col}</li>`)
      setError(`<strong>${worksheet.name}</strong> has invalid data on the following row${items.length > 1 ? 's' : ''}: <ul>${items.join('')}</ul>`)
      return
    }

    updateCharges.mutate({ batch, charges }, {
      onSuccess: () => {
        Toast.success(`${charges.length} charge${charges.length === 1 ? ' was' : 's were'} imported successfully`)
        modal.close()
      },
    })
  }

  const styles = {
    wrapper: css({
      width: 430,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing * 1.5,
      rowGap: theme.spacing,
    }),
    dropzone: css({
      background: theme.colors.pageBackground,
      border: `2px dashed ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.m,
      height: 220,
      padding: theme.spacing * 1.5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&.over': {
        borderColor: theme.colors.success,
      },
    }),
    prompt: css({
      display: 'flex',
      flexDirection: 'column',
      fontFamily: theme.fonts.body[400],
      fontSize: 15,
      textAlign: 'center',
      rowGap: 10,
    }),
    downloadTemplate: css({
      color: theme.colors.accent,
      display: 'flex',
      alignItems: 'center',
      columnGap: 5,
    }),
    loading: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    }),
    error: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 15,
      lineHeight: 1.4,
      'ul': {
        margin: '7px 0 0 0',
        padding: '0 0 0 1em',
      },
      'li': {
        marginTop: 5,
      },
      'strong': {
        fontFamily: theme.fonts.body[500],
        fontWeight: 500,
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div
        ref={dropzoneRef}
        css={styles.dropzone}
        onClick={() => inputRef.current?.click()}
        onDragOver={(e) => {
          e.preventDefault()
          e.stopPropagation()
          dropzoneRef.current?.classList.add('over')
        }}
        onDragLeave={() => {
          dropzoneRef.current?.classList.remove('over')
        }}
        onDrop={(e) => {
          e.preventDefault()
          e.stopPropagation()
          dropzoneRef.current?.classList.remove('over')
          if (e.dataTransfer.files) handleFile(e.dataTransfer.files[0])
        }}
      >
        <div css={styles.prompt}>
          {updateCharges.isPending ? (
            <div css={styles.loading}><ActivityIndicator scale={0.8} />Importing...</div>
          ) : <>
            <div>Drop file here or <a style={{ textDecoration: 'underline' }}>Browse</a></div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <a
                css={styles.downloadTemplate}
                onClick={async (e) => {
                  e.stopPropagation()
                  const workbook = new excel.Workbook()
                  const worksheet = workbook.addWorksheet('Sheet1', {
                    views: [{ state: 'frozen', ySplit: 1 }],
                  })
                  worksheet.columns = [
                    { header: COLUMNS[0], width: 15 },
                    { header: COLUMNS[1], width: 15 },
                    { header: COLUMNS[2], width: 12, style: { numFmt: 'm/d/yy' } },
                    { header: COLUMNS[3], width: 12, style: { numFmt: 'm/d/yy' } },
                    { header: COLUMNS[4], width: 12, style: { numFmt: 'm/d/yy' } },
                    { header: COLUMNS[5], width: 12 },
                    { header: COLUMNS[6], width: 12, style: { numFmt: '#,##0.00_);(#,##0.00)' } },
                    { header: COLUMNS[7], width: 25 },
                    { header: COLUMNS[8], width: 20 },
                    { header: COLUMNS[9], width: 20 },
                    { header: COLUMNS[10], width: 15 },
                    { header: COLUMNS[11], width: 15 },
                    { header: COLUMNS[12], width: 17 },
                    { header: COLUMNS[13], width: 25 },
                    { header: COLUMNS[14], width: 25 },
                  ]

                  const buffer = await workbook.xlsx.writeBuffer()
                  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                  const url = window.URL.createObjectURL(blob)
                  const link = document.createElement('a')
                  link.href = url
                  link.download = 'import-template.xlsx'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'file-arrow-down']}
                  size={15}
                  color={theme.colors.accent}
                />
                Download template
              </a>
            </div>
          </>}
        </div>
      </div>

      <input
        ref={inputRef}
        type='file'
        accept={supportedFileTypes.join(',')}
        style={{ display: 'none' }}
        onChange={(e) => {
          if (e.target.files?.length) handleFile(e.target.files[0])
        }}
      />

      {error && (
        <Message status='danger'>
          <div dangerouslySetInnerHTML={{ __html: error }} css={styles.error} />
        </Message>
      )}
    </div>
  )
}

function ReplacementAccidents({ batch }: { batch: Batch }) {
  const theme = useTheme()

  const updateAssignedAccountsForReplacementTransaction = useUpdateAssignedAccountsForReplacementTransaction()

  const chargesMarkedAsReplacement = batch.charges.filter((c) => c.status === 'Replacement')
  const pendingReplacementAccounts = batch.pendingReplacementAccounts

  const [assignment, setAssignment] = useState<Record<string, string[]>>(() => {
    const value: Record<string, string[]> = {}

    for (const charge of chargesMarkedAsReplacement) {
      if (!charge.transaction) throw new Error('No charge transaction')

      if (charge.transaction.replacingAccounts.length) {
        value[charge.id.toString()] = charge.transaction.replacingAccounts.map((id) => id.toString())
      }
    }

    return value
  })

  let targetToAssigned = 0
  for (const charge of chargesMarkedAsReplacement) {
    if (!Object.keys(assignment).includes(charge.id.toString())) {
      targetToAssigned += chargeTarget(charge)
    }
  }

  function chargeTarget(charge: Charge) {
    return charge.invoice ? charge.invoice * ((charge.rate ?? batch.rate) / 100) * 2 : 0
  }

  const borderRadius = theme.borderRadius.m - 2

  const styles = {
    wrapper: css({
      maxHeight: 600,
      minHeight: 500,
      overflowY: 'auto',
      marginTop: theme.spacing * 0.5,
      padding: theme.spacing * 1.4,
      paddingTop: theme.spacing * 0.3,
    }),
    header: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing * 0.5,
    }),
    heading: css({
      fontFamily: theme.fonts.body[500],
      fontSize: 15,
    }),
    headingInfo: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 14,
      'strong': {
        fontFamily: theme.fonts.body[500],
        fontWeight: '500',
      },
    }),
    table: css({
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={0.5} />
      <div css={styles.header}>
        <div css={styles.heading}>Marked as Replacement ({chargesMarkedAsReplacement.length})</div>
        <div css={styles.headingInfo}>Target To Assign: <strong>{format.currency(targetToAssigned)}</strong></div>
      </div>
      <div css={styles.table}>
        <Table
          columns={[
            {
              data: 'name',
              title: 'Name',
              width: 220,
            },
            {
              data: 'target',
              title: 'Target',
              width: 110,
              alignRight: true,
              renderer: (row) => {
                const charge = chargesMarkedAsReplacement[row]
                const target = chargeTarget(charge)
                let pendingTarget = 0
                if (assignment[charge.id]) {
                  for (const accountId of assignment[charge.id]) {
                    const account = pendingReplacementAccounts.find((a) => a.id === Number(accountId))
                    pendingTarget += account?.target ?? 0
                  }
                }
                const remaining = target - pendingTarget

                return <>
                  <div>{format.currency(target)}</div>
                  <div css={{ opacity: 0.6, whiteSpace: 'nowrap' }}>{format.currency(remaining)} left</div>
                </>
              }
            },
            {
              data: 'assignedAccount',
              title: 'Assigned To',
              width: 250,
              renderer: (row) => {
                const charge = chargesMarkedAsReplacement[row]
                return (
                  <MultiSelect
                    value={assignment[charge.id] ?? []}
                    options={pendingReplacementAccounts.map((a) => {
                      return { value: a.id.toString(), label: a.accident.patient.fullName }
                    })}
                    placeholder='Select cases(s)'
                    noOptions='Case not found'
                    closeMenuOnSelect={true}
                    compact={true}
                    onChange={(value) => {
                      const newAssignment = { ...assignment }
                      if (value.length === 0) {
                        delete newAssignment[charge.id]
                      } else {
                        newAssignment[charge.id] = value
                      }
                      setAssignment(newAssignment)

                      if (!charge.transaction) throw new Error('No charge transaction')

                      updateAssignedAccountsForReplacementTransaction.mutate({
                        batch,
                        transactionId: charge.transaction.id,
                        accountIds: newAssignment[charge.id] ? newAssignment[charge.id].map((accountId) => Number(accountId)) : [],
                      })
                    }}
                  />
                )
              },
            },
          ]}
          data={chargesMarkedAsReplacement.map((charge) => ({
            name: `${charge.firstName} ${charge.lastName}`,
            target: format.currency(chargeTarget(charge)),
          }))}
          noDataMessage='No rows marked as replacements'
          compact={true}
          scrollHorizontally={false}
          borderRadius={borderRadius - 1}
        />
      </div>
      <Spacing height={1.5} />
      <div css={styles.header}>
        <div css={styles.heading}>Pending Replacement ({pendingReplacementAccounts.length})</div>
      </div>
      <div css={styles.table}>
        <Table
          columns={[
            {
              data: 'name',
              title: 'Name',
              width: 220,
            },
            {
              data: 'target',
              title: 'Target',
              width: 110,
              alignRight: true,
            },
            {
              data: 'replacementCharges',
              title: 'Replaced By',
            },
          ]}
          data={pendingReplacementAccounts.map((account) => {
            const replacementCharges = []
            for (const chargeId of Object.keys(assignment)) {
              if (assignment[chargeId].includes(account.id.toString())) {
                const charge = chargesMarkedAsReplacement.find((c) => c.id.toString() === chargeId)
                if (charge) replacementCharges.push(`${charge.firstName} ${charge.lastName}`)
              }
            }

            return {
              name: account.accident.patient.fullName,
              target: format.currency(account.target ?? 0),
              replacementCharges: replacementCharges.join(', '),
            }
          })}
          noDataMessage='No pending replacement cases found'
          compact={true}
          scrollHorizontally={false}
          borderRadius={borderRadius - 1}
        />
      </div>
    </div>
  )
}

function Submit({ batch, totals, canSubmit, spreadsheetRef, onSuccess }: {
  batch: Batch
  totals: Totals
  canSubmit: boolean
  spreadsheetRef: RefObject<HotTableClass>
  onSuccess: () => void
}) {
  const modal = useStore.useModal()
  const theme = useTheme()

  const updateBatch = useUpdateBatch()

  const issues: { row: number, issue: string }[] = []

  for (const charge of approvedCharges(batch.charges)) {
    const row = sortedCharges(spreadsheetRef, batch.charges).findIndex((c) => c.id === charge.id) + 1
    if (charge.accident) {
      if (charge.accident.missingAnyDocuments) {
        issues.push({
          row,
          issue: 'is missing required documents',
        })
      }
    } else {
      issues.push({
        row,
        issue: 'hasn\'t been matched to a case',
      })
    }
    if (!charge.dos) {
      issues.push({
        row,
        issue: 'is missing a DOS',
      })
    }
  }

  issues.sort((a, b) => a.row - b.row)

  let issuesMessage = ''
  if (issues.length) {
    issuesMessage += 'Please correct the following issues: <ul>'
    for (const issue of issues) {
      issuesMessage += `<li><strong>Row ${issue.row}</strong> ${issue.issue}</li>`
    }
    issuesMessage += '</ul>'

    issuesMessage += '<br/>A portion of this funding request may be adjusted or rejected due to incomplete information.'
  }

  let status: BatchStatus = 'Staff - Review'
  if (batch.status === 'Provider - Review') {
    let hasChanges = false
    const lastStatus = batch.events.findLast((e) => e.user.role !== 'provider')
    if (lastStatus) {
      for (const charge of batch.charges) {
        if (charge.updatedAt.isAfter(lastStatus?.createdAt)) hasChanges = true
      }
    }
    if (hasChanges) {
      status = 'Staff - Review'
    } else {
      switch (lastStatus?.user.role) {
        case 'staff':
          status = 'Manager - Review'
          break
        case 'manager':
        case 'executive':
          status = 'Executive - Review'
          break
      }
    }
  }

  const styles = {
    wrapper: css({
      maxWidth: 400,
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    notice: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      lineHeight: 1.4,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <BatchSummary totals={totals} />
      {issuesMessage && <>
        <Spacing height={1} />
        <BatchIssues message={issuesMessage} />
      </>}
      <Spacing height={1} />
      <Message status='info'>
        <div css={styles.notice}>
          {canSubmit
            ? 'By submitting this funding request, you agree that the submitted accounts funded by Lienstar will be treated according to the signed agreement between the provider and Lienstar which remains in effect on the day the funding request is submitted.'
            : 'This funding request will be sent to Lienstar staff for review.'
          }
        </div>
      </Message>
      <Spacing height={1.4} />
      <Button
        text='Submit'
        type='primary'
        loading={updateBatch.isPending}
        onPress={() => {
          updateBatch.mutate({ batch, status }, {
            onSuccess: () => {
              Toast.success('Funding request submitted')
              modal.close()
              onSuccess()
            },
          })
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function UpdateStatus({ batch, totals, spreadsheetRef, onSuccess }: {
  batch: Batch
  totals: Totals
  spreadsheetRef: RefObject<HotTableClass>
  onSuccess: () => void
}) {
  const modal = useStore.useModal()
  const theme = useTheme()

  const user = useUser()
  const updateBatch = useUpdateBatch()

  let hasChanges = false
  const lastStatus = batch.events.findLast((e) => e.user.role === 'provider')
  if (lastStatus) {
    for (const charge of batch.charges) {
      if (charge.updatedAt.isAfter(lastStatus?.createdAt)) hasChanges = true
    }
  }

  const [status, setStatus] = useState<BatchStatus | ''>(hasChanges ? 'Provider - Review' : '')
  const [dop, setDop] = useState(format.date(dayjs(), { iso: true }))
  const [note, setNote] = useState('')
  const [error, setError] = useState<{
    status?: string
    dop?: string
    note?: string
  }>()

  let statuses: BatchStatus[] = ['Provider - Pending', 'Provider - Review']
  if (!hasChanges || user.data?.role === 'executive') {
    switch (user.data?.role) {
      case 'staff':
        statuses = lastStatus?.user.canSubmit ? [...statuses, 'Manager - Review'] : statuses
        break
      case 'manager':
        statuses = [...statuses, 'Staff - Review', 'Executive - Review']
        break
      case 'executive':
        statuses = [...statuses, 'Staff - Review', 'Manager - Review', 'Approved', 'Rejected']
        break
    }
  }

  const issues: { row: number, issue: string }[] = []

  for (const charge of approvedCharges(batch.charges)) {
    if (!charge.accident) {
      issues.push({
        row: physicalRow(charge),
        issue: 'hasn\'t been matched to a case',
      })
    }
    if (!charge.dos) {
      issues.push({
        row: physicalRow(charge),
        issue: 'is missing a DOS',
      })
    }
    if (charge.status === 'Replacement' && charge.transaction?.replacingAccounts.length === 0) {
      issues.push({
        row: physicalRow(charge),
        issue: 'needs to be assigned to a pending replacement case',
      })
    }
  }

  function physicalRow(charge: Charge) {
    return sortedCharges(spreadsheetRef, batch.charges).findIndex((c) => c.id === charge.id) + 1
  }

  issues.sort((a, b) => a.row - b.row)

  let issuesMessage = ''
  const statusesToBeChecked: BatchStatus[] = ['Provider - Review', 'Manager - Review', 'Executive - Review', 'Approved']
  if (issues.length && status !== '' && statusesToBeChecked.includes(status)) {
    issuesMessage += 'Please correct the following issues: <ul>'
    for (const issue of issues) {
      issuesMessage += `<li><strong>Row ${issue.row}</strong> ${issue.issue}</li>`
    }
    issuesMessage += '</ul>'
  }

  const styles = {
    wrapper: css({
      maxWidth: 400,
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <BatchSummary totals={totals} />
      {issuesMessage && <>
        <Spacing height={1} />
        <BatchIssues message={issuesMessage} />
      </>}
      <Spacing height={1} />
      <Select
        label='Status'
        value={status}
        options={statuses}
        placeholder='Select status'
        compact={true}
        error={error?.status}
        onChange={(value) => setStatus(value as BatchStatus)}
      />
      {status === 'Approved' && <>
        <Spacing height={1} />
        <TextInput
          type='date'
          label='DOP'
          value={dop}
          compact={true}
          error={error?.dop}
          onChange={(e) => setDop(e.target.value)}
        />
      </>}
      <Spacing height={1} />
      <TextArea
        label='Note'
        value={note}
        rows={3}
        compact={true}
        error={error?.note}
        onChange={(e) => setNote(e.target.value)}
      />
      <Spacing height={1.4} />
      <Button
        text='Update Status'
        type='primary'
        loading={updateBatch.isPending}
        disabled={!!issuesMessage}
        onPress={() => {
          updateBatch.mutate({ batch, status, dop, note }, {
            onSuccess: () => {
              Toast.success('Status updated')
              modal.close()
              onSuccess()
            },
            onError: (error) => setError(fieldErrors(error)),
          })
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function BatchSummary({ totals }: { totals: Totals }) {
  const theme = useTheme()

  const styles = css({
    fontFamily: theme.fonts.body[400],
    fontSize: 15,
    lineHeight: 1.4,
    'strong': {
      fontFamily: theme.fonts.body[500],
      fontWeight: 500,
    },
  })

  return (
    <div css={styles}>
      This funding request includes <strong>{totals.approved}</strong> charge{totals.approved === 1 ? ' ' : 's '}
      with invoices totaling <strong>{format.currency(totals.invoices)}</strong> and, if approved,
      would provide total funding of <strong>{format.currency(totals.cost)}</strong>.
    </div>
  )
}

function BatchIssues({ message }: { message: string }) {
  const theme = useTheme()

  const styles = css({
    fontFamily: theme.fonts.body[400],
    fontSize: 14.5,
    lineHeight: 1.4,
    'ul': {
      margin: '7px 0 0 0',
      padding: '0 0 0 1em',
    },
    'li': {
      marginTop: 5,
    },
    'strong': {
      fontFamily: theme.fonts.body[500],
      fontWeight: 500,
    },
  })

  return (
    <Message status='danger'>
      <div dangerouslySetInnerHTML={{ __html: message }} css={styles} />
    </Message>
  )
}

function BatchNote({ batch }: { batch: Batch }) {
  const theme = useTheme()

  const user = useUser()

  const lastStatus = batch.events.length ? batch.events[batch.events.length - 1] : undefined
  const recipientRole = lastStatus?.status.split(' - ')[0].toLowerCase()

  if (lastStatus?.note && recipientRole === user.data?.role) {
    const sender = recipientRole === 'provider' ? 'Lienstar' : capitalize(lastStatus.user.role)
    return (
      <Message status='info' compact={true}>
        <Text style={{ fontSize: 14.5, lineHeight: 19 }}>
          <Text style={{ fontFamily: theme.fonts.body[500] }}>Note from {sender}</Text>: {lastStatus.note}
        </Text>
      </Message>
    )
  }
}

function BatchTimeline({ batch }: { batch: Batch }) {
  const DOT_SIZE = 11

  const theme = useTheme()

  const styles = {
    wrapper: css({
      padding: theme.spacing * 1.6,
    }),
    timeline: css({
      maxWidth: `calc(100vw - ${theme.spacing * 6}px)`,
      overflowX: 'auto',
      display: 'grid',
      gridTemplateColumns: `${DOT_SIZE}px max-content max-content max-content max-content`,
      gridColumnGap: theme.spacing,
      rowGap: theme.spacing * 0.8,
      alignItems: 'center',
      fontFamily: theme.fonts.body[400],
      fontSize: 14,
      position: 'relative',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        width: 1,
        top: 8,
        left: 5,
        bottom: 8,
        background: theme.colors.panelBorder,
      },
      '> .dot': {
        width: DOT_SIZE,
        height: DOT_SIZE,
        background: colorWithBrightness(theme.colors.panelBorder, -50),
        borderRadius: '50%',
        alignSelf: 'flex-start',
        marginTop: 3,
      },
      '> .body': {
        minHeight: 22,
        'strong': {
          fontFamily: theme.fonts.body[500],
          fontWeight: 500,
        },
        '> .note': {
          color: colorWithOpacity(theme.colors.foreground, 0.8),
          fontFamily: theme.fonts.body[400],
          fontSize: 13.5,
          lineHeight: 1.4,
          marginTop: 3,
          marginBottom: 0,
          whiteSpace: 'pre-wrap',
        },
      },
      '> .status': {
        marginLeft: theme.spacing * 0.5,
      },
      '> .date': {
        textAlign: 'right',
        opacity: 0.7,
      },
      '> .time': {
        textAlign: 'right',
        opacity: 0.7,
        marginLeft: -(theme.spacing * 0.5),
      },
    }),
    noEvents: css({
      height: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      opacity: 0.7,
    }),
  }

  return (
    <div css={styles.wrapper}>
      {batch.events.length ? (
        <div css={styles.timeline}>
          {batch.events.map((event) => {
            let action = ''
            switch (event.status) {
              case 'Approved':
                action = 'approved the funding request'
                break
              case 'Rejected':
                action = 'rejected the funding request'
                break
              default:
                action = `${event.user.role === 'provider' ? 'submitted' : 'reviewed'} the funding request`
            }

            return (
              <Fragment key={event.createdAt.valueOf()}>
                <div className='dot' />
                <div className='body'>
                  <div>
                    <strong>{event.user.fullName}</strong>
                    {' '}
                    <span dangerouslySetInnerHTML={{ __html: action }} />
                  </div>
                  {event.note && (
                    <pre className='note'>{event.note}</pre>
                  )}
                </div>
                <div className='status'><BatchStatusBadge status={batch.status} displayStatus={event.status} compact={true} /></div>
                <div className='date'>{format.date(event.createdAt)}</div>
                <div className='time'>{format.time(event.createdAt)}</div>
              </Fragment>
            )
          })}
        </div>
      ) : (
        <div css={styles.noEvents}>No events found</div>
      )}
    </div>
  )
}

registerAllModules()

function Spreadsheet({
  spreadsheetRef,
  batch,
  totals,
  locked,
  status,
  selectedCharge,
  onChange,
  onDelete,
  onChargeSelected
}: {
  spreadsheetRef: RefObject<HotTableClass>
  batch: Batch
  totals: Totals
  locked: boolean
  status: ChargeStatusOption
  selectedCharge: Charge | undefined
  onChange: (charges: Charge[]) => void
  onDelete: (charges: Charge[]) => void
  onChargeSelected: (charge: Charge | undefined) => void
}) {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const alert = useStore.useAlert()

  const user = useUser()

  const filteredCharges = batch.charges.filter((c) => {
    switch (status) {
      case 'All Charges': return c
      case 'On Hold':
      case 'Rejected':
      case 'Replacement':
        return c.status === status
      case 'Changed': {
        const lastReviewed = batch.events.findLast((e) => e.user.role === user.data?.role)
        return lastReviewed ? c.updatedAt.isAfter(lastReviewed.createdAt) : undefined
      }
    }
  })

  const [charges, setCharges] = useState(filteredCharges)
  const [prevCharges, setPrevCharges] = useState(filteredCharges)
  if (!isEqual(filteredCharges, prevCharges)) {
    setCharges(filteredCharges)
    setPrevCharges(filteredCharges)
  }

  function physicalRow(visualRow: number) {
    const chargeId = sortedCharges(spreadsheetRef, charges)[visualRow].id
    return charges.findIndex((c) => c.id === chargeId)
  }

  const styles = {
    global: css({
      '.htContextMenu .ht_master table.htCore': {
        color: theme.colors.foreground,
        fontFamily: theme.fonts.body[400],
        fontSize: 13.5,
        border: 'none',
        borderRadius: theme.borderRadius.s,
        boxShadow: `0 1px 3px ${colorWithOpacity('#000000', 0.15)}`,
        overflow: 'hidden',
        'td:not(.htSeparator)': {
          height: '34px !important',
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          '.htItemWrapper': {
            marginLeft: 12,
          },
        },
      },
      '.pika-single': {
        fontFamily: theme.fonts.body[400],
        border: 'none',
        borderRadius: theme.borderRadius.s,
        boxShadow: `0 1px 3px ${colorWithOpacity('#000000', 0.15)}`,
        '.is-today .pika-button': {
          color: theme.colors.primary,
        },
        '.has-event .pika-button, .is-selected .pika-button': {
          background: theme.colors.primary,
          boxShadow: 'none',
        },
        '.pika-button:hover, .pika-row.pick-whole-week:hover .pika-button': {
          background: colorWithOpacity(theme.colors.foreground, 0.5),
          color: '#FFFFFF',
        },
      },
    }),
    wrapper: css({
      margin: -1,
      minHeight: 377,
    }),
    table: css({
      'th': {
        color: theme.colors.foreground,
        background: theme.colors.headerBackground,
        borderColor: `${theme.colors.panelBorder} !important`,
        verticalAlign: 'middle',
      },
      '.ht_clone_top th, .ht_clone_top_inline_start_corner th': {
        fontFamily: theme.fonts.body[600],
        fontSize: 13.5,
        textAlign: 'left',
        padding: '0 10px',
        '.relative': {
          padding: 0,
        },
      },
      '.ht_clone_left th': {
        fontFamily: theme.fonts.mono,
        fontSize: 12.5,
        letterSpacing: -0.1,
      },
      'thead th, tbody th': {
        '&.ht__highlight': {
          background: '#EBEBEB',
        },
        '&.ht__active_highlight': {
          background: '#EBEBEB',
        },
      },
      'td': {
        color: theme.colors.foreground,
        fontFamily: theme.fonts.mono,
        fontSize: 12.5,
        letterSpacing: -0.1,
        borderColor: `${theme.colors.panelBorder} !important`,
        verticalAlign: 'middle',
        padding: '0 10px',
        '&.area:before': {
          background: theme.colors.info,
        },
        '&.selected, &.selected-rejected': {
          background: colorWithOpacity(theme.colors.info, 0.1),
        },
        '&.onHold, &.selected-onHold': {
          background: colorWithOpacity(theme.colors.warning, 0.1),
          color: colorWithOpacity(theme.colors.foreground, 0.5),
          '> .status': {
            opacity: 0.5,
          },
          '&.selected-onHold': {
            background: colorWithOpacity(theme.colors.warning, 0.15),
          },
        },
        '&.rejected, &.selected-rejected': {
          color: colorWithOpacity(theme.colors.foreground, 0.5),
          textDecoration: 'line-through',
          '> .status': {
            opacity: 0.5,
          },
        },
        '&.replacement': {
          background: colorWithOpacity(Constants.replacementColor, 0.1),
        },
        '&.selected-replacement': {
          background: colorWithOpacity(Constants.replacementColor, 0.15),
        },
        '&.sameDos, &.selected-sameDos, &.onHold-sameDos, &.selected-onHold-sameDos, &.replacement-sameDos, &.selected-replacement-sameDos': {
          background: colorWithOpacity(theme.colors.danger, 0.1),
        },
        '.htAutocompleteArrow': {
          color: colorWithOpacity(theme.colors.foreground, 0.1),
          marginRight: -5,
        },
        '> .status': {
          display: 'flex',
          alignItems: 'center',
          columnGap: 9,
          '> .icon': {
            fontSize: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&.success path': {
              fill: theme.colors.success,
            },
            '&.warning path': {
              fill: theme.colors.warning,
            },
            '&.danger path': {
              fill: theme.colors.danger,
            },
            'path': {
              fill: colorWithOpacity(theme.colors.foreground, 0.25),
            },
          },
        },
      },
      '.handsontableInput': {
        fontFamily: theme.fonts.mono,
        fontSize: 12.5,
        letterSpacing: -0.1,
        lineHeight: '33px',
        padding: '0 11px',
      },
    }),
    footer: css({
      zIndex: 1000,
      background: theme.colors.headerBackground,
      borderTop: `1px solid ${theme.colors.panelBorder}`,
      padding: '12px 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      flexWrap: 'wrap',
      [`@media (min-width: ${theme.breakpoints.m}px)`]: {
        height: 38,
        padding: 0,
      },
      '> .icons': {
        display: 'flex',
        alignItems: 'center',
        columnGap: 9,
      },
      '> .item': {
        fontFamily: theme.fonts.body[400],
        fontSize: 14,
        opacity: 0.8,
        'strong': {
          fontFamily: theme.fonts.body[500],
          fontWeight: '500',
        },
      },
      '> .divider': {
        width: 1,
        height: 15,
        background: theme.colors.divider,
        margin: '0 5px',
      },
    }),
  }

  return <>
    <div css={styles.wrapper}>
      <HotTable
        ref={spreadsheetRef}
        columns={[
          {
            data: 'status',
            title: 'Status',
            width: 100,
            readOnly: true,
            multiColumnSorting: { headerAction: false },
            renderer: (instance, td, row, col, prop, rawValue, cellProperties) => {
              const charge: Charge = rawValue

              const wrapper = document.createElement('div')
              wrapper.classList.add('status')

              let missingAnyDocuments: boolean | undefined = undefined
              if (charge.accident) {
                missingAnyDocuments = charge.accident.missingAnyDocuments
              }

              let policyLimitStatus: 'Over' | 'Under' | 'Missing' | undefined = undefined
              if (charge.accident) {
                if (charge.accident.policyLimitAmount === 0) {
                  policyLimitStatus = 'Missing'
                } else {
                  policyLimitStatus = 'Under'
                  for (const account of charge.accident.accounts) {
                    let policyLimitPercentage = Constants.defaultPolicyLimitPercentage
                    if (account?.policyLimitPercentage) {
                      policyLimitPercentage = account.policyLimitPercentage
                    } else if (charge.accident.policyLimitPercentage) {
                      policyLimitPercentage = charge.accident.policyLimitPercentage
                    }

                    const transactions = charge.accident.transactions.filter((t) => t.accountId === account.id)
                    const cost = transactions.reduce((sum, t) => sum + (t.cost ?? 0), 0)
                    const policyLimitValue = Math.round(cost / charge.accident.policyLimitAmount * 100)

                    if (policyLimitValue > policyLimitPercentage) policyLimitStatus = 'Over'
                  }
                }
              }

              let hasChanges: boolean | undefined = undefined
              const lastReviewed = batch.events.findLast((e) => e.user.role === user.data?.role)
              if (lastReviewed && charge.updatedAt?.isAfter(lastReviewed.createdAt)) hasChanges = true

              const items: { status?: 'success' | 'warning' | 'danger', title: string, icon: Record<string, string> }[] = [
                {
                  status: charge.accident ? 'success' : undefined,
                  title: `Charge ${charge.accident ? 'Matched' : 'Unmatched'}`,
                  icon: {
                    viewBox: '0 0 448 512',
                    d: 'M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z',
                  },
                },
                {
                  status: missingAnyDocuments === undefined ? undefined : missingAnyDocuments ? 'warning' : 'success',
                  title: !charge.accident ? 'Charge Unmatched' : `Documents ${missingAnyDocuments ? 'Required' : 'Uploaded'}`,
                  icon: {
                    viewBox: '0 0 384 512',
                    d: 'M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z',
                  },
                },
                {
                  status: policyLimitStatus === undefined ? undefined
                    : policyLimitStatus === 'Over' ? 'danger'
                      : policyLimitStatus === 'Under' ? 'success' : 'warning',
                  title: !charge.accident ? 'Charge Unmatched' : `${policyLimitStatus} Policy Limit`,
                  icon: {
                    viewBox: '0 0 512 512',
                    d: 'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm20.8-378.4v14.2c9.7 1.2 19.4 3.9 29 6.6c1.9 .5 3.7 1 5.6 1.6c11.5 3.2 18.3 15.1 15.1 26.6s-15.1 18.2-26.6 15.1c-1.6-.4-3.1-.9-4.7-1.3c-7-2-14-3.9-21.1-5.3c-13.2-2.5-28.5-1.3-40.8 4c-11 4.8-20.1 16.4-7.6 24.4c9.8 6.3 21.8 9.5 33.2 12.6c2.4 .6 4.7 1.3 7 1.9c15.6 4.4 35.5 10.1 50.4 20.3c19.4 13.3 28.5 34.9 24.2 58.1c-4.1 22.4-19.7 37.1-38.4 44.7c-7.8 3.2-16.3 5.2-25.2 6.2l0 15.2c0 11.9-9.7 21.6-21.6 21.6s-21.6-9.7-21.6-21.6l0-17.4c-14.5-3.3-28.7-7.9-42.8-12.5c-11.3-3.7-17.5-16-13.7-27.3s16-17.5 27.3-13.7c2.5 .8 5 1.7 7.5 2.5c11.3 3.8 22.9 7.7 34.5 9.6c17 2.5 30.6 1 39.5-2.6c12-4.8 17.7-19.1 5.9-27.1c-10.1-6.9-22.6-10.3-34.5-13.5c-2.3-.6-4.5-1.2-6.8-1.9c-15.1-4.3-34-9.6-48.2-18.7c-19.5-12.5-29.4-33.3-25.2-56.4c4-21.8 21-36.3 39-44.1c5.5-2.4 11.4-4.3 17.5-5.7V133.6c0-11.9 9.7-21.6 21.6-21.6s21.6 9.7 21.6 21.6z',
                  },
                },
                {
                  status: hasChanges === true ? 'warning' : undefined,
                  title: `${hasChanges ? '' : 'No '}Changes Made Since Last Review`,
                  icon: {
                    viewBox: '0 0 512 512',
                    d: hasChanges
                      ? 'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z'
                      : 'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z',
                  },
                }
              ]

              for (const item of items) {
                const el = document.createElement('div')
                el.classList.add('icon')
                if (item.status) el.classList.add(item.status)
                el.title = item.title
                const icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
                icon.setAttribute('height', '1em')
                icon.setAttribute('viewBox', item.icon.viewBox)
                const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
                iconPath.setAttribute('d', item.icon.d)
                icon.appendChild(iconPath)
                el.appendChild(icon)
                wrapper.appendChild(el)
              }

              if (typeof cellProperties.className === 'string') td.classList.add(cellProperties.className)
              td.innerHTML = wrapper.outerHTML
              return td
            },
          },
          {
            data: 'firstName',
            title: 'First Name',
            width: 140,
          },
          {
            data: 'lastName',
            title: 'Last Name',
            width: 140,
          },
          {
            data: 'dob',
            title: 'Date of Birth',
            width: 120,
            type: 'date',
            dateFormat: 'YYYY-MM-DD',
          },
          {
            data: 'doa',
            title: 'DOA',
            width: 120,
            type: 'date',
            dateFormat: 'YYYY-MM-DD',
          },
          {
            data: 'dos',
            title: 'DOS',
            width: 120,
            type: 'date',
            dateFormat: 'YYYY-MM-DD',
          },
          {
            data: 'procedure',
            title: 'Procedure',
            width: 120,
            type: 'dropdown',
            source: ProcedureSchema.options,
          },
          {
            data: 'invoice',
            title: 'Invoice',
            width: 120,
            type: 'numeric',
            editor: AmountEditor,
            numericFormat: {
              pattern: '$0,0.00',
              culture: 'en-US',
            },
          },
          {
            data: 'rate',
            title: 'Rate',
            width: 100,
            type: 'numeric',
            readOnly: locked || user.data?.role === 'provider',
            editor: AmountEditor,
          },
          {
            data: 'cost',
            title: 'To Be Funded',
            width: 120,
            type: 'numeric',
            readOnly: locked || user.data?.role === 'provider',
            editor: AmountEditor,
            numericFormat: {
              pattern: '$0,0.00',
              culture: 'en-US',
            },
          },
          {
            data: 'lawFirm',
            title: 'Law Firm',
            width: 205,
          },
          {
            data: 'contactName',
            title: 'Contact Name',
            width: 180,
          },
          {
            data: 'email',
            title: 'Email',
            width: 180,
          },
          {
            data: 'phone',
            title: 'Phone',
            width: 130,
          },
          {
            data: 'insuranceCarrier',
            title: 'Insurance Carrier',
            width: 180,
          },
          {
            data: 'policyLimit',
            title: 'Policy Limit',
            width: 150,
            type: 'numeric',
            editor: AmountEditor,
            numericFormat: {
              pattern: '$0,0.00',
              culture: 'en-US',
            },
          },
          {
            data: 'accidentNote',
            title: 'Note (Case)',
            width: 250,
          },
          {
            data: 'transactionNote',
            title: 'Note (Transaction)',
            width: 250,
          },
        ]}
        data={[...charges].map((charge) => ({
          status: charge,
          firstName: charge.firstName,
          lastName: charge.lastName,
          dob: charge.dob ? format.date(charge.dob, { iso: true }) : null,
          doa: charge.doa ? format.date(charge.doa, { iso: true }) : null,
          dos: charge.dos ? format.date(charge.dos, { iso: true }) : null,
          procedure: charge.procedure ?? batch.provider.procedure,
          invoice: charge.invoice ? charge.invoice / 100 : null,
          rate: charge.rate ?? batch.rate,
          cost: charge.invoice ? charge.invoice * ((charge.rate ?? batch.rate) / 100) / 100 : null,
          lawFirm: charge.lawFirm,
          contactName: charge.contactName,
          email: charge.email,
          phone: charge.phone,
          insuranceCarrier: charge.insuranceCarrier,
          policyLimit: charge.policyLimit ? charge.policyLimit / 100 : null,
          accidentNote: charge.accidentNote,
          transactionNote: charge.transactionNote,
        }))}
        cells={(row, col, prop) => {
          const charge = charges[row]
          if (!charge) return {}

          const classes: string[] = []
          if (charge.id === selectedCharge?.id) classes.push('selected')
          if (charge.status) classes.push(camelCase(charge.status))
          if (!batch.isComplete && charge.accident && prop === 'dos') {
            let sameDos = false
            for (const transaction of charge.accident.transactions.filter((t) => t.providerId === batch.provider.id)) {
              if (charge.id === transaction.charge?.id) continue
              if (charge.dos && charge.dos.isSame(transaction.dos, 'date')) sameDos = true
            }
            if (sameDos) classes.push('sameDos')
          }

          return { className: classes.length ? classes.join('-') : undefined }
        }}
        contextMenu={{
          items: {
            'place_on_hold': {
              name: 'Place on hold',
              disabled: locked || user.data?.role === 'provider',
              callback: (key, selection) => {
                const newCharges = [...charges]
                const updatedCharges: Charge[] = []
                for (const s of selection) {
                  for (let i = s.start.row; i <= s.end.row; i++) {
                    const row = physicalRow(i)
                    newCharges[row].status = newCharges[row].status === 'On Hold' ? null : 'On Hold'
                    updatedCharges.push(newCharges[row])
                  }
                }
                setCharges(newCharges)
                onChange(updatedCharges)
              },
            },
            'reject': {
              name: 'Reject',
              disabled: locked || user.data?.role === 'provider',
              callback: (key, selection) => {
                const newCharges = [...charges]
                const updatedCharges: Charge[] = []
                for (const s of selection) {
                  for (let i = s.start.row; i <= s.end.row; i++) {
                    const row = physicalRow(i)
                    newCharges[row].status = newCharges[row].status === 'Rejected' ? null : 'Rejected'
                    updatedCharges.push(newCharges[row])
                  }
                }
                setCharges(newCharges)
                onChange(updatedCharges)
              },
            },
            'mark_as_replacement': {
              name: 'Mark as replacement',
              disabled: locked || user.data?.role === 'provider',
              callback: (key, selection) => {
                const newCharges = [...charges]
                const updatedCharges: Charge[] = []
                for (const s of selection) {
                  for (let i = s.start.row; i <= s.end.row; i++) {
                    const row = physicalRow(i)

                    if (!newCharges[row].accident) {
                      Toast.error('Match charge before marking it as a replacement')
                      continue
                    }

                    if (!newCharges[row].transaction) {
                      Toast.error('Enter DOS and invoice amount before marking it as a replacement')
                      continue
                    }

                    newCharges[row].status = newCharges[row].status === 'Replacement' ? null : 'Replacement'
                    updatedCharges.push(newCharges[row])
                  }
                }
                setCharges(newCharges)
                onChange(updatedCharges)
              },
            },
            'separator1': ContextMenu.SEPARATOR,
            'add_row': {
              name: 'Add row',
              disabled: locked,
              callback: () => {
                setCharges([...charges, {} as Charge])
                onChange([{} as Charge])
              },
            },
            'add_10_rows': {
              name: 'Add 10 rows',
              disabled: locked,
              callback: () => {
                const newCharges: Charge[] = []
                for (let i = 0; i < 10; i++) {
                  newCharges.push({} as Charge)
                }
                setCharges([...charges, ...newCharges])
                onChange(newCharges)
              },
            },
            'remove_row': {
              disabled: locked,
            },
          },
        }}
        readOnly={locked}
        fixedColumnsStart={width >= theme.breakpoints.m ? 3 : width >= theme.breakpoints.xs ? 1 : 0}
        columnHeaderHeight={38}
        multiColumnSorting={true}
        rowHeaders={true}
        rowHeaderWidth={45}
        rowHeights={32}
        wordWrap={false}
        copyPaste={true}
        stretchH='last'
        width='100%'
        height='100%'
        css={styles.table}
        afterSelection={(row) => {
          onChargeSelected(sortedCharges(spreadsheetRef, charges)[row])
        }}
        beforeChange={(changes, source) => {
          if (!changes) return

          for (const change of changes) {
            if (!change) continue
            const row = change[0]
            const col = change[1]
            const colIndex = spreadsheetRef.current?.hotInstance?.propToCol(col as string) as number
            if (!colIndex) continue
            const colMeta = spreadsheetRef.current?.hotInstance?.getCellMeta(0, colIndex)
            if (!colMeta) continue

            if (change[3]) {
              if (colMeta.type === 'date') {
                change[3] = format.date(dayjs.utc(change[3]), { iso: true })
              }

              if (colMeta.type === 'numeric' && typeof change[3] === 'string') {
                change[3] = change[3].replace(',', '')
              }

              if (colMeta.prop === 'procedure') {
                const procedure = ProcedureSchema.safeParse(change[3])
                if (!procedure.success) change[3] = change[2]
              }

              if (colMeta.prop === 'rate' && typeof change[3] === 'string') {
                change[3] = change[3].replace('%', '')
              }
            }

            if (sortedCharges(spreadsheetRef, charges)[row]?.accident
              && ['lawFirm', 'contactName', 'email', 'phone', 'insuranceCarrier', 'policyLimit'].includes(col as string)) {
              alert.open({
                title: 'Update in Case Viewer',
                message: 'Use the case viewer to make updates to a matched case.',
                buttons: [
                  {
                    text: 'OK',
                    onPress: () => alert.close(),
                  },
                ],
              })
              change[3] = change[2]
            }
          }
        }}
        afterChange={async (changes) => {
          if (!changes) return

          const newCharges = [...charges]
          const changedRows: number[] = []
          for (const change of changes) {
            const row = physicalRow(change[0])
            const col = change[1]
            const value = change[3]
            const colIndex = spreadsheetRef.current?.hotInstance?.propToCol(col as string) as number
            if (!colIndex) continue
            const colMeta = spreadsheetRef.current?.hotInstance?.getCellMeta(0, colIndex)
            if (!colMeta) continue

            if (!newCharges[row]) newCharges[row] = {} as Charge

            if (colMeta.type === 'date' && value) {
              (newCharges[row] as any)[col as string] = dayjs(value)
            } else if (colMeta.numericFormat?.pattern === '$0,0.00') {
              (newCharges[row] as any)[col as string] = Math.round(value * 100)
            } else if (colMeta.type === 'numeric') {
              (newCharges[row] as any)[col as string] = value ? Number(value) : undefined
            } else {
              (newCharges[row] as any)[col as string] = value ?? undefined
            }

            if (colMeta.prop === 'cost') {
              (newCharges[row] as any)['rate'] = value / (newCharges[row] as any)['invoice'] * 100 * 100
            }

            if (!changedRows.includes(row)) changedRows.push(row)
          }

          const updatedCharges: Charge[] = []
          for (const row of changedRows) {
            updatedCharges.push(newCharges[row])
          }

          setCharges(newCharges)
          onChange(updatedCharges)
        }}
        afterRemoveRow={(index, amount, physicalRows) => {
          const newCharges = [...charges]
          const chargesToBeRemoved: Charge[] = []
          for (const row of physicalRows) {
            newCharges.splice(row, 1)
            chargesToBeRemoved.push(charges[row])
          }
          setCharges(newCharges)
          onDelete(chargesToBeRemoved)
        }}
        licenseKey='non-commercial-and-evaluation'
      />
    </div>

    <div css={styles.footer}>
      <div className='icons'>
        <Tooltip
          content={totals.unmatched === 0 ? 'All Charges Matched'
            : `${totals.unmatched} Unmatched Charge${totals.unmatched === 1 ? '' : 's'}`
          }>
          <FontAwesomeIcon
            icon={['fas', 'user']}
            size={15}
            color={totals.unmatched === 0 ? theme.colors.success : colorWithOpacity(theme.colors.foreground, 0.25)}
          />
        </Tooltip>
        <Tooltip
          content={totals.missingDocuments === undefined ? 'All Charges Unmatched'
            : `${totals.missingDocuments} Charge${totals.missingDocuments === 1 ? ' is' : 's are'} Missing Documents`
          }>
          <FontAwesomeIcon
            icon={['fas', 'file']}
            size={15}
            color={totals.missingDocuments === undefined
              ? colorWithOpacity(theme.colors.foreground, 0.25)
              : totals.missingDocuments === 0 ? theme.colors.success : theme.colors.warning}
          />
        </Tooltip>
        <Tooltip
          content={totals.policyLimit === undefined ? 'All Charges Unmatched'
            : totals.policyLimit.over === 0 && totals.policyLimit.missing === 0 ? 'All Charges Under Policy Limit'
              : totals.policyLimit.over > 0 && totals.policyLimit.missing > 0
                ? `${totals.policyLimit.over} Charge${totals.policyLimit.over === 1 ? '' : 's'} Over Policy Limit, `
                + `${totals.policyLimit.missing} Charge${totals.policyLimit.missing === 1 ? '' : 's'} Missing Policy Limit`
                : totals.policyLimit.over > 0 ? `${totals.policyLimit.over} Charge${totals.policyLimit.over === 1 ? '' : 's'} Over Policy Limit`
                  : `${totals.policyLimit.missing} Charge${totals.policyLimit.missing === 1 ? '' : 's'} Missing Policy Limit`
          }>
          <FontAwesomeIcon
            icon={['fas', 'circle-dollar']}
            size={15}
            color={totals.policyLimit === undefined
              ? colorWithOpacity(theme.colors.foreground, 0.25)
              : totals.policyLimit.over === 0 && totals.policyLimit.missing === 0 ? theme.colors.success
                : totals.policyLimit.over > 0 ? theme.colors.danger : theme.colors.warning}
          />
        </Tooltip>
        <Tooltip
          content={totals.changes === undefined ? 'All Charges Unmatched'
            : `${totals.changes} Change${totals.changes === 1 ? '' : 's'} Made Since Last Review`
          }>
          <FontAwesomeIcon
            icon={['fas', 'circle-info']}
            size={15}
            color={!totals.changes ? colorWithOpacity(theme.colors.foreground, 0.25) : theme.colors.warning}
          />
        </Tooltip>
      </div>
      <div className='divider' />
      <div className='item'>Charges: <strong>{totals.approved}</strong></div>
      <div className='divider' />
      <div className='item'>Same DOS: <strong>{totals.sameDos}</strong></div>
      <div className='divider' />
      <div className='item'>Invoices: <strong>{format.currency(totals.invoices)}</strong></div>
      <div className='divider' />
      <div className='item'>To Be Funded: <strong>{format.currency(totals.cost)}</strong></div>
      {totals.replacementInvoices > 0 && <>
        <div className='divider' />
        <div className='item'>Replacement Invoices: <strong>{format.currency(totals.replacementInvoices)}</strong></div>
      </>}
    </div>

    <Global styles={styles.global} />
  </>
}

class AmountEditor extends Handsontable.editors.NumericEditor {
  createElements() {
    super.createElements()
    this.TEXTAREA.style.textAlign = 'right'
  }
}

function AccidentDetails({ accidentId, batch, onNav, onUpdate }: {
  accidentId: number
  batch: Batch
  onNav: (accidentId: number) => void
  onUpdate: (accident: Accident) => void
}) {
  const theme = useTheme()

  const accident = useAccident(accidentId)

  const accidentIds: number[] = []
  for (const charge of batch.charges) {
    if (charge.accident && !accidentIds.includes(charge.accident.id)) accidentIds.push(charge.accident.id)
  }

  return (
    <div>
      {accident.isPending ? (
        <ActivityIndicator style={{ marginHorizontal: 'auto', marginTop: theme.spacing * 8 }} />
      ) : accident.data ? (
        <AccidentViewer
          accident={accident.data}
          list={accidentIds.length > 0 ? {
            items: accidentIds,
            onNav,
          } : undefined}
          batch={batch}
          onUpdate={onUpdate}
        />
      ) : (
        <Panel>
          <NotFound item='Case' style={{ marginVertical: theme.spacing * 3 }} />
        </Panel>
      )}
    </div>
  )
}

function ChargeMatcher({ batch, charge, onMatch, onCreateAccident }: {
  batch: Batch
  charge: Charge
  onMatch: (batch: Batch) => void
  onCreateAccident: (batch: Batch) => void
}) {
  const theme = useTheme()
  const alert = useStore.useAlert()

  const user = useUser()
  const accidents = useMatchCharge(charge.id)
  const updateCharges = useUpdateCharges()
  const updatePatient = useUpdatePatient()
  const updateAccident = useUpdateAccident()
  const createAccident = useCreateAccidentFromCharge()

  const selectedAccidentId = useRef<number>()

  const styles = {
    matches: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing * 0.5,
      [`@media (min-width: ${theme.breakpoints.l}px)`]: {
        flexDirection: 'row',
      },
    }),
    matchesList: css({
      display: 'flex',
      justifyContent: 'center',
      gap: theme.spacing * 0.5,
      flexWrap: 'wrap',
    }),
    match: css({
      minWidth: 210,
      maxWidth: 250,
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s - 1,
      fontFamily: theme.fonts.body[400],
      boxShadow: theme.web.shadow.input,
      padding: theme.spacing,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '> .content': {
        '> .name': {
          display: 'block',
          fontFamily: theme.fonts.body[500],
          fontSize: 15.5,
          lineHeight: 1.3,
          marginTop: -(theme.spacing * 0.2),
        },
        '> .meta': {
          fontSize: 14.5,
          opacity: 0.9,
          lineHeight: 1.3,
        },
        '.highlight': {
          color: theme.colors.danger,
        },
      },
    }),
    divider: css({
      alignSelf: 'center',
      fontFamily: theme.fonts.body[600],
      fontSize: 12,
      opacity: 0.7,
      textTransform: 'uppercase',
      margin: theme.spacing * 0.5,
    }),
  }

  return (
    <Feedback
      status='info'
      icon={faMagnifyingGlass}
      body={!accidents.data
        ? 'Searching for matches...'
        : `Found ${accidents.data?.length} potential match${accidents.data.length === 1 ? '' : 'es'}${accidents.data.length > 0 ? ':' : ''}`}
      maxWidth={400}
      compact={true}
      style={{ paddingHorizontal: theme.spacing, paddingVertical: theme.spacing * 2 }}
      action={(
        <div css={styles.matches}>
          {accidents.isPending ? (
            <ActivityIndicator />
          ) : accidents.data ? <>
            {accidents.data.length > 0 && <>
              <div css={styles.matchesList}>
                {accidents.data.map((accident) => {
                  const firstNameDifferent = accident.patient.firstName !== charge.firstName
                  const lastNameDifferent = accident.patient.lastName !== charge.lastName
                  const dobDifferent = !accident.patient.dob?.isSame(charge.dob, 'date')
                  const doaDifferent = !accident.doa?.isSame(charge.doa, 'date')

                  return (
                    <div key={accident.id} css={styles.match}>
                      <div className='content'>
                        <Link className='name' href={`/cases/${accident.id}`}>
                          <span className={firstNameDifferent ? 'highlight' : ''}>{accident.patient.firstName}</span>
                          {' '}
                          <span className={lastNameDifferent ? 'highlight' : ''}>{accident.patient.lastName}</span>
                        </Link>
                        <Spacing height={0.5} />
                        <div className='meta'>
                          DOB: <span className={dobDifferent ? 'highlight' : ''}>{accident.patient.dob ? format.date(accident.patient.dob) : ''}</span>
                        </div>
                        <Spacing height={0.3} />
                        <div className='meta'>
                          DOA: <span className={doaDifferent ? 'highlight' : ''}>{accident.doa ? format.date(accident.doa) : ''}</span>
                        </div>
                        {(user.data?.role !== 'provider' || user.data.providers.length > 1) && <>
                          <Spacing height={0.3} />
                          <div className='meta'>
                            {accident.accounts.map((a) => a.provider.name).join(', ')}
                          </div>
                        </>}
                        <Spacing height={0.8} />
                      </div>
                      <Button
                        text='Mark as Match'
                        type='tertiary'
                        compact={true}
                        loading={accident.id === selectedAccidentId.current
                          && (updateCharges.isPending || updatePatient.isPending || updateAccident.isPending)}
                        onPress={async () => {
                          selectedAccidentId.current = accident.id
                          let accidentUpdatedFromCharge = false

                          if (!accident.patient.firstName || !accident.patient.lastName || !accident.patient.dob || !accident.doa) {
                            if (charge.firstName && charge.lastName && charge.dob && charge.doa) {
                              try {
                                await updatePatient.mutateAsync({
                                  accident,
                                  patient: {
                                    firstName: charge.firstName,
                                    lastName: charge.lastName,
                                    dob: format.date(charge.dob, { iso: true }),
                                  },
                                })
                                await updateAccident.mutateAsync({
                                  id: accident.id,
                                  doa: format.date(charge.doa, { iso: true }),
                                })
                                accidentUpdatedFromCharge = true
                              } catch { }
                            } else {
                              const missing: string[] = []
                              if (!charge.firstName) missing.push('First Name')
                              if (!charge.lastName) missing.push('Last Name')
                              if (!charge.dob) missing.push('Date of Birth')
                              if (!charge.doa) missing.push('DOA')
                              Toast.error(`Missing field${missing.length === 1 ? '' : 's'}: ${missing.join(', ')}`)
                              return
                            }
                          }

                          const updatedCharges: Charge[] = []
                          for (const c of batch.charges) {
                            if (c.id === charge.id || (!c.accident
                              && c.firstName === charge.firstName
                              && c.lastName === charge.lastName
                              && c.dob?.isSame(charge.dob, 'date')
                              && c.doa?.isSame(charge.doa, 'date'))
                            ) {
                              updatedCharges.push({
                                ...c,
                                firstName: accidentUpdatedFromCharge ? charge.firstName : accident.patient.firstName,
                                lastName: accidentUpdatedFromCharge ? charge.lastName : accident.patient.lastName,
                                dob: accidentUpdatedFromCharge ? charge.dob : accident.patient.dob,
                                doa: accidentUpdatedFromCharge ? charge.doa : accident.doa,
                                matchedAccidentId: accident.id,
                              })
                            }
                          }
                          updateCharges.mutate({ batch, charges: updatedCharges }, {
                            onSuccess: (batch) => onMatch(batch),
                          })
                        }}
                      />
                    </div>
                  )
                })}
              </div>
              <div css={styles.divider}>or</div>
            </>}
            <div css={styles.match}>
              <div className='content'>
                <div className='name'>{charge.firstName ?? '(first name)'} {charge.lastName ?? '(last name)'}</div>
                <Spacing height={0.5} />
                <div className='meta'>DOB: {charge.dob ? format.date(charge.dob) : ''}</div>
                <Spacing height={0.3} />
                <div className='meta'>DOA: {charge.doa ? format.date(charge.doa) : ''}</div>
                {(user.data?.role !== 'provider' || user.data.providers.length > 1) && <>
                  <Spacing height={0.3} />
                  <div className='meta'>{batch.provider.name}</div>
                </>}
                <Spacing height={0.8} />
              </div>
              <Button
                text='Create New Case'
                type='tertiary'
                compact={true}
                textLeft={(
                  <FontAwesomeIcon
                    icon={['far', 'user-plus']}
                    size={15}
                    color={colorWithOpacity(theme.colors.foreground, 0.9)}
                    style={{ marginRight: 7 }}
                  />
                )}
                loading={createAccident.isPending || (createAccident.isSuccess && updateCharges.isPending)}
                onPress={() => {
                  createAccident.mutate({ charge }, {
                    onSuccess: ({ accidentId }) => {
                      const updatedCharges: Charge[] = []
                      for (const c of batch.charges) {
                        if (c.id === charge.id || (!c.accident
                          && c.firstName === charge.firstName
                          && c.lastName === charge.lastName
                          && c.dob?.isSame(charge.dob, 'date')
                          && c.doa?.isSame(charge.doa, 'date'))
                        ) {
                          updatedCharges.push({
                            ...c,
                            firstName: charge.firstName,
                            lastName: charge.lastName,
                            dob: charge.dob,
                            doa: charge.doa,
                            matchedAccidentId: accidentId,
                          })
                        }
                      }
                      updateCharges.mutate({ batch, charges: updatedCharges }, {
                        onSuccess: (batch) => onCreateAccident(batch)
                      })
                    },
                    onError: (error) => {
                      const alertOptions = alertError(error)
                      if (alertOptions) {
                        alert.open({
                          title: alertOptions.title,
                          message: alertOptions.message,
                          buttons: [
                            {
                              text: 'OK',
                              onPress: () => alert.close(),
                            },
                          ],
                        })
                      }
                    },
                  })
                }}
              />
            </div>
          </> : null}
        </div>
      )}
    />
  )
}

function sortedCharges(spreadsheetRef: RefObject<HotTableClass>, charges: Charge[]) {
  const sorted = []
  const data = spreadsheetRef.current?.hotInstance?.getData() ?? []
  for (const row of data) {
    const charge = charges.find((c) => c.id === row[0].id)
    if (charge) sorted.push(charge)
  }
  return sorted
}

function approvedCharges(charges: Charge[]) {
  return charges.filter((c) => c.status !== 'On Hold' && c.status !== 'Rejected')
}
