import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation'
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { ReactNode, useEffect, useRef } from 'react'
import { StyleSheet, View, ViewProps } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withSequence, withTiming } from 'react-native-reanimated'
import { ColorStatus, colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Text } from './Text'

type Props = ViewProps & {
  status: ColorStatus
  inset?: boolean
  heading?: string
  body?: string
  icon?: IconProp
  iconSize?: number
  action?: ReactNode
  maxWidth?: number
  compact?: boolean
}

export function Feedback({
  status,
  inset,
  heading,
  body,
  icon,
  iconSize = 70,
  action,
  maxWidth = 280,
  compact,
  style,
  ...props
}: Props) {
  const theme = useTheme()
  const scale = useSharedValue(1)
  const animatedRef = useRef(false)

  useEffect(() => {
    if (animatedRef.current || status === 'info') return

    const easing = theme.easing.curveLinearToEaseOut
    scale.value = withSequence(
      withTiming(1.3, { duration: 150, easing }),
      withTiming(1, { duration: 250, easing }),
    )
    animatedRef.current = true
  }, [theme, status, scale])

  const animatedStyles = useAnimatedStyle(() => ({
    transform: [{ scale: scale.value }],
  }))

  let backgroundColor: string
  let iconColor: string
  const spacingFactor = compact ? 0.8 : 1

  switch (status) {
    case 'success':
      backgroundColor = colorWithOpacity(theme.colors.success, 0.18)
      iconColor = theme.colors.success
      break
    case 'warning':
      backgroundColor = colorWithOpacity(theme.colors.warning, 0.18)
      iconColor = theme.colors.warning
      break
    case 'danger':
      backgroundColor = colorWithOpacity(theme.colors.danger, 0.18)
      iconColor = theme.colors.danger
      break
    case 'info':
      backgroundColor = colorWithOpacity(theme.colors.foreground, 0.08)
      iconColor = colorWithOpacity(theme.colors.foreground, 0.3)
      break
  }

  const styles = StyleSheet.create({
    wrapper: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: inset ? colorWithOpacity(theme.colors.surface, 0.5) : undefined,
      borderWidth: inset ? 1 : 0,
      borderColor: inset ? colorWithOpacity(theme.colors.foreground, 0.1) : undefined,
      borderRadius: inset ? theme.borderRadius.m : 0,
      paddingVertical: inset ? theme.spacing * 2 * spacingFactor : 0,
    },
    iconOuter: {
      width: iconSize,
      height: iconSize,
      borderRadius: iconSize / 2,
      backgroundColor: theme.colors.background,
      overflow: 'hidden',
    },
    iconInner: {
      flex: 1,
      backgroundColor,
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      color: iconColor,
    },
    heading: {
      fontSize: compact ? 20 : 21,
      textAlign: 'center',
      marginTop: theme.spacing * 1.6 * spacingFactor,
      marginBottom: body ? -(theme.spacing * 0.5) : 0,
    },
    body: {
      fontSize: compact ? 15.5 : 16,
      lineHeight: compact ? 20 : 22,
      textAlign: 'center',
      opacity: status === 'info' ? 0.8 : 1,
      maxWidth,
      marginTop: theme.spacing * 1.6 * spacingFactor,
    },
    action: {
      minWidth: 180,
      marginTop: theme.spacing * 1.6 * spacingFactor,
    },
  })

  return (
    <View {...props} style={[styles.wrapper, style]}>
      <Animated.View style={[styles.iconOuter, animatedStyles]}>
        <View style={styles.iconInner}>
          <FontAwesomeIcon
            icon={icon ?? (status === 'info' ? faInfo : status === 'success' ? faCheck : faExclamation)}
            size={iconSize / 2.2}
            style={styles.icon}
          />
        </View>
      </Animated.View>

      {heading && <Text type='heading' style={styles.heading}>{heading}</Text>}

      {body && <Text type='body' style={styles.body}>{body}</Text>}

      {action && <View style={styles.action}>{action}</View>}
    </View>
  )
}
