/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { ComponentProps, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { ActivityIndicator, colorWithBrightness, colorWithOpacity, Divider, useTheme } from 'ui'
import { useAccidentSearch } from '@/queries/accident.queries'
import { useUser } from '@/queries/user.queries'
import { AccidentPreview } from '@/types/accident'
import { format } from '@/utils'

type Context = 'header' | 'input'

type AccidentSearchProps = {
  context: Context
  placeholder?: string
  fullWidth?: boolean
  onSelect: (accident: AccidentPreview) => void
}

export const AccidentSearch = forwardRef<HTMLInputElement, AccidentSearchProps>(({ context, placeholder, fullWidth, onSelect }, ref) => {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const [input, setInput] = useState('')
  const [hovered, setHovered] = useState(false)
  const [focused, setFocused] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => inputRef.current!)

  const accidents = useAccidentSearch(input)

  let inputHeight, fontSize, borderColor, borderColorHover, borderColorFocus, borderRadius
  switch (context) {
    case 'header':
      inputHeight = 38
      fontSize = 15
      borderColor = colorWithOpacity(theme.colors.foreground, 0.1)
      borderColorHover = colorWithOpacity(theme.colors.foreground, 0.15)
      borderColorFocus = colorWithOpacity(theme.colors.foreground, 0.2)
      borderRadius = inputHeight
      break
    case 'input':
      inputHeight = theme.input.height
      fontSize = theme.input.fontSize
      borderColor = theme.input.border
      borderColorHover = theme.input.borderHover
      borderColorFocus = theme.colors.primary
      borderRadius = theme.borderRadius.s
      break
  }

  useEffect(() => {
    if (accidents.data) setActiveIndex(0)
  }, [accidents.data])

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) return

      if (e.key === '/' && context === 'header') {
        inputRef.current?.focus()
        if (!(e.target instanceof HTMLInputElement)) e.preventDefault()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [context])

  function handleSelect(accident: AccidentPreview) {
    onSelect(accident)
    setInput('')
    inputRef.current?.blur()
  }

  const styles = {
    wrapper: css({
      flex: 1,
      minWidth: width >= theme.breakpoints.l ? 380 : width >= theme.breakpoints.m ? 340 : undefined,
      maxWidth: fullWidth ? undefined : 450,
      position: 'relative',
    }),
    input: css({
      width: '100%',
      height: inputHeight,
      background: theme.colors.surface,
      fontFamily: theme.fonts.body[400],
      fontSize,
      border: `1px solid ${borderColor}`,
      borderRadius,
      margin: 0,
      padding: 0,
      paddingLeft: 40,
      paddingRight: 15,
      '::placeholder': {
        color: theme.input.placeholder,
      },
      ':hover': {
        borderColor: borderColorHover,
      },
      ':focus': {
        borderColor: borderColorFocus,
      },
    }),
    icon: css({
      position: 'absolute',
      height: '100%',
      top: 0,
      left: 15,
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'none',
    }),
    shortcut: css({
      position: 'absolute',
      opacity: context === 'header' && hovered && !focused ? 1 : 0,
      height: '100%',
      top: 0,
      right: 15,
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'none',
      transition: 'opacity 0.06s ease-out',
    }),
    shortcutIcon: css({
      width: 21,
      height: 21,
      background: colorWithOpacity(theme.colors.foreground, 0.05),
      borderRadius: 4,
      fontFamily: 'monospace',
      fontSize: 15,
      lineHeight: 1,
      color: colorWithOpacity(theme.colors.foreground, 0.5),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.icon}>
        {accidents.isFetching ? (
          <ActivityIndicator
            color={theme.input.placeholder}
            scale={0.7}
            style={{ marginLeft: -3 }}
          />
        ) : (
          <FontAwesomeIcon
            icon={['far', 'magnifying-glass']}
            size={16}
            color={theme.input.placeholder}
          />
        )}
      </div>

      <input
        ref={inputRef}
        type='text'
        value={input}
        placeholder={placeholder ?? 'Search...'}
        autoCapitalize='words'
        autoCorrect='off'
        css={styles.input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => {
          switch (e.key) {
            case 'Escape':
              if (!input) inputRef.current?.blur()
              setInput('')
              break
            case 'ArrowUp':
              if (activeIndex < 1) return
              setActiveIndex((index) => index - 1)
              break
            case 'ArrowDown':
              if (!accidents.data || activeIndex >= accidents.data.length - 1) return
              setActiveIndex((index) => index + 1)
              break
            case 'Enter':
              if (accidents.data && accidents.data[activeIndex]) {
                handleSelect(accidents.data[activeIndex])
              }
              break
          }
        }}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />

      <div css={styles.shortcut}>
        <div css={styles.shortcutIcon}>/</div>
      </div>

      <Overlay
        context={context}
        input={input}
        inputHeight={inputHeight}
        focused={context === 'header' ? focused : focused && input.length > 0}
        activeIndex={activeIndex}
        loading={accidents.isFetching}
        accidents={accidents.data}
        onItemHover={(index) => setActiveIndex(index)}
        onItemClick={(index) => {
          if (!accidents.data) return
          handleSelect(accidents.data[index])
        }}
      />
    </div>
  )
})

function Overlay({
  context,
  input,
  inputHeight,
  focused,
  activeIndex,
  loading,
  accidents,
  onItemHover,
  onItemClick,
}: {
  context: Context
  input: string
  inputHeight: number
  focused: boolean
  activeIndex: number
  loading: boolean
  accidents: AccidentPreview[] | null | undefined
  onItemHover: (index: number) => void
  onItemClick: (index: number) => void
}) {
  const theme = useTheme()

  const styles = {
    overlay: css({
      position: 'absolute',
      top: inputHeight + (context === 'header' ? 13.5 : theme.spacing * 0.5),
      zIndex: 2,
      width: '100%',
      maxHeight: 380,
      overflowY: 'auto',
      background: theme.colors.surface,
      borderRadius: theme.borderRadius.s + (context === 'header' ? 2 : 0),
      boxShadow: theme.web.shadow.overlay,
      transition: `0.25s ${theme.web.easing.curveLinearToEaseOut}`,
      transformOrigin: '50% 0',
    }),
    info: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 15,
      columnGap: 8,
    }),
    infoIcon: css({
      width: 19,
      height: 19,
      border: `1px solid ${colorWithOpacity(theme.colors.foreground, 0.8)}`,
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    infoText: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      opacity: 0.8,
      '> strong': {
        fontFamily: theme.fonts.body[500],
        fontWeight: '500',
      },
    }),
  }

  return (
    <div
      css={styles.overlay}
      style={{
        visibility: focused ? 'visible' : 'hidden',
        opacity: focused ? 1 : 0,
        transform: focused ? 'none' : 'scale(0.96)',
      }}
    >
      {accidents?.length ? (
        accidents.map((accident, index) => (
          <div key={accident.id}>
            <AccidentSearchItem
              accident={accident}
              active={index === activeIndex}
              onMouseOver={() => onItemHover(index)}
              onClick={() => onItemClick(index)}
            />
            {index !== accidents.length - 1 && <Divider />}
          </div>
        ))
      ) : (
        <div css={styles.info}>
          <div css={styles.infoIcon}>
            <FontAwesomeIcon
              icon={['fas', 'info']}
              size={10}
              color={colorWithOpacity(theme.colors.foreground, 0.8)}
            />
          </div>
          {input.length === 0 ? (
            <div css={styles.infoText}>
              Search for cases by
              <strong> patient name </strong>
              or
              <strong> date of birth</strong>
            </div>
          ) : loading ? (
            <div css={styles.infoText}>Loading...</div>
          ) : (
            <div css={styles.infoText}>No cases found</div>
          )}
        </div>
      )}
    </div>
  )
}

type AccidentSearchItemProps = {
  accident: AccidentPreview
  active?: boolean
} & ComponentProps<'div'>

export function AccidentSearchItem({ accident, active, ...props }: AccidentSearchItemProps) {
  const theme = useTheme()

  const user = useUser()

  const providers = accident.accounts.filter((a) => {
    return user.data?.role === 'provider' ? user.data.providers.map((p) => p.id).includes(a.provider.id) : a
  }).map((a) => a.provider.name)

  const styles = {
    item: css({
      display: 'flex',
      flexDirection: 'column',
      padding: '12px 15px',
      cursor: props.onClick ? 'pointer' : undefined,
    }),
    itemText: css({
      fontFamily: theme.fonts.body[500],
      fontSize: 15,
      marginBottom: theme.spacing * 0.1,
    }),
    itemMeta: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 14,
      opacity: 0.8,
      marginTop: theme.spacing * 0.2,
    }),
  }

  return (
    <div
      {...props}
      css={styles.item}
      style={{
        backgroundColor: active ? colorWithBrightness(theme.colors.surface, -10) : undefined
      }}
    >
      <div css={styles.itemText}>{accident.patient.fullName}</div>
      {accident.patient.dob && (
        <div css={styles.itemMeta}>DOB: {format.date(accident.patient.dob)}</div>
      )}
      {accident.doa && (
        <div css={styles.itemMeta}>DOA: {format.date(accident.doa)}</div>
      )}
      {(user.data?.role !== 'provider' || user.data.providers.length > 1) && (
        <div css={styles.itemMeta}>{providers.join(', ')}</div>
      )}
    </div>
  )
}
