import { useQuery } from '@tanstack/react-query'
import { api } from '@/queries/api'
import { ConfigSchema } from '@/types/config'

export function useConfig() {
  return useQuery({
    queryKey: ['config'],
    queryFn: async () => {
      const res = await api.get('config')
      return ConfigSchema.parse(res.data.config)
    },
    staleTime: 1000 * 60,
  })
}
