import { QueryKey, useQuery } from '@tanstack/react-query'
import { DateRange } from 'ui'
import { api } from './api'
import { ExecutiveDashboardSchema, StaffDashboardSchema } from '@/types/dashboard'

export const keys = {
  staffDashboard: (weekOffset?: number): QueryKey => {
    return weekOffset ? ['dashboard/staff', weekOffset] : ['dashboard/staff']
  },
  executiveDashboard: (dateRange: DateRange, provider: string | undefined): QueryKey => {
    return dateRange && provider
      ? ['dashboard/executive', dateRange, provider]
      : ['dashboard/executive', dateRange]
  },
}

export function useStaffDashboard(weekOffset?: number) {
  return useQuery({
    queryKey: keys.staffDashboard(weekOffset),
    queryFn: async () => {
      const res = await api.get('dashboard/staff', { params: { weekOffset } })
      return StaffDashboardSchema.parse(res.data)
    },
    placeholderData: (previousData) => previousData,
  })
}

export function useExecutiveDashboard(dateRange: DateRange, provider: string | undefined) {
  return useQuery({
    queryKey: keys.executiveDashboard(dateRange, provider),
    queryFn: async () => {
      const res = await api.get('dashboard/executive', { params: { from: dateRange.from, to: dateRange.to, provider } })
      return ExecutiveDashboardSchema.parse(res.data)
    },
    placeholderData: (previousData) => previousData,
  })
}
