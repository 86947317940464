import { QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { z } from 'zod'
import { useStore } from '@/providers/store'
import { api } from '@/queries/api'
import { keys as dashboardKeys } from '@/queries/dashboard.queries'
import { Accident, AccidentSchema } from '@/types/accident'
import { Batch, BatchListItem, BatchListItemSchema, BatchSchema, BatchStatus, BatchStatusSchema, Charge, NewCharge } from '@/types/batch'
import { BatchFilter } from '@/types/filter'
import { format } from '@/utils'

export const keys = {
  batches: (filter?: BatchFilter): QueryKey => filter ? ['batches', filter] : ['batches'],
  batch: (id: number): QueryKey => ['batch', id],
  matchCharge: (id: number): QueryKey => ['matchCharge', id],
}

export function useBatches(filter: BatchFilter) {
  return useQuery({
    queryKey: keys.batches(filter),
    queryFn: async () => {
      const res = await api.get('batches', {
        params: {
          ...filter,
          locations: filter.locations.length ? filter.locations.join(',') : undefined,
          providers: filter.providers.length ? filter.providers.join(',') : undefined,
          statuses: filter.statuses.length ? filter.statuses.join(',') : undefined,
        },
      })
      return {
        batches: res.data.batches.map((item: any) => BatchListItemSchema.parse(item)) as BatchListItem[],
        total: res.data.total,
      }
    },
  })
}

export function useCreateBatch() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (providerId: number) => {
      const res = await api.post('batches', { providerId })
      return BatchSchema.parse(res.data.batch)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.batches() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useBatch(batchId: number) {
  return useQuery({
    queryKey: keys.batch(batchId),
    queryFn: async () => {
      const res = await api.get(`batches/${batchId}`)
      return res.data.batch ? BatchSchema.parse(res.data.batch) : null
    },
  })
}

export function useUpdateBatch() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ batch, status, dop, note }: {
      batch: Batch
      status: BatchStatus | ''
      dop?: string,
      note?: string
    }) => {
      const res = await api.patch(`batches/${batch.id}`, { status, dop, note })
      return BatchSchema.parse(res.data.batch)
    },
    onSuccess: async (batch) => {
      queryClient.setQueryData(keys.batch(batch.id), batch)
      await queryClient.invalidateQueries({ queryKey: keys.batches() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useUpdateBatchNumber() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ batch, buy, number }: {
      batch: Batch
      buy?: string
      number?: string
    }) => {
      const res = await api.patch(`batches/${batch.id}/number`, { buy, number })
      return BatchSchema.parse(res.data.batch)
    },
    onSuccess: async (batch) => {
      queryClient.setQueryData(keys.batch(batch.id), batch)
      queryClient.invalidateQueries({ queryKey: keys.batches() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useDeleteBatch() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ batch }: { batch: Batch }) => {
      return api.delete(`batches/${batch.id}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: keys.batches() })
      queryClient.invalidateQueries({ queryKey: dashboardKeys.staffDashboard() })
    },
  })
}

export function useUpdateCharges() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ batch, charges }: { batch: Batch, charges: Charge[] | NewCharge[] }) => {
      const res = await api.patch(`batches/${batch.id}/charges`, {
        rate: batch.rate,
        charges: charges.map((charge) => ({
          ...charge,
          dob: charge.dob ? format.date(charge.dob, { iso: true }) : undefined,
          doa: charge.doa ? format.date(charge.doa, { iso: true }) : undefined,
          dos: charge.dos ? format.date(charge.dos, { iso: true }) : undefined,
          accident: undefined,
          transaction: undefined,
        })),
      })

      if (res.data.alert) {
        const alert = z.object({
          title: z.string(),
          message: z.string().optional(),
        }).parse(res.data.alert)

        useStore.getState().alert.open({
          title: alert.title,
          message: alert.message,
          buttons: [
            {
              text: 'OK',
              onPress: () => useStore.getState().alert.close(),
            },
          ],
        })
      }

      return BatchSchema.parse(res.data.batch)
    },
    onSuccess: (batch) => {
      queryClient.setQueryData(keys.batch(batch.id), batch)
    },
  })
}

export function useDeleteCharges() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ batch, charges }: { batch: Batch, charges: Charge[] }) => {
      const ids = charges.map((c) => c.id).join(',')
      const res = await api.delete(`batches/${batch.id}/charges?ids=${ids}`)
      return BatchSchema.parse(res.data.batch)
    },
    onSuccess: (batch) => {
      queryClient.setQueryData(keys.batch(batch.id), batch)
    },
  })
}

export function useMatchCharge(id: number) {
  return useQuery({
    queryKey: keys.matchCharge(id),
    queryFn: async () => {
      const res = await api.get(`batches/matchCharge/${id}`)
      return res.data.accidents.map((item: any) => AccidentSchema.parse(item)) as Accident[]
    },
  })
}

export function useCreateAccidentFromCharge() {
  return useMutation({
    mutationFn: async ({ charge }: { charge: Charge }) => {
      const res = await api.post(`batches/accidentFromCharge/${charge.id}`)
      return z.object({ accidentId: z.number() }).parse(res.data)
    },
  })
}

export function useUpdateAssignedAccountsForReplacementTransaction() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ batch, transactionId, accountIds }: {
      batch: Batch
      transactionId: number
      accountIds: number[]
    }) => {
      const res = await api.patch(`batches/${batch.id}/assignedAccountsForReplacementTransaction`, { transactionId, accountIds })
      return BatchSchema.parse(res.data.batch)
    },
    onSuccess: (batch) => {
      queryClient.setQueryData(keys.batch(batch.id), batch)
    },
  })
}

export function useSubmitBatch() {
  return useMutation({
    mutationFn: (slug?: string) => api.post('batches/submit', { slug }),
  })
}

export function useApproveBatch() {
  return useMutation({
    mutationFn: async (slug?: string) => {
      const res = await api.post('batches/approve', { slug })
      return BatchStatusSchema.parse(res.data.status)
    },
  })
}
