import { BottomSheetBackdrop, BottomSheetModal, BottomSheetView, useBottomSheetTimingConfigs } from '@gorhom/bottom-sheet'
import { ReactNode, useEffect, useRef } from 'react'
import { StyleSheet, useWindowDimensions, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTheme } from '../hooks/useTheme'
import { Text } from './Text'
import { TextLink } from './TextLink'

type Props = {
  open: boolean
  title: string
  headerActionLeft?: HeaderAction
  children: ReactNode
  onClose: () => void
}

type HeaderAction = {
  text: string
  destructive?: boolean
  onPress: () => void
}

export function BottomSheet({ open, title, headerActionLeft, children, onClose }: Props) {
  const { width } = useWindowDimensions()
  const insets = useSafeAreaInsets()
  const theme = useTheme()

  const ref = useRef<BottomSheetModal>(null)

  useEffect(() => {
    if (open) {
      ref.current?.present()
    } else {
      ref.current?.dismiss()
    }
  }, [open, onClose])

  const styles = StyleSheet.create({
    wrapper: {
      width: width >= theme.breakpoints.s ? 450 : undefined,
      marginHorizontal: width >= theme.breakpoints.s ? (width - 450) / 2 : undefined,
    },
    background: {
      backgroundColor: theme.colors.background,
      borderRadius: theme.borderRadius.m,
    },
    header: {
      flexDirection: 'row',
      paddingTop: 12,
      paddingHorizontal: theme.spacing,
    },
    headerTitle: {
      flex: 1,
      textAlign: 'center',
    },
    headerAction: {
      width: 70,
    },
    content: {
      paddingBottom: insets.bottom,
    },
  })

  return (
    <BottomSheetModal
      ref={ref}
      enableDynamicSizing={true}
      handleComponent={null}
      animationConfigs={useBottomSheetTimingConfigs({
        duration: 350,
        easing: theme.easing.curveLinearToEaseOut,
      })}
      backdropComponent={props => (
        <BottomSheetBackdrop
          {...props}
          appearsOnIndex={0}
          disappearsOnIndex={-1}
          opacity={0.15}
          onPress={onClose}
        />
      )}
      style={styles.wrapper}
      backgroundStyle={styles.background}
      onDismiss={onClose}
    >
      <BottomSheetView style={styles.content}>
        <View style={styles.header}>
          {headerActionLeft && (
            <TextLink
              type={headerActionLeft.destructive ? 'danger' : 'info'}
              size={16.5}
              style={styles.headerAction}
              onPress={headerActionLeft.onPress}
            >{headerActionLeft.text}
            </TextLink>
          )}

          <Text type='headerTitle' style={styles.headerTitle}>{title}</Text>

          <TextLink
            type='info'
            size={16.5}
            style={{ ...styles.headerAction, justifyContent: 'flex-end' }}
            onPress={onClose}
          >Done
          </TextLink>
        </View>
        {children}
      </BottomSheetView>
    </BottomSheetModal>
  )
}
