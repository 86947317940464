import { z } from 'zod'
import { ProcedureSchema } from './procedure'

export const ProviderClassificationSchema = z.enum([
  'Mature',
  'New',
  'Former',
  'Other',
])

export const ProviderSchema = z.object({
  id: z.number(),
  name: z.string(),
  procedure: ProcedureSchema,
  rate: z.number().nullable(),
})

export const ProviderListItemSchema = ProviderSchema.extend({
  users: z.object({
    id: z.number(),
    fullName: z.string(),
  }).array(),
  staff: z.object({
    id: z.number(),
    fullName: z.string(),
  }).nullable(),
  state: z.string(),
  classification: ProviderClassificationSchema,
}).transform((provider) => ({
  ...provider,
  manager: provider.users[0],
}))

export type Provider = z.infer<typeof ProviderSchema>
export type ProviderListItem = z.infer<typeof ProviderListItemSchema>
