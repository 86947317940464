import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { router } from 'expo-router'
import { Platform } from 'react-native'
import { Toast } from 'react-native-toast-universal'
import z from 'zod'
import { useStore } from '@/providers/store'

export const client = new QueryClient({
  defaultOptions: {
    queries: { retry: false },
    mutations: { retry: false },
  },
  queryCache: new QueryCache({
    onError: (error) => handleError(error, false),
  }),
  mutationCache: new MutationCache({
    onError: (error) => handleError(error, true),
  }),
})

function handleError(error: Error, showToast: boolean) {
  if (isAxiosError(error) && error.response) {
    if (error.response.data.toast) {
      Toast.error(error.response.data.toast)
    }

    switch (error.response.status) {
      case 400:
        return
      case 401:
        useStore.getState().modal.close()
        useStore.getState().alert.close()
        // TODO: make redirect work on native
        if (location.pathname !== '/auth') {
          router.replace(Platform.OS === 'web' ? `/auth?redirect=${location.pathname}` : '/welcome')
        }
        return
      case 403:
        router.replace('/')
        return
    }
  }

  if (showToast) {
    Toast.error('Something went wrong. Our team has been notified. Please try again later.')
  }

  if (process.env.NODE_ENV === 'development') throw error
}

export function fieldErrors(error: Error) {
  if (isAxiosError(error) && error.response?.data.error) {
    return error.response.data.error
  } else {
    return undefined
  }
}

export function alertError(error: Error) {
  if (isAxiosError(error) && error.response?.data.alert) {
    return z.object({
      title: z.string(),
      message: z.string().optional(),
    }).parse(error.response.data.alert)
  } else {
    return undefined
  }
}
