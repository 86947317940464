import { StyleSheet, View } from 'react-native'
import { useTheme } from '../hooks/useTheme'

type Props = {
  width?: number
  height?: number
}

export function Spacing({ width = 0, height = 0 }: Props) {
  const theme = useTheme()

  const styles = StyleSheet.create({
    wrapper: {
      width: width * theme.spacing,
      height: height * theme.spacing,
    }
  })

  return <View style={styles.wrapper} />
}
