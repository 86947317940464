/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import { ReactNode } from 'react'
import { useTheme } from '../../hooks/useTheme'

type Props = {
  content: ReactNode
  children: ReactNode
}

export function Tooltip({ content, children }: Props) {
  const theme = useTheme()


  const slideUpFadeIn = keyframes({
    from: {
      opacity: 0,
      transform: 'translateY(2px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  })
  const slideDownFadeOut = keyframes({
    from: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    to: {
      opacity: 0,
      transform: 'translateY(2px)',
    },
  })

  const styles = {
    trigger: css({
      lineHeight: 0.7,
    }),
    content: css({
      color: theme.colors.foreground,
      background: theme.colors.surface,
      borderRadius: theme.borderRadius.s + 1,
      border: `1px solid ${theme.colors.panelBorder}`,
      boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
      fontFamily: theme.fonts.body[400],
      fontSize: 14,
      lineHeight: 0.9,
      padding: theme.spacing * 0.5,
      transformOrigin: 'var(--radix-tooltip-content-transform-origin)',
      animationDuration: '0.2s',
      animationTimingFunction: theme.web.easing.curveLinearToEaseOut,
      willChange: 'transform, opacity',
      '&[data-state=delayed-open]': {
        animationName: slideUpFadeIn,
      },
      '&[data-state=closed]': {
        animationName: slideDownFadeOut,
      },
    }),
  }

  return (
    <RadixTooltip.Provider delayDuration={200}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild={true}>
          <span css={styles.trigger}>{children}</span>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content sideOffset={theme.spacing * 0.5} css={styles.content}>
            {content}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}
