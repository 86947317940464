import { StyleSheet, View, ViewProps } from 'react-native'
import { useTheme } from '../hooks/useTheme'

type Props = ViewProps & {
  maxWidth?: number
  margin?: boolean
  disabled?: boolean
  fillAvailableSpace?: boolean
}

export function Wrapper({
  maxWidth,
  margin = true,
  disabled,
  fillAvailableSpace,
  style,
  children,
  ...props
}: Props) {
  const theme = useTheme()

  const styles = StyleSheet.create({
    outer: {
      flex: fillAvailableSpace ? 1 : undefined,
      alignItems: 'center',
      marginHorizontal: margin ? theme.spacing : 0,
    },
    inner: {
      width: '100%',
      maxWidth: maxWidth ?? (margin ? theme.breakpoints.l : theme.breakpoints.l + theme.spacing * 2),
    },
  })

  if (disabled) return children
  return (
    <View {...props} style={styles.outer}>
      <View style={[styles.inner, style]}>
        {children}
      </View>
    </View>
  )
}
