import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { useTheme } from '../hooks/useTheme'

type Props = {
  vertical?: boolean
  style?: StyleProp<ViewStyle>
}

export function Divider({ vertical, style }: Props) {
  const theme = useTheme()

  const styles = StyleSheet.create({
    divider: {
      height: vertical ? undefined : StyleSheet.hairlineWidth,
      width: vertical ? StyleSheet.hairlineWidth : undefined,
      backgroundColor: theme.colors.divider,
    },
  })

  return <View style={[styles.divider, style]} />
}
