import { router } from 'expo-router'
import { View } from 'react-native'
import { Button, Spacing, Text } from 'ui'

export function Welcome() {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text type='subheading'>Welcome Screen</Text>
      <Spacing height={3} />
      <Button
        text='Get Started'
        type='primary'
        style={{ minWidth: 220 }}
        onPress={() => router.navigate('/auth')}
      />
    </View>
  )
}
