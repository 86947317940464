import { Redirect } from 'expo-router'
import { useUser } from '@/queries/user.queries'

export default function Home() {
  const user = useUser()

  if (user.data) {
    return <Redirect href={user.data.role !== 'provider' ? '/dashboard' : '/cases'} />
  } else {
    return null
  }
}
