import { faCamera } from '@fortawesome/pro-solid-svg-icons/faCamera'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { MenuView } from '@react-native-menu/menu'
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker'
import { Dispatch, SetStateAction, useState } from 'react'
import { Platform, Pressable, StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native'
import { useTheme } from '../hooks/useTheme'

type Props = ViewProps & {
  setPhoto: Dispatch<SetStateAction<string | undefined>>
  width: number
  height: number
  contentStyle?: StyleProp<ViewStyle>
  onCameraPermissionsDenied: () => void
}

export function EditablePhoto({
  setPhoto,
  width,
  height,
  contentStyle,
  children,
  onCameraPermissionsDenied,
  ...props
}: Props) {
  const theme = useTheme()

  const [pressed, setPressed] = useState(false)

  async function handlePress(source: string) {
    let result: ImagePicker.ImagePickerResult
    if (source === 'library') {
      result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
      })
    } else {
      const cameraPermissions = await ImagePicker.requestCameraPermissionsAsync()
      if (!cameraPermissions.granted) {
        onCameraPermissionsDenied()
        return
      }

      result = await ImagePicker.launchCameraAsync({
        allowsEditing: true,
        aspect: [1, 1],
      })
    }

    if (!result.canceled) {
      if (!result.assets[0]) throw new Error('No picked asset')
      const resized = await manipulateAsync(
        result.assets[0].uri,
        [{ resize: { width, height } }],
        { compress: 0.8, format: SaveFormat.JPEG, base64: true },
      )
      setPhoto('data:image/jpeg;base64,' + resized.base64)
    }
  }

  const styles = StyleSheet.create({
    edit: {
      position: 'absolute',
      bottom: -3,
      right: -3,
      width: 30,
      height: 30,
      backgroundColor: theme.colors.pageBackground,
      borderRadius: 15,
      justifyContent: 'center',
      alignItems: 'center',
    },
    editInner: {
      width: 26,
      height: 26,
      backgroundColor: theme.colors.primary,
      borderRadius: 13,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  return (
    <View {...props}>
      <MenuView
        actions={[
          {
            id: 'library',
            title: 'Add Photo from Library',
            image: Platform.select({
              ios: 'photo.on.rectangle',
              android: 'ic_menu_gallery',
            }),
          },
          {
            id: 'camera',
            title: 'Take Photo with Camera',
            image: Platform.select({
              ios: 'camera',
              android: 'ic_menu_camera',
            }),
          },
        ]}
        onPressAction={async ({ nativeEvent }) => handlePress(nativeEvent.event)}
      >
        <Pressable
          style={contentStyle}
          onPressIn={() => setPressed(true)}
          onPressOut={() => setPressed(false)}
          onPress={Platform.OS === 'web' ? () => handlePress('libary') : undefined}
        >
          <View style={{ opacity: pressed ? 0.3 : 1 }}>{children}</View>

          <View style={styles.edit}>
            <View style={styles.editInner}>
              <FontAwesomeIcon
                icon={faCamera}
                size={15}
                color={theme.colors.background}
              />
            </View>
          </View>
        </Pressable>
      </MenuView>
    </View>
  )
}
