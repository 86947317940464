import { Stack, useLocalSearchParams } from 'expo-router'
import { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { ActivityIndicator, Feedback } from 'ui'
import { Head } from '@/components/web/Head'
import { useApproveBatch } from '@/queries/batch.queries'

export function ApproveBatch() {
  const params = useLocalSearchParams<{ slug?: string }>()

  const { mutate: approveBatch, isPending, isSuccess, data } = useApproveBatch()

  useEffect(() => approveBatch(params.slug), [params.slug, approveBatch])

  const styles = StyleSheet.create({
    wrapper: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  return <>
    <Stack.Screen options={{ title: 'Approve Funding Request' }} />
    <Head title='Approve Funding Request' />

    <View style={styles.wrapper}>
      {isPending ? (
        <ActivityIndicator />
      ) : isSuccess ? (
        <Feedback
          status='success'
          heading='Funding Request Approved'
          body={`The funding request ${data === 'Approved' ? 'was successfully approved' : 'is now pending final review and approval'}.`}
        />
      ) : (
        <Feedback
          status='danger'
          heading='Approve Funding Request'
          body='We were unable to approve the funding request.'
        />
      )}
    </View>
  </>
}
