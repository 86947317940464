/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { MenuView } from '@react-native-menu/menu'
import { useState } from 'react'
import { Platform, Pressable, StyleSheet, View, ViewProps } from 'react-native'
import { colorWithBrightness, colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Text } from './Text'

type TOptionShape = string | { value: string, label: string }

type Props<TOption extends TOptionShape> = ViewProps & {
  label?: string
  value: string
  options: TOption[]
  compact?: boolean
  placeholder?: string
  disabled?: boolean
  error?: string | boolean
  reverseOptions?: boolean
  onChange: (value: string) => void
}

export function Select<TOption extends TOptionShape>(props: Props<TOption>) {
  return Platform.OS === 'web' ? <SelectWeb {...props} /> : <SelectNative {...props} />
}

function SelectNative<TOption extends TOptionShape>({
  label,
  value,
  options,
  compact,
  placeholder,
  disabled,
  error,
  reverseOptions,
  onChange,
  ...props
}: Props<TOption>) {
  const theme = useTheme()

  // FIXME: options flip if menu is in lower part of screen: https://github.com/react-native-menu/menu/issues/304
  const _options = reverseOptions ? [...options].reverse() : options
  const height = compact ? theme.input.compact.height : theme.input.height

  let valueLabel
  if (value) {
    const option = options.find((o) => typeof o === 'string' ? o === value : o.value === value)
    if (option) {
      valueLabel = typeof option === 'string' ? option : option.label
    }
  }

  const styles = StyleSheet.create({
    input: {
      position: 'relative',
      height,
      paddingHorizontal: height * 0.3,
      borderWidth: 1,
      borderColor: error ? theme.colors.danger : theme.input.border,
      borderRadius: theme.borderRadius.s,
      justifyContent: 'center',
      ...theme.input.shadow,
    },
    inputIcon: {
      position: 'absolute',
      right: height * 0.3 - 1,
    },
  })

  return (
    <View {...props}>
      {label && <Text type='inputLabel' compact={compact}>{label}</Text>}

      <MenuView
        actions={_options.map((option) => {
          const optionValue = typeof option === 'string' ? option : option.value
          const optionLabel = typeof option === 'string' ? option : option.label
          return {
            id: optionValue,
            title: optionLabel,
            state: value === optionValue ? 'on' : 'off',
          }
        })}
        onPressAction={({ nativeEvent }) => onChange(nativeEvent.event)}
      >
        <Pressable
          disabled={disabled}
          style={({ pressed }) => ({
            ...styles.input,
            // FIXME: only highlight when menu is closed, need onOpenChange event to fix: https://github.com/react-native-menu/menu/issues/305
            backgroundColor: pressed ? colorWithOpacity(theme.colors.foreground, 0.1) : theme.colors.surface,
          })}
        >
          {value ? (
            <Text type='inputText'>{valueLabel}</Text>
          ) : (
            <Text type='inputText' style={{ color: theme.input.placeholder }}>{placeholder}</Text>
          )}

          <FontAwesomeIcon
            icon={faAngleDown}
            size={19}
            color={colorWithOpacity(theme.colors.foreground, 0.7)}
            style={styles.inputIcon}
          />
        </Pressable>
      </MenuView>

      {typeof error === 'string' && <Text type='inputError' compact={compact}>{error}</Text>}
    </View>
  )
}

function SelectWeb<TOption extends TOptionShape>({
  label,
  value,
  options,
  compact,
  placeholder,
  disabled,
  error,
  onChange,
  ...props
}: Props<TOption>) {
  const theme = useTheme()

  const [focused, setFocused] = useState(false)

  const height = compact ? theme.input.compact.height : theme.input.height

  const styles = {
    wrapper: css({
      position: 'relative',
    }),
    select: css({
      width: '100%',
      appearance: 'none',
      height,
      backgroundColor: theme.colors.surface,
      color: colorWithOpacity(theme.colors.foreground, value ? 1 : 0.55),
      paddingLeft: height * 0.3,
      paddingRight: height * 0.3 + height * 0.6,
      borderWidth: 1,
      borderColor: error ? theme.colors.danger
        : focused ? theme.colors.primary : theme.input.border,
      borderRadius: theme.borderRadius.s,
      boxShadow: theme.web.shadow.input,
      fontFamily: theme.input.fontFamily,
      fontSize: compact ? theme.input.compact.fontSize : theme.input.fontSize,
      userSelect: 'none',
      textOverflow: 'ellipsis',
      '&:not(:disabled)': {
        cursor: 'pointer',
      },
      '&:hover:not(:disabled)': {
        backgroundColor: colorWithBrightness(theme.colors.surface, -5),
      },
      '&:disabled': {
        opacity: 0.5,
      },
    }),
    icon: css({
      position: 'absolute',
      top: 0,
      right: height * 0.3 - 1,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'none',
    }),
  }

  return (
    <View {...props}>
      {label && (
        <Text type='inputLabel' compact={compact}>
          <label htmlFor={label}>{label}</label>
        </Text>
      )}

      <div css={styles.wrapper}>
        <select
          id={label}
          value={value}
          disabled={disabled}
          css={styles.select}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        >
          {placeholder && <option value='' disabled={true}>{placeholder}</option>}
          {options.map((option) => {
            const optionValue = typeof option === 'string' ? option : option.value
            const optionLabel = typeof option === 'string' ? option : option.label
            return (
              <option key={optionValue} value={optionValue}>{optionLabel}</option>
            )
          })}
        </select>

        <div css={styles.icon}>
          <FontAwesomeIcon
            icon={faAngleDown}
            size={compact ? 17 : 19}
            color={colorWithOpacity(theme.colors.foreground, disabled ? 0.35 : 0.7)}
          />
        </div>
      </div>

      {typeof error === 'string' && <Text type='inputError' compact={compact}>{error}</Text>}
    </View>
  )
}
