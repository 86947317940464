import { ReactNode } from 'react'
import { FlatList, Platform, Pressable, StyleSheet, useWindowDimensions, View } from 'react-native'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Card } from './Card'
import { Divider } from './Divider'
import { Spacing } from './Spacing'

type Props = {
  items: {
    children: ReactNode
    onPress?: () => void
  }[]
}

export function CardList({ items }: Props) {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const numColumns = width >= theme.breakpoints.l ? 3 : width >= theme.breakpoints.s ? 2 : 1
  const totalWidth = width >= theme.breakpoints.l + theme.spacing * 2 ? theme.breakpoints.l : width - theme.spacing * 2

  const styles = StyleSheet.create({
    flatListContent: {
      marginHorizontal: theme.spacing,
      marginBottom: theme.spacing * 2,
    },
    singleColumnCard: {
      marginHorizontal: theme.spacing,
      marginBottom: theme.spacing * 2,
    },
  })

  return (
    numColumns > 1 ? (
      <FlatList
        key={numColumns}
        data={items}
        renderItem={({ item, index }) => (
          <View
            style={{
              backgroundColor: theme.colors.surface,
              borderRadius: theme.borderRadius.m,
              width: (totalWidth - (numColumns - 1) * theme.spacing) / numColumns,
              marginRight: numColumns > 1 && (index + 1) % numColumns !== 0 ? theme.spacing : 0,
            }}
          >
            <Pressable
              disabled={!item.onPress}
              style={({ hovered, pressed }) => ({
                flex: 1,
                backgroundColor: pressed && Platform.OS !== 'web' ? colorWithOpacity(theme.colors.foreground, 0.15) : undefined,
                borderWidth: 1,
                borderColor: colorWithOpacity(theme.colors.foreground, hovered ? 0.25 : 0.1),
                borderRadius: theme.borderRadius.m,
              })}
              onPress={item.onPress}
            >
              {item.children}
            </Pressable>
          </View>
        )}
        numColumns={numColumns}
        scrollEnabled={false}
        ItemSeparatorComponent={() => <Spacing height={1} />}
        contentContainerStyle={styles.flatListContent}
      />
    ) : (
      <Card style={styles.singleColumnCard}>
        {items.map((item, index) => (
          <View key={index}>
            <Pressable
              disabled={!item.onPress}
              style={({ pressed }) => ({
                backgroundColor: pressed ? colorWithOpacity(theme.colors.foreground, 0.15) : undefined,
              })}
              onPress={item.onPress}
            >
              {item.children}
            </Pressable>
            {index < items.length - 1 && <Divider />}
          </View>
        ))}
      </Card>
    )
  )
}
