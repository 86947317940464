import * as AppleAuthentication from 'expo-apple-authentication'
import * as AuthSession from 'expo-auth-session'
import AppleLogin from 'react-apple-login'
import { Platform, View } from 'react-native'
import { Toast } from 'react-native-toast-universal'
import { Button } from '../../components/Button'
import { AppleLogo } from '../../components/Icons'
import { setMode, SignInWithIdToken } from './AuthModule'

type Props = {
  setMode: setMode
  apple: {
    servicesId: string
    signIn: SignInWithIdToken
  }
}

export function AppleAuth({ setMode, apple }: Props) {
  switch (Platform.OS) {
    case 'ios': return <AppleAuthIos setMode={setMode} apple={apple} />
    case 'web': return <AppleAuthWeb setMode={setMode} apple={apple} />
    default: return null
  }
}

function AppleAuthIos({ setMode, apple }: Props) {
  return (
    <AppleAuthButton
      loading={apple.signIn.loading}
      onPress={async () => {
        try {
          const credential = await AppleAuthentication.signInAsync({
            requestedScopes: [
              AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
              AppleAuthentication.AppleAuthenticationScope.EMAIL,
            ],
          })

          apple.signIn.onPress(credential.identityToken!, (form) => {
            if (form) {
              setMode({
                screen: 'signup',
                params: {
                  email: form.email,
                  firstName: credential.fullName?.givenName ?? undefined,
                  lastName: credential.fullName?.familyName ?? undefined,
                  emailVerified: true,
                },
              })
            }
          })
        } catch (e: any) {
          if (e.code !== 'ERR_REQUEST_CANCELED') Toast.error(e.message)
        }
      }}
    />
  )
}

function AppleAuthWeb({ setMode, apple }: Props) {
  return (
    <AppleLogin
      clientId={apple.servicesId}
      scope='name email'
      redirectURI={AuthSession.makeRedirectUri()}
      usePopup={true}
      render={({ onClick }) => (
        <AppleAuthButton
          loading={apple.signIn.loading}
          onPress={onClick}
        />
      )}
      callback={async (response) => {
        if (response.error) {
          if (response.error.error !== 'popup_closed_by_user') Toast.error(response.error.error)
          return
        }

        apple.signIn.onPress(response.authorization.id_token, (form) => {
          if (form) {
            setMode({
              screen: 'signup',
              params: {
                email: form.email,
                firstName: response.user?.name?.firstName,
                lastName: response.user?.name?.lastName,
                emailVerified: true,
              },
            })
          }
        })
      }}
    />
  )
}

function AppleAuthButton({ disabled, loading, onPress }: {
  disabled?: boolean
  loading?: boolean
  onPress: () => void
}) {
  return (
    <Button
      text='Continue with Apple'
      type='tertiary'
      textLeft={(
        <View style={{ flex: 1 }}>
          <AppleLogo style={{ marginLeft: 1 }} />
        </View>
      )}
      textRight={(
        <View style={{ flex: 1 }} />
      )}
      disabled={disabled}
      loading={loading}
      onPress={onPress}
    />
  )
}
