import { router } from 'expo-router'
import { useStore } from '@/providers/store'
import { useStaffDashboard } from '@/queries/dashboard.queries'
import { AccidentDetails } from '@/screens/accidents/AccidentDetails'

export default function DashboardAccidentDetailsScreen() {
  const weekOffset = useStore.useStaffDashboardWeekOffset()

  const dashboard = useStaffDashboard(weekOffset)

  const accidentIds: number[] = []
  for (const check of dashboard.data?.checksReceived ?? []) {
    if (!accidentIds.includes(check.accidentId)) accidentIds.push(check.accidentId)
  }

  return (
    <AccidentDetails
      list={accidentIds.length ? {
        items: accidentIds,
        onNav: (accidentId: number) => router.navigate(`/dashboard/checks-received/${accidentId}`),
      } : undefined}
    />
  )
}
