import { useState } from 'react'
import { GestureResponderEvent, Platform, Pressable, StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'

type Props = ViewProps & {
  contentStyle?: StyleProp<ViewStyle>
  onPress?: (e: GestureResponderEvent) => void
}

export function Card({ contentStyle, children, onPress, ...props }: Props) {
  const theme = useTheme()

  const [hovered, setHovered] = useState(false)

  const scale = useSharedValue(1)
  const animatedStyles = useAnimatedStyle(() => ({
    flex: 1,
    transform: [{
      scale: withTiming(scale.value, {
        duration: 350,
        easing: theme.easing.curveLinearToEaseOut,
      }),
    }],
  }))

  const styles = StyleSheet.create({
    outerShadow: {
      flex: 1,
      shadowColor: colorWithOpacity('#000000', 0.1),
      shadowOffset: { width: 0, height: 8 },
      shadowRadius: 16,
      shadowOpacity: hovered ? 0.5 : 0.25,
      elevation: 16,
      backgroundColor: theme.colors.surface,
      borderRadius: theme.borderRadius.m,
    },
    innerShadow: {
      flex: 1,
      shadowColor: colorWithOpacity('#000000', 0.1),
      shadowOffset: { width: 0, height: 4 },
      shadowRadius: 8,
      shadowOpacity: hovered ? 0.5 : 0.25,
      elevation: 8,
      backgroundColor: theme.colors.surface,
      borderRadius: theme.borderRadius.m,
    },
    content: {
      flex: 1,
      borderRadius: theme.borderRadius.m,
      overflow: 'hidden',
    },
  })

  if (onPress) {
    return (
      <Pressable
        {...props}
        onHoverIn={() => setHovered(true)}
        onHoverOut={() => setHovered(false)}
        onPress={(e) => {
          scale.value = 1
          onPress(e)
        }}
        onPressIn={() => (scale.value = Platform.OS === 'web' ? 1 : 0.96)}
        onPressOut={() => (scale.value = 1)}
      >
        <Animated.View style={animatedStyles}>
          <View style={styles.outerShadow}>
            <View style={styles.innerShadow}>
              <View style={[styles.content, contentStyle]}>
                {children}
              </View>
            </View>
          </View>
        </Animated.View>
      </Pressable>
    )
  } else {
    return (
      <View {...props}>
        <View style={styles.outerShadow}>
          <View style={styles.innerShadow}>
            <View style={[styles.content, contentStyle]}>
              {children}
            </View>
          </View>
        </View>
      </View>
    )
  }
}
