import { Platform, ActivityIndicator as RNActivityIndicator, StyleSheet, ViewProps } from 'react-native'
import { useTheme } from '../hooks/useTheme'

type Props = ViewProps & {
  color?: string
  scale?: number
  inverted?: boolean
}

export function ActivityIndicator({ color, scale = 1, inverted, style, ...props }: Props) {
  const theme = useTheme()

  let _scale = scale
  if (Platform.OS === 'web') {
    _scale = scale * 1.15
  } else if (Platform.OS === 'android') {
    _scale = scale * 1.3
  }

  const styles = StyleSheet.create({
    activityIndicator: {
      transform: [{ scale: _scale }],
      width: Platform.OS === 'android' ? 23 : 20,
      height: Platform.OS === 'android' ? 23 : 20,
    },
  })

  return (
    <RNActivityIndicator
      {...props}
      color={color ?? (inverted ? '#FFFFFF' : (Platform.OS === 'ios' ? undefined : theme.colors.primary))}
      size='small'
      style={[styles.activityIndicator, style]}
    />
  )
}
