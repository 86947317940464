import axios from 'axios'
import * as Application from 'expo-application'
import Constants from 'expo-constants'
import * as Device from 'expo-device'
import { Platform } from 'react-native'

let baseURL: string | undefined
if (__DEV__) {
  const port = 8302
  if (Platform.OS === 'web') {
    baseURL = `http://localhost:${port}`
  } else {
    const hostname = Constants.expoConfig?.hostUri?.split(':').shift()
    baseURL = `http://${hostname}:${port}`
  }
}

let userAgent: string | undefined
if (Platform.OS !== 'web') {
  userAgent = Device.osName?.toLowerCase()
    + ':' + Device.osVersion
    + '/' + Application.nativeApplicationVersion
}

export const api = axios.create({
  baseURL: baseURL ?? process.env.EXPO_PUBLIC_API_URL,
  withCredentials: true,
  headers: { 'User-Agent': userAgent },
})
