import { router } from 'expo-router'
import { Platform, StyleSheet, View } from 'react-native'
import { Button, Spacing, Text, useTheme } from 'ui'
import { Head } from '@/components/web/Head'

export default function NotFound() {
  const theme = useTheme()

  const styles = StyleSheet.create({
    wrapper: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    heading: {
      fontFamily: theme.fonts.body[700],
      fontWeight: '700',
      fontSize: 22,
    },
    body: {
      fontSize: 16,
      lineHeight: 24,
      textAlign: 'center',
      maxWidth: 220,
    },
    button: {
      minWidth: 150,
    },
  })

  return <>
    <Head title='Page Not Found' />

    <View style={styles.wrapper}>
      <Text style={styles.heading}>Page Not Found</Text>
      <Spacing height={1.4} />
      <Text style={styles.body}>We couldn't find the page you were looking for. Sorry!</Text>
      <Spacing height={1.8} />
      <Button
        text='Back to Home'
        type='primary'
        style={styles.button}
        onPress={() => {
          if (Platform.OS === 'web') {
            router.replace('/')
          } else {
            router.back()
          }
        }}
      />
    </View>
  </>
}
