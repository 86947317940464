/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { router, useLocalSearchParams } from 'expo-router'
import isEqual from 'lodash/isEqual'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Toast } from 'react-native-toast-universal'
import { states } from 'shared'
import { ActivityIndicator, Autocomplete, Button, colorWithBrightness, colorWithOpacity, EditableValue, EditableValueTooltip, NotFound, Panel, Select, Spacing, Table, Text, useTheme, Wrapper } from 'ui'
import { Head } from '@/components/web/Head'
import { TextArea, TextInput } from '@/components/web/TextInput'
import { useStore } from '@/providers/store'
import { fieldErrors } from '@/queries/client'
import { useCreateLawFirmContact, useDeleteLawFirm, useDeleteLawFirmContact, useLawFirm, useLawFirmSearch, useMergeLawFirms, useUpdateLawFirm, useUpdateLawFirmContact } from '@/queries/law-firm.queries'
import { useSaveAccidentFilter } from '@/queries/user.queries'
import { statusesFromPortfolioStatus } from '@/types/accident'
import { LawFirmContact, LawFirmContactRoleSchema, LawFirmProfile, lawFirmRatingMap } from '@/types/law-firm'
import { colorFromIRR, colorFromROI, format } from '@/utils'

export function LawFirm() {
  const params = useLocalSearchParams<{ lawFirmId?: string }>()
  const theme = useTheme()

  const lawFirm = useLawFirm(Number(params.lawFirmId))

  const styles = {
    loading: css({
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }

  return <>
    <Head title='Law Firm' />

    {lawFirm.isPending ? (
      <div css={styles.loading}><ActivityIndicator /></div>
    ) : (
      <Wrapper maxWidth={1000} style={{ marginVertical: theme.spacing * 1.5 }}>
        {lawFirm.data ? (
          <LawFirmViewer lawFirm={lawFirm.data} />
        ) : (
          <Panel>
            <NotFound item='Law Firm' style={{ marginVertical: theme.spacing * 3 }} />
          </Panel>
        )}
      </Wrapper>
    )}
  </>
}

function LawFirmViewer({ lawFirm }: { lawFirm: LawFirmProfile }) {
  const theme = useTheme()

  const styles = {
    panelContent: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 2,
      padding: theme.spacing * 1.2,
    }),
  }

  return <>
    <Header lawFirm={lawFirm} />
    <Panel>
      <div css={styles.panelContent}>
        <Overview lawFirm={lawFirm} />
        <Notes lawFirm={lawFirm} />
        <Contacts lawFirm={lawFirm} />
        <Portfolio lawFirm={lawFirm} />
        <ChecksReceived lawFirm={lawFirm} />
      </div>
    </Panel>
  </>
}

function Header({ lawFirm }: { lawFirm: LawFirmProfile }) {
  const theme = useTheme()
  const modal = useStore.useModal()
  const alert = useStore.useAlert()

  const updateLawFirm = useUpdateLawFirm()
  const deleteLawFirm = useDeleteLawFirm()

  const [name, setName] = useState(lawFirm.name)
  const [rating, setRating] = useState(lawFirmRatingMap[lawFirm.rating])
  const [prevLawFirm, setPrevLawFirm] = useState(lawFirm)
  if (!isEqual(lawFirm, prevLawFirm)) {
    setName(lawFirm.name)
    setRating(lawFirmRatingMap[lawFirm.rating])
    setPrevLawFirm(lawFirm)
  }

  const styles = {
    wrapper: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing,
      flexWrap: 'wrap',
      marginBottom: theme.spacing,
    }),
    heading: css({
      display: 'flex',
      flexDirection: 'column',
      columnGap: theme.spacing * 0.8,
      rowGap: theme.spacing * 0.4,
      [`@media (min-width: ${theme.breakpoints.s}px)`]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      '.info': {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing * 0.8,
        rowGap: theme.spacing * 0.4,
        flexWrap: 'wrap',
      },
      '.text': {
        fontFamily: theme.fonts.body[400],
        fontSize: 15.5,
        opacity: 0.9,
      },
      '.divider': {
        display: 'inline-block',
        width: 1,
        height: 20,
        background: theme.colors.divider,
        ':first-child': {
          display: 'none',
          [`@media (min-width: ${theme.breakpoints.s}px)`]: {
            display: 'inline-block',
          },
        },
      },
    }),
    buttons: css({
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing * 0.5,
      flexWrap: 'wrap',
    }),
  }

  return (
    <div css={styles.wrapper}>
      <EditableValue
        form={{
          fields: [
            <TextInput
              label='Name'
              value={name}
              compact={true}
              onChange={(e) => setName(e.target.value)}
            />,
            <Select
              label='Rating'
              value={rating}
              options={Object.entries(lawFirmRatingMap)
                .sort(([keyA], [keyB]) => Number(keyB) - Number(keyA))
                .map(([_, value]) => value)}
              placeholder='Select rating'
              compact={true}
              onChange={setRating}
            />
          ],
          loading: updateLawFirm.isPending,
          onSubmit: async () => {
            try {
              const ratingValue = Number(Object.entries(lawFirmRatingMap).find(([_, val]) => val === rating)?.[0])
              await updateLawFirm.mutateAsync({ id: lawFirm.id, name, rating: ratingValue })
              Toast.success('Law firm updated')
            } catch {
              return false
            }
          },
        }}
      >
        <div css={styles.heading}>
          <Text type='heading'>{lawFirm.name}</Text>
          <div className='info'>
            {(lawFirm.states && lawFirm.states.length > 0) && <>
              <div className='divider' />
              <div className='text'>{lawFirm.states.map((state) => states[state]).join(', ')}</div>
            </>}
            <div className='divider' />
            <div className='text'>Rating: {lawFirmRatingMap[lawFirm.rating]}</div>
          </div>
        </div>
      </EditableValue>

      <div css={styles.buttons}>
        <Button
          text=''
          type='tertiary'
          textLeft={(
            <FontAwesomeIcon
              icon={['far', 'trash']}
              size={15}
              color={colorWithOpacity(theme.colors.foreground, 0.9)}
            />
          )}
          compact={true}
          onPress={() => {
            alert.open({
              title: 'Delete Law Firm',
              message: 'Do you really want to delete this law firm? This can not be undone.',
              buttons: [
                {
                  text: 'Cancel',
                  onPress: () => alert.close(),
                },
                {
                  text: 'Delete',
                  style: 'destructive',
                  onPress: () => {
                    deleteLawFirm.mutate({ id: lawFirm.id }, {
                      onSuccess: () => {
                        alert.close()
                        Toast.success('Law firm deleted')
                        router.navigate('/law-firms')
                      },
                    })
                  },
                },
              ],
            })
          }}
        />
        <Button
          text='Merge'
          type='tertiary'
          compact={true}
          onPress={() => modal.open({
            title: 'Merge Law Firms',
            content: <MergeLawFirms lawFirm={lawFirm} />,
            disableOverflow: true,
          })}
        />
      </div>
    </div>
  )
}

function MergeLawFirms({ lawFirm }: { lawFirm: LawFirmProfile }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const lawFirmSearch = useLawFirmSearch()
  const mergeLawFirms = useMergeLawFirms()

  const [intoLawFirm, setIntoLawFirm] = useState<{ value: string, label: string }>()
  const [error, setError] = useState<{ from?: string, into?: string }>()

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    lawFirmPreview: css({
      background: theme.colors.pageBackground,
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s,
      fontFamily: theme.fonts.body[500],
      fontSize: 15,
      padding: '12px 15px',
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <Text type='inputLabel'>From</Text>
      <div css={styles.lawFirmPreview}>{lawFirm.name}</div>
      <Spacing height={1} />
      <Text type='inputLabel'>Into</Text>
      <Autocomplete
        value={intoLawFirm}
        loadOptions={async (input) => {
          try {
            const lawFirms = await lawFirmSearch.mutateAsync(input)
            return lawFirms.map((l) => ({ value: l.id.toString(), label: l.name }))
          } catch {
            return []
          }
        }}
        placeholder='Search for law firm...'
        noOptions='No law firms found'
        onChange={setIntoLawFirm}
      />
      {error?.into && <Text type='inputError'>{error.into}</Text>}
      <Spacing height={1.4} />
      <Button
        text='Merge Law Firms'
        type='primary'
        loading={mergeLawFirms.isPending}
        onPress={() => {
          mergeLawFirms.mutate({ from: lawFirm.id, into: intoLawFirm ? Number(intoLawFirm.value) : 0 }, {
            onSuccess: (lawFirm) => {
              modal.close()
              router.replace(`/law-firms/${lawFirm.id}`)
              Toast.success('Law firms merged')
            },
            onError: (error) => setError(fieldErrors(error)),
          })
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function SectionHeader({ heading, info, children }: { heading: string, info?: string, children?: ReactNode }) {
  const theme = useTheme()

  const styles = {
    wrapper: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing * 0.6,
    }),
    heading: css({
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing * 0.5,
    }),
    info: css({
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.heading}>
        <Text type='subheading'>{heading}</Text>
        {info && <div css={styles.info}>{info}</div>}
      </div>
      {children}
    </div>
  )
}

function Overview({ lawFirm }: { lawFirm: LawFirmProfile }) {
  const theme = useTheme()

  const styles = {
    metrics: css({
      minHeight: 130,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      columnGap: theme.spacing * 3,
      rowGap: theme.spacing * 1.2,
    }),
    metric: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 0.3,
      '> div': {
        fontFamily: theme.fonts.body[600],
        fontSize: 24,
        color: theme.colors.foreground,
      },
      '> label': {
        fontFamily: theme.fonts.body[400],
        fontSize: 15,
        color: colorWithOpacity(theme.colors.foreground, 0.7),
      },
    }),
  }

  return (
    <div css={styles.metrics}>
      <div css={styles.metric}>
        <div>{lawFirm.totalAccidents ?? 0}</div>
        <label>Total Cases</label>
      </div>
      <div css={styles.metric}>
        <div>{lawFirm.settledAccidents ?? 0}</div>
        <label>Settled Cases</label>
      </div>
      <div css={styles.metric}>
        <div>{lawFirm.ROI ? lawFirm.ROI + 'x' : 'n/a'}</div>
        <label>ROI</label>
      </div>
      <div css={styles.metric}>
        <div>{lawFirm.IRR ? lawFirm.IRR + '%' : 'n/a'}</div>
        <label>IRR</label>
      </div>
      <div css={styles.metric}>
        <div>{lawFirm.score !== null ? Math.round(lawFirm.score) : 'n/a'}</div>
        <label>Score</label>
      </div>
    </div>
  )
}

function Notes({ lawFirm }: { lawFirm: LawFirmProfile }) {
  const theme = useTheme()

  const updateLawFirm = useUpdateLawFirm()

  const [notes, setNotes] = useState(lawFirm.notes ?? '')
  const [editMode, setEditMode] = useState(false)

  const [prevNotes, setPrevNotes] = useState(lawFirm.notes)
  if (prevNotes !== lawFirm.notes) {
    setNotes(lawFirm.notes ?? '')
    setPrevNotes(lawFirm.notes)
  }

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const cancel = useCallback(() => {
    setNotes(lawFirm.notes ?? '')
    setEditMode(false)
  }, [lawFirm.notes])

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') cancel()
    }

    if (editMode) {
      textareaRef.current?.focus()
      const notesLength = textareaRef.current?.value.length ?? 0
      textareaRef.current?.setSelectionRange(notesLength, notesLength)
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [editMode, cancel])

  const styles = {
    wrapper: css({
      borderTop: `1px solid ${colorWithBrightness(theme.colors.panelBorder, 5)}`,
      borderBottom: `1px solid ${colorWithBrightness(theme.colors.panelBorder, 5)}`,
      margin: `0 -${theme.spacing * 1.2}px`,
      padding: `${theme.spacing * (lawFirm.notes || editMode ? 1 : 0.5)}px ${theme.spacing}px`,
    }),
    preview: css({
      color: colorWithOpacity(theme.colors.foreground, 0.9),
      fontFamily: theme.fonts.body[400],
      fontSize: 14,
      lineHeight: 1.4,
      whiteSpace: 'pre-wrap',
      margin: 0,
    }),
    editMode: css({
      marginTop: -11,
      marginLeft: -11,
      marginRight: -11,
      'textarea': {
        padding: '10px !important',
      },
    }),
    actions: css({
      display: 'flex',
      justifyContent: 'center',
      columnGap: theme.spacing * 0.8,
      marginTop: theme.spacing * 0.5,
      '> div': {
        fontFamily: theme.fonts.body[500],
        fontSize: 14.5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        columnGap: 6,
        '&.disabled': {
          pointerEvents: 'none',
          opacity: 0.5,
        },
      },
    }),
    addNotes: css({
      display: 'inline-flex',
      alignItems: 'center',
      fontFamily: theme.fonts.body[500],
      fontSize: 13,
      columnGap: 5,
      color: colorWithOpacity(theme.colors.foreground, 0.8),
      background: colorWithBrightness(theme.colors.surface, -15),
      height: 27,
      borderRadius: 27,
      padding: '0 11px 0 9px',
      cursor: 'pointer',
      ':hover': {
        background: colorWithBrightness(theme.colors.surface, -20),
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      {editMode ? (
        <div css={styles.editMode}>
          <TextArea
            ref={textareaRef}
            value={notes}
            rows={3}
            compact={true}
            fontSize={14}
            placeholder='Enter notes here...'
            onChange={(e) => setNotes(e.target.value)}
          />
          <div css={styles.actions}>
            <div
              className={!notes && !lawFirm.notes ? 'disabled' : ''}
              style={{ color: theme.colors.success }}
              onClick={() => {
                updateLawFirm.mutate({ id: lawFirm.id, notes }, {
                  onSuccess: () => {
                    setEditMode(false)
                    Toast.success('Notes updated')
                  },
                })
              }}
            >
              <FontAwesomeIcon
                icon={['fal', 'floppy-disk']}
                size={16}
                color={theme.colors.success}
              />
              Save
            </div>
            <div style={{ opacity: 0.7 }} onClick={cancel}>Cancel</div>
          </div>
        </div>
      ) : lawFirm.notes ? (
        <EditableValueTooltip transparent={false} onIconClick={() => setEditMode(true)}>
          <pre css={styles.preview}>{lawFirm.notes}</pre>
        </EditableValueTooltip >
      ) : (
        <div css={styles.addNotes} onClick={() => setEditMode(true)}>
          <FontAwesomeIcon
            icon={['fas', 'plus']}
            size={13}
            color={colorWithOpacity(theme.colors.foreground, 0.8)}
          />
          Notes
        </div>
      )}
    </div >
  )
}

function Contacts({ lawFirm }: { lawFirm: LawFirmProfile }) {
  const theme = useTheme()
  const modal = useStore.useModal()
  const alert = useStore.useAlert()

  const deleteContact = useDeleteLawFirmContact()

  const styles = {
    list: css({
      background: theme.colors.pageBackground,
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s + 2,
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing * 0.5,
      padding: theme.spacing * 0.5,
    }),
    item: css({
      background: theme.colors.surface,
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s - 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 0.3,
      padding: '12px 15px',
      cursor: 'pointer',
      ':hover, :focus': {
        borderColor: colorWithBrightness(theme.colors.panelBorder, -25),
      },
    }),
    itemHeading: css({
      fontFamily: theme.fonts.body[500],
      fontSize: 15,
    }),
    itemMeta: css({
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing * 0.2,
      fontFamily: theme.fonts.body[400],
      fontSize: 14,
      opacity: 0.8,
    }),
    noItems: css({
      width: '100%',
      height: 130,
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      opacity: 0.7,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }

  return (
    <div>
      <SectionHeader heading='Contacts'>
        <Button
          text='Add Contact'
          type='tertiary'
          compact={true}
          onPress={() => modal.open({
            title: 'Add Contact',
            content: <ContactDialog lawFirm={lawFirm} />
          })}
        />
      </SectionHeader>

      <div css={styles.list}>
        {lawFirm.contacts.length > 0 ? (
          lawFirm.contacts.map((contact) => (
            <div
              key={contact.id}
              css={styles.item}
              onClick={() => modal.open({
                title: 'Edit Contact',
                content: <ContactDialog lawFirm={lawFirm} contact={contact} />,
                headerActionLeft: {
                  text: 'Delete',
                  destructive: true,
                  onPress: () => alert.open({
                    title: 'Delete Contact',
                    message: 'Do you really want to delete this contact? This can not be undone.',
                    buttons: [
                      {
                        text: 'Cancel',
                        onPress: () => alert.close(),
                      },
                      {
                        text: 'Delete Contact',
                        style: 'destructive',
                        onPress: () => {
                          deleteContact.mutate({ id: contact.id, lawFirmId: lawFirm.id }, {
                            onSuccess: () => {
                              alert.close()
                              modal.close()
                              Toast.success('Contact deleted')
                            },
                          })
                        },
                      },
                    ],
                  }),
                },
              })}
            >
              {contact.name && <div css={styles.itemHeading}>{contact.name}</div>}
              {(contact.role || contact.email || contact.phone) && (
                <div css={styles.itemMeta}>
                  {contact.role && <div>{contact.role}</div>}
                  {contact.email && <div>{contact.email}</div>}
                  {contact.phone && <div>{contact.phone}</div>}
                </div>
              )}
            </div>
          ))
        ) : (
          <div css={styles.noItems}>No contacts found</div>
        )}
      </div>
    </div>
  )
}

function ContactDialog({ lawFirm, contact }: { lawFirm: LawFirmProfile, contact?: LawFirmContact }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const createContact = useCreateLawFirmContact()
  const updateContact = useUpdateLawFirmContact()

  const [name, setName] = useState(contact?.name ?? '')
  const [role, setRole] = useState(contact?.role ?? '')
  const [email, setEmail] = useState(contact?.email ?? '')
  const [phone, setPhone] = useState(contact?.phone ?? '')
  const [notes, setNotes] = useState(contact?.notes ?? '')

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    fields: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing * 0.8,
      [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 200px)',
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <div css={styles.fields}>
        <TextInput
          label='Contact Name'
          value={name}
          compact={true}
          onChange={(e) => setName(e.target.value)}
        />
        <Select
          label='Role'
          value={role}
          options={LawFirmContactRoleSchema.options}
          placeholder='Select role'
          compact={true}
          onChange={setRole}
        />
        <TextInput
          type='email'
          label='Email'
          value={email}
          compact={true}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextInput
          type='tel'
          label='Phone'
          value={phone}
          compact={true}
          onChange={(e) => setPhone(e.target.value)}
        />
        <div css={{ gridColumn: 'span 2' }}>
          <TextArea
            label='Notes'
            value={notes}
            compact={true}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
      </div>
      <Spacing height={1.4} />
      <Button
        text={`${contact ? 'Update' : 'Add'} Contact`}
        type='primary'
        loading={createContact.isPending || updateContact.isPending}
        onPress={() => {
          if (contact) {
            updateContact.mutate({ id: contact.id, lawFirmId: lawFirm.id, name, role, email, phone, notes }, {
              onSuccess: () => {
                modal.close()
                Toast.success('Contact updated')
              },
            })
          } else {
            createContact.mutate({ lawFirmId: lawFirm.id, name, role, email, phone, notes }, {
              onSuccess: () => {
                modal.close()
                Toast.success('Contact added')
              },
            })
          }
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function Portfolio({ lawFirm }: { lawFirm: LawFirmProfile }) {
  const theme = useTheme()

  const accidentFilter = useStore.useAccidentFilter()
  const setAccidentFilter = useStore.useSetAccidentFilter()
  const clearAccidentFilter = useStore.useClearAccidentFilter()

  const saveAccidentFilter = useSaveAccidentFilter()

  const styles = {
    table: css({
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s + 2,
      overflow: 'hidden',
    }),
  }

  return (
    <div>
      <SectionHeader heading='Outstanding Portfolio' />

      <div css={styles.table}>
        <Table
          columns={[
            {
              data: 'status',
              title: 'Status',
              width: 270,
            },
            {
              data: 'accidents',
              title: '# of Cases',
              width: 130,
            },
            {
              data: 'invoices',
              title: 'Invoices',
              width: 180,
              alignRight: true,
            },
            {
              data: 'cost',
              title: 'Cost',
              width: 180,
              alignRight: true,
            },
            {
              data: 'target',
              title: 'Target Collections',
              width: 180,
              alignRight: true,
            },
          ]}
          data={lawFirm.portfolio.map((item) => ({
            status: item.label,
            accidents: format.number(item.accidents),
            invoices: format.currency(item.invoices),
            cost: format.currency(item.cost),
            target: format.currency(item.target),
          }))}
          noDataMessage='No open cases'
          compact={true}
          fixedLayout={true}
          onRowClick={(row) => {
            const statuses = statusesFromPortfolioStatus(row.status)
            const cleared = clearAccidentFilter(accidentFilter)
            const filter = { ...cleared, lawFirms: [lawFirm.id.toString()], statuses }
            setAccidentFilter(filter)
            saveAccidentFilter.mutate(filter)
            router.navigate('/cases')
          }}
        />
      </div>
    </div>
  )
}

function ChecksReceived({ lawFirm }: { lawFirm: LawFirmProfile }) {
  const theme = useTheme()

  const styles = {
    table: css({
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s + 2,
      overflow: 'hidden',
    }),
  }

  return (
    <div>
      <SectionHeader heading='Checks Received' />

      <div css={styles.table}>
        <Table
          columns={[
            {
              data: 'provider',
              title: 'Provider',
              width: 220,
            },
            {
              data: 'name',
              title: 'Name',
              width: 220,
            },
            {
              data: 'amount',
              title: 'Amount',
              width: 110,
              alignRight: true,
            },
            {
              data: 'ROI',
              title: 'ROI',
              width: 110,
              alignRight: true,
              renderer: (row) => {
                const ROI = lawFirm.checksReceived[row].ROI
                return (
                  <span style={{ color: colorFromROI(ROI, theme), fontFamily: theme.fonts.body[500] }}>{ROI}x</span>
                )
              }
            },
            {
              data: 'IRR',
              title: 'IRR',
              width: 110,
              alignRight: true,
              renderer: (row) => {
                const IRR = lawFirm.checksReceived[row].IRR
                return (
                  <span style={{ color: colorFromIRR(IRR, theme), fontFamily: theme.fonts.body[500] }}>{IRR}%</span>
                )
              }
            },
          ]}
          data={lawFirm.checksReceived.map((check) => ({
            accidentId: check.accidentId,
            provider: check.provider,
            name: check.name,
            amount: format.currency(check.amount * -1),
            ROI: check.ROI,
            IRR: check.IRR,
          }))}
          noDataMessage='No checks received'
          minHeight={130}
          fixedLayout={true}
          onRowClick={(row) => router.navigate(`/cases/${row.accidentId}`)}
        />
      </div>
    </div>
  )
}
