import { z } from 'zod'

export const LawFirmSchema = z.object({
  id: z.number(),
  name: z.string(),
})

export const LawFirmListItemSchema = LawFirmSchema.extend({
  states: z.string().nullable().transform((val) => val ? val.split(',') : null),
  totalAccidents: z.number().nullable(),
  settledAccidents: z.number().nullable(),
  ROI: z.number().nullable(),
  IRR: z.number().nullable(),
  score: z.number().nullable(),
})

export const LawFirmContactRoleSchema = z.enum([
  'Attorney',
  'Paralegal',
  'Case Manager',
  'Receptionist',
  'Other',
])

export const LawFirmContactSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  role: LawFirmContactRoleSchema.nullish(),
  email: z.string().nullable(),
  phone: z.string().nullable(),
  notes: z.string().nullish(),
  displayName: z.string(),
})

export const lawFirmRatingMap: Record<number, string> = {
  100: 'Great',
  80: 'Good',
  60: 'OK',
  40: 'Poor',
  0: 'Bad',
}

export const LawFirmProfileSchema = LawFirmListItemSchema.extend({
  rating: z.number(),
  notes: z.string().nullable(),
  contacts: LawFirmContactSchema.array(),
  portfolio: z.object({
    label: z.string(),
    accidents: z.number(),
    invoices: z.number(),
    cost: z.number(),
    target: z.number(),
  }).array(),
  checksReceived: z.object({
    accidentId: z.number(),
    provider: z.string(),
    name: z.string(),
    amount: z.number(),
    ROI: z.number(),
    IRR: z.number(),
  }).array(),
})

export const LawFirmFilterSchema = z.object({
  input: z.string().default(''),
  orderBy: z.string().default('name'),
  sortOrder: z.enum(['asc', 'desc']).default('asc'),
  page: z.number().default(1),
  show: z.number().default(50),
})

export type LawFirm = z.infer<typeof LawFirmSchema>
export type LawFirmListItem = z.infer<typeof LawFirmListItemSchema>
export type LawFirmContact = z.infer<typeof LawFirmContactSchema>
export type LawFirmProfile = z.infer<typeof LawFirmProfileSchema>
export type LawFirmFilter = z.infer<typeof LawFirmFilterSchema>
