import { useLocalSearchParams } from 'expo-router'
import { useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { AuthModule, useTheme } from 'ui'
import { Logo } from '@/components/Logo'
import { Head } from '@/components/web/Head'
import { Constants } from '@/constants'
import { useSignInAsTestUser, useSignInWithCode, useSignInWithEmail, useSignInWithGoogle } from '@/queries/auth.queries'
import { fieldErrors } from '@/queries/client'
import { UserRole } from '@/types/user'

export function Auth() {
  const params = useLocalSearchParams<{ redirect?: string }>()
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const signInWithEmail = useSignInWithEmail()
  const signInWithCode = useSignInWithCode()
  const signInWithGoogle = useSignInWithGoogle()
  const signInAsTestUser = useSignInAsTestUser()

  type UserRoleExtended = Exclude<UserRole, 'provider'> | 'providerSubmitter' | 'providerStaff'

  const [testUserRole, setTestUserRole] = useState<UserRoleExtended>()

  return <>
    <Head title='Sign In' />

    <AuthModule
      title='Get Started'
      wrapperStyle={{
        flex: Platform.OS === 'web' ? 1 : undefined,
        justifyContent: Platform.OS === 'web' ? 'center' : undefined,
      }}
      contentStyle={{
        width: width >= theme.breakpoints.xs ? 400 : undefined,
        alignSelf: width >= theme.breakpoints.xs ? 'center' : undefined,
      }}
      preContent={Platform.OS === 'web' && (
        <Logo vertical={true} style={{ alignSelf: 'center', marginBottom: theme.spacing * 1 }} />
      )}
      access={{
        prompt: `Enter your email address to access the ${Constants.appName} below.`,
        signInWithEmail: {
          loading: signInWithEmail.isPending,
          onPress: (email, onSuccess, onError) => signInWithEmail.mutate({ email, redirect: params.redirect }, {
            onSuccess: (isUser) => onSuccess(isUser),
            onError: (error) => onError(fieldErrors(error)),
          }),
        },
        google: {
          iosClientId: process.env.EXPO_PUBLIC_GOOGLE_OAUTH_IOS_CLIENT_ID,
          androidClientId: process.env.EXPO_PUBLIC_GOOGLE_OAUTH_ANDROID_CLIENT_ID,
          webClientId: process.env.EXPO_PUBLIC_GOOGLE_OAUTH_WEB_CLIENT_ID,
          signIn: {
            loading: signInWithGoogle.isPending,
            onPress: (idToken, onSuccess) => signInWithGoogle.mutate(idToken, {
              onSuccess: (form) => onSuccess(form),
            }),
          },
        },
        dev: [
          {
            text: 'Sign in as Lienstar Staff',
            loading: signInAsTestUser.isPending && testUserRole === 'staff',
            onPress: () => {
              setTestUserRole('staff')
              signInAsTestUser.mutate('staff@lienstar.com')
            },
          },
          {
            text: 'Sign in as Lienstar Manager',
            loading: signInAsTestUser.isPending && testUserRole === 'manager',
            onPress: () => {
              setTestUserRole('manager')
              signInAsTestUser.mutate('manager@lienstar.com')
            },
          },
          {
            text: 'Sign in as Lienstar Executive',
            loading: signInAsTestUser.isPending && testUserRole === 'executive',
            onPress: () => {
              setTestUserRole('executive')
              signInAsTestUser.mutate('executive@lienstar.com')
            },
          },
          {
            text: 'Sign in as Provider Staff',
            loading: signInAsTestUser.isPending && testUserRole === 'providerStaff',
            onPress: () => {
              setTestUserRole('providerStaff')
              signInAsTestUser.mutate('provider.staff@lienstar.com')
            },
          },
          {
            text: 'Sign in as Provider Submitter',
            loading: signInAsTestUser.isPending && testUserRole === 'providerSubmitter',
            onPress: () => {
              setTestUserRole('providerSubmitter')
              signInAsTestUser.mutate('provider.submitter@lienstar.com')
            },
          },
        ],
      }}
      signin={{
        signInWithCode: {
          loading: signInWithCode.isPending,
          onPress: async (code) => {
            try {
              const res = await signInWithCode.mutateAsync(code)
              return res.status === 200
            } catch {
              return false
            }
          },
        },
        signInWithEmail: {
          loading: signInWithEmail.isPending,
          onPress: (email, onSuccess, onError) => signInWithEmail.mutate({ email, redirect: params.redirect }, {
            onSuccess: (isUser) => onSuccess(isUser),
            onError: (error) => onError(fieldErrors(error)),
          }),
        },
      }}
    />
  </>
}

