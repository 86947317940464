/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { colorWithOpacity, useTheme } from 'ui'
import { TextInput } from '@/components/web/TextInput'
import { Cashflow } from '@/types/accident'
import { calculateIRR, calculateROI, format } from '@/utils'

type Props = {
  invoices: number
  collected: number
  cost: number
  cashflow: Cashflow
}

export function ReturnsCalculator({ invoices, collected, cost, cashflow }: Props) {
  const theme = useTheme()

  const [settlementValue, setSettlementValue] = useState(format.currency(invoices, { editable: true }))
  const [settlementDate, setSettlementDate] = useState(format.date(dayjs(), { iso: true }))
  const [ROI, setROI] = useState<number>()
  const [IRR, setIRR] = useState<number>()
  const [error, setError] = useState<{
    form?: string
    settlementValue?: string
    settlementDate?: string
  }>()

  useEffect(() => {
    if (!settlementValue) return

    setError({})
    if (!Number.isFinite(Number(settlementValue)) || Number(settlementValue) <= 0) {
      setError((error) => ({ ...error, settlementValue: 'Settlement value should be greater than 0' }))
      return
    }
    if (!settlementDate) {
      setError((error) => ({ ...error, settlementDate: 'Enter settlement date' }))
      return
    }

    const calculatedROI = calculateROI(Number(settlementValue) * 100 + collected, cost)
    if (Number.isFinite(calculatedROI)) {
      setROI(calculatedROI)
    } else {
      setROI(0)
      setError({ form: 'Could not calculate ROI' })
    }

    const calculatedIRR = calculateIRR([
      ...cashflow,
      {
        date: dayjs(settlementDate, 'YYYY-MM-DD'),
        amount: Number(settlementValue) * 100 * -1,
      },
    ])
    if (Number.isFinite(calculatedIRR)) {
      setIRR(calculatedIRR)
    } else {
      setIRR(0)
      setError({ form: 'Could not calculate IRR' })
    }
  }, [collected, cost, cashflow, settlementValue, settlementDate])

  const styles = {
    wrapper: css({
      padding: theme.spacing * 1.2,
    }),
    heading: css({
      fontFamily: theme.fonts.body[600],
      fontSize: 18,
      textAlign: 'center',
    }),
    inputs: css({
      display: 'flex',
      columnGap: theme.spacing * 0.6,
      marginTop: theme.spacing,
      '> fieldset': {
        width: 160,
      },
    }),
    results: css({
      background: theme.colors.pageBackground,
      border: `1px solid ${theme.colors.panelBorder}`,
      borderRadius: theme.borderRadius.s,
      display: 'grid',
      gridTemplateColumns: 'auto min-content',
      alignItems: 'center',
      columnGap: theme.spacing,
      rowGap: theme.spacing * 1.2,
      marginTop: theme.spacing * 1.2,
      padding: theme.spacing,
    }),
    error: css({
      color: theme.colors.danger,
      background: colorWithOpacity(theme.colors.danger, 0.1),
      fontFamily: theme.fonts.body[400],
      fontSize: 14.5,
      border: `1px solid ${theme.colors.danger}`,
      borderRadius: theme.borderRadius.s,
      padding: '10px 12px',
      marginTop: theme.spacing,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.heading}>Returns Calculator</div>
      <div css={styles.inputs}>
        <TextInput
          type='text'
          label='Settlement value'
          value={settlementValue}
          compact={true}
          error={error?.settlementValue}
          onChange={(e) => setSettlementValue(e.target.value)}
        />
        <TextInput
          type='date'
          label='Settlement date'
          value={settlementDate}
          compact={true}
          error={error?.settlementDate}
          onChange={(e) => setSettlementDate(e.target.value)}
        />
      </div>

      {(ROI !== undefined && IRR !== undefined) && (
        <div css={styles.results}>
          <Result
            label='ROI'
            value={ROI + 'x'}
            gauge={83.3 * ROI - 91.7}
            ticks={[
              { offset: 33.33, label: '1.5x' },
              { offset: 66.66, label: '1.9x' },
            ]}
            onGaugeChange={(value) => {
              const ROI = (value + 91.7) / 83.3
              setSettlementValue(format.currency(ROI * cost - collected, { editable: true }))
            }}
          />
          <Result
            label='IRR'
            value={IRR + '%'}
            gauge={1.33 * IRR}
            ticks={[
              { offset: 33.33, label: '25%' },
              { offset: 66.66, label: '50%' },
            ]}
          />
        </div>
      )}

      {error?.form && <div css={styles.error}>{error.form}</div>}
    </div>
  )
}

type ResultProps = {
  label: string
  value: string
  gauge: number
  ticks: { offset: number, label: string }[]
  onGaugeChange?: (value: number) => void
}

export function Result({ label, value, gauge, ticks, onGaugeChange }: ResultProps) {
  const theme = useTheme()

  const [dragging, setDragging] = useState(false)
  const gaugeRef = useRef<HTMLDivElement>(null)

  const gaugeHeight = 12
  const gaugeOffset = Math.min(Math.max(gauge, 0), 100)
  const gaugeHandleSize = 22

  const styles = {
    gauge: css({
      position: 'relative',
      flex: 1,
      height: 28,
      cursor: onGaugeChange ? 'pointer' : undefined,
    }),
    gaugeBg: css({
      position: 'absolute',
      width: '100%',
      height: gaugeHeight,
      borderRadius: gaugeHeight / 2,
      background: theme.colors.panelBorder,
    }),
    gaugeBar: css({
      position: 'absolute',
      width: gaugeOffset + '%',
      height: gaugeHeight,
      borderRadius: gaugeHeight / 2,
      background: gauge > 66.66 ? theme.colors.success
        : gauge > 33.33 ? theme.colors.warning : theme.colors.danger
    }),
    gaugeBarWarning: css({
      position: 'absolute',
      left: '33.33%',
      width: '33.33%',
      height: gaugeHeight,
      background: theme.colors.warning,
    }),
    gaugeBarDanger: css({
      position: 'absolute',
      left: 0,
      width: '33.33%',
      height: gaugeHeight,
      borderTopLeftRadius: gaugeHeight / 2,
      borderBottomLeftRadius: gaugeHeight / 2,
      background: theme.colors.danger,
    }),
    gaugeTick: css({
      position: 'absolute',
      top: 0,
      bottom: 0,
    }),
    gaugeTickMark: css({
      width: 1,
      height: gaugeHeight,
      background: theme.colors.surface,
    }),
    gaugeTickLabel: css({
      transform: 'translateX(-50%)',
      fontFamily: theme.fonts.body[400],
      fontSize: 12.5,
      marginTop: 3,
      userSelect: 'none',
    }),
    gaugeHandle: css({
      position: 'absolute',
      width: gaugeHandleSize,
      height: gaugeHandleSize,
      left: gaugeOffset + '%',
      transform: `translate(-${gaugeHandleSize / 2}px, -${gaugeHandleSize / 4}px)`,
      background: theme.colors.surface,
      borderRadius: '50%',
      boxShadow: `0 1px 3px ${colorWithOpacity(theme.colors.foreground, 0.2)}`,
      cursor: dragging ? 'grabbing' : 'grab',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    gaugeHandleDot: css({
      width: 9,
      height: 9,
      background: colorWithOpacity(theme.colors.foreground, 0.08),
      borderRadius: '50%',
    }),
    label: css({
      fontFamily: theme.fonts.body[400],
      fontSize: 14,
      opacity: 0.7,
      textAlign: 'right',
      lineHeight: 1,
      userSelect: 'none',
    }),
    value: css({
      fontFamily: theme.fonts.body[500],
      fontSize: 16.5,
      textAlign: 'right',
      lineHeight: 1,
      minWidth: 48,
      marginTop: 3,
      userSelect: 'none',
    }),
  }

  return <>
    <div
      ref={gaugeRef}
      css={styles.gauge}
      onMouseMove={(e) => {
        if (!onGaugeChange || !dragging) return
        let offset = e.clientX - gaugeRef.current!.getBoundingClientRect().left
        if (offset < 0) offset = 0
        if (offset > gaugeRef.current!.clientWidth) offset = gaugeRef.current!.clientWidth
        onGaugeChange(offset / gaugeRef.current!.clientWidth * 100)
      }}
      onMouseUp={() => setDragging(false)}
      onClick={(e) => {
        if (!onGaugeChange) return
        const offset = e.clientX - gaugeRef.current!.getBoundingClientRect().left
        onGaugeChange(offset / gaugeRef.current!.clientWidth * 100)
      }}
    >
      <div css={styles.gaugeBg} />
      <div css={styles.gaugeBar} />
      {gauge > 66.66 && <div css={styles.gaugeBarWarning} />}
      {gauge > 33.33 && <div css={styles.gaugeBarDanger} />}
      {ticks.map((tick, index) => (
        <div key={index} css={styles.gaugeTick} style={{ left: tick.offset + '%' }}>
          <div css={styles.gaugeTickMark} />
          <div css={styles.gaugeTickLabel}>{tick.label}</div>
        </div>
      ))}
      {onGaugeChange && (
        <div css={styles.gaugeHandle} onMouseDown={() => setDragging(true)}>
          <div css={styles.gaugeHandleDot} />
        </div>
      )}
    </div>
    <div>
      <div css={styles.label}>{label}</div>
      <div css={styles.value}>{value}</div>
    </div>
  </>
}
