/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState } from 'react'
import { Toast } from 'react-native-toast-universal'
import { Button, MultiSelect, Panel, Select, Spacing, Table, Text, useTheme, Wrapper } from 'ui'
import { Head } from '@/components/web/Head'
import { TextInput } from '@/components/web/TextInput'
import { useStore } from '@/providers/store'
import { fieldErrors } from '@/queries/client'
import { useAllProviders } from '@/queries/provider.queries'
import { useCreateUser, useUpdateUser, useUsers } from '@/queries/user.queries'
import { Provider } from '@/types/provider'
import { User, UserRoleSelect, UserRoleSelectSchema, UserStatusSelect, UserStatusSelectSchema } from '@/types/user'

export function Users() {
  const theme = useTheme()

  const providers = useAllProviders()

  return <>
    <Head title='Users' />

    <Wrapper maxWidth={1300} style={{ marginVertical: theme.spacing * 1.5 }}>
      <Header providers={providers.data ?? []} />
      <Panel><UsersTable providers={providers.data ?? []} /></Panel>
    </Wrapper>
  </>
}

function Header({ providers }: { providers: Provider[] }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const styles = {
    wrapper: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing,
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Text type='heading'>Users</Text>

      <Button
        text='New User'
        type='primary'
        compact={true}
        fontFamily={theme.fonts.body[500]}
        onPress={() => modal.open({
          title: 'New User',
          content: <UserDialog providers={providers} />,
          disableOverflow: true,
        })}
      />
    </div>
  )
}

function UserDialog({ user, providers }: { user?: User, providers: Provider[] }) {
  const theme = useTheme()
  const modal = useStore.useModal()

  const createUser = useCreateUser()
  const updateUser = useUpdateUser()

  const [firstName, setFirstName] = useState(user?.firstName ?? '')
  const [lastName, setLastName] = useState(user?.lastName ?? '')
  const [email, setEmail] = useState(user?.email ?? '')
  const [role, setRole] = useState<UserRoleSelect | ''>(() => {
    if (!user) return ''

    switch (user.role) {
      case 'staff':
        return 'Staff'
      case 'manager':
        return 'Market Manager'
      case 'executive':
        return 'Executive'
      case 'provider':
        return user.canSubmit ? 'Provider - Submitter' : 'Provider - Staff'
    }
  })
  const [status, setStatus] = useState<UserStatusSelect>(user?.active ? 'Active' : 'Inactive')
  const [notifications, setNotifications] = useState(user?.providers.map((p) => p.id.toString()) ?? [])
  const [error, setError] = useState<{
    firstName?: string
    lastName?: string
    email?: string
    role?: string
    status?: string
  }>()

  const styles = {
    wrapper: css({
      padding: `0 ${theme.spacing * 1.4}px`,
    }),
    fields: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing * 0.8,
      [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 200px)',
      },
    }),
  }

  return (
    <div css={styles.wrapper}>
      <Spacing height={1.2} />
      <div css={styles.fields}>
        <TextInput
          label='First Name'
          value={firstName}
          compact={true}
          error={error?.firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextInput
          label='Last Name'
          value={lastName}
          compact={true}
          error={error?.lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextInput
          label='Email'
          value={email}
          type='email'
          compact={true}
          error={error?.email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Select
          label='Role'
          value={role}
          options={UserRoleSelectSchema.options}
          placeholder='Select role'
          compact={true}
          error={error?.role}
          onChange={(value) => setRole(value as UserRoleSelect)}
        />
        {user && (
          <Select
            label='Status'
            value={status}
            options={UserStatusSelectSchema.options}
            placeholder='Select status'
            compact={true}
            error={error?.status}
            onChange={(value) => setStatus(value as UserStatusSelect)}
          />
        )}
        <div css={{ gridColumn: 'span 2' }}>
          <MultiSelect
            label='Notifications'
            value={notifications}
            options={providers.map((p) => ({ value: p.id.toString(), label: p.name }))}
            placeholder='Select provider(s)'
            noOptions='Provider not found'
            closeMenuOnSelect={true}
            compact={true}
            onChange={setNotifications}
          />
        </div>
      </div>
      <Spacing height={1.4} />
      <Button
        text={`${user ? 'Update' : 'Add'} User`}
        type='primary'
        loading={createUser.isPending || updateUser.isPending}
        onPress={() => {
          if (user) {
            updateUser.mutate({ id: user.id, firstName, lastName, email, role, status, notifications }, {
              onSuccess: () => {
                modal.close()
                Toast.success('User updated')
              },
              onError: (error) => setError(fieldErrors(error)),
            })
          } else {
            createUser.mutate({ firstName, lastName, email, role, notifications }, {
              onSuccess: () => {
                modal.close()
                Toast.success('User added')
              },
              onError: (error) => setError(fieldErrors(error)),
            })
          }
        }}
      />
      <Spacing height={1.4} />
    </div>
  )
}

function UsersTable({ providers }: { providers: Provider[] }) {
  const modal = useStore.useModal()

  const [orderBy, setOrderBy] = useState('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const users = useUsers(orderBy, sortOrder)

  return (
    <Table
      columns={[
        {
          data: 'name',
          title: 'Name',
          width: 230,
          sortable: true,
        },
        {
          data: 'email',
          title: 'Email',
          width: 310,
          sortable: true,
        },
        {
          data: 'role',
          title: 'Role',
          width: 200,
          sortable: true,
        },
        {
          data: 'status',
          title: 'Status',
          width: 120,
          sortable: true,
        },
        {
          data: 'notifications',
          title: 'Notifications',
          width: 400,
          renderer: (row) => {
            const user = users.data ? users.data[row] : undefined
            return (
              <div
                title={user?.providers.map((p) => p.name).join('\n')}
                css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >{user?.providers.map((p) => p.name).join(' | ')}</div>
            )
          },
        },
      ]}
      data={users.data?.map((user) => {
        let role: UserRoleSelect
        switch (user.role) {
          case 'staff':
            role = 'Staff'
            break
          case 'executive':
            role = 'Executive'
            break
          case 'manager':
            role = 'Market Manager'
            break
          case 'provider':
            role = user.canSubmit ? 'Provider - Submitter' : 'Provider - Staff'
            break
        }

        return {
          id: user.id,
          name: user.fullName,
          email: user.email,
          role,
          status: user.active ? 'Active' : 'Inactive',
          notifications: user.providers,
        }
      }) ?? []}
      loading={users.isPending}
      noDataMessage='No users found'
      fixedLayout={true}
      stickyHeader={true}
      orderBy={orderBy}
      sortOrder={sortOrder}
      onUpdateSort={({ orderBy, sortOrder }) => {
        setOrderBy(orderBy)
        setSortOrder(sortOrder)
      }}
      onRowClick={(row) => modal.open({
        title: 'Edit User',
        content: <UserDialog user={users.data?.find((u) => u.id === row.id)} providers={providers} />,
        disableOverflow: true,
      })}
    />
  )
}
