import { useWindowDimensions } from 'react-native'

export function useDeviceIsHorizontallyConstrained() {
  const { width, fontScale } = useWindowDimensions()
  return width < 350 || (width < 400 && fontScale > 1.1)
}

export function useDeviceIsVerticallyConstrained() {
  const { height } = useWindowDimensions()
  return height < 700
}
