import { GoogleSignin } from '@react-native-google-signin/google-signin'
import * as Google from 'expo-auth-session/providers/google'
import * as WebBrowser from 'expo-web-browser'
import { useEffect } from 'react'
import { Platform, View } from 'react-native'
import { Button } from '../../components/Button'
import { GoogleLogo } from '../../components/Icons'
import { setMode, SignInWithIdToken } from './AuthModule'

WebBrowser.maybeCompleteAuthSession()

type Props = {
  setMode: setMode
  google: {
    iosClientId: string
    androidClientId: string
    webClientId: string
    signIn: SignInWithIdToken
  }
}

export function GoogleAuth({ setMode, google }: Props) {
  if (Platform.OS === 'web') {
    return <GoogleAuthWeb setMode={setMode} google={google} />
  } else {
    return <GoogleAuthNative setMode={setMode} google={google} />
  }
}

function GoogleAuthNative({ setMode, google }: Props) {
  return (
    <GoogleAuthButton
      loading={google.signIn.loading}
      onPress={async () => {
        GoogleSignin.configure({
          iosClientId: google.iosClientId,
          webClientId: google.webClientId,
        })
        await GoogleSignin.hasPlayServices()
        const response = await GoogleSignin.signIn()
        if (response.type === 'success') {
          google.signIn.onPress(response.data.idToken!, (form) => {
            if (form) {
              setMode({
                screen: 'signup',
                params: {
                  email: form.email,
                  firstName: form.firstName,
                  lastName: form.lastName,
                  photo: form.photo,
                  emailVerified: true,
                },
              })
            }
          })
        } else if (response.type !== 'cancelled') {
          console.error(response)
        }
      }}
    />
  )
}

function GoogleAuthWeb({ setMode, google }: Props) {
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    webClientId: google.webClientId,
  })

  useEffect(() => {
    async function signIn(idToken: string) {
      google.signIn.onPress(idToken, (form) => {
        if (form) {
          setMode({
            screen: 'signup',
            params: {
              email: form.email,
              firstName: form.firstName,
              lastName: form.lastName,
              photo: form.photo,
              emailVerified: true,
            },
          })
        }
      })
    }

    if (response?.type === 'success') signIn(response.params.id_token!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  return (
    <GoogleAuthButton
      disabled={!request}
      loading={google.signIn.loading}
      onPress={() => promptAsync()}
    />
  )
}

function GoogleAuthButton({ disabled, loading, onPress }: {
  disabled?: boolean
  loading?: boolean
  onPress: () => void
}) {
  return (
    <Button
      text='Continue with Google'
      type='tertiary'
      textLeft={(
        <View style={{ flex: 1 }}>
          <GoogleLogo />
        </View>
      )}
      textRight={(
        <View style={{ flex: 1 }} />
      )}
      disabled={disabled}
      loading={loading}
      onPress={onPress}
    />
  )
}
