import { QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { api } from '@/queries/api'
import { AccidentFilter, BatchFilter } from '@/types/filter'
import { User, UserSchema } from '@/types/user'

export const keys = {
  user: (): QueryKey => ['user'],
  users: (orderBy?: string, sortOrder?: string): QueryKey => {
    return orderBy && sortOrder ? ['users', orderBy, sortOrder] : ['users']
  },
}

export function useUser() {
  return useQuery({
    queryKey: keys.user(),
    queryFn: async () => {
      const res = await api.get('user')
      return UserSchema.parse(res.data.user)
    },
    staleTime: 1000 * 60,
  })
}

export function useUpdateProfile() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ firstName, lastName, photo }: {
      firstName: string
      lastName: string
      photo: string | undefined
    }) => {
      return api.patch('user', { firstName, lastName, photo })
    },
    onSuccess: (res, { photo }) => {
      const user = UserSchema.parse(res.data.user)
      if (photo) user.photoUrl = `${user.photoUrl}?timestamp=${Date.now()}`
      queryClient.setQueryData(keys.user(), user)
    },
  })
}

export function useSaveAccidentFilter() {
  return useMutation({
    mutationFn: (filter: AccidentFilter) => api.patch('user/filter', { accidentFilter: filter }),
  })
}

export function useSaveBatchFilter() {
  return useMutation({
    mutationFn: (filter: BatchFilter) => api.patch('user/filter', { batchFilter: filter }),
  })
}

export function useUsers(orderBy?: string, sortOrder?: string) {
  return useQuery({
    queryKey: keys.users(orderBy, sortOrder),
    queryFn: async () => {
      const res = await api.get('users', { params: { orderBy, sortOrder } })
      return res.data.users.map((item: any) => UserSchema.parse(item)) as User[]
    },
  })
}

export function useCreateUser() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ firstName, lastName, email, role, notifications }: {
      firstName: string
      lastName: string
      email: string
      role: string
      notifications: string[]
    }) => {
      return api.post('users', { firstName, lastName, email, role, notifications })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.users() })
    },
  })
}

export function useUpdateUser() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, firstName, lastName, email, role, status, notifications }: {
      id: number,
      firstName: string
      lastName: string
      email: string
      role: string
      status: string
      notifications: string[]
    }) => {
      return api.patch(`users/${id}`, { firstName, lastName, email, role, status, notifications })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.user() })
      queryClient.invalidateQueries({ queryKey: keys.users() })
    },
  })
}

export function useProxyUser() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }: { id: number }) => api.post(`users/proxy/${id}`),
    onSuccess: () => queryClient.clear(),
  })
}
