/* eslint-disable import/order */
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft as farAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft'
import { faAngleRight as farAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import { faArrowsFromLine as falArrowsFromLine } from '@fortawesome/pro-light-svg-icons/faArrowsFromLine'
import { faArrowsRotate as farArrowsRotate } from '@fortawesome/pro-regular-svg-icons/faArrowsRotate'
import { faArrowsToLine as falArrowsToLine } from '@fortawesome/pro-light-svg-icons/faArrowsToLine'
import { faBars as farBars } from '@fortawesome/pro-regular-svg-icons/faBars'
import { faCalculator as falCalculator } from '@fortawesome/pro-light-svg-icons/faCalculator'
import { faCalendarDay as fasCalendarDay } from '@fortawesome/pro-solid-svg-icons/faCalendarDay'
import { faCircleDollar as fasCircleDollar } from '@fortawesome/pro-solid-svg-icons/faCircleDollar'
import { faCircleInfo as falCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo'
import { faCircleInfo as fasCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo'
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faCloudArrowUp as fasCloudArrowUp } from '@fortawesome/pro-solid-svg-icons/faCloudArrowUp'
import { faCompress as farCompress } from '@fortawesome/pro-regular-svg-icons/faCompress'
import { faEllipsisVertical as farEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical'
import { faEnvelope as falEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faExpand as farExpand } from '@fortawesome/pro-regular-svg-icons/faExpand'
import { faFile as fasFile } from '@fortawesome/pro-solid-svg-icons/faFile'
import { faFileArrowDown as falFileArrowDown } from '@fortawesome/pro-light-svg-icons/faFileArrowDown'
import { faFileContract as falFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract'
import { faFileContract as fasFileContract } from '@fortawesome/pro-solid-svg-icons/faFileContract'
import { faFileExport as farFileExport } from '@fortawesome/pro-regular-svg-icons/faFileExport'
import { faFileImage as fasFileImage } from '@fortawesome/pro-solid-svg-icons/faFileImage'
import { faFileImport as farFileImport } from '@fortawesome/pro-regular-svg-icons/faFileImport'
import { faFileInvoiceDollar as falFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar'
import { faFileInvoiceDollar as fasFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar'
import { faFileLines as falFileLines } from '@fortawesome/pro-light-svg-icons/faFileLines'
import { faFileLines as fasFileLines } from '@fortawesome/pro-solid-svg-icons/faFileLines'
import { faFileMedical as falFileMedical } from '@fortawesome/pro-light-svg-icons/faFileMedical'
import { faFileMedical as fasFileMedical } from '@fortawesome/pro-solid-svg-icons/faFileMedical'
import { faFilePlus as fasFilePlus } from '@fortawesome/pro-solid-svg-icons/faFilePlus'
import { faFileSpreadsheet as fasFileSpreadsheet } from '@fortawesome/pro-solid-svg-icons/faFileSpreadsheet'
import { faFiles as fasFiles } from '@fortawesome/pro-solid-svg-icons/faFiles'
import { faFileWord as fasFileWord } from '@fortawesome/pro-solid-svg-icons/faFileWord'
import { faFloppyDisk as falFloppyDisk } from '@fortawesome/pro-light-svg-icons/faFloppyDisk'
import { faInfo as fasInfo } from '@fortawesome/pro-solid-svg-icons/faInfo'
import { faLock as falLock } from '@fortawesome/pro-light-svg-icons/faLock'
import { faMagnifyingGlass as farMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass'
import { faMagnifyingGlass as fasMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlass'
import { faMerge as falMerge } from '@fortawesome/pro-light-svg-icons/faMerge'
import { faMoneyCheckDollar as falMoneyCheckDollar } from '@fortawesome/pro-light-svg-icons/faMoneyCheckDollar'
import { faPenToSquare as falPenToSquare } from '@fortawesome/pro-light-svg-icons/faPenToSquare'
import { faPhone as falPhone } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faSplit as falSplit } from '@fortawesome/pro-light-svg-icons/faSplit'
import { faTableList as farTableList } from '@fortawesome/pro-regular-svg-icons/faTableList'
import { faTableList as fasTableList } from '@fortawesome/pro-solid-svg-icons/faTableList'
import { faTrash as farTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faUser as falUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faUserGroup as falUserGroup } from '@fortawesome/pro-light-svg-icons/faUserGroup'
import { faUserPen as falUserPen } from '@fortawesome/pro-light-svg-icons/faUserPen'
import { faUserPlus as farUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus'
import { faUsers as falUsers } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faXmark as farXmark } from '@fortawesome/pro-regular-svg-icons/faXmark'

library.add(
  farAngleLeft,
  farAngleRight,
  farArrowsRotate,
  falArrowsFromLine,
  falArrowsToLine,
  farBars,
  falCalculator,
  fasCalendarDay,
  fasCircleDollar,
  falCircleInfo,
  fasCircleInfo,
  fasCheck,
  fasCloudArrowUp,
  farCompress,
  farEllipsisVertical,
  falEnvelope,
  farExpand,
  fasFile,
  falFileArrowDown,
  falFileContract,
  fasFileContract,
  farFileExport,
  fasFileImage,
  farFileImport,
  falFileInvoiceDollar,
  fasFileInvoiceDollar,
  falFileLines,
  fasFileLines,
  falFileMedical,
  fasFileMedical,
  fasFilePlus,
  fasFileSpreadsheet,
  fasFiles,
  fasFileWord,
  falFloppyDisk,
  fasInfo,
  falLock,
  farMagnifyingGlass,
  fasMagnifyingGlass,
  falMerge,
  falMoneyCheckDollar,
  falPenToSquare,
  falPhone,
  fasPlus,
  falSplit,
  farTableList,
  fasTableList,
  farTrash,
  falUser,
  fasUser,
  falUserGroup,
  falUserPen,
  farUserPlus,
  falUsers,
  farXmark,
)
