import { faCircleUser } from '@fortawesome/pro-solid-svg-icons/faCircleUser'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Image } from 'expo-image'
import { StyleSheet, View, ViewProps } from 'react-native'
import { colorWithOpacity, useTheme } from '../hooks/useTheme'
import { Text } from './Text'

type Props = ViewProps & {
  source?: string
  size?: number
  placeholderName?: string
  placeholderColor?: string
}

export function Avatar({ source, size = 44, placeholderName, placeholderColor: propPlaceholderColor, ...props }: Props) {
  const theme = useTheme()

  const placeholderColor = propPlaceholderColor ?? colorWithOpacity(theme.colors.foreground, 0.3)

  const styles = StyleSheet.create({
    wrapper: {
      position: 'relative',
      width: size,
      height: size,
      borderRadius: size / 2,
      overflow: 'hidden',
    },
    image: {
      position: 'absolute',
      width: size,
      height: size,
      borderRadius: size / 2,
    },
    placeholder: {
      position: 'absolute',
    },
    placeholderNameWrapper: {
      position: 'absolute',
      width: size,
      height: size,
      backgroundColor: theme.colors.transparencyBase,
    },
    placeholderName: {
      flex: 1,
      backgroundColor: placeholderColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    placeholderNameText: {
      fontFamily: theme.fonts.body[600],
      fontSize: size / 2.5,
      color: theme.colors.background,
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
  })

  return (
    <View {...props} style={[styles.wrapper, props.style]}>
      <FontAwesomeIcon
        icon={faCircleUser}
        size={size}
        color={placeholderColor}
        style={styles.placeholder}
      />
      {source ? (
        <Image source={source} style={styles.image} />
      ) : placeholderName ? (
        <View style={styles.placeholderNameWrapper}>
          <View style={styles.placeholderName}>
            <Text style={styles.placeholderNameText}>{placeholderName.split(' ').map((w) => w[0])}</Text>
          </View>
        </View>
      ) : null}
    </View>
  )
}
