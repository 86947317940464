import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { AlertOptions, ModalOptions } from 'ui'
import { create } from 'zustand'
import { AccidentFilter, AccidentFilterSchema, BatchFilter, BatchFilterSchema, StaffDashboardFilter } from '@/types/filter'

export const defaultAccidentFilter = AccidentFilterSchema.parse({})
export const defaultBatchFilter = BatchFilterSchema.parse({})

type Store = {
  modal: {
    active: ModalOptions | undefined
    open: (modal: ModalOptions) => void
    close: () => void
  }
  alert: {
    active: AlertOptions | undefined
    open: (alert: AlertOptions) => void
    close: () => void
  }
  accidentFilter: AccidentFilter
  setAccidentFilter: (filter: AccidentFilter) => void
  clearAccidentFilter: (filter?: AccidentFilter) => AccidentFilter
  batchFilter: BatchFilter
  setBatchFilter: (filter: BatchFilter) => void
  clearBatchFilter: (filter?: BatchFilter) => BatchFilter
  staffDashboardWeekOffset: number
  setStaffDashboardWeekOffset: (offset: number) => void
  staffDashboardFilter: StaffDashboardFilter
  setStaffDashboardFilter: (filter: StaffDashboardFilter) => void
}

const useStoreBase = create<Store>()((set) => ({
  modal: {
    active: undefined,
    open: (modal) => set((state) => ({ modal: { ...state.modal, active: modal } })),
    close: () => set((state) => ({ modal: { ...state.modal, active: undefined } })),
  },
  alert: {
    active: undefined,
    open: (alert) => set((state) => ({ alert: { ...state.alert, active: alert } })),
    close: () => set((state) => ({ alert: { ...state.alert, active: undefined } })),
  },
  accidentFilter: defaultAccidentFilter,
  setAccidentFilter: (filter) => set(() => ({ accidentFilter: filter })),
  clearAccidentFilter: (filter) => {
    const cleared: AccidentFilter = {
      ...defaultAccidentFilter,
      orderBy: filter?.orderBy ?? defaultAccidentFilter.orderBy,
      sortOrder: filter?.sortOrder ?? defaultAccidentFilter.sortOrder,
    }
    set(() => ({ accidentFilter: cleared }))
    return cleared
  },
  batchFilter: defaultBatchFilter,
  setBatchFilter: (filter) => set(() => ({ batchFilter: filter })),
  clearBatchFilter: (filter) => {
    const cleared: BatchFilter = {
      ...defaultBatchFilter,
      orderBy: filter?.orderBy ?? defaultBatchFilter.orderBy,
      sortOrder: filter?.sortOrder ?? defaultBatchFilter.sortOrder,
    }
    set(() => ({ batchFilter: cleared }))
    return cleared
  },
  staffDashboardWeekOffset: 0,
  setStaffDashboardWeekOffset: (offset) => set(() => ({ staffDashboardWeekOffset: offset })),
  staffDashboardFilter: 'highPriority',
  setStaffDashboardFilter: (filter) => set(() => ({ staffDashboardFilter: filter })),
}))

export const useStore = createSelectorHooks(useStoreBase)
